// FileStorageImplement.js
import moment from "moment";
import {
  handler401ApiService,
  handlerTimeOutApiService,
} from "@/utils/ApiService";
import { handlerServerErrorAxios } from "@/services/internalService";

moment.locale("id");

const ENDPOINT = "/school/data/klasdrive";

export default class FileStorageImplement {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async studentGetIjazahInSchool(uuid) {
    try {
      //return await this.apiService.get(`http://localhost:3000/api/error401`, { params });
      return await this.apiService.post(
        `school/data/klasdrive/collect/${uuid}`,
        {},
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.studentGetIjazahInSchool:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async fileStoragePkks(data) {
    try {
      const params = this.prepareParamsPkks(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStoragePkks.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/datases200`, { params });
      return await this.apiService.get(`${ENDPOINT}-pkks`, { params });
    } catch (error) {
      console.error("Error in FileStorageImplement.fileStoragePkks:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async fileStoragePkksRecyclebin(data) {
    try {
      const params = this.prepareParamsPkksRecyclebin(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStoragePkksRecyclebin.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/datases200`, { params });
      return await this.apiService.get(`${ENDPOINT}-pkks/recyclebin`, {
        params,
      });
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.fileStoragePkksRecyclebin:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async addFileStorage({ file, original_size, name, category, year }) {
    try {
      if (
        !file ||
        !original_size ||
        !name ||
        !category ||
        year === undefined ||
        isNaN(parseInt(year, 10))
      ) {
        throw new Error("Invalid FormData for addFileStorage.");
      }
      const formData = new FormData();
      formData.append("_method", "post");
      formData.append("file", file);
      formData.append("original_size", original_size);
      formData.append("name", name);
      formData.append("year", year);
      formData.append("category", category.toUpperCase());
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(`${ENDPOINT}-pkks`, formData, config);
    } catch (error) {
      console.error("Error in FileStorageImplement.addFileStorage:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async updateNameFileStorage(klasdrive_id, { name, category }) {
    try {
      if (!name || !category || !klasdrive_id) {
        throw new Error("Invalid FormData for updateNameFileStorage.");
      }
      const formData = {
        name,
        category,
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.put(
        `${ENDPOINT}-pkks/${klasdrive_id}`,
        formData,
      );
    } catch (error) {
      console.error("Error in FileStorageImplement.addFileStorage:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async restoreFileStoragePkks(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for restoreFileStoragePkks.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(
        `${ENDPOINT}-pkks/restore/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.restoreFileStoragePkks:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async archiveFileStoragePkks(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for archiveFileStorage.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(`${ENDPOINT}-pkks/${klasdrive_id}`);
    } catch (error) {
      console.error("Error in FileStorageImplement.addFileStorage:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async deletePermanenFileStoragePkks(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for deletePermanenFileStoragePkks.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(
        `${ENDPOINT}-pkks/forcedelete/${klasdrive_id}`,
      );
    } catch (error) {
      console.error("Error in FileStorageImplement.addFileStorage:", error);
      throw error;
    }
  }

  async fileStorageInovasi(data) {
    try {
      const params = this.prepareParamsPkks(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStoragePkks.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/klasdrive/error500`, { params });
      return await this.apiService.get(`${ENDPOINT}-inovation`, { params });
    } catch (error) {
      console.error("Error in FileStorageImplement.fileStorageInovasi:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async fileStorageInovasiRecyclebin(data) {
    try {
      const params = this.prepareParamsPkksRecyclebin(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStoragePkksRecyclebin.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/klasdrive/bin/datases200`, { params });
      return await this.apiService.get(`${ENDPOINT}-inovation/recyclebin`, {
        params,
      });
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.fileStorageInovasiRecyclebin:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async addFileStorageInovasi({ file, original_size, name, category, year }) {
    try {
      if (
        !file ||
        !original_size ||
        !name ||
        !category ||
        year === undefined ||
        isNaN(parseInt(year, 10))
      ) {
        throw new Error("Invalid FormData for addFileStorageInovasi.");
      }
      const formData = new FormData();
      formData.append("_method", "post");
      formData.append("file", file);
      formData.append("original_size", original_size);
      formData.append("name", name);
      formData.append("year", year);
      formData.append("category", category.toUpperCase());
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(
        `${ENDPOINT}-inovation`,
        formData,
        config,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.addFileStorageInovasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async updateNameFileStorageInovasi(klasdrive_id, { name, category }) {
    try {
      if (!name || !category || !klasdrive_id) {
        throw new Error("Invalid FormData for updateNameFileStorageInovasi.");
      }
      const formData = {
        name,
        category,
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.put(
        `${ENDPOINT}-inovation/${klasdrive_id}`,
        formData,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.updateNameFileStorageInovasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async restoreFileStorageInovasi(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for restoreFileStorageInovasi.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(
        `${ENDPOINT}-inovation/restore/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.restoreFileStorageInovasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async archiveFileStorageInovasi(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for archiveFileStorageInovasi.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(
        `${ENDPOINT}-inovation/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.archiveFileStorageInovasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async deletePermanenFileStorageInovasi(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error(
          "Invalid FormData for deletePermanenFileStorageInovasi.",
        );
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(
        `${ENDPOINT}-inovation/forcedelete/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.deletePermanenFileStorageInovasi:",
        error,
      );
      throw error;
    }
  }

  // ======== kriteria kelulusan
  async fileStorageGraduation(data) {
    try {
      const params = this.prepareParamsGraduation(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStorageGraduation.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/klasdrive/error500`, { params });
      return await this.apiService.get(`${ENDPOINT}-graduation`, { params });
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.fileStorageGraduation:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async fileStorageGraduationRecyclebin(data) {
    try {
      const params = this.prepareParamsGraduationRecyclebin(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStoragePkksRecyclebin.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/klasdrive/bin/datases200`, { params });
      return await this.apiService.get(`${ENDPOINT}-graduation/recyclebin`, {
        params,
      });
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.fileStorageGraduationRecyclebin:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async addFileStorageGraduation({
    file,
    original_size,
    name,
    category,
    year,
  }) {
    try {
      if (
        !file ||
        !original_size ||
        !name ||
        !category ||
        year === undefined ||
        isNaN(parseInt(year, 10))
      ) {
        throw new Error("Invalid FormData for addFileStorageGraduation.");
      }
      const formData = new FormData();
      formData.append("_method", "post");
      formData.append("file", file);
      formData.append("original_size", original_size);
      formData.append("name", name);
      formData.append("year", year);
      formData.append("category", category.toUpperCase());
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(
        `${ENDPOINT}-graduation`,
        formData,
        config,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.addFileStorageGraduation:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async updateNameFileStorageGraduation(klasdrive_id, { name, category }) {
    try {
      if (!name || !category || !klasdrive_id) {
        throw new Error(
          "Invalid FormData for updateNameFileStorageGraduation.",
        );
      }
      const formData = {
        name,
        category,
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.put(
        `${ENDPOINT}-graduation/${klasdrive_id}`,
        formData,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.updateNameFileStorageGraduation:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async restoreFileStorageGraduation(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for restoreFileStorageGraduation.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(
        `${ENDPOINT}-graduation/restore/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.restoreFileStorageGraduation:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async archiveFileStorageGraduation(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for archiveFileStorageGraduation.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(
        `${ENDPOINT}-graduation/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.archiveFileStorageGraduation:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async deletePermanenFileStorageGraduation(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error(
          "Invalid FormData for deletePermanenFileStorageGraduation.",
        );
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(
        `${ENDPOINT}-graduation/forcedelete/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.deletePermanenFileStorageGraduation:",
        error,
      );
      throw error;
    }
  }
  // ======== kriteria kelulusan

  // ======== SNPMB
  async fileStorageSNPMB(data) {
    try {
      const params = this.prepareParamsSNPMB(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStorageSNPMB.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/klasdrive/error500`, { params });
      return await this.apiService.get(`${ENDPOINT}-snpmb`, { params });
    } catch (error) {
      console.error("Error in FileStorageImplement.fileStorageSNPMB:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async fileStorageSNPMBRecyclebin(data) {
    try {
      const params = this.prepareParamsSNPMBRecyclebin(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStoragePkksRecyclebin.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/klasdrive/bin/datases200`, { params });
      return await this.apiService.get(`${ENDPOINT}-snpmb/recyclebin`, {
        params,
      });
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.fileStorageSNPMBRecyclebin:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async addFileStorageSNPMB({ file, original_size, name, category, year }) {
    try {
      if (
        !file ||
        !original_size ||
        !name ||
        !category ||
        year === undefined ||
        isNaN(parseInt(year, 10))
      ) {
        throw new Error("Invalid FormData for addFileStorageSNPMB.");
      }
      const formData = new FormData();
      formData.append("_method", "post");
      formData.append("file", file);
      formData.append("original_size", original_size);
      formData.append("name", name);
      formData.append("year", year);
      formData.append("category", category.toUpperCase());
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(`${ENDPOINT}-snpmb`, formData, config);
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.addFileStorageSNPMB:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async updateNameFileStorageSNPMB(klasdrive_id, { name, category }) {
    try {
      if (!name || !category || !klasdrive_id) {
        throw new Error("Invalid FormData for updateNameFileStorageSNPMB.");
      }
      const formData = {
        name,
        category,
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.put(
        `${ENDPOINT}-snpmb/${klasdrive_id}`,
        formData,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.updateNameFileStorageSNPMB:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async restoreFileStorageSNPMB(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for restoreFileStorageSNPMB.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(
        `${ENDPOINT}-snpmb/restore/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.restoreFileStorageSNPMB:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async archiveFileStorageSNPMB(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for archiveFileStorageSNPMB.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(`${ENDPOINT}-snpmb/${klasdrive_id}`);
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.archiveFileStorageSNPMB:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async deletePermanenFileStorageSNPMB(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error("Invalid FormData for deletePermanenFileStorageSNPMB.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(
        `${ENDPOINT}-snpmb/forcedelete/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.deletePermanenFileStorageSNPMB:",
        error,
      );
      throw error;
    }
  }

  prepareParamsSNPMB(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama employee
    }

    if (data.year) {
      // Check if data.page is a numeric value
      if (!isNaN(data.year) && data.year !== 0) {
        params.year = parseInt(data.year);
      } else {
        // Handle the case where data.page is not a number
        throw new Error("Error: 'year' must be a numeric value");
      }
    }
    return params;
  }

  prepareParamsSNPMBRecyclebin(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    return params;
  }
  // ======== SNPMB

  async fileStoragePenghargaanPrestasi(data) {
    try {
      const params = this.prepareParamsPkks(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStoragePkks.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/klasdrive/datases200`, { params });
      return await this.apiService.get(`${ENDPOINT}-prestasi`, { params });
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.fileStoragePenghargaanPrestasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async fileStoragePenghargaanPrestasiRecyclebin(data) {
    try {
      const params = this.prepareParamsPkksRecyclebin(data);
      if (!params) {
        throw new Error("Invalid parameters for fileStoragePkksRecyclebin.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/klasdrive/bin/datases200`, { params });
      return await this.apiService.get(`${ENDPOINT}-prestasi/recyclebin`, {
        params,
      });
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.fileStoragePenghargaanPrestasiRecyclebin:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async addFileStoragePenghargaanPrestasi({
    file,
    original_size,
    name,
    category,
    year,
  }) {
    try {
      if (
        !file ||
        !original_size ||
        !name ||
        !category ||
        year === undefined ||
        isNaN(parseInt(year, 10))
      ) {
        throw new Error(
          "Invalid FormData for addFileStoragePenghargaanPrestasi.",
        );
      }
      const formData = new FormData();
      formData.append("_method", "post");
      formData.append("file", file);
      formData.append("original_size", original_size);
      formData.append("name", name);
      formData.append("year", year);
      formData.append("category", category.toUpperCase());
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(
        `${ENDPOINT}-prestasi`,
        formData,
        config,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.addFileStoragePenghargaanPrestasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async updateNameFileStoragePenghargaanPrestasi(
    klasdrive_id,
    { name, category },
  ) {
    try {
      if (!name || !category || !klasdrive_id) {
        throw new Error(
          "Invalid FormData for updateNameFileStoragePenghargaanPrestasi.",
        );
      }
      const formData = {
        name,
        category,
      };
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.put(
        `${ENDPOINT}-prestasi/${klasdrive_id}`,
        formData,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.updateNameFileStoragePenghargaanPrestasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async restoreFileStoragePenghargaanPrestasi(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error(
          "Invalid FormData for restoreFileStoragePenghargaanPrestasi.",
        );
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.post(
        `${ENDPOINT}-prestasi/restore/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.restoreFileStoragePenghargaanPrestasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async archiveFileStoragePenghargaanPrestasi(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error(
          "Invalid FormData for archiveFileStoragePenghargaanPrestasi.",
        );
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(
        `${ENDPOINT}-prestasi/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.archiveFileStoragePenghargaanPrestasi:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async deletePermanenFileStoragePenghargaanPrestasi(klasdrive_id) {
    try {
      if (!klasdrive_id) {
        throw new Error(
          "Invalid FormData for deletePermanenFileStoragePenghargaanPrestasi.",
        );
      }
      //return await this.apiService.get(`http://localhost:3000/api/create201`);
      return await this.apiService.delete(
        `${ENDPOINT}-prestasi/forcedelete/${klasdrive_id}`,
      );
    } catch (error) {
      console.error(
        "Error in FileStorageImplement.deletePermanenFileStoragePenghargaanPrestasi:",
        error,
      );
      throw error;
    }
  }

  truncateString(value, key) {
    const max = {
      purpose: 100,
      subject: 240,
    };
    if (!value) {
      return "";
    }
    return value.length > max[key] ? value.substring(0, max[key]) : value;
  }

  prepareParamsPkks(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama employee
    }

    if (data.year) {
      // Check if data.page is a numeric value
      if (!isNaN(data.year) && data.year !== 0) {
        params.year = parseInt(data.year);
      } else {
        // Handle the case where data.page is not a number
        throw new Error("Error: 'year' must be a numeric value");
      }
    }
    return params;
  }

  prepareParamsGraduation(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama employee
    }

    if (data.year) {
      // Check if data.page is a numeric value
      if (!isNaN(data.year) && data.year !== 0) {
        params.year = parseInt(data.year);
      } else {
        // Handle the case where data.page is not a number
        throw new Error("Error: 'year' must be a numeric value");
      }
    }
    return params;
  }

  prepareParamsPkksRecyclebin(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    return params;
  }

  prepareParamsGraduationRecyclebin(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    return params;
  }

  validatePage({ page }, params) {
    if (page) {
      if (!isNaN(page) && page !== 0) {
        params.page = parseInt(page);
      } else {
        throw new Error("Error: 'page' must be a numeric value");
      }
    }
  }

  validateLimit({ limit }, params) {
    const maxLimit = 500;
    if (limit) {
      const numericLimit = parseInt(limit, 10);
      if (isNaN(numericLimit) || numericLimit > maxLimit) {
        throw new Error(
          `Invalid limit value: '${limit}'. The limit must be a number and cannot exceed ${maxLimit}.`,
        );
      }
      params.limit = numericLimit;
    }
  }

  validateSortField(
    { sort_field },
    params,
    validSortFields = ["name", "created_at", "agenda_number"],
  ) {
    if (sort_field && !validSortFields.includes(sort_field)) {
      throw new Error(
        `Invalid sort_field value: '${sort_field}'. Valid values are ${validSortFields.join(
          ", ",
        )}.`,
      );
    }
    if (sort_field) {
      params.sort_field = sort_field;
    }
  }

  validateSortDir({ sort_dir }, params) {
    if (typeof sort_dir === "boolean") {
      params.sort_dir = sort_dir ? "desc" : "asc";
    } else {
      const validSortDirections = ["asc", "desc"];
      if (sort_dir && !validSortDirections.includes(sort_dir)) {
        throw new Error(
          `Invalid sort_dir value: '${sort_dir}'. Valid values are ${validSortDirections.join(
            ", ",
          )}.`,
        );
      }
      if (sort_dir) {
        params.sort_dir = sort_dir;
      }
    }
  }

  validateType({ type }, params) {
    const validTypes = ["sd", "smp", "sma", "smk", "slb"];
    if (type && !validTypes.includes(type.toLowerCase())) {
      throw new Error(
        `Invalid type value: '${type}'. Valid values are ${validTypes.join(
          ", ",
        )}.`,
      );
    }
    params.type = type && type.toUpperCase();
  }
}
