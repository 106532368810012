import { ApiPostRequest, ApiPutRequest, ApiGetRequest } from "@/utils/Api";
import {
  GlobalHandleErrorApi,
  HandlerNotification,
  HandlerPageStartEnd,
  HandlerResult,
} from "@/utils/Helpers";

const state = {
  data: {
    columns: [
      {
        prop: "date",
        label: "Tutup Buku",
        "class-name": "body-14 col-black-2",
      },
    ],
    formData: {},
    formDataDetail: {},
    items: [],
    itemsAll: [],
    filter: "",
    errors: [],
    limit: 0,
    page: 1,
    total: 0,
    creator: null,
    updater: null,
    sort_field: "",
    sort_dir: "",
    currentStatus: "active",
    processAction: "read",
    isProcess: false,
    showmodalAddForm: false,
    loading: false,
  },
};

const mutations = {
  changeKlasKonselingAutoGenerate(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKlasKonselingAutoGenerateFormData(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeKlasKonselingAutoGenerateFormDataDetail(state, payload) {
    state.data.formDataDetail = Object.assign(
      {},
      state.data.formDataDetail,
      payload,
    );
  },
};

const actions = {
  handlerLoading({ commit }, payload = null) {
    const property = {};
    property.loading = payload === null || payload ? true : false;
    commit("changeKlasKonselingAutoGenerate", property);
  },
  async getDataAutoGenerate({ commit, dispatch, state }, payload) {
    dispatch("handlerLoading");
    const params = {};
    const { data } = state;
    params.limit = payload ? payload.limit ?? data.limit : data.limit;
    let resPage = HandlerPageStartEnd(data.page, data.items.length, data.limit);
    if (data.processAction === "update") {
      resPage = data.page;
    }
    params.page = payload ? payload.page || resPage : resPage;
    //const result = await import("@/db/AutoGenerate.json");
    const result = await ApiGetRequest(`klaskonseling/setting`, params);
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    commit("changeKlasKonselingAutoGenerate", {
      loading: false,
    });
    HandlerResult(result, `Auto Generate`);
    if (payload && payload.itemsAll) {
      await commit("changeKlasKonselingAutoGenerate", {
        itemsAll: result.data.data,
        total: result.data.data.length,
      });
      // dispatch("postDataAutoGenerate");
    } else {
      commit("changeKlasKonselingAutoGenerate", {
        items: result.data.data,
        page: (result.data.meta && result.data.meta.current_page) || 1,
        total:
          (result.data.meta && result.data.meta.total) ||
          result.data.data.length,
      });
    }
  },
  async postDataAutoGenerate({ dispatch }, payload) {
    let result;
    const formDataRequest = {
      type: payload.type,
      status: payload.status,
    };
    dispatch("handlerLoading");
    if (payload && payload.id) {
      result = await ApiPutRequest(
        `klaskonseling/setting/${payload.id}`,
        formDataRequest,
      );
    } else {
      result = await ApiPostRequest(`klaskonseling/setting`, formDataRequest);
    }
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return false;
      }
      if (result.errors) {
        let resError = GlobalHandleErrorApi(result.errors, "string");
        HandlerNotification("error", resError);
        return false;
      }
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerNotification();
    dispatch("handlerLoading", false);
    return true;
  },
  changePage({ commit, dispatch }, payload) {
    commit("changeKlasKonselingAutoGenerate", { page: payload ?? 1 });
    dispatch("getDataAutoGenerate");
  },
  handlerOnCloseModal({ commit }) {
    commit("changeKlasKonselingAutoGenerate", {
      showmodalAddForm: false,
      processAction: `read`,
    });
    commit("changeKlasKonselingAutoGenerateFormDataDetail", {
      id: "",
    });
  },
  resetFormData({ commit } = null) {
    commit("changeKlasKonselingAutoGenerate", {
      formData: {},
      formDataDetail: {},
    });
  },
  validasiFormData({ state }) {
    let d = state.data.formData;
    let noValidate = 0;
    let msg = "";
    if (d.type === "") {
      noValidate++;
      msg += "Type tidak sesuai";
    }
    if (!noValidate) {
      return false;
    }
    HandlerNotification("error", msg.replace(/,\s*$/, ""));
    return true;
  },
  async filterDataAutoGenerate({ state }, payload) {
    console.log(payload);
    const { data } = state;
    let res = await data.itemsAll.filter((item) => {
      if (item.type === ((payload && payload.type) || "")) {
        return item;
      }
    });
    if (res.length && res[0].status === `active`) {
      return true;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
