import {requirePermission} from "@/router/index";

export default [
	{
		path: "/kiosk",
		name: "ControlKiosk",
		component: () =>
			import(/* webpackChunkName: "control-kosk" */ "../views/ControlKiosk"),
		meta: {
			title: "Control KiosK | ",
			allowedRole: [
				"Head Master",
				"School Admin",
			],
		},
		beforeEnter: requirePermission,
		redirect: {name: "ControlKioskDevice"},
		children: [
			{
				path: "device",
				name: "ControlKioskDevice",
				component: () =>
					import(
						/* webpackChunkName: "control-kosk-devices" */
						"../views/ControlKiosk/Devices"
						),
				meta: {
					title: "Control KiosK | ",
					allowedRole: [
						"Head Master",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "device/:deviceId/images",
				name: "ControlKioskDeviceImages",
				component: () =>
					import(
						/* webpackChunkName: "control-kosk-device-images" */
						"../views/ControlKiosk/Devices/Images/index.vue"
						),
				meta: {
					title: "Control KiosK | ",
					allowedRole: [
						"Head Master",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "device/:deviceId/videos",
				name: "ControlKioskDeviceVideos",
				component: () =>
					import(
						/* webpackChunkName: "control-kosk-device-videos" */
						"../views/ControlKiosk/Devices/Videos/index.vue"
						),
				meta: {
					title: "Control KiosK | ",
					allowedRole: [
						"Head Master",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "device/:deviceId/website",
				name: "ControlKioskDeviceWebsite",
				component: () =>
					import(
						/* webpackChunkName: "control-kosk-device-website" */
						"../views/ControlKiosk/Devices/Website/index.vue"
						),
				meta: {
					title: "Control KiosK | ",
					allowedRole: [
						"Head Master",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
		],
	},
]
