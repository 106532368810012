import {requirePermission} from "@/router/index";

export default [
	{
		path: "/kantin",
		name: "Kantin",
		component: () =>
			import(/* webpackChunkName: "kantin-digital" */ "../views/KantinDigital"),
		meta: {
			title: "Kantin | ",
			allowedRole: ["Head Master", "Cooperative"],
		},
		redirect: {name: "KoperasiKantin"},
		children: [
			{
				path: "/",
				name: "KoperasiKantin",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-kantin" */
						"../views/KantinDigital/KoperasiKantin"
						),
				meta: {
					title: "Koperasi Kantin | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "penjualan/:id",
				name: "KoperasiKantinPenjualan",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-kantin" */
						"../views/KantinDigital/KoperasiKantinPenjualan"
						),
				meta: {
					title: "Koperasi Kantin Penjualan | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "penjualan/:id/transaksi/:date",
				name: "KoperasiKantinPenjualanByDate",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-kantin" */
						"../views/KantinDigital/KoperasiKantinPenjualan"
						),
				meta: {
					title: "Penjualan Sesuai Tanggal | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "detail-transaksi-kantin/:id",
				name: "KoperasiKantinDetail",
				component: () =>
					import(
						/* webpackChunkName: "kantin-tarik-saldo" */
						"../views/KantinDigital/KoperasiKantin/DaftarKantin/DetailTransaksi"
						),
				meta: {
					title: "Koperasi Kantin | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
		],
	},
	{
		path: "/kantin/laporan/:year/:month",
		name: "KantinRekapLaporan",
		component: () =>
			import(
				/* webpackChunkName: "recap-tunggakan" */
				"../views/KantinDigital/KoperasiKantin/Penjualan/CetakLaporan.vue"
				),
		meta: {
			title: "Laporan Kantin Digital | ",
			allowedRole: ["Head Master", "Cooperative"],
		},
		beforeEnter: requirePermission,
	},
	{
		path: "/kantin/print/qr-code",
		name: "KantinPrintQrCode",
		component: () => import(
			/* webpackChunkName: "recap-tunggakan" */
			"../views/KantinDigital/KoperasiKantin/Penjualan/PrintQrCode.vue"
			),
		meta: {
			title: "Print QR-Code Kantin | ",
			allowedRole: ["Head Master", "Cooperative"],
		},
		beforeEnter: requirePermission,
	},
]
