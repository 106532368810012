import {
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
// import { Message } from "element-ui";
import {
  GlobalHandleErrorApi,
  HandlerNotification,
  HandlerPageStartEnd,
} from "@/utils/Helpers";
import storeAutoGenerate from "@/store/KlasKonseling/AutoGenerate";

const state = {
  data: {
    columns: [],
    pelanggaran: {
      violationType: [
        {
          label: "Lainnya",
          name: "Another",
          created: 99,
        },
        {
          label: "Terlambat",
          name: "Late",
          created: 1,
        },
        {
          label: "Alpha",
          name: "Alpha",
          created: 1,
        },
        {
          label: "Tidak Absen Keluar",
          name: "Not Out",
          created: 1,
        },
        {
          label: "Tidak Mengikuti Mapel",
          name: "Not Attending Class",
          created: 1,
        },
      ],
      formData: {
        violation_name: "",
        violation_score: "",
        violation_type: "Another",
      },
      columns: [
        {
          prop: "violation_name",
          label: "Jenis Pelanggaran",
          "class-name": "body-14 col-black-2",
          sortable: true,
        },
        {
          prop: "violation_type",
          label: "Tipe",
          width: "200",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "violation_score",
          label: "Poin",
          width: "130",
          "class-name": "body-14 col-black-2 text-center",
          sortable: true,
        },
        {
          prop: "soft_deleted",
          label: "Status",
          width: "100",
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          prop: "generator",
          label: "Auto Proses",
          width: "100",
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          label: "Aksi",
          width: "100",
          "class-name": "body-14 col-black-2",
        },
      ],
      status: [
        {
          label: "Aktif",
          value: "Active",
        },
        {
          label: "Nonaktif",
          value: "InActive",
        },
      ],
      violationTypeFilter: [],
      formDataDetail: {},
      items: [],
      filter: "",
      errors: [],
      limit: 10,
      page: 1,
      total: 0,
      creator: null,
      updater: null,
      sort_field: null,
      sort_dir: null,
      currentStatus: "Active",
      processAction: "read",
      isProcess: false,
      showmodalAddForm: false,
      loading: false,
    },
    prestasi: {
      formData: {
        achievement_name: "",
        achievement_score: "",
      },
      columns: [
        {
          prop: "achievement_name",
          label: "Prestasi",
          "class-name": "body-14 col-black-2",
          sortable: true,
        },
        {
          prop: "achievement_score",
          label: "Poin",
          width: "130",
          "class-name": "body-14 col-black-2 text-center",
          sortable: true,
        },
        {
          prop: "creator",
          label: "Pembuat",
          width: "160",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "updater",
          label: "Update",
          width: "160",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "soft_deleted",
          label: "Status",
          width: "100",
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          label: "Aksi",
          width: "100",
          "class-name": "body-14 col-black-2",
        },
      ],
      status: [
        {
          label: "Aktif",
          value: "Active",
        },
        {
          label: "Nonaktif",
          value: "InActive",
        },
      ],
      violationTypeFilter: [],
      formDataDetail: {},
      items: [],
      filter: "",
      errors: [],
      limit: 10,
      page: 1,
      total: 0,
      creator: null,
      updater: null,
      sort_field: null,
      sort_dir: null,
      currentStatus: "Active",
      processAction: "read",
      isProcess: false,
      showmodalAddForm: false,
      loading: false,
    },
    kamusPoinMenu: [
      {
        name: "Pelanggaran",
        active: true,
      },
      {
        name: "Prestasi",
        active: false,
      },
    ],
    tabActive: "Pelanggaran",
  },
};

const mutations = {
  changeKlaskonselingKamusPoin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changePelanggaran(state, payload) {
    state.data.pelanggaran = Object.assign({}, state.data.pelanggaran, payload);
  },
  changePelanggaranFormData(state, payload) {
    state.data.pelanggaran.formData = Object.assign(
      {},
      state.data.pelanggaran.formData,
      payload,
    );
  },
  changePelanggaranFormDataDetail(state, payload) {
    state.data.pelanggaran.formDataDetail = Object.assign(
      {},
      state.data.pelanggaran.formDataDetail,
      payload,
    );
  },
  changePrestasi(state, payload) {
    state.data.prestasi = Object.assign({}, state.data.prestasi, payload);
  },
  changePrestasiFormData(state, payload) {
    state.data.prestasi.formData = Object.assign(
      {},
      state.data.prestasi.formData,
      payload,
    );
  },
  changePrestasiFormDataDetail(state, payload) {
    state.data.prestasi.formDataDetail = Object.assign(
      {},
      state.data.prestasi.formDataDetail,
      payload,
    );
  },
};

const actions = {
  handlerLoading({ commit, state }, payload = null) {
    const property = {};
    property.loading = payload === null || payload ? true : false;
    let changeCommit =
      state.data.tabActive === "Pelanggaran"
        ? "changePelanggaran"
        : "changePrestasi";
    commit(changeCommit, property);
  },
  async getDataPelanggaran({ commit, state, dispatch }, payload = null) {
    dispatch("handlerLoading");
    const { data } = state;
    const params = {};
    params.limit = payload
      ? payload.limit || data.pelanggaran.limit
      : data.pelanggaran.limit;
    const resPage = HandlerPageStartEnd(
      data.pelanggaran.page,
      data.pelanggaran.items.length,
      data.pelanggaran.limit,
    );
    params.page = payload ? payload.page || resPage : resPage;
    params.so = data.pelanggaran.currentStatus;
    if (data.pelanggaran.filter) params.q = data.pelanggaran.filter;
    if (data.pelanggaran.creator) params.creator = data.pelanggaran.creator;
    if (data.pelanggaran.updater) params.updater = data.pelanggaran.updater;
    if (data.pelanggaran.sort_field)
      params.sort_field = data.pelanggaran.sort_field;
    if (data.pelanggaran.sort_dir) params.sort_dir = data.pelanggaran.sort_dir;
    // let reqClasses = dataClasses.length > 0 ? `&${dataClasses.join("&")}` : "";
    // `school/presence/attendance/${data.filter_date}/student?page=${data.page}&limit=${data.limit}&q=${data.search}${reqClasses}`
    const result = await ApiGetRequest(`klaskonseling/violation`, params);
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    let resDt;
    if (result.data.data.length) {
      const { itemsAll } = storeAutoGenerate.state.data;
      resDt = await result.data.data.map((item) => {
        return {
          ...item,
          auto_generator: itemsAll.filter((ag) => {
            if (ag.type === item.violation_type) {
              return ag;
            }
          }),
        };
      });
    } else {
      resDt = [];
    }
    await commit("changePelanggaran", {
      loading: false,
      items: resDt,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total: (result.data.meta && result.data.meta.total) || 0,
    });
  },
  filterDataAutoGenerate(payload) {
    const { data } = storeAutoGenerate.state;
    let res = data.itemsAll.filter((item) => {
      if (item.type === ((payload && payload.type) || "")) {
        return item;
      }
    });
    if (res.length && res[0].status === `active`) {
      return res;
    }
    return false;
  },
  async postDataPelanggaran({ commit, state, dispatch }) {
    if (await dispatch("validasiFormData")) {
      return;
    }
    dispatch("handlerLoading");
    const { data } = state;
    let result;
    const formDataRequest = {
      violation_name: data.pelanggaran.formData.violation_name,
      violation_type: data.pelanggaran.formData.violation_type,
      score: data.pelanggaran.formData.violation_score,
    };
    if (data.pelanggaran.formDataDetail.id) {
      result = await ApiPutRequest(
        `klaskonseling/violation/${data.pelanggaran.formDataDetail.id}`,
        formDataRequest,
      );
    } else {
      result = await ApiPostRequest(`klaskonseling/violation`, formDataRequest);
    }
    if (result.error) {
      dispatch("handlerLoading", false);
      if (parseInt(result.code) === 403) {
        HandlerNotification("notAkses");
        return;
      }
      if (result.errors) {
        let resError = GlobalHandleErrorApi(result.errors, "string");
        HandlerNotification("error", resError);
        return;
      }
      HandlerNotification("error", result.error);
      return;
    } else {
      HandlerNotification();
      dispatch("getDataPelanggaran");
      dispatch("resetFormData");
      dispatch("handlerLoading", false);
      commit("changePelanggaran", {
        showmodalAddForm: false,
      });
    }
  },
  async deleteDataPelanggaran({ state, dispatch }, payload) {
    const { data } = state;
    if (!data.pelanggaran.formDataDetail.id) {
      HandlerNotification("id");
      return;
    }
    dispatch("handlerLoading");
    let result;
    if (payload.type === "nonActive") {
      result = payload.jenis === "permanen" ? `/delete` : ``;
      result = await ApiDeleteRequest(
        `klaskonseling/violation/${data.pelanggaran.formDataDetail.id}${result}`,
      );
    } else {
      result = await ApiPostRequest(
        `klaskonseling/violation/${data.pelanggaran.formDataDetail.id}/restore`,
      );
    }
    if (result.error) {
      dispatch("handlerLoading", false);
      if (parseInt(result.code) === 403) {
        HandlerNotification("notAkses");
        return;
      }
      if (parseInt(result.code) === 512) {
        HandlerNotification("permanen");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    } else {
      HandlerNotification();
      dispatch("getDataPelanggaran");
      dispatch("handlerLoading", false);
    }
  },
  resetFormData({ commit }, payload = null) {
    if (!payload) {
      if (state.data.tabActive === "Pelanggaran") {
        commit("changePelanggaranFormData", {
          violation_name: "",
          violation_score: "",
          violation_type: "Another",
        });
      } else {
        commit("changePrestasiFormData", {
          achievement_name: "",
          achievement_score: "",
        });
      }
    } else {
      if (state.data.tabActive === "Pelanggaran") {
        commit("changePelanggaran", {
          formDataDetail: {},
          formData: {
            violation_name: "",
            violation_score: "",
            violation_type: "Another",
          },
        });
      } else {
        commit("changePrestasi", {
          formDataDetail: {},
          formData: {
            achievement_name: "",
            achievement_score: "",
          },
        });
      }
    }
  },
  handlerOnCloseModal({ commit, state }) {
    let changeCommit = "changePrestasi";
    if (state.data.tabActive === "Pelanggaran") {
      changeCommit = "changePelanggaran";
    }
    commit(changeCommit, {
      showmodalAddForm: false,
      processAction: `read`,
    });
    commit(`${changeCommit}FormDataDetail`, {
      id: "",
    });
  },
  validasiFormData({ state }) {
    let d =
      state.data.tabActive === "Pelanggaran"
        ? state.data.pelanggaran.formData
        : state.data.prestasi.formData;
    let noValidate = 0;
    let msg = "";

    if (state.data.tabActive === "Pelanggaran") {
      if (d.violation_score === "") {
        noValidate++;
        msg += "Score Belum Diisi, ";
      }
      if (d.violation_name === "") {
        noValidate++;
        msg += "Nama Pelanggaran Belum Diisi";
      }
    } else {
      if (d.achievement_score === "") {
        noValidate++;
        msg += "Score Belum Diisi, ";
      }
      if (d.achievement_name === "") {
        noValidate++;
        msg += "Nama Prestasi Belum Diisi";
      }
    }
    if (!noValidate) {
      return false;
    }
    HandlerNotification("error", msg.replace(/,\s*$/, ""));
    return true;
  },
  changePage({ commit, dispatch }, payload) {
    if (state.data.tabActive === "Pelanggaran") {
      commit("changePelanggaran", { page: payload ?? 1 });
      dispatch("getDataPelanggaran");
    } else {
      commit("changePrestasi", { page: payload ?? 1 });
      dispatch("getDataPrestasi");
    }
  },
  async getDataPrestasi({ commit, state, dispatch }, payload = null) {
    dispatch("handlerLoading");
    const { data } = state;
    const params = {};
    params.limit = payload
      ? payload.limit || data.prestasi.limit
      : data.prestasi.limit;
    const resPage = HandlerPageStartEnd(
      data.prestasi.page,
      data.prestasi.items.length,
      data.prestasi.limit,
    );
    params.page = payload ? payload.page || resPage : resPage;
    params.so = data.prestasi.currentStatus;
    if (data.prestasi.filter) params.q = data.prestasi.filter;
    if (data.prestasi.creator) params.creator = data.prestasi.creator;
    if (data.prestasi.updater) params.updater = data.prestasi.updater;
    if (data.prestasi.sort_field) params.sort_field = data.prestasi.sort_field;
    if (data.prestasi.sort_dir) params.sort_dir = data.prestasi.sort_dir;
    const result = await ApiGetRequest(`klaskonseling/achievement`, params);
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    } else {
      commit("changePrestasi", {
        loading: false,
        items: result.data.data,
        page: (result.data.meta && result.data.meta.current_page) || 1,
        total:
          (result.data.meta && result.data.meta.total) ||
          result.data.data.length,
      });
    }
  },
  async postDataPrestasi({ commit, state, dispatch }) {
    if (await dispatch("validasiFormData")) {
      return;
    }
    dispatch("handlerLoading");
    const { data } = state;
    let result;
    const formDataRequest = {
      achievement_name: data.prestasi.formData.achievement_name,
      achievement_type: data.prestasi.formData.achievement_type,
      score: data.prestasi.formData.achievement_score,
    };
    if (data.prestasi.formDataDetail.id) {
      result = await ApiPutRequest(
        `klaskonseling/achievement/${data.prestasi.formDataDetail.id}`,
        formDataRequest,
      );
    } else {
      result = await ApiPostRequest(
        `klaskonseling/achievement`,
        formDataRequest,
      );
    }
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      if (result.errors) {
        let resError = GlobalHandleErrorApi(result.errors, "string");
        HandlerNotification("error", resError);
        return;
      }
      HandlerNotification("error", result.error);
      return;
    } else {
      HandlerNotification();
      dispatch("getDataPrestasi");
      dispatch("resetFormData");
      dispatch("handlerLoading", false);
      commit("changePrestasi", {
        showmodalAddForm: false,
      });
    }
  },
  async deleteDataPrestasi({ state, dispatch }, payload) {
    const { data } = state;
    if (!data.prestasi.formDataDetail.id) {
      HandlerNotification("id");
      return;
    }
    dispatch("handlerLoading");
    let result;
    if (payload.type === "nonActive") {
      result = payload.jenis === "permanen" ? `/delete` : ``;
      result = await ApiDeleteRequest(
        `klaskonseling/achievement/${data.prestasi.formDataDetail.id}${result}`,
      );
    } else {
      result = await ApiPostRequest(
        `klaskonseling/achievement/${data.prestasi.formDataDetail.id}/restore`,
      );
    }
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      if (result.code === 512) {
        HandlerNotification("permanen");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    } else {
      HandlerNotification();
      dispatch("getDataPrestasi");
      dispatch("handlerLoading", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
