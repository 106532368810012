import {requirePermission} from "@/router/index";

export default [
	{
		path: "/print-mutasi-keuangan",
		name: "PrintMutasiRecap",
		component: () =>
			import(
				/* webpackChunkName: "print-mutasi-recap" */
				"../views/Keuangan/Mutasi/PrintRekapMutasi"
				),
		meta: {
			title: "Mutasi Keuangan | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/print-mutasi-keuangan/:date",
		name: "PrintMutasiRecapDay",
		component: () =>
			import(
				/* webpackChunkName: "print-mutasi-recap-day" */
				"../views/Keuangan/Mutasi/PrintRekapMutasiDay"
				),
		meta: {
			title: "Mutasi Keuangan Perhari | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/keuangan",
		name: "Keuangan",
		redirect: "/keuangan/mutasi",
		component: () =>
			import(
				/* webpackChunkName: "keuangan" */
				"../views/Keuangan"
				),
		meta: {
			title: "Keuangan | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		children: [
			{
				path: "mutasi",
				name: "KeuanganMutasi",
				component: () =>
					import(
						/* webpackChunkName: "keuangan-mutasi" */
						"../views/Keuangan/Mutasi"
						),
				meta: {
					title: "Mutasi Dana | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "penarikan",
				name: "KeuanganPenarikan",
				component: () =>
					import(
						/* webpackChunkName: "keuangan-penarikan" */
						"../views/Keuangan/Penarikan"
						),
				meta: {
					title: "Penarikan Dana | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "akun-bank",
				name: "KeuanganAkunBank",
				component: () =>
					import(
						/* webpackChunkName: "keuangan-akun-bank" */
						"../views/Keuangan/AkunBank"
						),
				meta: {
					title: "Akun Bank | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
]
