import repository from "@/repositories/UserTemporaryRepository";
import StrukturErrorService from "@/services/StrukturErrorService";

export default class UserTemporaryService {
	constructor() {
		this.dataRepository = repository;
	}

	async userTemporarySummaryGet() {
		try {
			return await this.dataRepository.getUserTemporarySummary()
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async userTemporaryGet(payload) {
		if (typeof payload !== "object") {
			throw new Error("payload not same");
		}

		const {page, limit, filterStatus, filterQ, sortField, sortDir} = payload;

		const params = {
			page: page || undefined,
			limit: limit || undefined,
			sort_field: sortField || undefined,
			sort_dir: sortDir || undefined,
			q: filterQ || undefined,
		}

		if (filterStatus !== 'all') {
			/* only 1 atau 0 */
			params.is_payment_activated = parseInt(filterStatus) ? 1 : 0
		}

		try {
			return await this.dataRepository.getUserTemporary(params);
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

}
