import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest,
  ApiNotification,
} from "@/utils/Api";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    error: false,
    loading: false,
    isPageAction: false,
    indexItem: null,
    usertimes: [],
    grouproles: [],
    rolesSelect: [],
    classesSelect: [],
    perananSelect: [],
    userSelect: [],
    indexModal: 0,
    roles: [],
    classrooms: [],
    users: [],
    searchUser: "",
    limit: 10,
    page: 1,
    total: 0,
    items_workgroup: [],
    typeTab: "staff",
    notFilter: {},
    cart_custom_users: [],
    detailId: {},
    detail: {
      custom_users: [],
      groupselected: [],
      roles: [],
    },
    rowuser: {
      name: "",
      custom_users: [],
      roles: [],
      times: [],
    },
    timesDefault: [
      {
        day: 1,
        check: false,
        label: "Senin",
        start_at: "",
        end_at: "",
      },
      {
        day: 2,
        check: false,
        label: "Selasa",
        start_at: "",
        end_at: "",
      },
      {
        day: 3,
        check: false,
        label: "Rabu",
        start_at: "",
        end_at: "",
      },
      {
        day: 4,
        check: false,
        label: "Kamis",
        start_at: "",
        end_at: "",
      },
      {
        day: 5,
        check: false,
        label: "Jumat",
        start_at: "",
        end_at: "",
      },
      {
        day: 6,
        check: false,
        label: "Sabtu",
        start_at: "",
        end_at: "",
      },
      {
        day: 7,
        check: false,
        label: "Minggu",
        start_at: "",
        end_at: "",
      },
    ],
    column_choose_user: [
      {
        prop: "select",
        label: "Check",
        type: "selection",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nis_nik",
        label: "NIK / NUPTK",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama Lengkap",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "role",
        label: "Profesi",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "peranan",
        label: "Jabatan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
  },
};

const mutations = {
  changeJam(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeJamKeyDetail(state, payload) {
    state.data.detail = Object.assign({}, state.data.detail, payload);
  },
};

const actions = {
  async getListWorkGroup({ commit, state }) {
    commit("changeJam", {
      loading: true,
      error: false,
    });

    const { data } = state;

    let params = {
      limit: data.limit,
    };

    const getResult = await ApiGetRequest(`school/presence/workgroup`, params);
    if (getResult.error) {
      commit("changeJam", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      commit("changeJam", {
        loading: false,
        usertimes: [],
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
    } else {
      const { data } = state;
      const dataItems = getResult.data.data;
      data.items_workgroup = getResult.data;

      // if (dataItems.length === 0) {
      //   dataItems.push(data.rowuser);
      // }

      dataItems.map(async (item, index) => {
        let defaultJam = JSON.stringify(data.timesDefault);
        defaultJam = JSON.parse(defaultJam);
        if (item.times && Object.keys(item.times).length === 0) {
          dataItems[index]["timesForm"] = defaultJam;
        } else {
          Object.keys(item.times).map((itemTimes) => {
            const checkHari = defaultJam.findIndex(
              (itemDefault) =>
                parseInt(itemDefault.day) === parseInt(itemTimes),
            );

            if (checkHari !== -1) {
              const dataTimes = item.times[itemTimes];
              defaultJam[checkHari].check = true;
              defaultJam[checkHari].start_at =
                dataTimes.in && dataTimes.in.value;
              defaultJam[checkHari].end_at =
                dataTimes.out && dataTimes.out.value;
            }
          });

          dataItems[index]["timesForm"] = defaultJam;
        }
      });

      commit("changeJam", {
        loading: false,
        usertimes: dataItems,
      });
    }
  },
  async getDetailWorkGroup({ commit, state }, payload = null) {
    commit("changeJam", {
      loading: true,
    });

    const getResult = await ApiGetRequest(
      `school/presence/workgroup/${payload.id}`,
    );
    if (getResult.error) {
      commit("changeJam", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
    } else {
      const { data } = state;
      const dataItems = getResult.data.data || {};

      let defaultJam = JSON.stringify(data.timesDefault);
      defaultJam = JSON.parse(defaultJam);
      if (dataItems.times && Object.keys(dataItems.times).length === 0) {
        dataItems["timesForm"] = defaultJam;
      } else {
        Object.keys(dataItems.times).map((itemTimes) => {
          const checkHari = defaultJam.findIndex(
            (itemDefault) => parseInt(itemDefault.day) === parseInt(itemTimes),
          );

          if (checkHari !== -1) {
            const dataTimes = dataItems.times[itemTimes];
            defaultJam[checkHari].check = true;
            defaultJam[checkHari].start_at = dataTimes.in && dataTimes.in.value;
            defaultJam[checkHari].end_at = dataTimes.out && dataTimes.out.value;
          }
        });

        dataItems["timesForm"] = defaultJam;
      }

      commit("changeJam", {
        loading: false,
        detailId: payload.id,
        detail: dataItems,
        notFilter: {},
      });
    }
  },
  async createWorkGroup({ commit, state }, idsave = null) {
    commit("changeJam", {
      loading: true,
    });

    const { data } = state;

    const usertimes = data.usertimes[idsave];
    const newTimes = usertimes.timesForm.filter((item) => item.check === true);

    const dataRoles = [];
    if (usertimes.roles) {
      usertimes.roles.map((item) => {
        if (!item.deleted) {
          dataRoles.push(item.id);
        }
      });
    }

    const dataUser = [];
    if (usertimes.custom_users) {
      usertimes.custom_users.map((item) => {
        if (!item.deleted) {
          dataUser.push(item.id);
        }
      });
    }

    const payload = {
      name: usertimes.name,
      times: newTimes,
      roles: dataRoles.length ? dataRoles : null,
      custom_users: dataUser.length > 0 ? dataUser : null,
    };

    if (usertimes.id && usertimes.id > 0) {
      const getResult = await ApiPutRequest(
        `school/presence/workgroup/${usertimes.id}`,
        payload,
      );

      if (getResult.error) {
        commit("changeJam", {
          loading: false,
        });
        if (getResult.code >= 500) {
          ApiNotification("error", SERVER_ERROR_MESSAGE);
          return false;
        }
        commit("changeJam", {
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal mengubah data pengaturan jam");
      } else {
        commit("changeJam", {
          loading: false,
          error: false,
        });

        ApiNotification("success", "Data pengaturan jam berhasil diubah");
      }
    } else {
      const getResult = await ApiPostRequest(
        `school/presence/workgroup`,
        payload,
      );

      if (getResult.error) {
        commit("changeJam", {
          loading: false,
        });
        if (getResult.code >= 500) {
          ApiNotification("error", SERVER_ERROR_MESSAGE);
          return false;
        }
        commit("changeJam", {
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal mengubah pengaturan jam");
      } else {
        commit("changeJam", {
          loading: false,
          error: false,
        });

        ApiNotification("success", "Data pengaturan jam berhasil ditambahkan");
      }
    }
  },
  async updateUsersWorkgroup({ commit, state }) {
    commit("changeJam", {
      loading: true,
      error: false,
    });

    const { data } = state;

    const usertimes = data.detail;
    const newTimes = usertimes.timesForm.filter((item) => item.check === true);

    const dataRoles = [];
    if (usertimes.roles) {
      usertimes.roles.map((item) => {
        if (!item.deleted) {
          dataRoles.push(item.id);
        }
      });
    }

    const dataUser = [];
    if (usertimes.custom_users) {
      usertimes.custom_users.map((item) => {
        if (!item.deleted) {
          dataUser.push(item.id);
        }
      });
    }

    const payload = {
      name: usertimes.name,
      times: newTimes,
      roles: dataRoles.length ? dataRoles : null,
      custom_users: dataUser.length > 0 ? dataUser : null,
    };

    const getResult = await ApiPutRequest(
      `school/presence/workgroup/${data.detailId}`,
      payload,
    );

    if (getResult.error) {
      commit("changeJam", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      commit("changeJam", {
        error: getResult.errorList || false,
      });

      ApiNotification("error", "Gagal mengubah Pengaturan Anggota");
      return false;
    } else {
      commit("changeJam", {
        loading: false,
        error: false,
      });

      ApiNotification("success", "Pengaturan Anggota Berhasil Diubah");
      return true;
    }
  },
  async deleteWorkGroup({ commit }, id = null) {
    commit("changeJam", {
      loading: true,
    });

    const getResult = await ApiDeleteRequest(
      `school/presence/workgroup/${id || ""}`,
    );

    if (getResult.error) {
      commit("changeJam", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menghapus data jam staff");
    } else {
      commit("changeJam", {
        loading: false,
      });

      ApiNotification("success", "Data berhasil dihapus");
    }
  },
  async getListClassGroup({ commit, state }) {
    commit("changeJam", {
      loading: true,
      error: false,
    });

    const getResult = await ApiGetRequest(`school/presence/classgroup`);
    //const getResult = await ApiGetRequest(`http://localhost:3000/api/error500`);
    commit("changeJam", {
      loading: false,
    });

    if (getResult.error) {
      commit("changeJam", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      commit("changeJam", {
        usertimes: [],
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan data jam pelajar");
      return false;
    }
    const { data } = state;
    const dataItems = getResult.data.data || [];

    // if (dataItems.length === 0) {
    //   dataItems.push(data.rowuser);
    // }

    dataItems.map(async (item, index) => {
      let defaultJam = JSON.stringify(data.timesDefault);
      defaultJam = JSON.parse(defaultJam);

      const dataTimes = {};
      if (item.settings) {
        await item.settings.map((iSetting) => {
          dataTimes[iSetting.day] = iSetting.times;
          dataItems[index].name =
            iSetting.times && iSetting.times.in && iSetting.times.in.label;
          dataItems[index].classes =
            iSetting.times && iSetting.times.in && iSetting.times.in.classes;
        });

        if (Object.keys(dataTimes).length > 0) {
          dataItems[index].times = dataTimes;
        }
      }

      Object.keys(item.times).map((itemTimes) => {
        const checkHari = defaultJam.findIndex(
          (itemDefault) => parseInt(itemDefault.day) === parseInt(itemTimes),
        );

        if (checkHari !== -1) {
          const dataTimes = item.times[itemTimes];
          defaultJam[checkHari].check = true;
          defaultJam[checkHari].start_at = dataTimes.in && dataTimes.in.value;
          defaultJam[checkHari].end_at = dataTimes.out && dataTimes.out.value;
        }
      });

      dataItems[index]["timesForm"] = defaultJam;
    });

    commit("changeJam", {
      loading: false,
      usertimes: dataItems,
    });
    return true;
  },
  async createClassesGroup({ commit, state }, idsave = null) {
    commit("changeJam", {
      loading: true,
      error: false,
    });

    const { data } = state;

    const usertimes = data.usertimes[idsave];
    const newTimes = usertimes.timesForm.filter((item) => item.check === true);

    const dataClasses = [];
    if (usertimes.classes) {
      usertimes.classes.map((item) => {
        if (!item.deleted) {
          dataClasses.push(item.id);
        }
      });
    }

    if (!dataClasses || !dataClasses.length) {
      ApiNotification("info", "Kelas pastikan terisi");
      return false;
    }

    const payload = {
      name: usertimes.name,
      times: newTimes,
      classes: dataClasses.length ? dataClasses : null,
    };

    if (usertimes.id && usertimes.id !== "") {
      const getResult = await ApiPutRequest(
        `school/presence/classgroup/${usertimes.id}`,
        payload,
      );
      if (getResult.error) {
        commit("changeJam", {
          loading: false,
        });
        if (getResult.code >= 500) {
          ApiNotification("error", SERVER_ERROR_MESSAGE);
          return false;
        }
        commit("changeJam", {
          error: getResult.errorList || false,
        });

        ApiNotification("error", "Gagal mengubah data pengaturan jam");

        return false;
      } else {
        commit("changeJam", {
          loading: false,
          error: false,
        });

        ApiNotification("success", "Data pengaturan jam berhasil diubah");
      }
    } else {
      const getResult = await ApiPostRequest(
        `school/presence/classgroup`,
        payload,
      );
      if (getResult.error) {
        commit("changeJam", {
          loading: false,
        });
        if (getResult.code >= 500) {
          ApiNotification("error", SERVER_ERROR_MESSAGE);
          return false;
        }
        commit("changeJam", {
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal menambahkan pengaturan jam");

        return true;
      } else {
        commit("changeJam", {
          loading: false,
          error: false,
        });

        ApiNotification("success", "Data pengaturan jam berhasil ditambahkan");

        return true;
      }
    }
  },
  async updateClassesGroup({ commit, state }) {
    commit("changeJam", {
      loading: true,
      error: false,
    });

    const { data } = state;

    const usertimes = data.detail;
    const newTimes = usertimes.timesForm.filter((item) => item.check === true);

    const dataClasses = [];
    if (usertimes.classes) {
      usertimes.classes.map((item) => {
        if (!item.deleted) {
          dataClasses.push(item.id);
        }
      });
    }

    const payload = {
      name: usertimes.name,
      times: newTimes,
      classes: dataClasses.length ? dataClasses : null,
    };

    const getResult = await ApiPutRequest(
      `school/presence/classgroup/${data.detailId}`,
      payload,
    );
    if (getResult.error) {
      commit("changeJam", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      commit("changeJam", {
        error: getResult.errorList || false,
      });

      ApiNotification("error", "Gagal mengubah data pengaturan jam");
      return false;
    } else {
      commit("changeJam", {
        loading: false,
        error: false,
      });

      ApiNotification("success", "Data pengaturan jam berhasil diubah");
      return true;
    }
  },
  async deleteClassesGroup({ commit }, id = null) {
    commit("changeJam", {
      loading: true,
    });

    const getResult = await ApiDeleteRequest(
      `school/presence/classgroup/${id || ""}`,
    );

    if (getResult.error) {
      commit("changeJam", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menghapus data jam sekolah");
    } else {
      commit("changeJam", {
        loading: false,
      });

      ApiNotification("success", "Data berhasil dihapus");
    }
  },
  async getListUsersStaff({ commit, state }, pagedata = null) {
    commit("changeJam", {
      loading: true,
      isPageAction: true,
    });

    if (pagedata) {
      commit("changeJam", {
        page: pagedata,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      page: data.page,
      q: data.searchUser,
      type: "staff",
    };

    if (data.rolesSelect) {
      params["roles[]"] = data.rolesSelect;
    }

    if (data.perananSelect) {
      params["peranan[]"] = data.perananSelect;
    }

    const getResult = await ApiGetRequest(`school/presence/user`, params);
    if (getResult.error) {
      commit("changeJam", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan data user staff");

      return false;
    } else {
      commit("changeJam", {
        loading: false,
        users: getResult.data.data,
        page: getResult.data.meta.current_page,
        total: getResult.data.meta.total,
      });

      return true;
    }
  },
  async getTotalRoles({ commit, state }) {
    const { data } = state;
    const dataRolesDetail = [];
    const selectedRoles = [];
    if (data.detail && data.detail.roles) {
      data.detail.roles.map((item) => {
        dataRolesDetail.push(`roles[]=${item.id}`);
        selectedRoles.push(item.id);
      });
    }

    let url = `school/presence/user/grouped?type=staff`;
    if (dataRolesDetail.length > 0) {
      url += `&${dataRolesDetail.join("&")}`;
    }

    const getResult = await ApiGetRequest(url);
    if (getResult.error) {
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      ApiNotification("error", "Gagal mengambil data");
    } else {
      const dataRoles = getResult.data.data;
      dataRoles.map((item, index) => {
        dataRoles[index].name = item.label;
      });
      commit("changeJam", {
        grouproles: dataRoles,
        detail: {
          ...data.detail,
          groupselected: selectedRoles,
        },
      });
    }
  },
  async getTotalClasses({ commit }, payload) {
    const dataClasses = [];
    if (payload.classes) {
      payload.classes.map((item) => dataClasses.push(`classes[]=${item.id}`));
    }

    let url = `school/presence/user/grouped?type=student`;

    if (dataClasses.length > 0) {
      url += `&${dataClasses.join("&")}`;
    }

    const getResult = await ApiGetRequest(url);
    if (getResult.error) {
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      ApiNotification("error", "Gagal mengambil data user");
    } else {
      const dataClasses = getResult.data.data;
      dataClasses.map((item, index) => {
        dataClasses[index].name = item.label;
      });

      commit("changeJam", {
        groupclasses: dataClasses,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
