import { ApiGetRequest, ApiNotification } from "@/utils/Api";
import { Message } from "element-ui";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    cities: [],
  },
};

const mutations = {
  changeCities(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getCities({ commit }, province_id = null) {
    if (province_id && province_id !== "") {
      const result = await ApiGetRequest(
        `school/city?province_id=${province_id}`,
      );
      if (result.error) {
        if (result.code >= 500) {
          ApiNotification("error", SERVER_ERROR_MESSAGE);
          return false;
        }
        Message({
          type: "error",
          message: result.error,
        });
      } else {
        commit("changeCities", {
          cities: result.data.data,
        });

        return result.data.data;
      }
    } else {
      return [];
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
