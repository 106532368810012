import { HandlerNotification, HandlerPageStartEnd } from "@/utils/Helpers";
import { ApiDeleteRequest, ApiGetRequest, ApiPutRequest } from "@/utils/Api";

const state = {
  data: {
    history: {
      columns: [
        {
          prop: "ket",
          label: "Keterangan",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "date",
          label: "Tanggal Proses",
          width: "180",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "poin",
          label: "Poin",
          width: "150",
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          label: "Aksi",
          width: "130",
          "class-name": "body-14 col-black-2",
        },
      ],
      formData: { message: "" },
      items: [],
      filter: "",
      errors: [],
      limit: 10,
      page: 1,
      total: 0,
      numberOfDay: 0,
      violationScore: 0,
      achievementScore: 0,
      activeDay: 0,
      inActiveDay: 0,
      score_student_id: null,
      date: null,
      loading: false,
    },
    printData: {
      items: [],
      results: {},
      filter: "",
      errors: [],
      limit: 10,
      page: 1,
      total: 0,
    },
    columns: [
      {
        prop: "avatar",
        label: "Profile",
        width: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nis/nisn",
        label: "NIS/NISN",
        width: "180",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "kelas",
        label: "Kelas",
        width: "200",
        sortable: true,
        "class-name": "body-14 col-black-2 text-left",
      },
      {
        prop: "violation_score",
        label: "Poin Pelanggaran",
        width: "100",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "achievement_score",
        label: "Poin Prestasi",
        width: "100",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "status",
        label: "Status",
        width: "100",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    status: [
      {
        label: "Aktif",
        value: "Active",
      },
      {
        label: "Nonaktif",
        value: "InActive",
      },
    ],
    formData: {
      violation_name: "",
      violation_score: "",
      violation_type: "Another",
    },
    formDataDetail: {},
    detailProfilSiswa: {},
    items: [],
    filter: "",
    errors: [],
    limit: 10,
    page: 1,
    total: 0,
    creator: null,
    updater: null,
    sort_field: null,
    sort_dir: null,
    currentKelas: [],
    currentStatus: null,
    processAction: "read",
    isProcess: false,
    showmodalAddForm: false,
    showmodalListRiwayat: false,
    showmodalFormUpdateRiwayat: false,
    loading: false,
  },
};

const mutations = {
  changeKlasKonselingRapor(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKlasKonselingRaporFormData(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeKlasKonselingRaporFormDataDetail(state, payload) {
    state.data.formDataDetail = Object.assign(
      {},
      state.data.formDataDetail,
      payload,
    );
  },
  changeKlasKonselingRaporHistory(state, payload) {
    state.data.history = Object.assign({}, state.data.history, payload);
  },
  changeKlasKonselingRaporPrintData(state, payload) {
    state.data.printData = Object.assign({}, state.data.printData, payload);
  },
};

const actions = {
  handlerLoading({ commit }, payload = null) {
    const property = {};
    property.loading = payload === null || payload ? true : false;
    commit("changeKlasKonselingRapor", property);
  },
  async getDataRapor({ dispatch, commit, state }, payload) {
    dispatch("handlerLoading");
    const params = {};
    const { data } = state;
    params.limit = payload ? payload.limit ?? data.limit : data.limit;
    if (data.filter) {
      params.q = data.filter;
    }
    if (data.currentStatus) {
      params.color_poin = data.currentStatus;
    }
    if (data.sort_field) {
      params.sort_field = data.sort_field;
    }
    if (data.sort_dir) {
      params.sort_dir = data.sort_dir;
    }
    let resPage = HandlerPageStartEnd(data.page, data.items.length, data.limit);
    params.page = payload ? payload.page || resPage : resPage;
    let url = ``;
    if (data.currentKelas.length) {
      url += `&classes[]=${data.currentKelas.join("&classes[]=")}`;
    }
    if (data.majorSelected.length) {
      url += `&majors[]=${data.majorSelected.join("&majors[]=")}`;
    }
    const result = await ApiGetRequest(
      `/klaskonseling/counseling-report?${url.replace(/^&/, "")}`,
      params,
    );
    if (result.error) {
      commit("changeKlasKonselingRapor", { loading: false });
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    await commit("changeKlasKonselingRapor", {
      loading: false,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },
  async getDataRaporHistory({ commit, state, dispatch }, payload) {
    const params = {};
    const { data } = state;
    if (!data.detailProfilSiswa.score_student_id) {
      alert("score_student_id tidak ditemukan");
      return false;
    }
    if (!data.history.date) {
      dispatch("resetDataRaporHistory");
      return false;
    }
    commit("changeKlasKonselingRaporHistory", { loading: true });
    params.limit = payload
      ? payload.limit ?? data.history.limit
      : data.history.limit;
    if (data.history.date) {
      params.start_date = data.history.date[0];
      params.end_date = data.history.date[1];
    }
    let resPage = HandlerPageStartEnd(
      data.history.page,
      data.history.items.length,
      data.history.limit,
    );
    params.page = payload ? payload.page || resPage : resPage;
    const result = await ApiGetRequest(
      `/klaskonseling/counseling-report/${data.detailProfilSiswa.score_student_id}`,
      params,
    );
    if (result.error) {
      commit("changeKlasKonselingRaporHistory", { loading: false });
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    await commit("changeKlasKonselingRaporHistory", {
      loading: false,
      numberOfDay: result.data.data.number_of_days,
      violationScore: result.data.data.violation_score,
      achievementScore: result.data.data.achievement_score,
      activeDay: result.data.data.active_day,
      inActiveDay: result.data.data.inactive_day,
      score_student_id:
        (result.data.data.details &&
          result.data.data.details[0] &&
          result.data.data.details[0].score_student_id) ||
        null,
      items: result.data.data.details,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) ||
        result.data.data.details.length,
    });
  },
  async getDataRaporCetak({ commit, state }, payload) {
    const { data } = state;
    let user_id = payload
      ? payload.user_id ?? 0
      : data.detailProfilSiswa.user_id;
    let queryString = `/${user_id}`;
    if (payload && payload.all) {
      // Mengonversi array studentIds menjadi query string
      if (payload.sid) {
        queryString =
          "?" + payload.sid.map((id) => `user_id[]=${id}`).join("&");
      } else {
        queryString = ``;
        if (data.currentKelas.length) {
          queryString += `&classes[]=${data.currentKelas.join("&classes[]=")}`;
        }
        if (data.majorSelected.length) {
          queryString += `&majors[]=${data.majorSelected.join("&majors[]=")}`;
        }
        if (data.majorSelected.length || data.currentKelas.length)
          queryString = `?` + queryString.substring(1);
      }
    }
    const result = await ApiGetRequest(
      `/klaskonseling/counseling-report/print-report${queryString}`,
    );
    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification(500);
        return false;
      }
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return false;
      }
      HandlerNotification("error", result.error);
      return false;
    }
    await commit("changeKlasKonselingRaporPrintData", {
      results: result.data,
    });
    return true;
  },
  async postDataRiwayat({ commit, state, dispatch }) {
    const { data } = state;
    if (!data.history.formData.message) {
      HandlerNotification("error", "Keterangan Tidak Boleh Kosong");
      return false;
    }
    if (!data.history.formData.id) {
      HandlerNotification(
        "error",
        "ID Tidak Boleh Kosong, Lakukan Refresh Browser",
      );
      return false;
    }
    commit("changeKlasKonselingRaporHistory", {
      loading: true,
    });
    let result = "";
    const formData = {};
    if (data.history.formData && data.history.formData.jenis === `violation`) {
      formData.violation_message = data.history.formData.message;
      result = await ApiPutRequest(
        `klaskonseling/counseling-report/student-violation/${data.history.formData.id}`,
        formData,
      );
    } else {
      formData.achievement_message = data.history.formData.message;
      result = await ApiPutRequest(
        `klaskonseling/counseling-report/student-achievement/${data.history.formData.id}`,
        formData,
      );
    }

    commit("changeKlasKonselingRaporHistory", {
      loading: false,
    });
    if (!result) {
      HandlerNotification("error", "Terjadi kesalahan, Coba Ulangi lagi!");
      return false;
    }
    if (result.error) {
      let message = result.error;
      HandlerNotification("error", message);
      return false;
    }
    HandlerNotification();
    dispatch("getDataRaporHistory");
    commit("changeKlasKonselingRaporHistory", {
      formData: {},
    });
    return true;
  },
  async deleteDataHistory({ commit, dispatch }, payload) {
    commit("changeKlasKonselingRaporHistory", { loading: true });
    let result;
    let formRequest = {};
    if (payload.where === `achievement`) {
      formRequest.school_achievement_id = payload.idid;
      result = await ApiDeleteRequest(
        `klaskonseling/counseling-report/student-achievement/${payload.id}`,
        formRequest,
      );
    } else {
      formRequest.school_violation_id = payload.idid;
      result = await ApiDeleteRequest(
        `klaskonseling/counseling-report/student-violation/${payload.id}`,
        formRequest,
      );
    }
    if (result.error) {
      commit("changeKlasKonselingRaporHistory", { loading: false });
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return false;
      }
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerNotification();
    dispatch("getDataRaporHistory");
    commit("changeKlasKonselingRaporHistory", { loading: false });
    return true;
  },
  resetDataRaporHistory({ commit }) {
    commit("changeKlasKonselingRaporHistory", {
      date: null,
      numberOfDay: 0,
      violationScore: 0,
      achievementScore: 0,
      activeDay: 0,
      inActiveDay: 0,
      items: [],
      page: 1,
      total: 0,
    });
  },
  changePage({ commit, dispatch }, payload) {
    commit("changeKlasKonselingRapor", { page: payload ?? 1 });
    dispatch("getDataRapor");
    return true;
  },
  changePageHistory({ commit }, payload) {
    commit("changeKlasKonselingRaporHistory", { page: payload ?? 1 });
    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
