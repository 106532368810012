import {
  ApiGetRequest,
  // ApiPutRequest,
  // ApiDeleteRequest,
  ApiPostRequest,
} from "@/utils/Api";

import { Message } from "element-ui";
import { _ } from "vue-underscore";

const state = {
  data: {
    tipePenilaian: [
      {
        name: "SUDAH DINILAI",
        active: true,
      },
      {
        name: "BELUM DINILAI",
        active: false,
      },
    ],
    items_instruction: [],
    items_list_done: [],
    items_answer_done: [],
    jawabanFormat: [],
    items_list_undone: [],
    items_answer_undone: [],
    data_header_student: [],
    activeTabPenilaian: "SUDAH DINILAI",
    limit: 150,
    page: 1,
    instruction_id: null,
    detail_instruction: [],
    perintah_soal: null,
    done: null,
    score_essay: null,
    loading: false,
  },
};

const mutations = {
  changeCekJawabanSoal(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getListInstruction({ commit }, payload) {
    commit("changeCekJawabanSoal", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/exam/kkm/grading/${payload.exam_schedule_id}/instruction`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data gagal ditampilkan",
      });

      commit("changeCekJawabanSoal", {
        loading: false,
      });

      return false;
    } else {
      commit("changeCekJawabanSoal", {
        instruction_id: result.data.data[0].id,
        items_instruction: result.data.data,
        loading: false,
      });

      return true;
    }
  },
  async getListDoneRate({ commit, state, dispatch }, payload) {
    commit("changeCekJawabanSoal", {
      loading: true,
    });

    const { data } = state;

    const params = {
      limit: 150,
      done: 1,
      instruction_id: data.instruction_id,
    };

    const result = await ApiGetRequest(
      `school/exam/kkm/grading/${payload.exam_schedule_id}/${payload.student_id}/answer`,
      params,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data",
      });

      commit("changeCekJawabanSoal", {
        loading: false,
      });

      return null;
    } else {
      commit("changeCekJawabanSoal", {
        items_list_done: result.data.data,
        loading: false,
      });
      dispatch("validateQuestionDone");
    }
  },
  async getListUndoneRate({ commit, state }, payload) {
    commit("changeCekJawabanSoal", {
      loading: true,
    });

    const { data } = state;

    const params = {
      limit: 0,
      done: 0,
      instruction_id: data.instruction_id,
    };

    const result = await ApiGetRequest(
      `school/exam/kkm/grading/${payload.exam_schedule_id}/${payload.student_id}/answer`,
      params,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data",
      });

      commit("changeCekJawabanSoal", {
        loading: false,
      });

      return null;
    } else {
      commit("changeCekJawabanSoal", {
        items_list_undone: result.data.data,
        loading: false,
      });
    }
  },
  async gerHeaderStudent({ commit }, payload) {
    commit("changeCekJawabanSoal", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/exam/kkm/grading/${payload.exam_schedule_id}/${payload.student_id}/header`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data gagal ditampilkan",
      });

      commit("changeCekJawabanSoal", {
        loading: false,
      });

      return false;
    } else {
      commit("changeCekJawabanSoal", {
        data_header_student: result.data.data,
        loading: false,
      });

      return true;
    }
  },

  async submitDataScoreEssay({ commit }, payload) {
    commit("changeCekJawabanSoal", {
      loading: true,
    });

    const formData = {
      score: payload.score_essay,
    };

    console.log(`----formData`, formData);
    let result = await ApiPostRequest(
      `school/exam/kkm/grading/${payload.exam_schedule_id}/${payload.student_id}/answer/${payload.exam_answer_id}`,
      formData,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeCekJawabanSoal", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      // Message({
      //   type: "success",
      //   message: "Data nilai telah tersimpan.",
      // });

      return true;
    }
  },
  async validateQuestionDone({ commit, state }) {
    let jawabanFormat = state.data.items_list_done;
    for (let i = 0; i < state.data.items_list_done.length; i++) {
      let answer = jawabanFormat[i].question_real.answer;
      if (jawabanFormat[i].question_real.answer_type === "MULTIPLE CHOICE") {
        jawabanFormat[i].type = 1;
        let checkImage = _.where(answer, { image: null });
        let checkAnswer = _.where(answer, { answer: "" });

        if (checkImage.length > 0) {
          jawabanFormat[i].format = 1;
        } else {
          if (checkAnswer.length > 0) {
            jawabanFormat[i].format = 3;
          } else {
            jawabanFormat[i].format = 2;
          }
        }
      } else if (jawabanFormat[i].question_real.answer_type === "ESSAY") {
        jawabanFormat[i].type = 2;
        jawabanFormat[i].format = 1;
      } else if (jawabanFormat[i].question_real.answer_type === "SHORT_ESSAY") {
        jawabanFormat[i].type = 2;
        jawabanFormat[i].format = 2;
      } else if (jawabanFormat[i].question_real.answer_type === "STATEMENT") {
        jawabanFormat[i].type = 3;

        let checkImage = _.where(answer, { image: null });
        let checkShowFalse = _.where(answer, { show_false: true });

        if (checkImage.length > 0) {
          if (checkShowFalse.length > 0) {
            jawabanFormat[i].format = 2;
          } else {
            jawabanFormat[i].format = 4;
          }
        } else {
          jawabanFormat[i].format = 3;
        }

        if (answer.length === 1) {
          jawabanFormat[i].format = 1;
        }
      } else if (jawabanFormat[i].question_real.answer_type === "PAIR") {
        jawabanFormat[i].type = 4;

        let checkImage = _.where(answer, { image1: null });

        if (checkImage.length > 0) {
          jawabanFormat[i].format = 1;
        } else {
          jawabanFormat[i].format = 2;
        }
      }
    }
    commit("changeCekJawabanSoal", { jawabanFormat: jawabanFormat });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
