import { ApiGetRequest } from "@/utils/Api";
import { HandlerNotification } from "@/utils/Helpers";
import InternalImplement from "@/api/InternalImplement";
import ApiService from "@/utils/ApiService";
import { Message } from "element-ui";
import moment from "moment";

const state = {
  formDataFiles: [],
  data: {
    loading: false,
    showModal: false,
    isPhoneSucces: false,
    buttonIsDisabled: false,
    isVerifyOtpSucces: false,
    countCallCreateOtp: 0,
    countCallVerifikasiOtp: 0,
    detail: {},
    detailProductInvoice: {},
    formData: {
      phone: "",
      otp: null,
    },
  },
};

const mutations = {
  changeOtp(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeOtpFormData(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeOtpShowModal(state, payload) {
    state.data.showModal = payload;
  },
  changeFormDataFiles(state) {
    state.formDataFiles = [];
  },
  addFormDataFiles(state, fileBlob) {
    state.formDataFiles.push(fileBlob);
  },
  changeLoading(state, payload) {
    state.data.loading = payload;
  },
};

const actions = {
  async postCreateOtp({ state, commit }) {
    const { formData, countCallCreateOtp } = state.data;
    if (countCallCreateOtp >= 5) {
      console.log("call enpoint duplicate: STOP");
      window.location.reload();
      return false;
    }

    try {
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      const result = await internalService.createOtp(formData.phone);
      commit("changeLoading", false);
      commit("changeOtp", {
        countCallCreateOtp: countCallCreateOtp + 1,
        isPhoneSucces: true,
        detail: result.data,
      });
      return true;
    } catch (error) {
      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        Message({
          type: "error",
          message: data.error || "Gagal Mengambil data",
        });
      }
      commit("changeOtp", {
        isPhoneSucces: false,
      });
      commit("changeLoading", false);
      return false;
    }
  },

  async postVerifyOtp({ state, commit }) {
    const { formData, countCallVerifikasiOtp } = state.data;
    if (countCallVerifikasiOtp >= 1) {
      console.log("call countCallVerifikasiOtp");
      window.location.reload();
      return false;
    }

    try {
      commit("changeLoading", true);
      const internalService = new InternalImplement(ApiService);
      const result = await internalService.verifyOtp(
        formData.otp,
        state.formDataFiles,
      );
      commit("changeLoading", false);
      commit("changeOtp", {
        countCallCreateOtp: 1,
        isVerifyOtpSucces: true,
        showModal: false,
        detail: result.data,
      });
      // Simpan status verifikasi telepon ke local storage menggunakan Moment.js
      const phoneVerificationData = {
        is: true,
        datetime: moment().format("YYYY-MM-DD HH:mm:ss"), // Tanggal dan waktu saat ini dalam format yang diinginkan
      };
      localStorage.setItem(
        "phone_verify",
        JSON.stringify(phoneVerificationData),
      );
      Message({
        type: "success",
        message: "Berhasil proses verifikasi data. Cek Whatsapp...",
      });
      return true;
    } catch (error) {
      const statusCode = error?.response?.status || 500;
      commit("changeLoading", false);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        if (statusCode === 453) {
          Message({
            type: "error",
            message: "Ulangi Proses Dapatkan Kode OTP.",
          });
          commit("changeOtp", {
            isVerifyOtpSucces: false,
            isPhoneSucces: false,
            countCallCreateOtp: 0,
          });
          return false;
        }

        Message({
          type: "error",
          message: data.error || "Gagal Mengambil data",
        });
      }
      commit("changeOtp", {
        isVerifyOtpSucces: false,
      });
      return false;
    }
  },

  async getProductPurchased({ state, commit }, payload) {
    let url = `school/product/history`;
    const params = {};
    params.limit = (payload && payload.limit) || state.data.limit;
    if (payload && payload.q) {
      params.q = payload && payload.q;
    } else if (state.data.filter) {
      params.q = state.data.filter;
    }
    if (payload && payload.filterDate) {
      if (
        typeof payload.filterDate === "object" &&
        payload.filterDate.length === 2
      ) {
        params.start_date = payload.filterDate[0];
        params.end_date = payload.filterDate[1];
      }
    } else if (state.data.filterDate) {
      if (
        typeof state.data.filterDate === "object" &&
        state.data.filterDate.length === 2
      ) {
        params.start_date = state.data.filterDate[0];
        params.end_date = state.data.filterDate[1];
      }
    }
    commit("changeBerandaPembelian", { loading: true });
    const result = await ApiGetRequest(url, params);
    if (result.error) {
      commit("changeBerandaPembelian", { loading: false });
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    commit("changeBerandaPembelian", {
      loading: false,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
