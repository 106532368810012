import {checkVw} from "@/services/internalService"
import RequestUserService from "@/services/RequestUserService"
import {Message} from "element-ui"
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils"
import {isServerErrorOrNoInternet} from "@/utils/Helpers";
import {NO_INTERNET_MESSAGE} from "@/services/messageService";

const defaultItemSummary = {
	"requested_student": null,
	"requested_teacher": null,
	"rejected_student": null,
	"rejected_teacher": null
}

const state = {
	data: {
		subMenuTabs: [
			{
				name: "Pelajar",
				active: true,
			},
			{
				name: "Guru&Tendik",
				active: false,
			},
		],
		loadingSummary: false,
		itemSummary: {
			"requested_student": 0,
			"requested_teacher": 0,
			"rejected_student": 0,
			"rejected_teacher": 0
		},
		errorSummary: null,
		dataStatus: [
			{
				label: "Menunggu Disetujui",
				value: "pending",
			},
			{
				label: "Ditolak",
				value: "reject",
			},
		],
		requestStudent: {
			errorDataItems: null,
			loading: false,
			userSelected: [],
			itemsMessageTable: null,
			items: [],
			limit: 10,
			page: 1,
			total: 0,
			sortBy: "",
			sortType: "",
			filterQ: "",
			filterStatus: "pending",
			filterClass: "",
			filterGender: "all",
			columns: [
				{
					prop: "id",
					type: "selection",
				},
				{
					prop: "name",
					label: "Nama",
					minWidth: 200,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "email",
					label: "Email",
					minWidth: 200,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "class",
					label: "Pengajuan Kelas",
					width: 200,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "created_at",
					label: "Tgl Pengajuan",
					width: 180,
					"class-name": "body-14 col-black-2",
					sortable: true,
				},
				{
					fixed: "right",
					prop: "action",
					label: "Aksi",
					width: checkVw(120),
					"class-name": "body-14 col-black-2",
				},
			],
		},
		requestTeacher: {
			errorDataItems: null,
			loading: false,
			userSelected: [],
			itemsMessageTable: null,
			items: [],
			limit: 20,
			page: 1,
			total: 0,
			sortBy: "",
			sortType: "",
			filterQ: "",
			filterStatus: "pending",
			filterRole: "",
			filterGender: "all",
			columns: [
				{
					prop: "id",
					type: "selection",
				},
				{
					prop: "name",
					label: "Nama",
					minWidth: 200,
					sortable: true,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "nik",
					label: "NIK/NUPT/NIP",
					width: 150,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "phone",
					label: "No.HP",
					width: 150,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "level",
					label: "Jabatan",
					minWidth: 200,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "created_at",
					label: "Tgl Pengajuan",
					width: 180,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "action",
					fixed: "right",
					label: "Aksi",
					width: checkVw(120),
					"class-name": "body-14 col-black-2",
				},
			],
		},
	},
}

const mutations = {
	changeRequestUserLoadingSummary(state, payload) {
		state.data.loadingSummary = payload
	},
	changeRequestUser(state, payload) {
		state.data = Object.assign({}, state.data, payload)
	},
	changeRequestUserStudent(state, payload) {
		state.data.requestStudent = Object.assign({}, state.data.requestStudent, payload)
	},
	changeRequestUserTeacher(state, payload) {
		state.data.requestTeacher = Object.assign({}, state.data.requestTeacher, payload)
	},
	changeRequestUserStudentLoading(state, payload) {
		state.data.requestStudent.loading = payload
	},
	changeRequestUserTeacherLoading(state, payload) {
		state.data.requestTeacher.loading = payload
	},
	setSummaryError(state, payload) {
		state.data.errorSummary = payload
	},
	setStudentError(state, payload) {
		state.data.requestStudent.errorDataItems = payload
	},
	setTeacherError(state, payload) {
		state.data.requestTeacher.errorDataItems = payload
	},
}

const actions = {

	async setSubMenuTab({commit, state}, routeName) {

		let tabName = "Pelajar"
		if (routeName === "RequestUserDataTeacher") {
			tabName = "Guru&Tendik"
		}

		const subMenuTabs = state.data.subMenuTabs

		commit("changeRequestUser", {
			subMenuTabs: subMenuTabs.map(item => ({
				...item,
				active: item.name === tabName
			}))
		})
	},

	async fetchSummaryRequestUser({commit}) {

		try {
			commit("setSummaryError", null)
			commit("changeRequestUserLoadingSummary", true)
			const service = new RequestUserService()
			const result = await service.requestUserSummaryGet()

			if (result.data && result.data.data) {
				commit("changeRequestUser", {
					itemSummary: result.data.data
				})
			}
		} catch (e) {
			commit("changeRequestUser", {
				itemSummary: JSON.parse(JSON.stringify(defaultItemSummary))
			})

			if (e.status >= 500) {
				commit("setSummaryError", "Data Summary: " + SERVER_ERROR_MESSAGE)
			} else if (e.code === "ERR_NETWORK") {
				commit("setSummaryError", "Data Summary: CORS Error")
			} else if (e.status >= 400) {
				commit("changeRequestUser", {
					errorSummary: e.error
				})

				Message({
					type: "error",
					message: e.error,
				})
			}
		} finally {
			commit("changeRequestUserLoadingSummary", false)
		}
	},

	/**
	 * STUDENT
	 * */
	async fetchStudentRequestUser({commit, state}) {

		const {requestStudent} = state.data

		const params = {
			limit: requestStudent.limit,
			page: requestStudent.page,
			sortField: requestStudent.sortBy,
			sortDir: requestStudent.sortType,
			filterQ: requestStudent.filterQ,
			filterStatus: requestStudent.filterStatus,
			filterClass: requestStudent.filterClass,
			filterGender: requestStudent.filterGender,
		}

		if (params.filterClass) {
			params.limit = 40
		} else {
			params.limit = 10
		}

		try {
			commit("setStudentError", null)
			commit("changeRequestUserStudentLoading", true)
			const service = new RequestUserService()
			const result = await service.studentRequestUserGet(params)

			if (result.data && result.data.data) {
				commit("changeRequestUserStudent", {
					limit: params.limit,
					items: result.data.data,
					itemsMessageTable: null,
					total: result.data?.meta?.total || result.data.data.length
				})
			}
		} catch (e) {
			commit("changeRequestUserStudent", {
				itemsMessageTable: "Gagal Mengambil Data",
				items: [],
				total: 0
			})

			if (e.status >= 500) {
				commit("setStudentError", "Data student: " + SERVER_ERROR_MESSAGE)
				commit("changeRequestUserStudent", {
					itemsMessageTable: SERVER_ERROR_MESSAGE,
				})
			} else if (e.code === "ERR_NETWORK") {
				commit("setStudentError", "Data student: CORS Error")
				commit("changeRequestUserStudent", {
					itemsMessageTable: NO_INTERNET_MESSAGE,
				})
			} else if (e.status >= 400) {
				commit("changeRequestUserStudent", {
					itemsMessageTable: e.error,
				})
				Message({
					type: "error",
					message: e.error,
				})
			}
		} finally {
			commit("changeRequestUserStudentLoading", false)
		}
	},

	async postApproveStudentRequestUser({dispatch, commit, state}, userTempoIds) {

		const {requestStudent} = state.data

		if (requestStudent.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return;
		}

		if (!userTempoIds) {
			console.log("ID not found")
			return;
		}

		try {
			commit("changeRequestUserStudentLoading", true)
			const service = new RequestUserService()
			await service.studentApproveRequestUserPost(userTempoIds)

			Message({
				type: "success",
				message: "Berhasil diproses."
			});

			commit("changeRequestUserStudent", {
				userSelected: []
			})
			dispatch("fetchStudentRequestUser")
			dispatch("fetchSummaryRequestUser")

			/*//proses hitung sisi FE
			if (typeof state.data.itemSummary.requested_student === "number") {
				if (state.data.itemSummary.requested_student >= userTempoIds.length) {
					commit("changeRequestUser", {
						itemSummary: {
							...state.data.itemSummary,
							requested_student: state.data.itemSummary.requested_student - userTempoIds.length
						}
					})
				}
			}*/

		} catch (e) {
			if (!isServerErrorOrNoInternet) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error
					})
				}
			}
		} finally {
			commit("changeRequestUserStudentLoading", false)
		}
	},

	async postRejectStudentRequestUser({dispatch, commit, state}, userTempoIds) {

		const {requestStudent} = state.data

		if (requestStudent.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return;
		}

		if (!userTempoIds) {
			console.log("ID not found")
			return;
		}

		try {
			commit("changeRequestUserStudentLoading", true)
			const service = new RequestUserService()
			await service.studentRejectRequestUserPost(userTempoIds)

			Message({
				type: "success",
				message: "Berhasil diproses."
			});

			commit("changeRequestUserStudent", {
				userSelected: []
			})
			dispatch("fetchStudentRequestUser")
			dispatch("fetchSummaryRequestUser")

		} catch (e) {
			if (!isServerErrorOrNoInternet) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error
					})
				}
			}
		} finally {
			commit("changeRequestUserStudentLoading", false)
		}
	},

	async deleteStudentRequestUser({dispatch, commit, state}, userTempoId) {
		const {requestStudent} = state.data

		if (requestStudent.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return;
		}

		if (!userTempoId) {
			console.log("ID not found")
			return;
		}

		try {
			commit("changeRequestUserStudentLoading", true)
			const service = new RequestUserService()
			await service.studentDeleteRequestUserDelete(userTempoId)

			Message({
				type: "success",
				message: "Berhasil diproses."
			});

			commit("changeRequestUserStudent", {
				userSelected: []
			})
			dispatch("fetchStudentRequestUser")
			dispatch("fetchSummaryRequestUser")

		} catch (e) {
			if (!isServerErrorOrNoInternet) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error
					})
				}
			}
		} finally {
			commit("changeRequestUserStudentLoading", false)
		}
	},

	/**
	 * TEACHER
	 * */
	async fetchTeacherRequestUser({commit, state}) {

		const {requestTeacher} = state.data

		const params = {
			limit: requestTeacher.limit,
			page: requestTeacher.page,
			sortField: requestTeacher.sortBy,
			sortDir: requestTeacher.sortType,
			filterQ: requestTeacher.filterQ,
			filterStatus: requestTeacher.filterStatus,
			filterRole: requestTeacher.filterRole,
			filterGender: requestTeacher.filterGender,
		}

		try {
			commit("setTeacherError", null)
			commit("changeRequestUserTeacherLoading", true)
			const service = new RequestUserService()
			const result = await service.teacherRequestUserGet(params)

			if (result.data && result.data.data) {
				commit("changeRequestUserTeacher", {
					items: result.data.data,
					itemsMessageTable: null,
					total: result.data?.meta?.total || result.data.data.length
				})
			}
		} catch (e) {
			commit("changeRequestUserTeacher", {
				itemsMessageTable: "Gagal mengambil data",
				items: [],
				total: 0
			})

			if (e.status >= 500) {
				commit("setTeacherError", "Data teacher: " + SERVER_ERROR_MESSAGE)
				commit("changeRequestUserTeacher", {
					itemsMessageTable: SERVER_ERROR_MESSAGE,
				})
			} else if (e.code === "ERR_NETWORK") {
				commit("setTeacherError", "Data teacher: CORS Error")
				commit("changeRequestUserTeacher", {
					itemsMessageTable: NO_INTERNET_MESSAGE,
				})
			} else if (e.status >= 400) {
				commit("changeRequestUserTeacher", {
					itemsMessageTable: e.error,
				})
				Message({
					type: "error",
					message: e.error,
				})
			}
		} finally {
			commit("changeRequestUserTeacherLoading", false)
		}
	},

	async postApproveTeacherRequestUser({dispatch, commit, state}, userTempoIds) {

		const {requestTeacher} = state.data

		if (requestTeacher.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return;
		}

		if (!userTempoIds) {
			console.log("ID not found")
			return;
		}

		try {
			commit("changeRequestUserTeacherLoading", true)
			const service = new RequestUserService()
			await service.teacherApproveRequestUserPost(userTempoIds)

			Message({
				type: "success",
				message: "Berhasil diproses."
			});

			commit("changeRequestUserTeacher", {
				userSelected: []
			})
			dispatch("fetchTeacherRequestUser")
			dispatch("fetchSummaryRequestUser")

		} catch (e) {
			if (!isServerErrorOrNoInternet) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error
					})
				}
			}
		} finally {
			commit("changeRequestUserTeacherLoading", false)
		}
	},

	async postRejectTeacherRequestUser({dispatch, commit, state}, userTempoIds) {

		const {requestTeacher} = state.data

		if (requestTeacher.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return;
		}

		if (!userTempoIds) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return;
		}

		try {
			commit("changeRequestUserTeacherLoading", true)
			const service = new RequestUserService()
			await service.teacherRejectRequestUserPost(userTempoIds)

			Message({
				type: "success",
				message: "Berhasil diproses."
			});

			commit("changeRequestUserTeacher", {
				userSelected: []
			})
			dispatch("fetchTeacherRequestUser")
			dispatch("fetchSummaryRequestUser")

		} catch (e) {
			if (!isServerErrorOrNoInternet) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error
					})
				}
			}
		} finally {
			commit("changeRequestUserTeacherLoading", false)
		}
	},

	async deleteTeacherRequestUser({dispatch, commit, state}, userTempoId) {
		const {requestTeacher} = state.data

		if (requestTeacher.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return;
		}

		if (!userTempoId) {
			console.log("ID not found")
			return;
		}

		try {
			commit("changeRequestUserTeacherLoading", true)
			const service = new RequestUserService()
			await service.teacherDeleteRequestUserDelete(userTempoId)

			Message({
				type: "success",
				message: "Berhasil diproses."
			});

			commit("changeRequestUserTeacher", {
				userSelected: []
			})
			dispatch("fetchTeacherRequestUser")
			dispatch("fetchSummaryRequestUser")

		} catch (e) {
			if (!isServerErrorOrNoInternet) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error
					})
				}
			}
		} finally {
			commit("changeRequestUserTeacherLoading", false)
		}
	},

}

const getters = {
	studentUserSelectedGetter: (state) => {
		return state.data.requestStudent.userSelected
	},
	teacherUserSelectedGetter: (state) => {
		return state.data.requestTeacher.userSelected
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
