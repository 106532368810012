import repository from "@/repositories/CanteenRepository";

export default class CanteenService {
	constructor() {
		this.dataRepository = repository;
	}

	async canteenGet(payload) {
		const {limit, page, sortField, sortDir} = payload;

		const params = {
			limit: limit ?? undefined,
			page: page || undefined,
			sort_field: sortField || undefined,
			sort_dir: sortDir || undefined,
		}

		try {
			return await this.dataRepository.getCanteen(params);
		} catch (error) {
			throw new Error(error);
		}
	}

	async valueQrCodeGet(walletId) {
		if (!walletId) {
			throw new Error("walletId must be provided");
		}

		const params = {
			wallet_id: walletId
		}

		try {
			return await this.dataRepository.getValueQrCode(params);
		} catch (error) {
			throw new Error(error);
		}
	}

}
