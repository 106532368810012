import { ApiGetRequest } from "@/utils/Api";
import { Message } from "element-ui";
// import moment from "moment";

const state = {
  data: {
    columns_report: [
      {
        prop: "name_student",
        label: "Nama",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nisn",
        label: "NISN/NIS",
        minWidth: "120",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "class",
        label: "Kelas",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "checkin_at",
        label: "Jam Masuk",
        minWidth: "120",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "checkout_at",
        label: "Jam Keluar",
        minWidth: "120",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "note",
        label: "Keterangan",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_group_magang: [
      {
        prop: "teacher",
        label: "Nama Guru",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nip",
        label: "NIP",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "start_date",
        label: "Tanggal Mulai",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "end_date",
        label: "Tanggal Berakhir",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    editdata: false,
    timesDefault: [
      {
        day: 1,
        check: false,
        label: "Senin",
        start_at: "",
        end_at: "",
      },
      {
        day: 2,
        check: false,
        label: "Selasa",
        start_at: "",
        end_at: "",
      },
      {
        day: 3,
        check: false,
        label: "Rabu",
        start_at: "",
        end_at: "",
      },
      {
        day: 4,
        check: false,
        label: "Kamis",
        start_at: "",
        end_at: "",
      },
      {
        day: 5,
        check: false,
        label: "Jumat",
        start_at: "",
        end_at: "",
      },
      {
        day: 6,
        check: false,
        label: "Sabtu",
        start_at: "",
        end_at: "",
      },
      {
        day: 7,
        check: false,
        label: "Minggu",
        start_at: "",
        end_at: "",
      },
    ],
    usertimes: [],
    rowuser: {
      name: "",
      custom_users: [],
      times: [],
    },
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    search: "",
    detailReport: {},
    errors: [],
    items: [],
    itemsTableGroup: [],
    attendance_id: null,
  },
};

const mutations = {
  changeMagangReport(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getDataReport({ commit }, payload) {
    commit("changeMagangReport", {
      loading: true,
    });
    const { data } = state;

    if (payload) {
      await commit("changeMagangReport", {
        page: data.page,
      });
    }

    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/attendance`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagangReport", {
        loading: false,
      });
    } else {
      commit("changeMagangReport", {
        items: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async getDataDetailReport({ commit }, payload) {
    commit("changeMagangReport", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/attendance/${payload.attendance_id}`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagangReport", {
        loading: false,
      });
    } else {
      commit("changeMagangReport", {
        detailReport: result.data.data,
        loading: false,
      });
    }
  },
  async getDataSettingInternship({ commit, state }, payload) {
    commit("changeMagangReport", {
      loading: true,
    });
    const { data } = state;
    if (payload) {
      await commit("changeMagangReport", {
        page: data.page,
      });
    }
    let params = {
      q: data.search,
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };
    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group`,
      params,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangReport", {
        loading: false,
      });
    } else {
      commit("changeMagangReport", {
        itemsTableGroup: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
