// SuratMasukSekolahImplement.js
import moment from "moment";
import {
  handler401ApiService,
  handlerTimeOutApiService,
} from "@/utils/ApiService";
import { handlerServerErrorAxios } from "@/services/internalService";

moment.locale("id");

const ENDPOINT = "/school/letter";

export default class SuratMasukSekolahImplement {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async fetchSuratMasukSekolah(data) {
    try {
      const params = this.prepareParams(data);
      if (!params) {
        throw new Error("Invalid parameters for agendaSurat.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/error500`, { params });
      return await this.apiService.get(`${ENDPOINT}`, { params });
    } catch (error) {
      console.error("Error in SuratMasukSekolahImplement.agendaSurat:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  truncateString(value, key) {
    const max = {
      sender: 100,
      letter_number: 240,
    };
    if (!value) {
      return "";
    }
    return value.length > max[key] ? value.substring(0, max[key]) : value;
  }

  async addSuratMasukSekolah(paramFormData) {
    try {
      if (!paramFormData.sender || !paramFormData.letter_number) {
        throw new Error(
          "FormData.sender,FormData.letter_number isRequired for addSuratMasukSekolah.",
        );
      }

      const formData = new FormData();
      const truncatedLetterNumber = this.truncateString(
        paramFormData.letter_number,
        "letter_number",
      );
      const truncatedSender = this.truncateString(
        paramFormData.sender,
        "sender",
      );
      formData.append("sender", truncatedSender);
      formData.append("letter_number", truncatedLetterNumber);
      if (paramFormData.date) {
        formData.append(
          "letter_date",
          moment(paramFormData.date).format("YYYY-MM-DD"),
        );
      }
      if (paramFormData.file) {
        formData.append("file", paramFormData.file);
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      return await this.apiService.post(`${ENDPOINT}`, formData, config);
    } catch (error) {
      console.error(
        "Error in SuratMasukSekolahImplement.addSuratMasukSekolah:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async updateSuratMasukSekolah(id, paramFormData) {
    try {
      if (!id) {
        throw new Error("Invalid id isRequired for updateSuratMasukSekolah.");
      }
      if (!paramFormData.letter_number) {
        throw new Error(
          "FormData.letter_number isRequired for updateSuratMasukSekolah.",
        );
      }
      if (!paramFormData.sender) {
        throw new Error(
          "FormData.sender isRequired for updateSuratMasukSekolah.",
        );
      }
      const truncatedLetterNumber = this.truncateString(
        paramFormData.letter_number,
        "letter_number",
      );
      const truncatedSender = this.truncateString(
        paramFormData.sender,
        "sender",
      );
      const formData = {
        letter_number: truncatedLetterNumber,
        sender: truncatedSender,
      };
      if (paramFormData.date) {
        formData.letter_date = moment(paramFormData.date).format("YYYY-MM-DD");
      }

      return await this.apiService.put(`${ENDPOINT}/${id}`, formData);
    } catch (error) {
      console.error(
        "Error in SuratMasukSekolahImplement.updateSuratMasukSekolah:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async uploadFileSuratMasukSekolah({ file, id }) {
    try {
      if (!file) {
        throw new Error(
          "FormData.file isRequired for uploadFileSuratMasukSekolah.",
        );
      }
      if (!id) {
        throw new Error(
          "FormData.id isRequired for uploadFileSuratMasukSekolah.",
        );
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("letter_id", id);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      return await this.apiService.post(`${ENDPOINT}/file`, formData, config);
    } catch (error) {
      console.error(
        "Error in SuratMasukSekolahImplement.uploadFileSuratMasukSekolah:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      throw error;
    }
  }

  async delete(id) {
    try {
      if (!id) {
        throw new Error("FormData.id isRequired for deleteSuratMasukSekolah.");
      }

      return await this.apiService.delete(`${ENDPOINT}/${id}`);
    } catch (error) {
      console.error(
        "Error in SuratMasukSekolahImplement.deleteSuratMasukSekolah:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  prepareParams(data) {
    const params = {};
    this.validateLimit(data, params);
    this.validatePage(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama employee
    }

    if (data.creator_id) {
      params.creator_id = data.creator_id; // ops | nama employee
    }

    if (data.letter_date) {
      params.letter_date = data.letter_date;
    }

    return params;
  }

  validatePage({ page }, params) {
    if (page) {
      if (!isNaN(page) && page !== 0) {
        params.page = parseInt(page);
      } else {
        throw new Error("Error: 'page' must be a numeric value");
      }
    }
  }

  validateLimit({ limit }, params) {
    const maxLimit = 500;
    if (limit) {
      const numericLimit = parseInt(limit, 10);
      if (isNaN(numericLimit) || numericLimit > maxLimit) {
        throw new Error(
          `Invalid limit value: '${limit}'. The limit must be a number and cannot exceed ${maxLimit}.`,
        );
      }
      params.limit = numericLimit;
    }
  }

  validateSortField(
    { sort_field },
    params,
    validSortFields = ["name", "created_at", "agenda_number"],
  ) {
    if (sort_field && !validSortFields.includes(sort_field)) {
      throw new Error(
        `Invalid sort_field value: '${sort_field}'. Valid values are ${validSortFields.join(
          ", ",
        )}.`,
      );
    }
    params.sort_field = sort_field;
  }

  validateSortDir({ sort_dir }, params) {
    if (typeof sort_dir === "boolean") {
      params.sort_dir = sort_dir ? "desc" : "asc";
    } else {
      const validSortDirections = ["asc", "desc"];
      if (sort_dir && !validSortDirections.includes(sort_dir)) {
        throw new Error(
          `Invalid sort_dir value: '${sort_dir}'. Valid values are ${validSortDirections.join(
            ", ",
          )}.`,
        );
      }
      params.sort_dir = sort_dir;
    }
  }
}
