import { ApiGetRequest } from "@/utils/Api";
import { Message } from "element-ui";
import moment from "moment/moment";

const localStorageValidasiDataKey = "diskolaValidasiData_raport";
//const localStorageDashboardStatisticKey = "diskolaDashboard_statistic";
const expirationTime = 10 * 60 * 1000; // 10 menit in milliseconds

const state = {
  data: {
    pelajar: {
			forceCallServerPelajar: false,
			filter: "all",
      items: [],
      itemsPelajarProgressColumn: [],
      itemsPelajarNotActiveKlasdrive: [],
      page: 1,
      limit: 40,
      total: 0,
      loading: false,
      columns: [
        {
          prop: "keterangan",
          label: "Keterangan",
          "class-name": "body-14 col-black-2",
          sortable: false,
          minWidth: 250,
        },
      ],
    },
    rapor: {
			forceCallServerRaport: false,
			callServerRaport: false,
      items: [],
      itemsNotFoundRaporInSemester: [],
      page: 1,
      limit: 40,
      total: 0,
      loading: false,
      columns: [
        {
          prop: "keterangan",
          label: "Keterangan",
          "class-name": "body-14 col-black-2",
          sortable: false,
          minWidth: 250,
        },
      ],
    },
  },
};

const mutations = {
  changeValidasiData(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeValidasiDataPelajar(state, payload) {
    state.data.pelajar = Object.assign({}, state.data.pelajar, payload);
  },
  changeValidasiDataRapor(state, payload) {
    state.data.rapor = Object.assign({}, state.data.rapor, payload);
  },
	setDataValidasiDataRapor(state, payload) {
		const {data, lastUpdated} = payload
		localStorage.setItem(localStorageValidasiDataKey, JSON.stringify({
			data: data,
			lastUpdated: lastUpdated,
		}));
	},
};

const actions = {
  async getValidasiPelajar({ commit, state, dispatch }) {
    if (state.data.pelajar.loading) {
      console.log("menunggu....");
      return false;
    }
    commit("changeValidasiDataPelajar", {
      loading: true,
    });
    const params = {
      limit: state.data.limit,
      page: state.data.page,
    };

    params.data_simple = true;
    params.show = "active";
    const result = await ApiGetRequest(`school/student`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeValidasiDataPelajar", {
        loading: false,
      });
      return false;
    }
    const send = {
      loading: false,
    };
    send.itemsPelajarProgressColumn = result.data.data;
    await commit("changeValidasiDataPelajar", send);
    await dispatch("pelajarCombineDataToItems");
    return true;
  },

  async getValidasiRapor({ commit, state, dispatch }) {

		const now = moment().valueOf();
		const storedData = JSON.parse(localStorage.getItem(localStorageValidasiDataKey));
		// Check if stored data is valid and not expired
		if (!state.data.rapor.forceCallServerRaport && storedData && typeof storedData === "object" && (now - storedData.lastUpdated < expirationTime)) {

			const send = {
				itemsNotFoundRaporInSemester: storedData.data,
				forceCallServerRaport: false
			}
			await commit("changeValidasiDataRapor", send);
			await dispatch("raporCombineDataToItems");
			return true;
		}

		if (state.data.rapor.loading) {
      console.log("menunggu....");
      return false;
    }
    commit("changeValidasiDataRapor", {
      loading: true,
    });
    const params = {
      limit: 5000,
      page: 1,
    };

    params.data_simple = true;
    params.show = "active";
    const result = await ApiGetRequest(
      `school/data/getDataStudentReport`,
      params,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeValidasiDataRapor", {
        loading: false,
      });
      return false;
    }
    const send = {
      loading: false,
    };
    send.itemsNotFoundRaporInSemester = result.data.data;
    send.forceCallServerRaport = false;
		commit("setDataValidasiDataRapor", {lastUpdated: now, data: result.data?.data || []});
    await commit("changeValidasiDataRapor", send);
    await dispatch("raporCombineDataToItems");
    return true;
  },

  /**
   * */
  pelajarCombineDataToItems({ commit, state }) {
    let pelajar = state.data.pelajar;
		let itemsPelajarProgressColumn = []

		if (state.data.pelajar.filter === 'not_class') {
			itemsPelajarProgressColumn = pelajar.itemsPelajarProgressColumn.filter((item) => !item.student.class_name);
		}else{
			itemsPelajarProgressColumn = pelajar.itemsPelajarProgressColumn;
		}

    let dataProgressColumn = itemsPelajarProgressColumn.map((item) => {
      let messageNotClass = "";
      let messageProgressData = "";
      let className = "";
      let checkProgressData = false;
      let checkClass = false;
      let class_name = "";
      try {
        class_name = item.student.class_name || "-";
      } catch (e) {
        class_name = "-";
      }
      if (parseInt(item.progress_data) !== 100) {
        checkProgressData = true;
        messageProgressData = `, Progres pengisian data induk masih <span class="font-weight: 600;">${item.progress_data}%</span>`;
      }
      if (class_name !== "-") {
        className = ` Kelas: <span class="font-weight: 600;">${class_name}</span>`;
      } else {
        // aktif tidak memiliki kelas
        checkClass = true;
        messageNotClass =
          ", Siswa <span style='font-weight: 600;'>Aktif</span> tapi belum Memiliki Kelas";
      }
      if (checkProgressData || checkClass) {
        return {
          name: item.name + class_name,
          keterangan: `Siswa a.n <span style="font-weight: 600;">${item.name}</span>${className}${messageProgressData}${messageNotClass}`,
        };
      }
    });


		let itemsPelajarNotActiveKlasdrive = []

		if (state.data.pelajar.filter === 'not_class') {
			itemsPelajarNotActiveKlasdrive = pelajar.itemsPelajarNotActiveKlasdrive.filter((item) => !item.student.class_name);
		}else{
			itemsPelajarNotActiveKlasdrive = pelajar.itemsPelajarNotActiveKlasdrive;
		}

    let dataInActiveKlasdrive = itemsPelajarNotActiveKlasdrive.map(
      (item) => {
        return {
          name: item.name,
          keterangan: `Siswa a.n <span style="font-weight: 600;">${item.name}</span>`,
        };
      },
    );
    let combinedData = [...dataProgressColumn, ...dataInActiveKlasdrive];
    // Sorting combinedData in ascending order based on the name
    combinedData.sort((a, b) => a.name.localeCompare(b.name));
    commit("changeValidasiDataPelajar", {
      items: combinedData,
    });
  },

  /**
   * */
  raporCombineDataToItems({ commit, state }) {
    const data = state.data.rapor;
    let dataProgressColumn = data.itemsNotFoundRaporInSemester.map((item) => {
      let messageUnfilledSemester = "";
      let class_name = "";
      try {
        class_name = item.student.class_name || "-";
      } catch (e) {
        class_name = "-";
      }
      if (item.unfilled_semesters && item.unfilled_semesters.length) {
        messageUnfilledSemester = item.unfilled_semesters
          .map((number) => `Semester ${number}`)
          .join(", ");
        messageUnfilledSemester +=
          " Belum Terdapat File Bernama <span style='font-weight: 600;'>RAPOR</span>";
      }
      let className = ` Kelas: <span style='font-weight: 600;'>${class_name}</span>`;
      return {
        name: item.name + class_name,
        keterangan: `Siswa a.n <span style='font-weight: 600;'>${item.name}</span>${className} ${messageUnfilledSemester}`,
      };
    });
    dataProgressColumn.sort((a, b) => a.name.localeCompare(b.name));
    commit("changeValidasiDataRapor", {
      items: dataProgressColumn,
    });
  },
};

const getters = {
  /**
   * Computes and returns a list of items from `state.data.dataImport` based on various filters.
   * This function also supports pagination through `page` and `limit`.
   *
   * @param {Object} state - The state containing data and filters.
   * @returns {Object} An object containing the filtered list and the total number of filtered data.
   */
  displayedItems: (state) => {
    let data = state.data.pelajar;
    let dataItems = state.data.pelajar.items;
    /*if (imp.filter) {
      filteredData = filteredData.filter((item) =>
        item.user.name.toLowerCase().includes(imp.filter.toLowerCase())
      );
    }
    */

    const start = (data.page - 1) * data.limit;
    const end = start + data.limit;
    return {
      list: dataItems.slice(start, end),
      totalData: dataItems.length,
    };
  },
  /**
   * Computes and returns a list of items from `state.data.dataImport` based on various filters.
   * This function also supports pagination through `page` and `limit`.
   *
   * @param {Object} state - The state containing data and filters.
   * @returns {Object} An object containing the filtered list and the total number of filtered data.
   */
  displayedItemsRapor: (state) => {
    let data = state.data.rapor;
    let dataItems = state.data.rapor.items;
    if (!dataItems.length) {
      return {
        list: [],
        totalData: 0,
      };
    }

    const start = (data.page - 1) * data.limit;
    const end = start + data.limit;
    return {
      list: dataItems.slice(start, end),
      totalData: dataItems.length,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
