import ApiService from "@/utils/ApiService";

export default class WebschoolRepository {
  constructor() {
    this.apiService = ApiService;
    this.mock = "http://localhost:3000/api";
  }

  async getWebContent(params) {
    return await this.apiService.get(`/school/web/content`, {params});
  }

  async postWebContent(formData) {
    return await this.apiService.post(`/school/web/content`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' }, // Set the header for multipart data
		});
  }

  async postWebContentById(id, formData) {
    return await this.apiService.post(`/school/web/content/${id}`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' }, // Set the header for multipart data
		});
  }

  async deleteWebContent(id) {
    return await this.apiService.delete(`/school/web/content/${id}`);
  }

  async getStudentBkkSummary() {
    return await this.apiService.get(`school/web/student-bkk/summary`);
  }

  async getStudentBkk(params) {
    return await this.apiService.get(`school/web/student-bkk`, { params });
  }

  async postStudentBkk(uuid, formData) {
    return await this.apiService.post(
      `school/web/student-bkk/${uuid}`,
      formData,
    );
  }

  async postStudentBkkBulk(formData) {
    return await this.apiService.post(`school/web/student-bkk`, formData);
  }

  async postStudentBkkByUuid(id, formData) {
    return await this.apiService.post(
      `school/web/student-bkk/${id}`,
      formData,
    );
  }

  async deleteStudentBkkByUuid(uuid) {
    return await this.apiService.delete(`school/web/student-bkk/${uuid}`);
  }

  async getCompanyStudentBkk(params) {
    return await this.apiService.get(`school/web/company`, { params });
  }
}
