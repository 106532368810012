import {requirePermission} from "@/router/index";

export default [
	{
		path: "/koperasi",
		name: "Koperasi",
		component: () =>
			import(/* webpackChunkName: "koperasi" */ "../views/Koperasi"),
		meta: {
			title: "Koperasi | ",
			allowedRole: ["Head Master", "Cooperative"],
		},
		redirect: {name: "koperasiDashboard"},
		children: [
			{
				path: "biaya-admin",
				name: "KoperasiBiayaAdmin",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-biaya-admin" */
						"../views/Koperasi/BiayaAdmin"
						),
				meta: {
					title: "Koperasi Biaya Admin | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},

			{
				path: "dashboard",
				name: "koperasiDashboard",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-dashboard" */
						"../views/Koperasi/KoperasiDashboard"
						),
				meta: {
					title: "Koperasi Dashboard | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "saldo-topup",
				name: "koperasiKlasPayTopUp",
				component: () =>
					import(
						/* webpackChunkName: "saldo-topup" */
						"../views/Koperasi/KoperasiPayment/TopUp"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "tarik-saldo",
				name: "koperasiKlasPayTarikSaldo",
				component: () =>
					import(
						/* webpackChunkName: "tarik-saldo" */
						"../views/Koperasi/KoperasiPayment/TarikSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "payment-isi-saldo",
				name: "koperasiKlasPayIsiSaldo",
				component: () =>
					import(
						/* webpackChunkName: "payment-isisaldo" */
						"../views/Koperasi/KoperasiPayment/IsiSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "riwayat-saldo",
				name: "KoperasiKlasPayTableSaldo",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-payment-tablesaldo" */
						"../views/Koperasi/KoperasiPayment/TableSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "mutasi-saldo-payment",
				name: "KoperasiMutasiSaldoPayment",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-mutasisaldo-payment" */
						"../views/Koperasi/KoperasiPayment/MutasiSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "atur-rekening",
				name: "KoperasiAturRekening",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-atur-rekening" */
						"../views/Koperasi/KoperasiPayment/AturRekening"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: ["Head Master", "Cooperative"],
				},
				beforeEnter: requirePermission,
			},
		],
	},
]
