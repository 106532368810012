import {
	ApiGetRequest,
	ApiGetRequestPartisipasi,
	ApiPostRequest,
	ApiPostRequestPartisipasi
} from "@/utils/Api";
import {Message} from "element-ui";
import {_} from "vue-underscore";
import moment from "moment";

const state = {
	data: {
		subMenuDanaPartisipasi: [
			{
				label: 'Dana Partisipasi',
				name: 'DanaPartisipasiProgram',
				url: '/dana-partisipasi',
			},
			{
				label: 'Biaya Admin',
				name: 'BiayaAdminDanaPartisipasi',
				url: '/dana-partisipasi/biaya-admin',
				lock: true,
			},
		],
		subTabDanaPartisipasi: [
			{
				name: 'PROGRAM',
				active: true,
			},
			{
				name: 'PEMBAYARAN',
				active: false,
			},
		],
		viewShow: "form",
		showModalTargetNominal: false,
		targetNominalGlobal: 0,
		selectStudentsNominalTarget: [],
		itemsNominalTarget: [],
		cartNominalTarget: [],
		listTargetNominal: [],
		listKelas: [],
		listKelas2: [],
		filter_kelas2: "",
		filter_student2: "",
		limit2: 1000,
		page2: 1,
		total2: 0,
		viewTargetNominal: {},

		filterNamePartisipasi: "",

		labelMenu: "Dana Partisipasi",
		limit: 1000,
		page: 1,
		total: 0,
		sortBy: "",
		sortType: "",
		filter: "",
		filter_student: "",
		loading: false,
		showpartisipan: false,
		errors: [],
		listClass: [],
		selectClass: [],
		selectOnlyClassName: [],
		selectChoiceClass: [],
		selectStudents: [],
		isPageAction: false,
		listStudentInClass: [],
		detailProgram: "",
		filterClass: null,
		filterSubject: "",
		searchParams: "",
		classroom: [],
		dataEditStudent: [],
		showEditStudent: [],
		dataDetailStudent: [],
		dataDetailPayment: [],
		addStudentEdit: [],
		dataSummaryParticipant: [],
		listProgram: [],
		dataMergeUniqInClassrooms: [],
		dataModalDetailPaymentFind: Object,
		tabMenu: [
			{
				name: "FormProgramPartisipasi",
				label: "Per Kelas",
				lock: false,
				url: "/dana-partisipasi/form-program-partisipasi"
			},
			{
				name: "FormProgramPartisipasiPerSiswa",
				label: "Per Siswa",
				lock: false,
				url: "/dana-partisipasi/form-program-partisipasi-siswa"
			}
		],
		dataModalDetailPayment: {
			name: "",
			nisn: "",
			kelas: "",
			bill_name: "",
			target_nominal: "",
			updated_date: "",
			bill_status: "",
			list_child: []
		},
		listProgramDetail: {
			program: "",
			data_expired: "",
			participation: "",
			bill_id: "",
			paid_nominal: "",
			detailClassroom: []
		},
		dataFindEdit: null,
		dataFindDetail: null,
		formData: {
			bill_id: "",
			bill_name: "",
			is_active: true,
			expired_date: "",
			target_nominal: 0,
			wallet_id: "",
			list: [],
			listEdit: [],
			paid_nominal: 0,
			list_child: 0
		},
		rulesFormData: {
			bill_name: [
				{
					required: true,
					message: "Nama program tidak boleh kosong",
					trigger: "blur"
				}
			],
			target_nominal: [
				{
					required: true,
					message: "Biaya partisipasi tidak boleh kosong",
					trigger: "blur"
				}
			],
			expired_date: [
				{
					required: true,
					message: "Tanggal tidak boleh kosong",
					trigger: "blur"
				}
			]
		},
		itemsOngoing: [
			{
				id: 1,
				programName: "Iuran untuk festival pentas seni kelas XII angkatan 2021",
				classes: ["XI A", "XI B", "XI C"],
				danaPartisipasi: 350000,
				berakhir: "2021-04-24 08:57",
				perolehan: 14325500,
				active: true
			},
			{
				id: 2,
				programName:
					"Sumbangan Rosalina Cantika Putri kelas XI C dirawat di RS",
				classes: ["XI A", "XI B", "XI C"],
				danaPartisipasi: 350000,
				berakhir: "2021-04-24 08:57",
				perolehan: 2121800,
				active: false
			}
		],
		itemsEnded: [],
		columns: [
			{
				prop: "programName",
				label: "Nama Program",
				minWidth: "150",
				sortable: false,
				"class-name": "body-14 col-black-2"
			},

			{
				prop: "danaPartisipasi",
				label: "Estimasi Partisipasi",
				minWidth: "150",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "berakhir",
				label: "Berakhir",
				sortable: false,
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "perolehan",
				label: "Perolehan",
				sortable: false,
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "active",
				label: "Status",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				fixed: "right",
				label: "Aksi",
				width: "100",
				"class-name": "body-14 col-black-2"
			}
		],
		product_klaspay: {}
	}
};

const mutations = {
	changeDanaPartisipasi(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	create_kelas2(state) {
		const {classroom, formData} = state.data;
		const data = state.data.selectStudents.map(item => {
			const cartNominal = state.data.cartNominalTarget.find(
				nom => nom.user_uuid === item
			);
			let target_nominal = parseInt(formData.target_nominal) || 0;
			if (cartNominal) {
				target_nominal = parseInt(cartNominal.target_nominal);
			}
			const student = classroom.find(std => std.user_uuid === item);
			return {
				name: student.name || "-",
				kelas: student.kelas || "-",
				user_uuid: student.user_uuid || "-",
				user_id: student.user_id || "-",
				wallet_id: student.wallet_id || "-",
				target_nominal: target_nominal
			};
		});
		state.data.listKelas2 = _.uniq(_.map(data, "kelas"));
	},
	changeViewShow(state, payload) {
		state.data.viewShow = payload;
	},
	CHANGE_NOMINAL_ITEMS(state) {
		state.data.itemsNominalTarget = state.data.itemsNominalTarget.map(item => {
			return {...item, target_nominal: state.data.targetNominalGlobal};
		});
	},
	MERGE_CART_ITEMS(state) {
		state.data.itemsNominalTarget.forEach(item => {
			const index = state.data.cartNominalTarget.findIndex(
				cartItem => cartItem.user_uuid === item.user_uuid
			);

			if (index !== -1) {
				// Jika user_uuid ditemukan di cart, replace item tersebut dengan yang ada di items
				state.data.cartNominalTarget.splice(index, 1, item);
			} else {
				// Jika tidak ditemukan, tambahkan item ke cart
				state.data.cartNominalTarget.push(item);
			}
		});
	}
};

const actions = {
	async checkProductIdDanaPartisipasi({commit}) {
		const result = await ApiGetRequestPartisipasi(
			`klaspay/external/onklasproduct/check`
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error ? result.error : "Gagal mengambil data pelajar"
			});

			commit("changeDanaPartisipasi", {
				product_klaspay: {}
			});

			return false;
		} else {
			let data_product = result.data.data || [];
			let product_spp = data_product.filter(item => {
				if (item.product_id.toLowerCase().indexOf("topup-54") !== -1) {
					return item;
				}
			});
			commit("changeDanaPartisipasi", {
				product_klaspay:
					product_spp && product_spp.length > 0 ? product_spp[0] : {}
			});

			return result.data.data || {};
		}
	},

	async setAdminDanaPartisipasi(context, payload = null) {
		const result = await ApiPostRequestPartisipasi(
			`klaspay/external/onklasproduct/set/id/${payload.id}`,
			payload.data
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error ? result.error : "Gagal setting biaya admin"
			});

			return false;
		} else {
			Message({
				type: "success",
				message: "Setting Biaya Admin berhasil"
			});

			return true;
		}
	},

	async getClassroom({commit}, payload) {
		commit("changeDanaPartisipasi", {
			loading: true
		});

		if (payload) {
			await commit("changeDanaPartisipasi", {});
		}

		const result = await ApiGetRequest(
			`dana-partisipasi/school/${payload.id}/student`
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			await commit("changeDanaPartisipasi", {
				classroom: result.data.data,
				loading: false
			});
		}
	},

	async getTableOngoing({commit, state}, payload) {
		commit("changeDanaPartisipasi", {
			loading: true
		});

		if (payload) {
			await commit("changeDanaPartisipasi", {});
		}

		const {data} = state;

		const params = {
			q: data.searchParams
		};

		const result = await ApiGetRequest(`klaspay/bill/list`, params);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			if (
				result.data.data.list_bill &&
				Array.isArray(result.data.data.list_bill)
			) {
				let dataDB = result.data.data.list_bill;
				let dafaFilter = _.where(dataDB, {is_expired: false});
				await commit("changeDanaPartisipasi", {
					itemsOngoing: dafaFilter,
					loading: false
				});
			}

			// await commit("changeDanaPartisipasi", {
			//   itemsOngoing: result.data.data.list_bill,
			//   loading: false,
			// });
		}
	},

	async getTableEnded({commit, state}, payload) {
		commit("changeDanaPartisipasi", {
			loading: true
		});

		if (payload) {
			await commit("changeDanaPartisipasi", {});
		}

		const {data} = state;

		const params = {
			q: data.searchParams
		};

		const result = await ApiGetRequest(`klaspay/bill/list`, params);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			if (
				result.data.data.list_bill &&
				Array.isArray(result.data.data.list_bill)
			) {
				let dataDB = result.data.data.list_bill;
				let dafaFilter = _.where(dataDB, {is_expired: true});
				await commit("changeDanaPartisipasi", {
					itemsEnded: dafaFilter,
					loading: false
				});
			}

			// await commit("changeDanaPartisipasi", {
			//   itemsOngoing: result.data.data.list_bill,
			//   loading: false,
			// });
		}
	},

	async updateStatusOngoing({commit}, payload) {
		commit("changeDanaPartisipasi", {
			loading: true
		});

		if (payload) {
			await commit("changeDanaPartisipasi", {});
		}

		const formData = {
			is_active: payload.isActive
		};

		const result = await ApiPostRequest(
			`klaspay/bill/activation/${payload.id}`,
			formData
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			Message({
				type: "success",
				message: "Data diubah status berhasil."
			});
			await commit("changeDanaPartisipasi", {
				loading: false
			});
		}
	},

	async getListClass({commit, state}, payload) {
		commit("changeDanaPartisipasi", {
			loading: true,
			listStudentInClass: []
			// listClass: [],
		});

		if (payload) {
			await commit("changeDanaPartisipasi", {});
		}

		const {data} = state;

		let classRooms = _.groupBy(data.classroom, "kelas");

		let listOfSubclass = Object.keys(classRooms);

		listOfSubclass.map(key => {
			let studentInClassrooms = classRooms[key];

			let studentWalletIdNull = _.where(studentInClassrooms, {
				wallet_id: null
			});

			if (!data.listClass.find(itemm => itemm.name === key)) {
				data.listClass.push({
					name: key,
					total: studentInClassrooms.length,
					student: studentInClassrooms,
					total_wallet_id_null: studentWalletIdNull.length
				});
			}
		});

		data.selectClass.map(studentClass => {
			studentClass.map(student => {
				data.listStudentInClass.push(student);
				// console.log(`listStudentInClass`, data.listStudentInClass);
			});
		});
	},

	async getDetailDataById({commit, state}, payload) {
		const {data} = state;

		commit("changeDanaPartisipasi", {
			loading: true
		});

		const result = await ApiGetRequest(`klaspay/bill/id/${payload.id}`);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			let formData = data.formData;
			formData.bill_id = result.data.data.bill_id;
			formData.bill_name = result.data.data.bill_name;
			formData.target_nominal = result.data.data.target_nominal;
			formData.is_active = result.data.data.is_active;
			formData.expired_date = result.data.data.expired_date;
			formData.list = result.data.data.list_child.length;
			formData.paid_nominal = result.data.data.paid_nominal;
			formData.list_child = result.data.data.list_child.length;
			let listChild = result.data.data.list_child;

			listChild.map(item => {
				let listName = item.name;
				let dataLocal = data.classroom;

				data.dataFindEdit = _.find(dataLocal, function (get) {
					return get.name == listName;
				});

				data.dataFindEdit = Object.assign({}, data.dataFindEdit, {
					bill_child_id: item.bill_id,
					target_nominal: item.target_nominal
				});

				data.dataEditStudent.push(data.dataFindEdit);
			});

			// Pengelompokan siswa berdasarkan kelas
			let classRoomsEdit = _.groupBy(data.dataEditStudent, "kelas");

			let listOfSubclass = Object.keys(classRoomsEdit);

			listOfSubclass.map(key => {
				// penggabungan data dari siswa yg belum terdapat
				// pada kelas yg sudah terdaftar pada dana partisiapsi
				// berdasarkan aktifasi klaspay

				// Mencari kelas berdasarkan data kelas yang
				// sudah dipilih pada saat edit data

				let searchChooseClass = _.where(data.listClass, {
					name: key
				});

				searchChooseClass.map(dataChoose => {
					let studentInClassrooms = classRoomsEdit[key];
					let dataMerge = studentInClassrooms.concat(dataChoose.student);
					let dataMergeUniqueUUID = _.uniq(dataMerge, x => x.user_uuid);

					if (dataMergeUniqueUUID.length > 0) {
						data.dataMergeUniqInClassrooms = dataMergeUniqueUUID;
					}
				});

				// let studentInClassrooms = classRoomsEdit[key];
				// console.log(`===studentInClassrooms`, studentInClassrooms);
				let studentWalletIdNull = _.where(data.dataMergeUniqInClassrooms, {
					wallet_id: null
				});

				data.listClass.push({
					name: key,
					total: data.dataMergeUniqInClassrooms.length,
					student: data.dataMergeUniqInClassrooms,
					total_wallet_id_null: studentWalletIdNull.length
				});

				data.selectClass.push(data.dataMergeUniqInClassrooms);
				// console.log(`----selectClass`, data.selectClass);

				data.dataMergeUniqInClassrooms.map(studentClass => {
					data.showEditStudent.push(studentClass);
				});
			});

			await commit("changeDanaPartisipasi", {
				loading: false,
				formData: formData
			});
		}
	},

	async getModalDetailProgram({commit, state}, payload) {
		const {data} = state;

		commit("changeDanaPartisipasi", {
			loading: true,
			dataDetailStudent: []
		});

		const result = await ApiGetRequest(`klaspay/bill/id/${payload.id}`);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			let formData = data.formData;
			formData.bill_id = result.data.data.bill_id;
			formData.bill_name = result.data.data.bill_name;
			formData.target_nominal = result.data.data.target_nominal;
			formData.is_active = result.data.data.is_active;
			formData.expired_date = result.data.data.expired_date;
			formData.list = result.data.data.list_child.length;
			formData.paid_nominal = result.data.data.paid_nominal;
			let listChild = result.data.data.list_child;

			listChild.map(item => {
				let listName = item.name;
				let dataLocal = data.classroom;

				// pencarian data berdasarkan nama

				data.dataFindDetail = _.find(dataLocal, function (get) {
					return get.name == listName;
				});

				data.dataDetailStudent.push(data.dataFindDetail);
			});

			// pengelompokan siswa berdasarkan kelas

			let classRoomsDetail = _.groupBy(data.dataDetailStudent, "kelas");

			let listOfSubclass = Object.keys(classRoomsDetail);
			listOfSubclass.map(key => {
				let studentInClassrooms = classRoomsDetail[key];
				data.selectClass.push({
					name: key,
					total: studentInClassrooms.length,
					student: studentInClassrooms
				});
			});
			await commit("changeDanaPartisipasi", {
				loading: false,
				formData: formData
			});
		}
	},

	async getModalDetailPayment({commit, state}, payload) {
		const {data} = state;

		commit("changeDanaPartisipasi", {
			loading: true
		});

		const result = await ApiGetRequest(`klaspay/bill/history/id/${payload.id}`);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			let listWallet = result.data.data.wallet_id;
			let dataLocal = data.classroom;

			data.dataModalDetailPaymentFind = _.find(dataLocal, function (get) {
				return get.wallet_id == listWallet;
			});

			data.dataModalDetailPayment.name = data.dataModalDetailPaymentFind.name;
			data.dataModalDetailPayment.nisn = data.dataModalDetailPaymentFind.nisn;
			data.dataModalDetailPayment.kelas = data.dataModalDetailPaymentFind.kelas;
			data.dataModalDetailPayment.bill_name = result.data.data.bill_name;
			data.dataModalDetailPayment.target_nominal =
				result.data.data.target_nominal;
			data.dataModalDetailPayment.updated_date = result.data.data.updated_date;
			data.dataModalDetailPayment.bill_status = result.data.data.bill_status;
			data.dataModalDetailPayment.list_child = result.data.data.list_child;
		}
	},

	async submitDanaPartisipasi({commit, state}) {
		const {data} = state;

		commit("changeDanaPartisipasi", {
			loading: true
		});

		if (data.formData.bill_id === "") {
			data.selectClass.map(classStudent => {
				let filterStudentWalletId = _.filter(classStudent, function (data) {
					return data.wallet_id != null;
				});

				// console.log(`===filterStudentWalletId`, filterStudentWalletId);

				filterStudentWalletId.map(student => {
					data.formData.list.push({
						wallet_id: student.wallet_id,
						target_nominal: parseInt(data.formData.target_nominal)
					});
				});
			});

			const dataForm = {
				bill_name: data.formData.bill_name,
				is_active: data.formData.is_active,
				expired_date: moment(data.formData.expired_date).format(),
				list: data.formData.list
			};

			let result = "";

			if (data.formData.id) {
				result = await ApiPostRequest(
					`klaspay/bill/${data.formData.id}`,
					dataForm
				);
			} else {
				result = await ApiPostRequest(`klaspay/bill`, dataForm);
			}

			if (result.error) {
				Message({
					type: "error",
					message: result.error
				});

				if (result.errorList) {
					let errors = [];
					for (const [key, value] of Object.entries(result.errorList)) {
						errors.push({
							message: value[0],
							path: [key]
						});
					}
					commit("changeDanaPartisipasi", {
						errors: errors,
						loading: false
					});
				}
			} else {
				Message({
					type: "success",
					message: "Data berhasil tersimpan."
				});

				commit("changeDanaPartisipasi", {
					errors: [],
					formData: {
						id: "",
						bill_id: "",
						bill_name: "",
						is_active: null,
						expired_date: "",
						target_nominal: 0,
						wallet_id: "",
						list: [],
						listEdit: []
					},
					loading: false
				});

				return true;
			}
		} else {
			data.selectClass.map(classStudent => {
				let filterStudentWalletId = _.filter(classStudent, function (data) {
					return data.wallet_id != null;
				});

				filterStudentWalletId.map(student => {
					data.formData.listEdit.push({
						bill_id: student.bill_child_id,
						wallet_id: student.wallet_id,
						target_nominal: parseInt(student.target_nominal)
					});
				});
			});

			// let filterBillId = _.filter(data.formData.listEdit, function(item) {
			//   return item.bill_id == null;
			// });

			// let dataUniqueUUID = _.uniq(filterBillId, (x) => x.user_uuid);

			// dataUniqueUUID.forEach((editAddStudent) => {
			//   data.addStudentEdit.push({
			//     target_nominal: editAddStudent.target_nominal,
			//     wallet_id: editAddStudent.wallet_id,
			//   });
			// });

			// console.log(`====addStudentEdit`, filterBillId);

			const dataForm = {
				bill_id: data.formData.bill_id,
				bill_name: data.formData.bill_name,
				is_active: data.formData.is_active,
				expired_date: data.formData.expired_date,
				list: data.formData.listEdit
			};
			// console.log(`====dataForm`, dataForm);

			const result = await ApiPostRequest(
				`klaspay/bill/${data.formData.bill_id}`,
				dataForm
			);

			if (result.error) {
				Message({
					type: "error",
					message: result.error
				});

				if (result.errorList) {
					let errors = [];
					for (const [key, value] of Object.entries(result.errorList)) {
						errors.push({
							message: value[0],
							path: [key]
						});
					}
					commit("changeDanaPartisipasi", {
						errors: errors,
						loading: false
					});
				}
			} else {
				Message({
					type: "success",
					message: "Data berhasil tersimpan."
				});

				commit("changeDanaPartisipasi", {
					errors: [],
					formData: {
						id: "",
						bill_id: "",
						bill_name: "",
						is_active: null,
						expired_date: "",
						target_nominal: 0,
						wallet_id: "",
						list: [],
						listEdit: []
					},
					loading: false
				});

				return true;
			}
		}
	},

	async submitPerSiswaDanaPartisipasi({commit, state, getters}) {
		const {data} = state;

		commit("changeDanaPartisipasi", {
			loading: true
		});

		/*data.selectClass.map(classStudent => {
			let filterStudentWalletId = _.filter(classStudent, function(data) {
				return data.wallet_id != null;
			});

			filterStudentWalletId.map(student => {
				data.formData.list.push({
					wallet_id: student.wallet_id,
					target_nominal: parseInt(data.formData.target_nominal)
				});
			});
		});*/
		const resultList = _.map(getters.mapDataStudent2, item => {
			return {
				target_nominal: parseInt(item.target_nominal),
				wallet_id: item.wallet_id
			};
		});

		const dataForm = {
			bill_name: data.formData.bill_name,
			is_active: data.formData.is_active,
			expired_date: moment(data.formData.expired_date).format(),
			list: resultList
		};
		console.log(dataForm);

		let result = "";

		if (data.formData.id) {
			result = await ApiPostRequest(
				`klaspay/bill/${data.formData.id}`,
				dataForm
			);
		} else {
			result = await ApiPostRequest(`klaspay/bill`, dataForm);
		}

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			if (result.errorList) {
				let errors = [];
				for (const [key, value] of Object.entries(result.errorList)) {
					errors.push({
						message: value[0],
						path: [key]
					});
				}
				commit("changeDanaPartisipasi", {
					errors: errors,
					loading: false
				});
			}
		} else {
			Message({
				type: "success",
				message: "Data berhasil tersimpan."
			});

			commit("changeDanaPartisipasi", {
				errors: [],
				formData: {
					id: "",
					bill_id: "",
					bill_name: "",
					is_active: null,
					expired_date: "",
					target_nominal: 0,
					wallet_id: "",
					list: [],
					listEdit: []
				},
				loading: false
			});

			return true;
		}
	},

	// async submitDanaPartisipasi({ commit, state }) {
	//   const { data } = state;

	//   commit("changeDanaPartisipasi", {
	//     loading: true,
	//   });

	//   if (data.formData.bill_id == "") {
	//     data.selectClass.map((classStudent) => {
	//       let filterStudentWalletId = _.filter(classStudent, function(data) {
	//         return data.wallet_id != null;
	//       });

	//       console.log(`===filterStudentWalletId`, filterStudentWalletId);

	//       filterStudentWalletId.map((student) => {
	//         data.formData.list.push({
	//           wallet_id: student.wallet_id,
	//           target_nominal: parseInt(data.formData.target_nominal),
	//         });
	//       });
	//     });

	//     const dataForm = {
	//       bill_name: data.formData.bill_name,
	//       is_active: data.formData.is_active,
	//       expired_date: moment(data.formData.expired_date).format(),
	//       list: data.formData.list,
	//     };

	//     let result = "";

	//     if (data.formData.id) {
	//       result = await ApiPostRequest(
	//         `klaspay/bill/${data.formData.id}`,
	//         dataForm
	//       );
	//     } else {
	//       result = await ApiPostRequest(`klaspay/bill`, dataForm);
	//     }

	//     if (result.error) {
	//       Message({
	//         type: "error",
	//         message: result.error,
	//       });

	//       if (result.errorList) {
	//         let errors = [];
	//         for (const [key, value] of Object.entries(result.errorList)) {
	//           errors.push({
	//             message: value[0],
	//             path: [key],
	//           });
	//         }
	//         commit("changeDanaPartisipasi", {
	//           errors: errors,
	//           loading: false,
	//         });
	//       }
	//     } else {
	//       Message({
	//         type: "success",
	//         message: "Data berhasil tersimpan.",
	//       });

	//       commit("changeDanaPartisipasi", {
	//         errors: [],
	//         formData: {
	//           id: "",
	//           bill_id: "",
	//           bill_name: "",
	//           is_active: null,
	//           expired_date: "",
	//           target_nominal: 0,
	//           wallet_id: "",
	//           list: [],
	//           listEdit: [],
	//         },
	//         loading: false,
	//       });

	//       return true;
	//     }
	//   } else {
	//     data.selectClass.map((classStudent) => {
	//       let filterStudentWalletId = _.filter(classStudent, function(data) {
	//         return data.wallet_id != null;
	//       });

	//       filterStudentWalletId.map((student) => {
	//         data.formData.listEdit.push({
	//           bill_id: student.bill_child_id,
	//           wallet_id: student.wallet_id,
	//           target_nominal: parseInt(data.formData.target_nominal),
	//         });
	//       });
	//     });

	//     let filterBillId = _.filter(data.formData.listEdit, function(item) {
	//       return item.bill_id == null;
	//     });

	//     let dataUniqueUUID = _.uniq(filterBillId, (x) => x.user_uuid);

	//     dataUniqueUUID.forEach((editAddStudent) => {
	//       data.addStudentEdit.push({
	//         target_nominal: editAddStudent.target_nominal,
	//         wallet_id: editAddStudent.wallet_id,
	//       });
	//     });

	//     console.log(`====addStudentEdit`, filterBillId);

	//     if (filterBillId.length > 0) {
	//       const dataForm = {
	//         bill_id: data.formData.bill_id,
	//         bill_name: data.formData.bill_name,
	//         is_active: data.formData.is_active,
	//         expired_date: data.formData.expired_date,
	//         list: data.addStudentEdit,
	//       };

	//       console.log(`====data.formData.bill_id`, data.formData.bill_id);
	//       console.log(`====data.addStudentEdit`, data.addStudentEdit);

	//       const result = await ApiPostRequest(
	//         `klaspay/bill/${data.formData.bill_id}`,
	//         dataForm
	//       );

	//       if (result.error) {
	//         Message({
	//           type: "error",
	//           message: result.error,
	//         });

	//         if (result.errorList) {
	//           let errors = [];
	//           for (const [key, value] of Object.entries(result.errorList)) {
	//             errors.push({
	//               message: value[0],
	//               path: [key],
	//             });
	//           }
	//           commit("changeDanaPartisipasi", {
	//             errors: errors,
	//             loading: false,
	//           });
	//         }
	//       } else {
	//         Message({
	//           type: "success",
	//           message: "Data berhasil tersimpan.",
	//         });

	//         commit("changeDanaPartisipasi", {
	//           errors: [],
	//           formData: {
	//             id: "",
	//             bill_id: "",
	//             bill_name: "",
	//             is_active: null,
	//             expired_date: "",
	//             target_nominal: 0,
	//             wallet_id: "",
	//             list: [],
	//             listEdit: [],
	//           },
	//           loading: false,
	//         });

	//         return true;
	//       }
	//     } else {
	//       const dataForm = {
	//         bill_id: data.formData.bill_id,
	//         bill_name: data.formData.bill_name,
	//         is_active: data.formData.is_active,
	//         expired_date: data.formData.expired_date,
	//         list: data.formData.listEdit,
	//       };
	//       console.log(`====data.formData.bill_id`, data.formData.bill_id);
	//       console.log(`====data.formData.listEdit`, data.formData.listEdit);

	//       const result = await ApiPostRequest(
	//         `klaspay/bill/${data.formData.bill_id}`,
	//         dataForm
	//       );

	//       if (result.error) {
	//         Message({
	//           type: "error",
	//           message: result.error,
	//         });

	//         if (result.errorList) {
	//           let errors = [];
	//           for (const [key, value] of Object.entries(result.errorList)) {
	//             errors.push({
	//               message: value[0],
	//               path: [key],
	//             });
	//           }
	//           commit("changeDanaPartisipasi", {
	//             errors: errors,
	//             loading: false,
	//           });
	//         }
	//       } else {
	//         Message({
	//           type: "success",
	//           message: "Data berhasil tersimpan.",
	//         });

	//         commit("changeDanaPartisipasi", {
	//           errors: [],
	//           formData: {
	//             id: "",
	//             bill_id: "",
	//             bill_name: "",
	//             is_active: null,
	//             expired_date: "",
	//             target_nominal: 0,
	//             wallet_id: "",
	//             list: [],
	//             listEdit: [],
	//           },
	//           loading: false,
	//         });

	//         return true;
	//       }
	//     }
	//   }
	// },

	async getList({commit, state}, payload) {
		commit("changeDanaPartisipasi", {
			loading: true
		});

		const {data} = state;

		if (payload) {
			await commit("changeDanaPartisipasi", {});
		}

		const result = await ApiGetRequest(`klaspay/bill/list`);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			if (
				result.data.data.list_bill &&
				Array.isArray(result.data.data.list_bill)
			) {
				result.data.data.list_bill.map(item => {
					data.listProgram.push({
						program: item.bill_name,
						bill_id: item.bill_id
					});
				});
				await commit("changeDanaPartisipasi", {
					loading: false
				});
			}
		}
	},

	async getListByUUID({commit, state}, payload) {
		const {data} = state;

		commit("changeDanaPartisipasi", {
			loading: true
		});

		const result = await ApiGetRequest(`klaspay/bill/id/${payload.id}`);

		if (result.error) {
			Message({
				type: "error",
				message: result.error
			});

			commit("changeDanaPartisipasi", {
				loading: false
			});

			return [];
		} else {
			let listProgramDetail = data.listProgramDetail;

			listProgramDetail.program = result.data.data.bill_name;
			listProgramDetail.data_expired = result.data.data.expired_date;
			listProgramDetail.participation = result.data.data.target_nominal;
			listProgramDetail.bill_id = result.data.data.bill_id;
			listProgramDetail.paid_nominal = result.data.data.paid_nominal;

			let listChild = result.data.data.list_child;

			listChild.map(item => {
				let listName = item.name;
				let dataLocal = data.classroom;

				// pencarian data berdasarkan nama

				data.dataFindDetail = _.find(dataLocal, function (get) {
					return get.name == listName;
				});

				data.listProgramDetail.detailClassroom.push({
					program: item.bill_name,
					date_expired: item.expired_date,
					partisipasi: item.target_nominal,
					payment_time: item.created_date,
					bill_id: item.bill_id,
					name: item.name,
					nisn:
						data.dataFindDetail && data.dataFindDetail.nisn !== undefined
							? data.dataFindDetail.nisn
							: ``,
					kelas:
						data.dataFindDetail && data.dataFindDetail.kelas !== undefined
							? data.dataFindDetail.kelas
							: ``,
					grade:
						data.dataFindDetail && data.dataFindDetail.grade !== undefined
							? data.dataFindDetail.grade
							: ``,
					target_nominal: item.target_nominal,
					paid_nominal: item.paid_nominal
				});
			});

			// console.log(`=====data.listProgramDetail`, data.listProgramDetail);
			await commit("changeDanaPartisipasi", {
				loading: false,
				listProgramDetail: listProgramDetail
			});
		}
	}
};

const getters = {
	dataStudentGetters: state => {
		const {selectOnlyClassName, filter_student, formData} = state.data;
		return (selectOnlyClassName
				? state.data.classroom
					.filter(item => selectOnlyClassName.includes(item.kelas))
					.filter(item => {
						if (filter_student) {
							const itemNameLower = item.name.toLowerCase();
							const filterLower = filter_student.toLowerCase();
							const itemKelasLower = item.kelas.toLowerCase();
							return (
								itemNameLower.indexOf(filterLower) !== -1 ||
								itemKelasLower.indexOf(filterLower) !== -1
							);
						} else {
							return true;
						}
					})
					.map(item => {
						const cartNominal = state.data.cartNominalTarget.find(
							nom => nom.user_uuid === item.user_uuid
						);
						let target_nominal = parseInt(formData.target_nominal) || 0;
						if (cartNominal) {
							target_nominal = parseInt(cartNominal.target_nominal);
						}
						return {
							...item,
							target_nominal: target_nominal
						};
					})
				: []
		).sort((a, b) => {
			// Kedua, urutkan berdasarkan wallet_id. Jika salah satu null, letakkan di akhir.
			if (!a.wallet_id) return 1;
			if (!b.wallet_id) return -1;
			if (a.wallet_id < b.wallet_id) return -1;
			if (a.wallet_id > b.wallet_id) return 1;

			// Pertama, urutkan berdasarkan kelas
			if (a.kelas < b.kelas) return -1;
			if (a.kelas > b.kelas) return 1;

			// Jika semuanya sama, return 0
			return 0;
		});
	},

	kelasDataStudentGetters2: (state, getters) => {
		return _.uniq(_.map(getters.dataStudentGetters2, "kelas"));
	},

	mapDataStudent2: state => {
		const {classroom, formData} = state.data;
		return state.data.selectStudents.map(item => {
			const cartNominal = state.data.cartNominalTarget.find(
				nom => nom.user_uuid === item
			);
			let target_nominal = parseInt(formData.target_nominal) || 0;
			if (cartNominal) {
				target_nominal = parseInt(cartNominal.target_nominal);
			}
			const student = classroom.find(std => std.user_uuid === item);
			return {
				name: student.name || "-",
				kelas: student.kelas || "-",
				user_uuid: student.user_uuid || "-",
				user_id: student.user_id || "-",
				wallet_id: student.wallet_id || "-",
				target_nominal: target_nominal
			};
		});
	},
	dataStudentGetters2: (state, getters) => {
		const {filter_student2, filter_kelas2} = state.data;
		return getters.mapDataStudent2
			.filter(item => {
				if (filter_kelas2) {
					const itemKelasLower = item.kelas;
					return itemKelasLower.indexOf(filter_kelas2) !== -1;
				} else {
					return true;
				}
			})
			.filter(item => {
				if (filter_student2) {
					const itemNameLower = item.name.toLowerCase();
					const itemKelasLower = item.kelas.toLowerCase();
					const filterLower = filter_student2.toLowerCase();
					return (
						itemNameLower.indexOf(filterLower) !== -1 ||
						itemKelasLower.indexOf(filterLower) !== -1
					);
				} else {
					return true;
				}
			});
	},

	itemsOngoingGetter: state => {
		const {itemsOngoing, filterNamePartisipasi} = state.data;
		if (!filterNamePartisipasi) {
			return itemsOngoing;
		}
		try {
			const filterLowerCase = filterNamePartisipasi.toLowerCase();
			return itemsOngoing.filter(item => {
				const billNameLowerCase = item.bill_name.toLowerCase();
				return billNameLowerCase.includes(filterLowerCase);
			});
		} catch (e) {
			console.log("itemsOngoingGetter", e);
			return itemsOngoing;
		}
	},

	isDataNotFoundGetter: state => {
		const {filterNamePartisipasi, loading, itemsOngoing} = state.data;
		return !filterNamePartisipasi && !loading && !itemsOngoing.length
	}

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
