import {
  ApiGetRequest,
  ApiPutRequest,
  ApiPostRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
import { Message } from "element-ui";
// import moment from "moment";

const state = {
  data: {
    columns_group_magang: [
      {
        prop: "teacher",
        label: "Nama Guru",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nip",
        label: "NIP",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "start_date",
        label: "Tanggal Mulai",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "end_date",
        label: "Tanggal Berakhir",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Setting Jadwal",
        width: "150",
        "class-name": "body-14 col-black-2 text-center",
      },
    ],
    columns_jadwal: [
      {
        prop: "day",
        label: "Hari",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "start_time",
        label: "Waktu Mulai",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "end_time",
        label: "Waktu Berakhir",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2 text-center",
      },
    ],
    editdata: false,
    timesDefault: [
      {
        day: 1,
        check: false,
        label: "Senin",
        start_at: "",
        end_at: "",
      },
      {
        day: 2,
        check: false,
        label: "Selasa",
        start_at: "",
        end_at: "",
      },
      {
        day: 3,
        check: false,
        label: "Rabu",
        start_at: "",
        end_at: "",
      },
      {
        day: 4,
        check: false,
        label: "Kamis",
        start_at: "",
        end_at: "",
      },
      {
        day: 5,
        check: false,
        label: "Jumat",
        start_at: "",
        end_at: "",
      },
      {
        day: 6,
        check: false,
        label: "Sabtu",
        start_at: "",
        end_at: "",
      },
      {
        day: 7,
        check: false,
        label: "Minggu",
        start_at: "",
        end_at: "",
      },
    ],
    usertimes: [],
    rowuser: {
      name: "",
      custom_users: [],
      times: [],
    },
    dataDay: [
      {
        id: 1,
        name: "Senin",
      },
      {
        id: 2,
        name: "Selasa",
      },
      {
        id: 3,
        name: "Rabu",
      },
      {
        id: 4,
        name: "Kamis",
      },
      {
        id: 5,
        name: "Jumat",
      },
      {
        id: 6,
        name: "Sabtu",
      },
    ],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    search: "",
    errors: [],
    itemsTable: [],
    itemsTableSchedule: [],
    dataMagang: [],
    detailSchedule: {},
    formData: {
      id: null,
      day: null,
      start_time: "",
      end_time: "",
    },
    activeTabMenu: "Data Perusahaan",
  },
};

const mutations = {
  changeMagangJadwal(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getDataSettingInternship({ commit, state }, payload) {
    commit("changeMagangJadwal", {
      loading: true,
    });
    const { data } = state;
    if (payload) {
      await commit("changeMagangJadwal", {
        page: data.page,
      });
    }
    let params = {
      q: data.search,
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };
    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group`,
      params,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangJadwal", {
        loading: false,
      });
    } else {
      commit("changeMagangJadwal", {
        itemsTable: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },

  async getDataSchedule({ commit, state }, payload) {
    commit("changeMagangJadwal", {
      loading: true,
    });
    const { data } = state;

    if (payload) {
      await commit("changeMagangJadwal", {
        pageSiswa: data.pageSiswa,
      });
    }

    let params = {
      q: data.search,
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };
    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/schedule`,
      params,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagangJadwal", {
        loading: false,
      });
    } else {
      commit("changeMagangJadwal", {
        itemsTableSchedule: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },

  async deleteDataSchedule({ commit }, payload) {
    commit("changeMagangJadwal", {
      loading: true,
    });
    const result = await ApiDeleteRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/schedule/${payload.schedule_id}`,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangJadwal", {
        loading: false,
      });
    } else {
      commit("changeMagangJadwal", {
        loading: false,
      });
      Message({
        type: "success",
        message: "Data berhasil dihapus.",
      });
    }
  },

  async getDataScheduleDetail({ commit }, payload) {
    commit("changeMagangJadwal", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/schedule/${payload.schedule_id}`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagangJadwal", {
        loading: false,
      });
    } else {
      commit("changeMagangJadwal", {
        detailSchedule: result.data.data,
        loading: false,
      });
    }
  },

  async submitSchedule({ commit, state }, payload) {
    commit("changeMagangJadwal", {
      loading: true,
    });

    const { data } = state;

    let bodyForm = {
      day: data.formData.day,
      start_time: data.formData.start_time,
      end_time: data.formData.end_time,
    };
    const result = await ApiPostRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/schedule`,
      bodyForm,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangJadwal", {
        loading: false,
      });
    } else {
      commit("changeMagangJadwal", {
        loading: false,
        status: "sukses",
      });
      Message({
        type: "success",
        message: "Data berhasil disimpan.",
      });
    }
  },

  async editSchedule({ commit, state }, payload) {
    commit("changeMagangJadwal", {
      loading: true,
    });

    const { data } = state;
    // let startTime = moment(data.formData.start_time).format("HH:mm");
    // let endTime = moment(data.formData.start_time).format("HH:mm");

    let bodyForm = {
      day: data.formData.day,
      start_time: data.formData.start_time,
      end_time: data.formData.end_time,
    };
    const result = await ApiPutRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/schedule/${payload.schedule_id}`,
      bodyForm,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangJadwal", {
        loading: false,
      });
    } else {
      commit("changeMagangJadwal", {
        loading: false,
        status: "sukses",
      });
      Message({
        type: "success",
        message: "Data berhasil disimpan.",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
