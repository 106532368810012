import { Message } from "element-ui";
import SuratMasukSekolahImplement from "@/api/SuratMasukSekolahImplement";
import ApiService from "@/utils/ApiService";
import { checkVw, convertErrorsToArray } from "@/services/internalService";
import Vue from "vue";
import InternalImplement from "@/api/InternalImplement";

const state = {
  data: {
    id: "",
    isServerError: false,
    loading: false,
    loadingSenderAll: false,
    showmodal: false,
    filter: "",
    filterLetterDate: null,
    sortBy: "agenda_number",
    sortType: "desc",
    items: [],
    itemsAll: [],
    itemsSenderAll: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    typeAction: "read",
    detailFormData: {},
    listLimit: [10, 25, 50, 100],
    columns: [
      {
        prop: "letter_number",
        label: "Nomor Surat",
        width: 160,
        fixed: window.innerWidth < 768 ? undefined : "left",
        sortable: true,
      },
      {
        prop: "date",
        label: "Tanggal Input",
        width: 180,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "sender",
        label: "Surat Dari",
        minWidth: 220,
      },
      {
        prop: "officer",
        label: "Petugas",
        width: 180,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(130),
        "class-name": "body-14 col-black-2",
      },
    ],
    formData: {
      id: null,
      uuid: null,
      letter_number: null,
      date: null,
      file: null,
      sender: null,
      subject: null,
      department: null,
      creator: {},
      updated_at: null,
      created_at: null,
    },
  },
};

const mutations = {
  changeSuratMasukSekolah(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },

  changeFormDataSuratMasukSekolahObjectAssign(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeFormDataSuratMasukSekolah(state, payload) {
    state.data.formData = {
      id: payload.id,
      letter_number: payload.letter_number,
      date: payload.date,
      officer: payload.officer,
      sender: payload.sender,
    };
  },

  changeLoading(state, payload) {
    state.data.loading = payload;
  },

  changeLoadingSenderAll(state, payload) {
    state.data.loadingSenderAll = payload;
  },

  ADD_ITEM_SENDERALL(state, purposeItem) {
    if (!state.data.itemsSenderAll.includes(purposeItem)) {
      state.data.itemsSenderAll.push(purposeItem);
    }
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  ADD_ITEMS(state, payload) {
    state.data.items.unshift(payload);
  },

  /**
   * Proses pembaruan item di dalam state.items.
   *
   * @function
   * @param {Object} state - State saat ini.
   * @param {Object} payload - Harus berupa objek yang mengandung id item yang akan diperbarui dan data baru.
   */
  UPDATE_ITEMS(state, payload) {
    // Mencari index item yang akan diperbarui berdasarkan id atau properti unik lainnya.
    const index = state.data.items.findIndex((item) => item.id === payload.id);

    // Jika item ditemukan, lakukan pembaruan.
    if (index !== -1) {
      state.data.items[index] = payload;
      Vue.set(state.data.items, index, payload);
    }
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  DELETE_ITEMS(state, payload) {
    const { detailFormData } = state.data;
    let id = detailFormData.id;
    if (payload.id) {
      id = payload.id;
    }
    const index = state.data.items.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.data.items.splice(index, 1);
    }
  },
};

const actions = {
  actionRead({ commit }) {
    commit("changeSuratMasukSekolah", { typeAction: "read" });
  },
  actionCreate({ commit }) {
    commit("changeSuratMasukSekolah", {
      detailFormData: {},
    });
    commit("changeSuratMasukSekolah", { typeAction: "create" });
  },
  actionEdit({ commit }) {
    commit("changeSuratMasukSekolah", { typeAction: "edit" });
  },
  actionUpload({ commit }) {
    commit("changeSuratMasukSekolah", { typeAction: "upload" });
  },
  actionDelete({ commit }) {
    commit("changeSuratMasukSekolah", { typeAction: "delete" });
  },

  async getSuratMasukSekolah({ commit, state }, payload = {}) {
    if (!isNaN(payload)) {
      commit("changeSuratMasukSekolah", { page: payload });
    }
    if (payload.page) {
      commit("changeSuratMasukSekolah", { page: payload.page });
    }

    if (payload.sortBy) {
      commit("changeSuratMasukSekolah", {
        sortBy: payload.sortBy,
        sortType: payload.sortType,
      });
    }

    const { filterLetterDate, filter, page, limit, sortBy, sortType, loading } =
      state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    const params = {
      q: filter,
      sort_field: sortBy,
      sort_dir: sortType || "asc",
      letter_date: filterLetterDate,
      limit: limit,
      page: parseInt(page),
    };

    try {
      commit("changeLoading", true);
      const suratMasukSekolahService = new SuratMasukSekolahImplement(
        ApiService,
      );
      const result =
        await suratMasukSekolahService.fetchSuratMasukSekolah(params);
      commit("changeLoading", false);

      commit("changeSuratMasukSekolah", {
        items: result?.data?.data || [],
        total: result?.data?.meta?.total || result?.data?.data.length,
        page: result?.data?.meta?.current_page || 1,
        lastPage: result?.data?.meta?.last_page || 1,
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);
      commit("changeSuratMasukSekolah", {
        items: [],
        total: 0,
        page: 1,
        lastPage: 1,
      });
      const statusCode = error?.response?.status || 500;
      console.log("===>", error.response);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        Message({
          type: "error",
          message: data.error || "Gagal Mengambil data",
        });
      }
      return false;
    }
  },

  async getSenderAll({ commit }) {
    try {
      commit("changeLoadingSenderAll", true);
      const internalService = new InternalImplement(ApiService);
      const result = await internalService.sender();
      commit("changeLoadingSenderAll", false);
      commit("changeSuratMasukSekolah", {
        itemsSenderAll: result?.data?.data || [],
      });
      return true;
    } catch (error) {
      commit("changeSuratMasukSekolah", {
        itemsSenderAll: [],
      });
      commit("changeLoadingSenderAll", false);
      return false;
    }
  },

  async postSuratMasukSekolah({ commit, state }) {
    const { loading } = state.data;
    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      commit("changeLoading", true);
      const { sender, file, letter_number, date } = state.data.formData;
      const formData = {
        sender,
        letter_number,
      };
      if (file) {
        formData.file = file;
      }
      if (date) {
        formData.date = date;
      }
      const suratMasukSekolahService = new SuratMasukSekolahImplement(
        ApiService,
      );
      const result =
        await suratMasukSekolahService.addSuratMasukSekolah(formData);
      commit("changeLoading", false);
      commit("ADD_ITEM_SENDERALL", formData.sender);
      commit("ADD_ITEMS", result.data.data);
      commit("changeSuratMasukSekolah", { formData: {} });
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        if (data.errors) {
          let errors = await convertErrorsToArray(data.errors);
          commit("changeSuratMasukSekolah", {
            errors: errors,
          });
          return false;
        }
        Message({
          type: "error",
          message: data.error || "Gagagal diproses",
        });
      }
      return false;
    }
  },

  async putSuratMasukSekolah({ commit, state, dispatch }) {
    const { loading } = state.data;
    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      commit("changeLoading", true);
      const { sender, id, letter_number, date } = state.data.formData;
      const formData = {
        sender,
        letter_number,
      };
      if (date) {
        formData.date = date;
      }
      const agendaSuratService = new SuratMasukSekolahImplement(ApiService);
      const result = await agendaSuratService.updateSuratMasukSekolah(
        id,
        formData,
      );
      commit("changeLoading", false);
      commit("ADD_ITEM_SENDERALL", formData.sender);
      commit("UPDATE_ITEMS", result.data.data);
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      dispatch("postFileSuratMasuk");
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        if (data.errors) {
          let errors = await convertErrorsToArray(data.errors);
          commit("changeSuratMasukSekolah", {
            errors: errors,
          });
          return false;
        }
        if (statusCode === 403) {
          Message({
            type: "error",
            message:
              "Maaf Hanya dapat diubah Oleh Petugas yang membuat data tersebut",
          });
          return false;
        }
        Message({
          type: "error",
          message: data.error || "Gagagal diproses",
        });
      }
      return false;
    }
  },

  async postFileSuratMasuk({ commit }) {
    try {
      const { file, id } = state.data.formData;
      if (!file) {
        return false;
      }
      const formData = {
        file,
        id,
      };
      commit("changeLoading", true);
      const suratMasukSekolahService = new SuratMasukSekolahImplement(
        ApiService,
      );
      const result =
        await suratMasukSekolahService.uploadFileSuratMasukSekolah(formData);
      commit("changeLoading", false);
      commit("UPDATE_ITEMS", result.data.data);
      commit("changeSuratMasukSekolah", { formData: {} });
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      const { data } = error.response;
      if (statusCode > 0 && statusCode < 500) {
        Message({
          type: "error",
          message: "Berkas " + data.error || "Berkas Gagagal diproses",
        });
      }
      Message({
        type: "error",
        message: "Layanan Upload Berkas Terkendala, Hubungi Admin!",
      });
      return false;
    }
  },
  async deleteSuratMasukSekolah({ commit }, payload) {
    if (!payload.id) {
      Message({
        type: "error",
        message: "ID tidak ditemukan",
      });
      return false;
    }
    try {
      commit("changeLoading", true);
      const agendaSuratService = new SuratMasukSekolahImplement(ApiService);
      await agendaSuratService.delete(payload.id);
      commit("changeLoading", false);
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      commit("changeSuratMasukSekolah", { formData: {} });
      commit("DELETE_ITEMS", { id: payload.id });
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        if (statusCode === 403) {
          Message({
            type: "error",
            message:
              "Maaf Hanya dapat dihapus Oleh Petugas yang membuat data tersebut",
          });
          return false;
        }

        Message({
          type: "error",
          message: data.error || "Gagagal diproses",
        });
      }
      return false;
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
