import {
  ApiGetRequestDepoPayment,
  ApiPostRequestDepoPayment,
} from "@/utils/Api";
import { Message } from "element-ui";
import { HandlerNotification } from "@/utils/Helpers";
import { defaultDetailPayment } from "@/services/paymentService";

const state = {
  data: {
    paymentType: "BANK",
    detailPayment: defaultDetailPayment,
    errors: [],
    loading: false,
    listVirtualAccount: [],
    formPayment: {
      nominal: 0,
      cycle: "bulan",
      payment_method: "",
    },
  },
};

const mutations = {
  changePayment(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getListPayment({ commit }) {
    let result = await ApiGetRequestDepoPayment(`channel/method/active`);

    if (result.error) {
      let message = "Metode pembayaran bermasalah, Tunggu beberapa saat lagi";

      if (
        !result.error.response ||
        (result.error.response && result.error.response.status !== 404)
      ) {
        message = result.error;
      }

      Message({
        type: "error",
        message: message,
      });

      commit("changePayment", {
        loading: false,
        listVirtualAccount: [],
      });
      return false;
    }

    commit("changePayment", {
      listVirtualAccount: result.data.data || [],
    });
    return true;
  },

  async submitCreatePaymentRetail({ commit, state }, payload) {
    const { data } = state;
    if (
      data.formPayment.nominal === 0 ||
      data.formPayment.payment_method === ""
    ) {
      Message({
        type: "error",
        message: "Permintaan Kurang lengkap, Harap ulangi lagi!",
      });
      return false;
    }

    const params = {
      user_id: `SC${payload.school_id}`,
      school_id: payload.school_id,
    };
    const queryString = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    const finalQueryString = `?${queryString}`;

    const formData = {
      subscription_plan_id: payload.product_id,
      cycle: payload.cycle,
      retail_code: payload.retail_code,
      payment_channel: "linkqu",
      bank_code: "",
      school_fee_invoice: "",
      voucher: "",
      transaction_note: payload.transaction_note || "",
      customer_name: payload.customer_name.substring(0, 20),
      customer_phone: payload.customer_phone.substring(0, 20),
      customer_email: payload.customer_email,
    };

    let result = await ApiPostRequestDepoPayment(
      `subscription-plan/create-retail${finalQueryString}`,
      formData,
    );

    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification("error", "Server Error");
        return;
      } else if (result.code === 403) {
        HandlerNotification(
          "error",
          "Sekolah anda tidak bisa menggunakan Paket ini! Pilih Paket yang lebih tinggi.",
        );
        return;
      }

      Message({
        type: "error",
        message: "Gagal membuat pembayaran!",
      });
      console.log(result.error);
      return false;
    }

    HandlerNotification();

    const dataResponse = result.data.data;
    commit("changePayment", {
      detailPayment: {
        reff_id: dataResponse.partner_reff2,
        payment_method: dataResponse.retail_code,
        expired_at: dataResponse.expired,
        total: dataResponse.amount,
        payment_code: dataResponse.payment_code,
        virtual_account: null,
        order_id: null,
        payment_status_url: null,
        transaction_type: dataResponse.retail_code,
        payment_type: "retail",
      },
    });
    return true;
  },

  async submitCreatePaymentEwalet({ commit, state }, payload) {
    const { data } = state;
    if (
      data.formPayment.nominal === 0 ||
      data.formPayment.payment_method === ""
    ) {
      Message({
        type: "error",
        message: "Permintaan Kurang lengkap, Harap ulangi lagi!",
      });
      return false;
    }

    const params = {
      user_id: `SC${payload.school_id}`,
      school_id: payload.school_id,
    };
    const queryString = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    const finalQueryString = `?${queryString}`;

    const formData = {
      subscription_plan_id: payload.product_id,
      cycle: payload.cycle,
      payment_method: data.detailPayment.channel_method_name,
      payment_channel: "linkqu",
      retail_code: payload.retail_code,
      school_fee_invoice: "",
      voucher: "",
      transaction_note: payload.transaction_note || "",
      customer_name: payload.customer_name.substring(0, 20),
      customer_phone: payload.customer_phone.substring(0, 20),
      customer_email: payload.customer_email,
      phone_number: payload.customer_phone,
    };

    let result = await ApiPostRequestDepoPayment(
      `subscription-plan/create-ewallet${finalQueryString}`,
      formData,
    );

    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification("error", "Server Error");
        return;
      } else if (result.code === 403) {
        HandlerNotification(
          "error",
          "Sekolah anda tidak bisa menggunakan Paket ini! Pilih Paket yang lebih tinggi.",
        );
        return;
      }

      Message({
        type: "error",
        message: "Gagal membuat pembayaran!",
      });
      console.log(result.error);
      return false;
    }

    HandlerNotification();

    const dataResponse = result.data.data;
    commit("changePayment", {
      detailPayment: {
        reff_id: dataResponse.partner_reff2,
        payment_method: dataResponse.retail_code,
        expired_at: dataResponse.expired,
        total: dataResponse.amount,
        payment_code: dataResponse.payment_code,
        virtual_account: null,
        order_id: null,
        payment_status_url: dataResponse.payment_url,
        transaction_type: dataResponse.retail_code,
        payment_type: "ewallet",
      },
    });
    return true;
  },

  async submitCreatePaymentBank({ commit, state }, payload) {
    const { data } = state;
    if (
      data.formPayment.nominal === 0 ||
      data.formPayment.payment_method === ""
    ) {
      Message({
        type: "error",
        message: "Permintaan Kurang lengkap, Harap ulangi lagi!",
      });
      return false;
    }

    const params = {
      user_id: `SC${payload.school_id}`,
      school_id: payload.school_id,
    };
    const queryString = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    const finalQueryString = `?${queryString}`;

    const formData = {
      subscription_plan_id: payload.product_id,
      cycle: payload.cycle,
      payment_method: payload.channel_method_name,
      payment_channel: "linkqu",
      bank_code: payload.bank_code,
      school_fee_invoice: "",
      voucher: "",
      transaction_note: payload.transaction_note || "",
      customer_name: payload.customer_name.substring(0, 20),
      customer_phone: payload.customer_phone.substring(0, 20),
      customer_email: payload.customer_email,
    };

    let result = await ApiPostRequestDepoPayment(
      `subscription-plan/create-va${finalQueryString}`,
      formData,
    );

    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification("error", "Server Error");
        return;
      } else if (result.code === 403) {
        HandlerNotification(
          "error",
          "Sekolah anda tidak bisa menggunakan Paket ini! Pilih Paket yang lebih tinggi.",
        );
        return;
      }

      Message({
        type: "error",
        message: "Gagal membuat pembayaran!",
      });
      console.log(result.error);
      return false;
    }

    HandlerNotification();

    const dataResponse = result.data.data;
    commit("changePayment", {
      detailPayment: {
        reff_id: dataResponse.partner_reff2,
        payment_method: dataResponse.bank_name,
        expired_at: dataResponse.expired,
        total: dataResponse.amount,
        payment_code: null,
        virtual_account: dataResponse.virtual_account,
        order_id: null,
        payment_status_url: null,
        transaction_type: dataResponse.retail_code || null,
        payment_type: "BANK",
      },
    });
    return true;
  },
};

const getters = {
  filterPaymentMethod: (state) => {
    const { listVirtualAccount, paymentType } = state.data;
    return (Array.isArray(listVirtualAccount) ? listVirtualAccount : []).filter(
      (item) => item.channel_method_category === paymentType,
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
