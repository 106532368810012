const state = {
  data: {
    tabMenu: [],
  },
};

const mutations = {
  changeBeranda(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  initializeBeranda({ commit }) {
    const nameProduct = process.env.VUE_APP_PRODUCT || "bigdata";
    const tabMenu = [
      {
        name: "BerandaStatistik",
        label: "Statistik",
        lock: false,
        url: `/${nameProduct}/statistik`,
      },
      {
        name: "BerandaProduk",
        label: "Produk",
        lock: false,
        url: `/${nameProduct}/produk`,
      },
      /*{
        name: "BerandaPembelian",
        label: "Pembelian",
        lock: false,
        url: `/${nameProduct}/pembelian`,
      },*/
      //{
      //  name: "BerandaTagihan",
      //  label: "Tagihan",
      //  lock: false,
      //  url: `/${nameProduct}/tagihan`,
      //},
    ];

    commit("changeBeranda", { tabMenu });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
