import {ApiDeleteRequest, ApiGetRequest, ApiPostRequest, ApiPutRequest} from "@/utils/Api";
import {Message} from "element-ui";
import PresensiService from "@/services/PresensiService";
import {SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";

/*
* */

const state = {
	data: {
		items: [],
		page: 1,
		limit: 10,
		total: 0,
		loading: false,
		filter: "",
		filter_role: "",
		filter_peranan: "",
		filter_class: "",
		filter_jurusan: "",
		filter_angkatan: "",
		detailCctv: {
			isDetailCctvNotFound: false,
			loading: false,
			limit: 10,
			page: 1,
			items: [],
			itemsAll: [],
			indexRowSpan: [],
			filterQ: "",
			filter_class: "",
			filter_role: "",
			filter_jurusan: "",
			deviceType: "CCTV",
			detail: {
				id: 0,
				name: "",
				sn: "",
				secret: "",
				last_ip: "",
				has_fingerprint: false,
				has_face: false,
				has_photo: false,
				last_sync: null,
				fw_version: null,
				fp_algo_version: null,
				face_algo_version: null,
				users_count: 0,
				created_at: null,
				tag: {}
			},
			pelajar: {
				loading: false,
				filterRole: "",
				filterClass: "",
				filterAngkatan: "",
				filterQ: "",
				limit: 10,
				page: 1,
				userSelected: [],
				selectRoles: [],
				selectClass: [],
				itemRoles: [],
				items: [],
				itemsAll: [],
			},
			guruStaff: {
				loading: false,
				filterRole: "",
				filterQ: "",
				filterPeranan: "",
				limit: 10,
				page: 1,
				userSelected: [],
				selectRoles: [],
				itemRoles: [],
				items: [],
				itemsAll: [],
			}
		},
		device: {
			loading: false,
			limit: 10,
			page: 10,
			items: [],
			itemsAll: [],
			filterQ: "",
			deviceType: "CCTV",
		},
		detail: {
			"id": null,
			"name": "",
			"sn": "",
			"secret": "",
			"type": null,
			"school_id": 0,
			school_device: []
		},
		isCctvIdNotFound: false,
		errorsDetail: [],
		detailLoadingCctv: false,
		detailFormCctv: {
			name: "",
			sn: "",
			password: "cctv123", // defualt onklas
			device_type: "CCTV", // nullable | default FINGERPRINT | in: CCTV,FINGERPRINT
			"type": "", // in:IN,OUT,CLASS
			"tag": "", // class_id
			"school_nvr_device_id": null
		},
		isDetailNotFound: false,
		listFilterNvr: [
			{
				name: "Semua",
				active: true,
			},
			{
				name: "Sekolah",
				active: false,
			},
			{
				name: "Kelas",
				active: false,
			}
		],
		formMesin: {
			name: "",
			type: "SCHOOL",
			password: "cctv123"
		},
		rules: {
			name: [
				{
					required: true,
					message: "Anda wajib mengisi nama mesin",
					trigger: "blur"
				}
			],
			type: [
				{
					required: true,
					message: "Anda wajib mengisi Tipe Absensi",
					trigger: "blur"
				}
			],
			password: [
				{min: 7, message: "Min password 7 karakter", trigger: "blur"}
			]
		},
		detailRules: {
			name: [
				{
					required: true,
					message: "Anda wajib mengisi nama mesin",
					trigger: "blur"
				}
			],
			sn: [
				{
					required: true,
					message: "Anda wajib mengisi IP CCTV",
					trigger: "blur"
				}
			],
			tag: [
				{
					required: true,
					message: "Anda wajib memilih Kelas",
					trigger: "blur"
				}
			],
			type: [
				{
					required: true,
					message: "Anda wajib mengisi Tipe Absensi",
					trigger: "blur"
				}
			],
			password: [
				{min: 7, message: "Min password 7 karakter", trigger: "blur"}
			]
		},
		columns_pelajar: [
			{
				prop: "avatar",
				label: "Foto",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "nis_nik",
				label: "NIS/NISN",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama",
				minWidth: "180",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "class",
				label: "Kelas",
				width: "140",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "synced_fingerprint_device_at",
				label: "Update",
				width: "110",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "sinkron",
				label: "Tersinkron",
				width: "120",
				"class-name": "body-14 col-black-2 text-center"
			},
			{
				fixed: "right",
				label: "Aksi",
				width: "120",
				"class-name": "body-14 col-black-2"
			}
		],
		columns_guru: [
			{
				prop: "avatar",
				label: "Foto",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "nis_nik",
				label: "NIP/NUPTK",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama",
				minWidth: "80",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "role",
				label: "Profesi",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "newtimes",
				label: "Jam Kerja",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "ketepatan_waktu",
				label: "Ketepatan Waktu",
				minWidth: "140",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "rfid",
				label: "RFID",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "synced_fingerprint_device_at",
				label: "Update",
				minWidth: "120",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "sinkron",
				label: "Tersinkron",
				minWidth: "120",
				"class-name": "body-14 col-black-2 text-center"
			},
			{
				fixed: "right",
				label: "Aksi",
				width: "135",
				"class-name": "body-14 col-black-2"
			}
		],
		columns_tambah_guru: [
			{
				prop: "select",
				label: "Check",
				type: "selection",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "nis_nik",
				label: "NIP/NUPTK",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama Lengkap",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "role",
				label: "Profesi",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "peranan",
				label: "Peranan",
				minWidth: "120",
				"class-name": "body-14 col-black-2"
			}
		],
		columns_tambah_pelajar: [
			{
				prop: "select",
				label: "Check",
				type: "selection",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "avatar",
				label: "Foto",
				width: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "nis_nik",
				label: "NIS / NISN",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama Lengkap",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "class",
				label: "Kelas",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "major",
				label: "Jurusan",
				minWidth: "120",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "grade",
				label: "Angkatan",
				minWidth: "120",
				"class-name": "body-14 col-black-2"
			}
		],
		columns_topup: [],
		dataTimezone: "",
		indexRowSpan: [],
		nvrId: 0,
		cctvId: 0,
		selectRoles: [],
		itemRoles: [],
		selectClass: [],
		itemClass: [],
		userSelected: [],
		column_detail_guru: [
			{
				prop: "nis_nik",
				label: "NIK / NUPTK",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama",
				minWidth: "90",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "role",
				label: "Profesi",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "times",
				label: "Jam Kerja & Ketepatan Waktu",
				minWidth: "170",
				"class-name": "body-14 col-black-2"
			}
		],
		column_detail_pelajar: [
			{
				prop: "nis_nik",
				label: "NIK / NUPTK",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama",
				minWidth: "80",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "class",
				label: "Kelas",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "times",
				label: "Jam Kerja & Ketepatan Waktu",
				minWidth: "170",
				"class-name": "body-14 col-black-2"
			}
		],
		column_pengaturan_guru: [
			{
				prop: "nis_nik",
				label: "NIK / NUPTK",
				minWidth: "130",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "role",
				label: "Profesi",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "times",
				label: "Jam Kerja",
				minWidth: "200",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "ketepatan_waktu",
				label: "Ketepatan Waktu",
				minWidth: "170",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "rfid",
				label: "RFID",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "synced_fingerprint_device_at",
				label: "Update Terakhir",
				minWidth: "170",
				"class-name": "body-14 col-black-2"
			}
		],
		columns_scan: [
			{
				prop: "used",
				label: "Aktif",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "jenis",
				label: "Tangan",
				minWidth: "70",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "jari",
				label: "Jari",
				minWidth: "120",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "status",
				label: "Status",
				minWidth: "250",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "update",
				label: "Update",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "aksi",
				label: "Aksi",
				minWidth: "60",
				"class-name": "body-14 col-black-2"
			}
		],
		dataSidikJari: [
			{
				key: 0,
				used: false,
				jenis: "kiri",
				jari: "(0) Jari kelingking",
				status: "",
				update: ""
			},
			{
				key: 1,
				used: false,
				jenis: "kiri",
				jari: "(1) Jari manis",
				status: "",
				update: ""
			},
			{
				key: 2,
				used: false,
				jenis: "kiri",
				jari: "(2) Jari tengah",
				status: "",
				update: ""
			},
			{
				key: 3,
				used: false,
				jenis: "kiri",
				jari: "(3) Jari telunjuk",
				status: "",
				update: ""
			},
			{
				key: 4,
				used: false,
				jenis: "kiri",
				jari: "(4) Ibu jari",
				status: "",
				update: ""
			},
			{
				key: 5,
				used: false,
				jenis: "kanan",
				jari: "(5) Ibu jari",
				status: "",
				update: ""
			},
			{
				key: 6,
				used: false,
				jenis: "kanan",
				jari: "(6) Jari telunjuk",
				status: "",
				update: ""
			},
			{
				key: 7,
				used: false,
				jenis: "kanan",
				jari: "(7) Jari tengah",
				status: "",
				update: ""
			},
			{
				key: 8,
				used: false,
				jenis: "kanan",
				jari: "(8) Jari manis",
				status: "",
				update: ""
			},
			{
				key: 9,
				used: false,
				jenis: "kanan",
				jari: "(9) Jari kelingking",
				status: "",
				update: ""
			}
		],
		userSidikJari: [],
		fingerSelect: false,
		formFinger: {
			type: "finger",
			sig_id: false,
			used: false
		},
		formRfid: {
			type: "rfid",
			signature: ""
		}
	}
};

const mutations = {
	changeMesin(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeMesinFormMesin(state, payload) {
		state.data.formMesin = Object.assign({}, state.data.formMesin, payload);
	},
	changeMesinLoading(state, payload) {
		state.data.loading = payload;
	},
	changeCctvFormIpCctv(state, payload) {
		state.data.detailFormCctv = Object.assign({}, state.data.detailFormCctv, payload);
	},
	changeCctvLoading(state, payload) {
		state.data.detailLoadingCctv = payload;
	},
	changeDevice(state, payload) {
		state.data.device = Object.assign({}, state.data.device, payload);
	},
	changeDeviceLoading(state, payload) {
		state.data.device.loading = payload;
	},
	changeDeviceCctvDetail(state, payload) {
		state.data.detailCctv = Object.assign({}, state.data.detailCctv, payload);
	},
	changeDeviceCctvDetailLoading(state, payload) {
		state.data.detailCctv.loading = payload;
	},
	changeDeviceCctvDetailPelajar(state, payload) {
		state.data.detailCctv.pelajar = Object.assign({}, state.data.detailCctv.pelajar, payload);
	},
	changeDeviceCctvDetailPelajarLoading(state, payload) {
		state.data.detailCctv.pelajar.loading = payload;
	},
	changeDeviceCctvDetailGuruStaff(state, payload) {
		state.data.detailCctv.guruStaff = Object.assign({}, state.data.detailCctv.guruStaff, payload);
	},
	changeDeviceCctvDetailGuruStaffLoading(state, payload) {
		state.data.detailCctv.guruStaff.loading = payload;
	}
};

const actions = {
	async getListCctvIp({commit}) {

		const params = {
			device_type: "CCTV"
		}

		commit("changeMesin", {loading: true});
		const getResult = await ApiGetRequest(`school/presence/device`, params);
		commit("changeMesin", {
			loading: false
		});

		if (getResult.code >= 500 || !getResult) {
			return false;
		}

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data mesin absensi"
			});

			return false;
		}
		commit("changeMesin", {
			items: getResult.data.data
		});
	},

	async getDeviceCctvAll({commit, state}) {

		const {deviceType} = state.data.device

		const params = {
			limit: 0,
			page: 1,
			device_type: deviceType
		}

		try {
			commit("changeDeviceLoading", true);
			const result = await PresensiService.deviceGet(params)
			commit("changeDeviceLoading", false);

			commit("changeDevice", {
				itemsAll: result.data.data
			});
			return true;
		} catch (error) {
			commit("changeDeviceLoading", false);

			commit("changeDevice", {
				itemsAll: []
			});

			if (error.status >= 500 || !error) {
				return false;
			}

			return false;
		}
	},

	async getDetailDeviceCctv({commit}) {

		commit("changeDeviceCctvDetail", {
			isDetailCctvNotFound: false,
			errorsDetail: []
		});
		const {cctvId} = state.data;

		try {
			commit("changeDeviceCctvDetailLoading", true);
			const result = await PresensiService.deviceByIdGet(cctvId);
			commit("changeDeviceCctvDetailLoading", false);

			if (result.data && result.data.data) {
				const formatSN = result.data.data.sn.replace("CCTV-", "");
				commit("changeDeviceCctvDetail", {
					detail: {
						...result.data.data,
						sn: formatSN,
					},
					isDetailCctvNotFound: false
				});
				return true
			}
			commit("changeDeviceCctvDetail", {
				detail: result.data.data,
				isDetailCctvNotFound: false
			});
			return true
		} catch (error) {
			console.log(error)
			commit("changeDeviceCctvDetailLoading", false);

			if (error.errorList) {
				if (error.errorList[404]) {
					commit("changeDeviceCctvDetail", {
						errorsDetail: {
							"404": [`Data dengan id: ${cctvId} tidak ditemukan.`]
						},
						isDetailCctvNotFound: true
					});
				}

				return false
			}

			if (error.status >= 500 || !error) {
				return false;
			}
			Message({
				type: "error",
				message: "Gagal menampilkan data, mohon mencoba kembali"
			});
			return false
		}

	},

	async postDeviceCctv({commit, state}) {
		commit("changeMesin", {
			error: false
		});

		const {detailFormCctv, nvrId} = state.data
		const formData = {
			name: detailFormCctv.name,
			sn: detailFormCctv.sn,
			type: detailFormCctv.type,
			secret: detailFormCctv.password,
			school_nvr_device_id: nvrId,
			device_type: "CCTV"
		}

		if (!["IN", "OUT"].includes(detailFormCctv.type)) {
			formData.tag = detailFormCctv.tag
		}

		try {
			commit("changeCctvLoading", true);
			await PresensiService.devicePost(formData)
			commit("changeCctvLoading", false);

			commit("changeMesin", {
				error: false
			});

			Message({
				type: "success",
				message: "Data berhasil ditambahkan"
			});
			return true
		} catch (error) {
			commit("changeCctvLoading", false);

			if (error.status >= 500) {
				commit("changeMesin", {
					error: {
						"server_error": [SERVER_ERROR_MESSAGE]
					},
				});
				return false
			}

			Message({
				type: "error",
				message: error.error
					? error.error
					: "Gagal menambahkan data mesin"
			});

			commit("changeMesin", {
				error: error.errorList || false
			});
			return false
		}

	},

	async updateDeviceCctv({commit, state}) {
		commit("changeDeviceCctvDetail", {
			error: false
		});

		try {

			const {detailCctv, nvrId, cctvId} = state.data
			const formData = {
				name: detailCctv.detail.name,
				sn: detailCctv.detail.sn,
				type: detailCctv.detail.type,
				secret: detailCctv.detail.secret,
				school_nvr_device_id: nvrId,
				device_type: "CCTV"
			}

			if (!["IN", "OUT"].includes(detailCctv.detail.type)) {
				if (typeof detailCctv.detail.tag === 'object') {
					formData.tag = detailCctv.detail.tag.id
				}else {
					formData.tag = detailCctv.detail.tag
				}
			} else {
				formData.tag = "sekolah"
			}
			commit("changeDeviceCctvDetailLoading", true)
			await PresensiService.devicePut(cctvId, formData)
			commit("changeDeviceCctvDetailLoading", false)
			commit("changeDeviceCctvDetail", {
				error: false
			});

			Message({
				type: "success",
				message: "Data CCTV berhasil diupdate"
			});

			return true;
		} catch (error) {
			console.log(error)
			commit("changeDeviceCctvDetailLoading", false)

			Message({
				type: "error",
				message: "Gagal update data CCTV"
			});

			commit("changeDeviceCctvDetail", {
				error: error.errorList || false,
			});

			return false;
		}
	},

	async hapusDeviceCctv({commit, state}) {
		commit("changeDeviceCctvDetail", {
			loading: true,
			error: false
		});

		const {data} = state;
		try {
			commit("changeDeviceCctvDetailLoading", true)
			await PresensiService.deviceDelete(data.cctvId)
			commit("changeDeviceCctvDetailLoading", false)

			commit("changeDeviceCctvDetail", {
				error: false
			});

			Message({
				type: "success",
				message: "Data CCTV berhasil dihapus"
			});

			return true;
		} catch (error) {
			console.log(error)
			commit("changeDeviceCctvDetailLoading", false)
			Message({
				type: "error",
				message: "Data CCTV gagal dihapus"
			});

			commit("changeDeviceCctvDetail", {
				error: error.errorList,
				loading: false
			});

			return false;
		}
	},

	async getListNvrProxy({commit}) {

		const params = {}

		try {
			commit("changeMesinLoading", true);
			const result = await PresensiService.nvrProxyGet(params)
			commit("changeMesinLoading", false);

			commit("changeMesin", {
				items: result.data.data
			});
			return true;
		} catch (error) {
			commit("changeMesinLoading", false);

			if (error.status >= 500 || !error) {
				return false;
			}

			Message({
				type: "error",
				message: "Gagal menampilkan data mesin absensi"
			});

			return false;
		}
	},

	async getDetailNvrProxy({commit}) {

		commit("changeMesin", {
			isDetailNotFound: false,
			errorsDetail: []
		});
		const {nvrId} = state.data;

		try {
			commit("changeMesinLoading", true);
			const result = await PresensiService.nvrProxyByIdGet(nvrId);
			commit("changeMesinLoading", false);

			commit("changeMesin", {
				detail: result.data.data || {}
			});
			return true
		} catch (error) {
			console.log(error)
			commit("changeMesinLoading", false);

			if (error.status >= 5000) {
				return false
			}

			if (error.errorList) {
				if (error.errorList[404]) {
					commit("changeMesin", {
						errorsDetail: {
							"404": [`Data dengan id: ${nvrId} tidak ditemukan.`]
						},
						isDetailNotFound: true
					});
					return false
				}
			}

			Message({
				type: "error",
				message: "Gagal menampilkan data, mohon mencoba kembali"
			});
			return false
		}

	},

	async createMesin({commit, state}) {
		commit("changeMesin", {
			error: false
		});

		const {formMesin} = state.data
		const formData = {
			name: formMesin.name,
			type: formMesin.type,
			secret: formMesin.password,
		}

		commit("changeMesinLoading", true);
		try {
			await PresensiService.nvrProxyPost(formData)
			commit("changeMesinLoading", false);

			commit("changeMesin", {
				error: false
			});

			Message({
				type: "success",
				message: "Data berhasil ditambahkan"
			});
			return true
		} catch (error) {
			commit("changeMesinLoading", false);

			if (error.status >= 500) {
				commit("changeMesin", {
					error: {
						"server_error": [SERVER_ERROR_MESSAGE]
					},
				});
				return false
			}

			Message({
				type: "error",
				message: error.error
					? error.error
					: "Gagal menambahkan data mesin"
			});

			commit("changeMesin", {
				error: error.errorList || false,
				loading: false
			});
			return false
		}

	},

	async updateDataNVR({commit, state}) {
		commit("changeMesin", {
			error: false
		});

		const {nvrId, detail} = state.data
		try {
			const formData = {
				name: detail.name,
				type: detail.type,
			}
			commit("changeMesinLoading", true)
			await PresensiService.nvrProxyPut(nvrId, formData)
			commit("changeMesinLoading", false)
			commit("changeMesin", {
				loading: false,
				error: false
			});

			Message({
				type: "success",
				message: "Data NVR berhasil diubah"
			});

			return true;
		} catch (error) {
			console.log(error)
			commit("changeMesinLoading", false)

			Message({
				type: "error",
				message: "Gagal update data NVR"
			});

			commit("changeMesin", {
				error: error.errorList || false,
			});

			return false;
		}
	},

	async hapusDataNVR({commit, state}) {
		commit("changeMesin", {
			loading: true,
			error: false
		});

		const {data} = state;
		try {
			commit("changeMesinLoading", true)
			await PresensiService.nvrProxyDelete(data.nvrId)
			commit("changeMesinLoading", false)

			commit("changeMesin", {
				error: false
			});

			Message({
				type: "success",
				message: "Data NVR berhasil dihapus"
			});

			return true;
		} catch (error) {
			console.log(error)
			commit("changeMesinLoading", false)
			Message({
				type: "error",
				message: "Data NVR gagal dihapus"
			});

			commit("changeMesin", {
				error: error.errorList,
				loading: false
			});

			return false;
		}
	},

	async searchUserGuruStaff({commit, state}) {
		commit("changeDeviceCctvDetailGuruStaff", {
			loading: true
		});

		const {data} = state;
		let params = {
			type: "staff"
		};

		let url = `/school/presence/user/grouped?`;
		if (data.selectRoles && data.selectRoles.length > 0) {
			url += `&roles[]=${data.selectRoles.join("&roles[]=")}`;
		}

		const getResult = await ApiGetRequest(url, params);

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data, mohon mencoba kembali"
			});

			commit("changeDeviceCctvDetailGuruStaff", {
				loading: false
			});

			return false;
		} else {
			let responseItems = getResult.data.data || [];
			let allItems = [];

			responseItems.map(dataItem => {
				if (dataItem.users && dataItem.users.length > 0) {
					allItems.push(...dataItem.users);
				}
			});

			commit("changeDeviceCctvDetailGuruStaff", {
				userSelected: allItems || [],
				itemRoles: getResult.data.data || [],
				loading: false
			});

			return true;
		}
	},

	async searchUserPelajar({commit, state}) {
		commit("changeDeviceCctvDetailPelajar", {
			loading: true
		});

		const {selectClass} = state.data.detailCctv.pelajar;
		let params = {
			type: "student"
		};

		let url = `/school/presence/user/grouped?`;
		if (selectClass && selectClass.length > 0) {
			url += `&classes[]=${selectClass.join("&classes[]=")}`;
		}

		const getResult = await ApiGetRequest(url, params);

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data, mohon mencoba kembali"
			});

			commit("changeDeviceCctvDetailPelajar", {
				loading: false
			});

			return false;
		}

		let responseItems = getResult.data.data || [];
		let allItems = [];

		responseItems.map(dataItem => {
			if (dataItem.users && dataItem.users.length > 0) {
				dataItem.users.map(user => {
					//if (user.avatar && !user.avatar.includes("default.png")) {
					allItems.push(user);
					//}
				})
			}
		});

		commit("changeDeviceCctvDetailPelajar", {
			userSelected: allItems || [],
			itemRoles: getResult.data.data || [],
			loading: false
		});

		return true;

	},

	async getListGuruStaff({commit, state}, payload = null) {
		if (payload && typeof payload === "number") {
			commit("changeMesin", {
				page: payload
			});
		}

		commit("changeDeviceCctvDetailGuruStaff", {loading: true});
		const {data} = state;
		let params = {
			limit: data.limit,
			page: data.page,
			type: "employee",
			q: data.filter || "",
			role: data.filter_role || "",
			peranan: data.filter_peranan || ""
		};

		const getResult = await ApiGetRequest(`/school/presence/user`, params);

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data, mohon mencoba kembali"
			});

			commit("changeDeviceCctvDetailGuruStaff", {
				loading: false
			});
			return false
		}
		commit("changeDeviceCctvDetailGuruStaff", {
			loading: false,
			items: getResult.data.data || [],
			total: getResult.data.meta.total
		});
		return false
	},

	async getListPelajar({commit, state}, payload = null) {
		if (payload && typeof payload === "number") {
			commit("changeDeviceCctvDetailPelajar", {
				page: payload
			});
		}

		const {page, limit, filterQ, filterClass, filterAngkatan} = state.data.detailCctv.pelajar;
		let params = {
			limit: limit,
			page: page,
			type: "student",
			q: filterQ || "",
			classId: filterClass || "",
			grade: filterAngkatan || ""
		};

		try {
			commit("changeDeviceCctvDetailPelajarLoading", true);
			const result = await PresensiService.klastimeUserGet(params);
			commit("changeDeviceCctvDetailPelajarLoading", false);

			commit("changeDeviceCctvDetailPelajar", {
				loading: false,
				items: result.data.data || [],
				total: result.data.meta.total
			});
		} catch (error) {
			console.log(error)
			commit("changeDeviceCctvDetailPelajarLoading", false);

			Message({
				type: "error",
				message: "Gagal menampilkan data, mohon mencoba kembali"
			});
		}

	},

	async registerUserToDevice({commit, state}) {
		commit("changeDeviceCctvDetailPelajar", {loading: true, error: false});
		commit("changeDeviceCctvDetailPelajarLoading", true);
		const {pelajar} = state.data.detailCctv;
		const {data} = state;

		const getUserIdSelected = [];
		pelajar.userSelected.map(item => getUserIdSelected.push(item.id));
		const payload = {
			users: getUserIdSelected
		};

		if (
			!getUserIdSelected ||
			(getUserIdSelected && getUserIdSelected.length === 0)
		) {
			Message({
				type: "error",
				message: "Anda harus memilih user yang akan ditambahkan"
			});

			return false;
		}

		const getResult = await ApiPostRequest(
			`school/presence/device/${data.cctvId || ""}/user`,
			payload
		);
		commit("changeDeviceCctvDetailPelajarLoading", false)

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal mendaftarkan user ke perangkat absensi"
			});

			commit("changeDeviceCctvDetailPelajar", {
				error: getResult.errorList || false,
				loading: false
			});

			return false;
		} else {
			commit("changeDeviceCctvDetailPelajar", {
				loading: false,
				error: false
			});

			Message({
				type: "success",
				message: "Data fingerprint berhasil ditambahkan"
			});

			return true;
		}
	},

	async removeUserFromDevice({commit, state}, payload = null) {
		commit("changeDeviceCctvDetail", {
			loading: true,
			error: false
		});

		const {data} = state;
		let result = await ApiDeleteRequest(`school/presence/device/${data.cctvId}/user/${payload.id}`);
		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menghapus user dari perangkat"
			});

			commit("changeDeviceCctvDetail", {
				loading: false,
				error: result.errorList || false
			});

			return false;
		} else {
			commit("changeDeviceCctvDetail", {
				loading: false,
				error: false
			});

			Message({
				type: "success",
				message: "Data user berhasil dihapus dari perangkat"
			});

			return true;
		}
	},

	async getDetailUserDevice({commit, state, dispatch}, payload = null) {
		commit("changeMesin", {loading: true});
		const {data} = state;
		const getResult = await ApiGetRequest(
			`/school/presence/device/${data.nvrId}/user/${payload.id}`
		);

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan detail user, mohon mencoba kembali"
			});

			commit("changeMesin", {
				loading: false
			});

			return false;
		} else {
			commit("changeMesin", {
				loading: false,
				detail: getResult.data.data
			});

			await dispatch("getInfoStatusFinger", payload);
			await dispatch("getInfoStatusRfid", payload);
			return true;
		}
	},

	async createNewFingerPrint({commit}, payload = null) {
		commit("changeMesin", {loading: true, error: false});
		const {data} = state;
		const getResult = await ApiPostRequest(
			`school/presence/user/${payload.id || ""}/signature`,
			data.formFinger
		);
		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menambahkan data sidik jari baru"
			});

			commit("changeMesin", {
				error: getResult.errorList || false,
				loading: false
			});

			return false;
		} else {
			commit("changeMesin", {
				loading: false,
				fingerSelect: false,
				formFinger: {
					type: "finger",
					sig_id: false,
					used: false
				},
				error: false
			});

			Message({
				type: "success",
				message: "Data fingerprint berhasil ditambahkan"
			});

			return true;
		}
	},

	async createNewRfid({commit}, payload = null) {
		commit("changeMesin", {loading: true, error: false});
		const {data} = state;
		const getResult = await ApiPostRequest(
			`school/presence/user/${payload.id || ""}/signature`,
			data.formRfid
		);
		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menambahkan data sidik jari baru"
			});

			commit("changeMesin", {
				error: getResult.errorList || false,
				loading: false
			});

			return false;
		} else {
			commit("changeMesin", {
				loading: false,
				fingerSelect: false,
				formRfid: {
					type: "rfid",
					signature: ""
				},
				error: false
			});

			Message({
				type: "success",
				message: "Data fingerprint berhasil ditambahkan"
			});

			return true;
		}
	},

	async updateFingerPrint({commit}, payload = null) {
		commit("changeMesin", {loading: true, error: false});
		const getResult = await ApiPutRequest(
			`school/presence/user/${payload.user || ""}/signature/${payload.id ||
			""}`,
			payload.data || {}
		);

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal mengubah status aktif finger print"
			});

			commit("changeMesin", {
				error: getResult.errorList,
				loading: false
			});
		} else {
			commit("changeMesin", {
				error: false,
				loading: false
			});

			return true;
		}
	},

	async deleteFingerPrint({commit}, payload = null) {
		commit("changeMesin", {loading: true, error: false});
		const getResult = await ApiDeleteRequest(
			`school/presence/user/${payload.user || ""}/signature/${payload.id || ""}`
		);

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menghapus data sidik jari"
			});

			commit("changeMesin", {
				error: getResult.errorList,
				loading: false
			});

			return false;
		} else {
			commit("changeMesin", {
				error: false,
				loading: false
			});

			Message({
				type: "success",
				message: "Data sidik jari berhasil dihapus"
			});

			return true;
		}
	},

	async getInfoStatusFinger({commit, state}, payload = null) {
		commit("changeMesin", {loading: true});
		const {data} = state;
		const getResult = await ApiGetRequest(
			`/school/presence/user/${payload.id}/signature?type[]=finger`
		);

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan detail user, mohon mencoba kembali"
			});

			commit("changeMesin", {
				loading: false
			});

			return false;
		} else {
			commit("changeMesin", {
				loading: false,
				detail: {
					...data.detail,
					list_fingerprint: getResult.data.data
				}
			});

			return true;
		}
	},

	async getInfoStatusRfid({commit, state}, payload = null) {
		commit("changeMesin", {loading: true});
		const {data} = state;
		const getResult = await ApiGetRequest(
			`/school/presence/user/${payload.id}/signature?type[]=rfid`
		);

		if (getResult.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan detail user, mohon mencoba kembali"
			});

			commit("changeMesin", {
				loading: false
			});

			return false;
		} else {
			commit("changeMesin", {
				loading: false,
				detail: {
					...data.detail,
					list_rfid: getResult.data.data
				}
			});

			return true;
		}
	},

	async getListGuruStaffDevice({commit, state}, payload = null) {
		if (payload && typeof payload === "number") {
			commit("changeDeviceCctvDetail", {
				page: payload
			});
		}
		const {data} = state;
		let params = {
			limit: data.limit,
			page: data.page,
			type: "staff",
			q: data.filterQ || undefined,
			role: data.filter_role || undefined,
		};

		commit("changeDeviceCctvDetail", {
			items: []
		});
		commit("changeDeviceCctvDetailLoading", true)
		const getResult = await ApiGetRequest(
			`school/presence/device/${data.cctvId || ""}/user`,
			params
		);
		commit("changeDeviceCctvDetailLoading", false)

		if (getResult && getResult.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data Guru dan Staff, mohon mencoba kembali"
			});
			return false
		}
		let dataItems = getResult.data.data || [];

		commit("changeDeviceCctvDetail", {
			loading: false,
			items: dataItems,
			indexRowSpan: [],
			total: getResult.data.meta.total
		});
		return true
	},

	async getListPelajarDevice({commit, state}, payload = null) {
		if (payload && typeof payload === "number") {
			commit("changeDeviceCctvDetail", {
				page: payload
			});
		}

		commit("changeDeviceCctvDetailLoading", true);
		const {limit, filterQ, filter_class, filter_role, page, filter_jurusan} = state.data.detailCctv;
		const {data} = state;
		let params = {
			limit: limit,
			page: page || undefined,
			type: "student",
			role: filter_role || undefined,
			class: filter_class || undefined,
			major: filter_jurusan || undefined,
			q: filterQ || undefined
		};

		const cctvId = data.cctvId || ""
		const result = await ApiGetRequest(
			`school/presence/device/${cctvId}/user`,
			params
		);

		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data Pelajar, mohon mencoba kembali"
			});

			commit("changeDeviceCctvDetail", {
				items: [],
				indexRowSpan: [],
				total: 0
			});

			commit("changeDeviceCctvDetailLoading", false);
			return false
		}

		let dataItems = result.data.data || [];

		commit("changeDeviceCctvDetail", {
			items: dataItems,
			indexRowSpan: [],
			total: result.data.meta.total
		});
		commit("changeDeviceCctvDetailLoading", false);
		return true
	}
};

const getters = {
	isClassDetailAbsensiCctvGetter: state => {
		try {
			return state.data.detail.type === "GROUP"
		} catch (e) {
			return null
		}
	},
	isClassDeviceCctvDetailGetter: state => {
		try {
			return state.data.detailCctv.detail.type === "CLASS"
		} catch (e) {
			return null
		}
	},
	isClassNameDeviceCctvDetailGetter: state => {
		try {
			return state.data.detailCctv.detail?.tag?.name || '-'
		} catch (e) {
			return null
		}
	},

	tabActiveListFilterNvr: state => {
		return state.data.listFilterNvr.find(item => item.active);
	},

	itemsNvrProxyGetter: (state, getters) => {
		const tabActive = getters.tabActiveListFilterNvr;
		if (tabActive.name === "Semua") {
			return state.data.items
		}

		const filterTab = tabActive.name === "Sekolah" ? "SCHOOL" : "GROUP";
		return state.data.items.filter(item => item.type === filterTab)
	},

	nvrProxyNumberOfDeviceCctvGetter: (state) => {
		try {
			return state.data.detail.number_of_school_devices
		} catch (e) {
			return 0
		}
	},

	isNvrProxyByIdNotFoundGetter: (state) => {
		try {
			return !state.data.detail.id
		} catch (e) {
			return false
		}
	},

	cctvNumberOfUserGetter: (state) => {
		try {
			return state.data.detailCctv.detail.users_count
		} catch (e) {
			return 0
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
