import moment from "moment/moment";
import store from "@/store/index";
import { _ } from "vue-underscore";

export const checkMaxUserStudentInCapacity = (maxStudent) => {
  try {
    const userSchool = store.state.user.school;
    return userSchool.total_student > maxStudent;
  } catch (e) {
    return false;
  }
};

// Metode untuk memeriksa apakah sudah melewati 24 jam sejak waktu tertentu
function isPast24Hours(lastVerifiedTime) {
  const currentDateTime = moment();
  const lastVerifiedDateTime = moment(lastVerifiedTime);
  return currentDateTime.diff(lastVerifiedDateTime, "hours") >= 24;
}

export function showModalIfNecessary(store) {
  //if (!store.getters.userPhoneIsVerified) {

  let userRole = _.pluck(store.state.user.role, "name");
  let checkHeadMaster = _.contains(userRole, "Head Master");
  if (checkHeadMaster) {
    // Jika nomor telepon belum diverifikasi, dan tidak ada catatan verifikasi sebelumnya atau sudah lebih dari 24 jam sejak verifikasi terakhir
    const phoneVerificationData = localStorage.getItem("phone_verify");
    let userProducts = store.state.user.school?.products || [];
    if (
      isSchoolProductsLocked(userProducts) &&
      (!phoneVerificationData ||
        isPast24Hours(JSON.parse(phoneVerificationData).datetime))
    ) {
      localStorage.removeItem("phone_verify");
      store.commit("berandaOtp/changeOtpShowModal", true);
    }
  }
  //}
}

function productsSortByActiveUserAsc(products) {
  return products.sort((a, b) => {
    if (a.active_user !== b.active_user) {
      return a.active_user - b.active_user;
    } else {
      return a.monthly_fee - b.monthly_fee;
    }
  });
}

export const countDaysService = (from, to) => {
  moment.locale("id");
  const startDate = moment(from, "DD-MM-YYYY");
  const endDate = moment(to, "DD-MM-YYYY");
  return endDate.diff(startDate, "days");
};
export const storageFormaterService = (name) => {
  if (!name) return "-";
  return name.replace(/.*?(\d+\s*GB).*/, "$1");
};

export const productByActiveUserFirst = (products, activeUser, product) => {
  console.log(activeUser);
  if (isProductFree(product)) {
    const sortProducts = productsSortByActiveUserAsc(products);
    return sortProducts.find((item) => item.active_user >= activeUser);
  }
  return products.find((item) => item.id === product.id);
};

export const isSchoolProductsLocked = (
  products,
  nameProduct = "storagePackage",
) => {
  const timeNow = moment();

  const isDatePassed = (date) => date && timeNow.diff(date, "minutes") > 0;

  let relatedProducts,
    productFound = null;
  if (nameProduct === "storagePackage") {
    relatedProducts = products
      .filter((p) => p.name.toLowerCase().startsWith(nameProduct.toLowerCase()))
      .sort((a, b) => moment(b.expired_at) - moment(a.expired_at));
    productFound = relatedProducts?.[0];
  } else {
    productFound = products.find(
      (p) => p.name.toLowerCase() === nameProduct.toLowerCase(),
    );
  }

  let isLocked = true;

  if (productFound) {
    if (!productFound.trial_ended_at && !productFound.expired_at) {
      isLocked = true;
    } else if (
      productFound.trial_ended_at &&
      isDatePassed(productFound.trial_ended_at)
    ) {
      isLocked =
        !productFound.expired_at || isDatePassed(productFound.expired_at);
    } else if (productFound.expired_at) {
      isLocked = isDatePassed(productFound.expired_at);
    } else {
      isLocked = false;
    }
  }

  return isLocked;
};

/**
 * @param product
 * is require {monthly_fee, anually_fee}
 * */
export const isProductFree = (product) => {
  try {
    if (product.monthly_fee === 0 && product.anually_fee === 0) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(`logic isProductFee ${e}`);
    return false;
  }
};

/**
 * @param product
 * is require {monthly_fee, anually_fee}
 * */
export const createStrukturResponseTunggakanLikeSALDOforProdukFree = (
  type,
  product,
) => {
  try {
    const dateNow = moment().format("DD-MM-YYYY");

    const total_price =
      type === "monthly" ? product.monthly_fee : product.anually_fee;
    const subscriptionEnd = moment(dateNow, "DD-MM-YYYY")
      .add(parseInt(type === "monthly" ? 30 : 360), "days")
      .format("DD-MM-YYYY");

    return {
      products: [
        {
          product_name: product.name,
          product_display_name: product.display_name,
          period: "",
          amount: total_price,
          overdue_days: 0,
          from: dateNow,
          to: subscriptionEnd,
          cycle: "monthly",
        },
      ],
      remaining_active_days: type === "monthly" ? 30 : 360,
      total_price: total_price,
      anually_count: type === "monthly" ? 0 : 1,
      monthly_count: type === "monthly" ? 1 : 0,
      each_price: [total_price],
      expired_at: dateNow,
      date_now: dateNow,
      subscription_start: dateNow,
      subscription_end: subscriptionEnd,
    };
  } catch (e) {
    console.log(
      `logic createStrukturResponseTunggakanLikeSALDOforProdukFree ${e}`,
    );
    return {};
  }
};
