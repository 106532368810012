import ApiService from "@/api/ApiService";
import axios from "axios";

class GlobalRepository {
	constructor() {
		this.apiService = ApiService;
		this.cancelTokenSource = null;
		this.mock = 'http://localhost:3000/api';
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getPresenceSummary(params) {
		this.createCancelToken()
		return await this.apiService.get(`/school/dashboard/presence/summary`, { params });
	}

	async schoolClassListStudentByClassId(classId) {
		return await this.apiService.get(`/school/class/${classId}/student`);
	}

	async schoolClassAddStudentArrayInClass(classId, formData) {
		return await this.apiService.post(`/school/class/${classId}/student`, formData);
	}

	async schoolClassListStudentAllByClassId(classId) {
		return await this.apiService.get(`/school/class/${classId}/student/all`);
	}

	async schoolClassById(classId) {
		return await this.apiService.get(`/school/class/${classId}`);
	}

	async schoolClassStudentRemoveInClass(classId, studentId) {
		return await this.apiService.delete(`/school/class/${classId}/student/${studentId}`);
	}

	async getVerificationCodeList() {
		return await this.apiService.get(`/school/verification-code`);
	}

	async postVerificationCodeGenerateStudent() {
		return await this.apiService.post(`/school/verification-code/student`);
	}

	async postVerificationCodeGenerateTeacher() {
		return await this.apiService.post(`/school/verification-code/teacher`);
	}

	async getBank(params) {
		return await this.apiService.get(`/school/bank`, {params});
	}

	async postBank(formData) {
		return await this.apiService.get(`/school/bank`, formData);
	}

	async getBankAccount() {
		return await this.apiService.get(`/school/bank-account`);
	}

	async postBankAccount(formData) {
		return await this.apiService.post(`/school/bank-account`, formData);
	}

	async deleteBankAccount(id) {
		return await this.apiService.delete(`/school/bank-account/${id}`);
	}

	async getBalancePayment() {
		return await this.apiService.get(`/school/balance`);
	}

	async getWithdraw() {
		return await this.apiService.get(`/school/withdraw`);
	}

	async postWithdraw(formData) {
		return await this.apiService.post(`/school/withdraw`, formData);
	}

	async deleteWithdraw(id) {
		return await this.apiService.delete(`/school/withdraw/${id}`);
	}

}

export default new GlobalRepository();
