import {
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
import { Message } from "element-ui";
import { _ } from "vue-underscore";
import { checkVw, tableOfWidth } from "@/services/internalService";

const state = {
  data: {
		showModalAlumnus: false,
		activeTypeClass: "class",
    labelMenu: "Kelas",
    columns: [
      {
        prop: "classroom",
        label: "Kelas",
        ...tableOfWidth.class.minWidth,
        "class-name": "body-14 col-black-2",
        sortable: true,
      },
			{
				prop: "class",
				label: "Jenjang",
				width: "90",
				"class-name": "body-14 col-black-2",
			},
      {
        prop: "major",
        label: "Jurusan",
        ...tableOfWidth.major.minWidth,
        "class-name": "body-14 col-black-2",
      },
			{
				prop: "l",
				label: "L/P",
				width: 80,
				"class-name": "body-14 col-black-2 text-center",
			},
      {
        prop: "student",
        label: "Jumlah Pelajar",
        width: "85",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "status",
        label: "Status",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(140),
        "class-name": "body-14 col-black-2",
      },
    ],
    items: [],
    items_tambah_siswa: [],
    items_detail_kelas: [],
    class_id: false,
    limit: 10,
    page: 1,
    pageStudent: 1,
    pageDetailStudent: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    filter_rombel: "",
    errors: [],
    multipleSelection: [],
    data_nisn: [],
    id: {
      detailKelasId: null,
    },
    loading: false,
    gradeOptions: [],
    schoolType: "",
    status: "all",
    classId: null,
    detailStudent: {
      student_class_export: [],
      items: [],
      loading: false,
      limit: 10,
      page: 1,
      total: 0,
      filter: "",
      sortBy: null,
      sortType: null,
      id: {
        detailKelasId: null,
      },
      columns: [
        // {
        //   prop: "sequence",
        //   label: "No",
        //   minWidth: "50",
        //   "class-name": "body-14 col-black-2",
        // },
        {
          prop: "nis_nisn",
          label: "NIS / NISN",
          width: "200",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "name",
          label: "Nama",
          minWidth: "160",
          "class-name": "body-14 col-black-2",
        },
        {
          fixed: "right",
          label: "Aksi",
          width: checkVw(90),
          "class-name": "body-14 col-black-2",
        },
      ],
    },
    form: {
      id: "",
      name: "",
      grade: "",
      school_major_id: "",
      major: [],
    },
    formAlumnus: {
      id: "",
      majorName: "",
      yearGraduate: "",
      grade: "",
      school_major_id: "",
    },
    formRules: {
      name: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      grade: [
        {
          required: true,
          message: "Harus dipilih",
          trigger: "blur",
        },
      ],
      school_major_id: [
        {
          required: true,
          message: "Harus dipilih",
          trigger: "blur",
        },
      ],
    },
    formRulesAlumnus: {
			majorName: [
        {
          required: true,
          message: "Harus dipilih",
          trigger: "blur",
        },
      ],
			yearGraduate: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
			grade: [
        {
          required: true,
          message: "Harus dipilih",
          trigger: "blur",
        },
      ],
    },
    grades: [
      {
        value: "1",
        label: "1",
        types: ["SD", "SLB"],
      },
      {
        value: "2",
        label: "2",
        types: ["SD", "SLB"],
      },
      {
        value: "3",
        label: "3",
        types: ["SD", "SLB"],
      },
      {
        value: "4",
        label: "4",
        types: ["SD", "SLB"],
      },
      {
        value: "5",
        label: "5",
        types: ["SD", "SLB"],
      },
      {
        value: "6",
        label: "6",
        types: ["SD", "SLB"],
      },
      {
        value: "7",
        label: "7",
        types: ["SMP", "SLB"],
      },
      {
        value: "8",
        label: "8",
        types: ["SMP", "SLB"],
      },
      {
        value: "9",
        label: "9",
        types: ["SMP", "SLB"],
      },
      {
        value: "10",
        label: "10",
        types: ["SMA", "SMK", "SLB"],
      },
      {
        value: "11",
        label: "11",
        types: ["SMA", "SMK", "SLB"],
      },
      {
        value: "12",
        label: "12",
        types: ["SMA", "SMK", "SLB"],
      },
      {
        value: "13",
        label: "13",
        types: ["SMK"],
      },
    ],
    columnsStudent: [
      // {
      //   prop: "sequence",
      //   label: "No",
      //   minWidth: "50",
      //   "class-name": "body-14 col-black-2",
      // },
      {
        prop: "nis_nisn",
        label: "NIS / NISN",
        minWidth: "180",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_tambah_siswa: [
      {
        prop: "nis_nisn",
        label: "NIS/NISN",
        minWidth: "180",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "160",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "rombel",
        label: "Rombel Dapodik",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "grade",
        label: "Jenjang",
        width: "90",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      // {
      //   fixed: "right",
      //   label: "Aksi",
      //   width: "100",
      //   "class-name": "body-14 col-black-2",
      // },
    ],
    columns_siswa_terpilih: [
      {
        prop: "status",
        label: "Status",
        width: "160",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "140",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nis_nisn",
        label: "NIS/NISN",
        minWidth: "180",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "70",
        "class-name": "body-14 col-black-2",
      },
    ],
  },
};

const mutations = {
  changeRuangKelas(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeRuangKelasForm(state, payload) {
    if (payload.major === null || !payload.major) {
      // tingkat selain SLTA
      state.data.form = {
        id: payload.id,
        name: payload.name,
        grade: payload.grade,
      };
    } else {
      state.data.form = {
        id: payload.id,
        name: payload.name,
        grade: payload.grade,
        school_major_id: payload.major.id,
        major: payload.major,
      };
    }
  },
  changeRuangKelasAlumnusForm(state, payload) {
		state.data.formAlumnus = Object.assign({}, state.data.formAlumnus, payload);
  },
  changeDetailStudent(state, payload) {
    state.data.detailStudent = Object.assign(
      {},
      state.data.detailStudent,
      payload,
    );
  },
};

const actions = {
  async getRuangKelas({ commit, state }, payload) {
    commit("changeRuangKelas", {
      loading: true,
    });

    const { data } = state;
    if (payload && typeof payload !== "object") {
      await commit("changeRuangKelas", {
        page: payload || data.page,
      });
    } else {
      await commit("changeRuangKelas", {
        page: (payload && payload.page) ?? data.page,
      });
    }
    const params = {
      limit: data.limit,
      page: state.data.page,
      show: data.status,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };
    if (payload && typeof payload === "object") {
      if (payload.wherepage) params.wherepage = "exam";
    }

    const result = await ApiGetRequest(`school/class`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeRuangKelas", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeRuangKelas", {
        items: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },

  getGrades({ commit, state }, payload) {
    const { data } = state;
    let grades = _.filter(data.grades, function (grade) {
      return _.contains(grade.types, payload);
    });

    commit("changeRuangKelas", {
      schoolType: payload,
      gradeOptions: grades,
    });
  },

  async submitRuangKelas({ commit, state }) {
    commit("changeRuangKelas", {
      loading: true,
    });

    const { data } = state;
    let major_id;
    try {
      major_id = data.form.school_major_id || [];
    } catch (e) {
      major_id = [];
    }

    if (
      major_id.length === 0 &&
      (data.schoolType === "SMA" || data.schoolType === "SMK")
    ) {
      let errors = [];
      errors.push({
        message: "Jurusan tidak boleh kosong",
        path: ["school_major_id"],
      });

      commit("changeRuangKelas", {
        loading: false,
        errors: errors,
      });
    } else {
      let result = "";
      let body = {
        name: data.form.name,
        grade: data.form.grade,
      };

      if (data.schoolType === "SMA" || data.schoolType === "SMK") {
        body.school_major_id = data.form.school_major_id;
      }

      if (data.form.id !== "") {
				console.log('testing class/undefined')
        result = await ApiPutRequest(
          `school/class/${data.form.id}?student-move=true`,
          body,
        );
      } else {
        result = await ApiPostRequest(`school/class`, body);
      }
			commit("changeRuangKelas", {
				loading: false,
			});

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });
        if (result.errorList) {
          let errors = [];
          for (const [key, value] of Object.entries(result.errorList)) {
            errors.push({
              message: value[0],
              path: [key],
            });
          }
          commit("changeRuangKelas", {
            errors: errors,
          });
        }
      } else {
        Message({
          type: "success",
          message: "Data telah tersimpan.",
        });
        commit("changeRuangKelas", {
          errors: [],
          form: {
            id: "",
            name: "",
            grade: "",
            school_major_id: "",
          },
        });

        return true;
      }
    }
  },

  async submitRuangKelasAlumnus({ commit, state }, className) {
    commit("changeRuangKelas", {loading: true});

    const { data } = state;
    let major_id;
    try {
      major_id = data.formAlumnus.school_major_id || [];
    } catch (e) {
      major_id = [];
    }

    if (
      major_id.length === 0 &&
      (data.schoolType === "SMA" || data.schoolType === "SMK")
    ) {
      let errors = [];
      errors.push({
        message: "Jurusan tidak boleh kosong",
        path: ["school_major_id"],
      });

      commit("changeRuangKelas", {
        loading: false,
        errors: errors,
      });
    } else {
      let result = "";
      let body = {
        name: className,
        grade: data.formAlumnus.grade,
      };

      if (data.schoolType === "SMA" || data.schoolType === "SMK") {
        body.school_major_id = data.formAlumnus.school_major_id;
      }

      if (data.formAlumnus.id !== "") {
        result = await ApiPutRequest(
          `school/class/${data.formAlumnus.id}?student-move=true`,
          body,
        );
      } else {
        result = await ApiPostRequest(`school/class`, body);
      }
			commit("changeRuangKelas", {
				loading: false,
			});

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });
        if (result.errorList) {
          let errors = [];
          for (const [key, value] of Object.entries(result.errorList)) {
            errors.push({
              message: value[0],
              path: [key],
            });
          }
          commit("changeRuangKelas", {
            errors: errors,
          });
        }
				return false;
      } else {
        Message({
          type: "success",
          message: "Data telah tersimpan.",
        });
        commit("changeRuangKelas", {
          errors: [],
          form: {
            id: "",
            name: "",
            grade: "",
            school_major_id: "",
          },
        });

        return true;
      }
    }
  },

  async getRuangKelasById({ commit }, payload) {
    commit("changeRuangKelas", {
      loading: true,
    });
		console.log('testing getRuangKelasById class/undefined')
    const result = await ApiGetRequest(`school/class/${payload}`);
    await commit("changeRuangKelas", {
      loading: false,
    });

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return [];
    }
    await commit("changeRuangKelasForm", result.data.data);
  },

  async getRuangKelasSiswa({ commit, state }, payload = null) {
    if (payload) {
      commit("changeRuangKelas", {
        page: parseInt(payload),
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
    };
    if (data.filter) params.q = data.filter;
    if (data.filter_rombel) params.rombel = data.filter_rombel;

    commit("changeRuangKelas", {
      loading: true,
    });
    const result = await ApiGetRequest(
      `school/class/${data.class_id}/student/all?simple=1`,
      params,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeRuangKelas", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeRuangKelas", {
        items_tambah_siswa: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getStudentInRoomClass({ commit }) {
    const { data } = state;
    const result = await ApiGetRequest(`school/class/${data.class_id}/student?simple=1`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return [];
    } else {
      await commit("changeRuangKelas", {
        multipleSelection: result.data.data,
      });
    }
  },

  async getDetailRuangKelas({ commit, dispatch }, payload) {
    if (payload && typeof payload !== "object") {
      commit("changeDetailStudent", {
        page: parseInt(payload),
      });
    }

    const { data } = state;
    const params = {
      limit: data.detailStudent.limit,
      page: data.detailStudent.page,
    };
    if (data.detailStudent.filter) {
      params.q = data.detailStudent.filter;
    }

    dispatch("filterRuangKelasById");

    if (payload && typeof payload === "object" && payload.export) {
      params.limit = 0;
      delete params.q;
      delete params.page;
    }

    commit("changeDetailStudent", {
      loading: true,
    });
    const result = await ApiGetRequest(
      `school/class/${data.id.detailKelasId}/student?simple=1`,
      params,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeDetailStudent", {
        loading: false,
      });

      return [];
    }

    if (payload && typeof payload === "object" && payload.export) {
      await commit("changeDetailStudent", {
        student_class_export: result.data.data,
        total: result.data.data.length,
        page: 1,
        loading: false,
      });
      return true;
    }
    await commit("changeDetailStudent", {
      items: result.data.data,
      total: result.data.meta.total,
      loading: false,
    });
  },

  filterRuangKelasById({ commit, state }) {
    const data = state.data.items.find(
      (item) => item.id === state.data.detailStudent.id.detailKelasId,
    );
    commit("changeRuangKelas", {
      form: {
        id: data.id || "",
        name: data.name || "",
        grade: data.grade || "",
        school_major_id: data.major?.id || "",
        major: data.major || "",
      },
      loading: false,
    });
  },

  async addSiswaDalamKelas({ commit, state }, payload) {
    commit("changeRuangKelas", {
      loading: true,
    });

    const { data } = state;

    let result = await ApiPostRequest(
      `school/class/${payload.id}/student`,
      data.data_nisn,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
      commit("changeRuangKelas", {
        errors: [],
      });

      return true;
    }
  },

  async deleteDataSiswa({ commit }, payload = null) {
    commit("changeDetailStudent", {
      loading: true,
    });
    let result = await ApiDeleteRequest(
      `school/class/${payload.class || ""}/student/${payload.student || ""}`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeDetailStudent", {
        loading: false,
      });
      return false;
    }
    Message({
      type: "success",
      message: "Data pelajar berhasil dihapus",
    });
    commit("changeRuangKelas", {
      errors: false,
      loading: false,
    });

    return true;
  },
  async deleteAllDataSiswa({ commit }, payload = null) {
    commit("changeRuangKelas", {
      loading: true,
    });

    let result = await ApiDeleteRequest(
      `school/class/${payload.class || ""}/student/all`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeRuangKelas", {
        loading: false,
      });
    } else {
      Message({
        type: "success",
        message: "Data semua pelajar berhasil dihapus",
      });
      commit("changeRuangKelas", {
        errors: false,
        loading: false,
      });

      return true;
    }
  },
  async inactiveClass({ commit }, payload) {
    commit("changeRuangKelas", {
      loading: true,
    });

    if (payload && typeof payload !== "object") {
      await commit("changeRuangKelas", {
        page: payload || data.page,
      });
    }

    const { data } = state;

		console.log('testing inactiveClass')
		const result = await ApiDeleteRequest(`school/class/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeRuangKelas", {
        loading: false,
      });

      return [];
    } else {
      Message({
        type: "success",
        message: "Kelas telah dinonaktifkan",
      });
      commit("changeRuangKelas", {
        loading: false,
      });
      return true;
    }
  },
  async activeClass({ commit, state }, payload) {
    commit("changeRuangKelas", {
      loading: true,
    });

    if (payload && typeof payload !== "object") {
      await commit("changeRuangKelas", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    const result = await ApiPostRequest(`school/class/${payload.id}/restore`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeRuangKelas", {
        loading: false,
      });

      return [];
    }
    Message({
      type: "success",
      message: "Kelas telah diaktifkan kembali",
    });
    commit("changeRuangKelas", {
      loading: false,
    });
    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
