import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { HandlerAlertUnauthorized } from "@/utils/Helpers";
import {
  REQUEST_TIMEOUT_MESSAGE,
  SERVER_ERROR_MESSAGE,
} from "@/services/messageService";

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "Authorization",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  mode: "no-cors",
  credentials: true,
  crossdomain: true,
});

const ApiV2 = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "Authorization",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "X-OK-Version": "2",
  },
  mode: "no-cors",
  credentials: true,
  crossdomain: true,
});

// declare a request API mobile (sementara)
const ApiMobile = axios.create({
  baseURL: process.env.VUE_APP_API_URL_MOBILE,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "Authorization",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  mode: "no-cors",
  credentials: true,
  crossdomain: true,
});

// Sementara
ApiMobile.interceptors.request.use(
  (config) => {
    const LocalDataVuex = JSON.parse(
      window.localStorage.getItem("storediskola"),
    );
    // kalo url yang diakses dari koperasi ganti pake token koperasi
    if (config.url.indexOf("koperasi") !== -1) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${
          LocalDataVuex && LocalDataVuex.tokenKoperasi
            ? LocalDataVuex.tokenKoperasi.token
            : LocalDataVuex
              ? LocalDataVuex.userToken
              : ""
        }`,
      };
    } else if (
      config.url.indexOf("payment") !== -1 &&
      config.url.indexOf("koperasi") === -1 &&
      config.url.indexOf("payment-token") === -1
    ) {
      // token untuk mutasi keuangan
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${
          LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
            ? LocalDataVuex.tokenDanaPartisipasi.token
            : LocalDataVuex
              ? LocalDataVuex.userToken
              : ""
        }`,
      };
    } else {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${
          (LocalDataVuex && LocalDataVuex.userToken) || ""
        }`,
      };
    }

    return config;
  },
  (error) => {
    // handle the error
    return Promise.reject(error);
  },
);

// declare a request interceptor
Api.interceptors.request.use(
  (config) => {
    const LocalDataVuex = JSON.parse(
      window.localStorage.getItem("storediskola"),
    );
    let token = `Bearer ${(LocalDataVuex && LocalDataVuex.userToken) || ""}`;
    // kalo url yang diakses dari koperasi ganti pake token koperasi
    /*if (config.url.includes("payment/")) {
      token = token.replace("Bearer ", "");
    }*/
    config.headers = {
      ...config.headers,
      Authorization: token,
    };

    return config;
  },
  (error) => {
    // handle the error
    return Promise.reject(error);
  },
);

// declare a request interceptor
ApiV2.interceptors.request.use(
  (config) => {
    const LocalDataVuex = JSON.parse(
      window.localStorage.getItem("storediskola"),
    );
    // kalo url yang diakses dari koperasi ganti pake token koperasi
    if (config.url.indexOf("koperasi") !== -1) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${
          LocalDataVuex && LocalDataVuex.tokenKoperasi
            ? LocalDataVuex.tokenKoperasi.token
            : LocalDataVuex
              ? LocalDataVuex.userToken
              : ""
        }`,
      };
    } else if (
      config.url.indexOf("payment") !== -1 &&
      config.url.indexOf("koperasi") === -1 &&
      config.url.indexOf("payment-token") === -1
    ) {
      // token untuk mutasi keuangan
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${
          LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
            ? LocalDataVuex.tokenDanaPartisipasi.token
            : LocalDataVuex
              ? LocalDataVuex.userToken
              : ""
        }`,
      };
      console.log(
        LocalDataVuex.tokenDanaPartisipasi.token,
        config.headers,
        "run heree",
      );
    } else {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${
          (LocalDataVuex && LocalDataVuex.userToken) || ""
        }`,
      };
    }

    return config;
  },
  (error) => {
    // handle the error
    return Promise.reject(error);
  },
);

export function ApiGetRequestMobile(url, data = {}) {
  return ApiMobile.get(url, {
    params: data,
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export const ApiPostRequestMobile = (url, data = {}) => {
  return ApiMobile.post(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

export function ApiGetRequestV2(url, data = {}) {
  return ApiV2.get(url, {
    params: data,
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export const ApiPostRequestV2 = (url, data = {}) => {
  return ApiV2.post(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error: (error.response && error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

export function ApiGetRequest(url, data = {}) {
  return Api.get(url, {
    params: data,
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch(async (error) => {
      try {
        if (error.response.status === 401) {
          if (store.state.stopRequest) {
            return {
              error: "Silahkan Login Ulang.",
            };
          } else {
            await store.commit("updateState", {
              stopRequest: true,
            });
            await HandlerAlertUnauthorized();
            return {
              error: "Silahkan Login Ulang.",
            };
          }
        }
        if (!error.response && error.response.status >= 500) {
          console.log("!error.response");
          throw {
            error: SERVER_ERROR_MESSAGE,
          };
        }
        const errors =
          error?.response?.data?.errors ||
          error?.response?.data?.messages ||
          "";
        const response = {
          code: error.response ? error.response.status : 500,
          error:
            (error.response && error.response.data.error
              ? error.response.data.error
              : error.response.data.message) || error,
          errorList: errors,
        };
        if (error.response.status === 307) {
          response.data = error.response.data;
        }
        return response;
      } catch (e) {
        return {
          code: 500,
          error: SERVER_ERROR_MESSAGE,
        };
      }
    });
}

export function ApiPostMultipart(url, data = {}) {
  return Api.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      if (!error.response) {
        return {
          code: 500,
          error: SERVER_ERROR_MESSAGE,
          errorList: [],
        };
      }
      return {
        code: error.response ? error.response.status : 500,
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export function ApiPostMultipartImportDapodikExecution(url, data = {}) {
  return Api.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-import": "2",
    },
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      if (!error.response) {
        return {
          code: 500,
          error: SERVER_ERROR_MESSAGE,
          errorList: [],
        };
      }
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export function ApiGetUploadFile(url, data = {}, callbackUpload = () => {}) {
  return Api.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (event) => callbackUpload(event),
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export const ApiPostRequest = (url, data = {}) => {
  return Api.post(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      if (!error.response) {
        console.log("!error.response");
        return {
          error: "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi",
        };
      }
      return {
        code: error.response ? error.response.status : 500,
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

export const ApiPutRequest = (url, data = {}) => {
  return Api.put(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      if (!error.response) {
        console.log("!error.response");
        return {
          error: "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi",
        };
      }
      return {
        code: error.response ? error.response.status : 500,
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

export const ApiDeleteRequest = (url, data = {}) => {
  return Api.delete(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      if (!error.response) {
        console.log("!error.response");
        return {
          error: "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi",
        };
      }
      return {
        code: error.response ? error.response.status : 500,
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

/** Api Khusus Dana DepoPayment */

const ApiDepoPayment = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "Authorization",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  mode: "no-cors",
  credentials: true,
  crossdomain: true,
});

ApiDepoPayment.interceptors.request.use(
  (config) => {
    const LocalDataVuex = JSON.parse(
      window.localStorage.getItem("storediskola"),
    );
    const resultTokenDepoPayment =
      LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
        ? LocalDataVuex.tokenDanaPartisipasi.token
        : "";

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${resultTokenDepoPayment}`,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function ApiGetRequestDepoPayment(url, data = {}) {
  return ApiDepoPayment.get(`payment/${url}`, {
    params: data,
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      try {
        return {
          error:
            (error.response && error.response.data.error
              ? error.response.data.error
              : error.response.data.message) || error,
          errorList:
            error.response && error.response.data && error.response.data.errors,
          code: error.response ? error.response.status : 500,
        };
      } catch (e) {
        return {
          error: SERVER_ERROR_MESSAGE,
          errorList: [],
          code: 500,
        };
      }
    });
}

export function ApiPostMultipartDepoPayment(url, data = {}) {
  return ApiDepoPayment.post(`payment/${url}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export function ApiGetUploadFileDepoPayment(
  url,
  data = {},
  callbackUpload = () => {},
) {
  return ApiDepoPayment.post(`payment/${url}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (event) => callbackUpload(event),
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export const ApiPostRequestDepoPayment = (url, data = {}) => {
  return ApiDepoPayment.post(`payment/${url}`, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

export const ApiPutRequestDepoPayment = (url, data = {}) => {
  return ApiDepoPayment.put(`payment/${url}`, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

export const ApiDeleteRequestDepoPayment = (url, data = {}) => {
  return ApiDepoPayment.delete(`payment/${url}`, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

/** Api Khusus SALDO KoperasoPayment */

const ApiKoperasiPayment = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "Authorization",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  mode: "no-cors",
  credentials: true,
  crossdomain: true,
});

ApiKoperasiPayment.interceptors.request.use(
  async (config) => {
    await store.dispatch("checkTokenKoperasi");
    const LocalDataVuex = JSON.parse(
      window.localStorage.getItem("storediskola"),
    );
    let resultTokenKoperasi =
      LocalDataVuex && LocalDataVuex.tokenKoperasi
        ? LocalDataVuex.tokenKoperasi.token
        : "";

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${resultTokenKoperasi}`,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function ApiGetRequestKoperasiPayment(url, data = {}) {
  return ApiKoperasiPayment.get(`payment/${url}`, {
    params: data,
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return {
          error: "Request dibatalkan",
          code: "CANCELLED_REQUEST",
        };
      } else if (error.response) {
        const response = error.response?.data;
        const errorString = response?.error || response?.message || error;
        const errors = response?.errors || [];
        const code = error.response?.status;
        return {
          error: errorString,
          errorList: errors,
          code: code,
        };
      } else if (error.request) {
        console.log("error.request");
        return {
          error: REQUEST_TIMEOUT_MESSAGE,
          code: 408,
        };
      } else {
        return {
          error: SERVER_ERROR_MESSAGE,
          code: 500,
        };
      }
    });
}

export const ApiPostRequestKoperasiPayment = (url, data = {}) => {
  return ApiKoperasiPayment.post(`payment/${url}`, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      const response = error.response?.data;
      const errors = response?.errors || response?.messages || "";
      const errorString = response?.error || response?.message || error;
      return {
        code: error.response?.status,
        error: errorString,
        errorList: errors,
      };
    });
};

/** Api Khusus Dana Partisipasi */

const ApiDanaPartisipasi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "Authorization",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  mode: "no-cors",
  credentials: true,
  crossdomain: true,
});

ApiDanaPartisipasi.interceptors.request.use(
  (config) => {
    const LocalDataVuex = JSON.parse(
      window.localStorage.getItem("storediskola"),
    );
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${
        LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
          ? LocalDataVuex.tokenDanaPartisipasi.token
          : ""
      }`,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function ApiGetRequestPartisipasi(url, data = {}) {
  return ApiDanaPartisipasi.get(url, {
    params: data,
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export function ApiPostMultipartPartisipasi(url, data = {}) {
  return ApiDanaPartisipasi.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export function ApiGetUploadFilePartisipasi(
  url,
  data = {},
  callbackUpload = () => {},
) {
  return ApiDanaPartisipasi.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (event) => callbackUpload(event),
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export const ApiPostRequestPartisipasi = (url, data = {}) => {
  return ApiDanaPartisipasi.post(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

export const ApiPutRequestPartisipasi = (url, data = {}) => {
  return ApiDanaPartisipasi.put(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

export const ApiDeleteRequestPartisipasi = (url, data = {}) => {
  return ApiDanaPartisipasi.delete(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
};

/** Api Khusus MOCK TESTING */

const ApiMockTesting = axios.create({
  baseURL: "http://localhost:3000/api/",
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "Authorization",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  mode: "no-cors",
  credentials: true,
  crossdomain: true,
});

ApiMockTesting.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function ApiMockGetRequest(url, data = {}) {
  return ApiMockTesting.get(url, {
    params: data,
  })
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return {
        code: error.response.status,
        error:
          (error.response && error.response.data.error
            ? error.response.data.error
            : error.response.data.message) || error,
        errorList:
          error.response && error.response.data && error.response.data.errors,
      };
    });
}

export const ApiNotification = (type = "success", message = "") => {
  Message({
    type: type,
    message: message,
  });

  return true;
};

export default {
  Api: Api,
  ApiV2: ApiV2,
  ApiMobile: ApiMobile,
};
