import repository from "@/repositories/PresensiRepository";
import { HandlerMessageServerError } from "@/utils/Helpers";
import ErrorService from "@/services/ErrorService";

class PresensiService {
	constructor() {
		this.presensiRepository = repository;
	}

	async nvrProxyGet(payload) {
		const { type, page, limit, q } = payload;
		if (type && !["GROUP", "SCHOOL"].includes(type)) {
			throw new Error("type must GROUP | SCHOOL.");
		}

		const params = {
			q: q || undefined,
			limit: limit || undefined,
			page: page || undefined,
			type: type || undefined,
		};

		try {
			return await this.presensiRepository.getNvrProxy(params);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async nvrProxyByIdGet(nvrId) {
		if (!nvrId || nvrId === 'undefined') {
			throw new Error("Client Request not complete.");
		}

		try {
			return await this.presensiRepository.getNvrProxyById(nvrId);
		} catch (error) {
			ErrorService.handleError(error);
		}
	}

	async nvrProxyPost(payload) {
		const { name, secret, type } = payload;
		if (!name || !secret || !type) {
			throw new Error("Client Request not complete.");
		}
		if (!["SCHOOL", "GROUP"].includes(type)) {
			throw new Error("Client Request type not same.");
		}

		const formData = {
			name: name,
			secret: secret,
			type: type,
		};

		try {
			return await this.presensiRepository.postNvrProxy(formData);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async nvrProxyPut(nvrId, payload) {
		const { name, type } = payload;
		if (!name || !type || !nvrId) {
			throw new Error("Client Request not complete.");
		}
		if (!["SCHOOL", "GROUP"].includes(type)) {
			throw new Error("Client Request type not same.");
		}

		const formData = {
			name: name,
			type: type,
		};

		try {
			return await this.presensiRepository.putNvrProxy(nvrId, formData);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async nvrProxyDelete(nvrId) {
		if (!nvrId) {
			throw new Error("Client Request not complete.");
		}

		try {
			return await this.presensiRepository.deleteNvrProxy(nvrId);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async deviceGet(payload) {
		const { device_type, page, limit, q, school_nvr_device_id } = payload;
		if (!device_type) {
			throw new Error("Client Request not complete.");
		}

		const params = {
			q: q || undefined,
			limit: limit || undefined,
			page: page || undefined,
			device_type: device_type || undefined,
			school_nvr_device_id: school_nvr_device_id || undefined,
		};

		try {
			return await this.presensiRepository.getDevice(params);
		} catch (error) {
			ErrorService.handleError(error)
		}
	}

	async deviceByIdGet(deviceId) {
		if (!deviceId) {
			throw new Error("Client Request not complete.");
		}

		try {
			return await this.presensiRepository.getDeviceById(deviceId);
		} catch (error) {
			ErrorService.handleError(error)
		}
	}

	async devicePost(payload) {
		const {
			name,
			sn,
			type,
			secret,
			school_nvr_device_id,
			tag,
			device_type,
		} = payload;
		if (
			!name ||
			!secret ||
			!type ||
			!sn ||
			!school_nvr_device_id ||
			!device_type
		) {
			throw new Error("Client Request not complete.");
		}
		if (!["IN", "OUT", "CLASS"].includes(type)) {
			throw new Error("Client Request type not same.");
		}

		const formData = {
			device_type: device_type,
			name: name,
			secret: secret,
			sn: sn,
			type: type || undefined,
			tag: tag || "sekolah",
			school_nvr_device_id: school_nvr_device_id,
		};

		try {
			return await this.presensiRepository.postDevice(formData);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async devicePut(deviceId, payload) {
		const {
			name,
			sn,
			type,
			secret,
			school_nvr_device_id,
			tag,
			device_type,
		} = payload;
		if (
			!deviceId ||
			!name ||
			!secret ||
			!type ||
			!sn ||
			!school_nvr_device_id ||
			!device_type
		) {
			throw new Error("Client Request not complete.");
		}
		if (!["IN", "OUT", "CLASS"].includes(type)) {
			throw new Error("Client Request type not same.");
		}

		const formData = {
			device_type: device_type,
			name: name,
			secret: secret,
			sn: sn,
			type: type || undefined,
			tag: tag || undefined,
			school_nvr_device_id: school_nvr_device_id,
		};

		try {
			return await this.presensiRepository.putDevice(deviceId, formData);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async deviceDelete(deviceId) {
		if (!deviceId) {
			throw new Error("Client Request not complete.");
		}

		try {
			return await this.presensiRepository.deleteDevice(deviceId);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async klastimeCctvUserSummary(payload) {
		const { include, page, limit, q, majors, classes } = payload;
		if (typeof include !== "object" || !include.length) {
			throw new Error("include must array ['avatar', 'photo']");
		}
		if (typeof majors !== "object") {
			throw new Error("majors must array");
		}
		if (typeof classes !== "object") {
			throw new Error("classes must array");
		}

		const handleInclude = [];
		for (let value of include) {
			if (["photo", "avatar"].includes(value)) {
				handleInclude.push(value);
			}
		}

		const params = {
			q: q || undefined,
			limit: parseInt(limit) || undefined,
			page: parseInt(page) || undefined,
			include: handleInclude,
		};

		if (majors.length && majors[0]) {
			params.majors = majors;
		}

		if (classes.length && classes[0]) {
			params.classes = classes;
		}

		try {
			return await this.presensiRepository.getUserSumaryKlastimeCctv(params);
		} catch (error) {
			console.error("call server error:", error);
			throw error;
		}
	}

	async klastimeUserGet(payload) {
		const { type, page, limit, q, classId, grade } = payload;
		if (!type) {
			throw new Error("Client Request not complete.");
		}

		const params = {
			q: q || undefined,
			limit: limit || undefined,
			page: page || undefined,
			type: type || undefined,
			class: classId || undefined,
			grade: grade || undefined,
		};

		try {
			return await this.presensiRepository.getUserInKlastime(params);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async klastimeUserPost(payload) {
		const { type, page, limit, q, classId, grade } = payload;
		if (!type) {
			throw new Error("Client Request not complete.");
		}

		const params = {
			q: q || undefined,
			limit: limit || undefined,
			page: page || undefined,
			type: type || undefined,
			class: classId || undefined,
			grade: grade || undefined,
		};

		try {
			return await this.presensiRepository.getUserInKlastime(params);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async klastimeUserPhotosCctvByUuidGet(uuid) {
		if (!uuid) {
			throw new Error("Client Request not complete.");
		}

		try {
			return await this.presensiRepository.getUserPhotosCctv(uuid);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async klastimeUserPhotosCctvByUuidPost(uuid, payload) {
		if (!uuid) {
			throw new Error("Client Request not complete.");
		}
		if (typeof payload !== "object") {
			throw new Error("payload Client Request not complete.");
		}

		const formData = new FormData();
		payload.forEach((file) => {
			formData.append("photos[]", file);
		});

		try {
			return await this.presensiRepository.postUserPhotosCctv(uuid, formData);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async klastimeUserPhotosCctvByUuidDelete(uuid, id) {
		if (!uuid || !id) {
			throw new Error("Client Request not complete.");
		}

		try {
			return await this.presensiRepository.deleteUserPhotosCctv(uuid, id);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}

	async createPresenceStudentPost(uuid, payload) {
		const {date, status} = payload
		if (!uuid || !date || !status) {
			throw new Error("Client Request not complete.");
		}

		// Daftar status yang diperbolehkan
		const allowedStatuses = ["Hadir", "Terlambat", "Izin", "Alpha"];

		// Validasi jika status tidak termasuk dalam daftar status yang diperbolehkan
		if (!allowedStatuses.includes(status)) {
			throw new Error(`Status "${status}" is not allowed. Allowed statuses are: ${allowedStatuses.join(", ")}`);
		}

		const formData = {
			date,
			status: status
		}

		try {
			return await this.presensiRepository.postCreatePresenceStudent(uuid, formData);
		} catch (error) {
			console.error("call server error:", error);
			if (error.status >= 500) {
				HandlerMessageServerError();
			}
			throw error;
		}
	}
}

export default new PresensiService();
