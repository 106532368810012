//import {checkVw} from "@/services/internalService";
import UserTemporaryService from "@/services/UserTemporaryService";
import {Message} from 'element-ui'
import {SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";

const defaultItemSummary = {
	"total": 0,
	"students": 0,
	"teachers": 0,
	"unverified": 0,
	"payment_active": 0
}

const state = {
	data: {
		loading: false,
		loadingSummary: false,
		itemSummary: {
			"total": 0,
			"students": 0,
			"teachers": 0,
			"unverified": 0,
			"payment_active": 0
		},
		errorSummary: null,
		errorDataItems: null,
		items: [],
		limit: 10,
		page: 1,
		total: 0,
		sortBy: "",
		sortType: "",
		filterQ: "",
		filterStatus: "all",
		columns: [
			{
				prop: "name",
				label: "Nama",
				minWidth: 200,
				sortable: true,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "email",
				label: "Email",
				minWidth: 200,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "referer",
				label: "Verif. Data",
				width: 150,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "is_klaspay_activated",
				label: "Regis Payment?",
				width: 150,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "created_at",
				label: "Registrasi",
				width: 180,
				"class-name": "body-14 col-black-2",
			},
			/*{
				fixed: "right",
				label: "Aksi",
				width: checkVw(170),
				"class-name": "body-14 col-black-2",
			},*/
		],
	},
};

const mutations = {
	changeUserTemporaryLoading(state, payload) {
		state.data.loading = payload;
	},
	changeUserTemporaryLoadingSummary(state, payload) {
		state.data.loadingSummary = payload;
	},
	changeUserTemporary(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
};

const actions = {

	async fetchUserTemporary({commit, state}) {

		const {data} = state;
		const params = {
			limit: data.limit,
			page: data.page,
			sortField: data.sortBy,
			sortDir: data.sortType,
			filterQ: data.filterQ,
			filterStatus: data.filterStatus,
		};

		try {
			commit("changeUserTemporaryLoading", true);
			const service = new UserTemporaryService()
			const result = await service.userTemporaryGet(params)

			if (result.data && result.data.data) {
				commit("changeUserTemporary", {
					items: result.data.data,
					total: result.data?.meta?.total || result.data.data.length
				})
			}
		} catch (e) {
			console.log(e)
			commit("changeUserTemporary", {
				items: []
			})

			if (e.status >= 500) {
				commit("changeUserTemporary", {
					errorDataItems: "Data Summary: "+SERVER_ERROR_MESSAGE
				})
			} else if (e.status >= 400) {
				Message({
					type: "error",
					message: e.error,
				});
			}
		} finally {
			commit("changeUserTemporaryLoading", false);
		}
	},

	async fetchSummaryUserTemporary({commit}) {

		try {
			commit("changeUserTemporaryLoadingSummary", true);
			const service = new UserTemporaryService()
			const result = await service.userTemporarySummaryGet()

			if (result.data && result.data.data) {
				commit("changeUserTemporary", {
					itemSummary: result.data.data
				})
			}
		} catch (e) {
			console.log(e)
			commit("changeUserTemporary", {
				itemSummary: JSON.parse(JSON.stringify(defaultItemSummary))
			})

			if (e.status >= 500) {
				commit("changeUserTemporary", {
					errorSummary: "Data Summary: "+SERVER_ERROR_MESSAGE
				})
			} else if (e.status >= 400) {
				commit("changeUserTemporary", {
					errorSummary: e.error
				})

				Message({
					type: "error",
					message: e.error,
				});
			}
		} finally {
			commit("changeUserTemporaryLoadingSummary", false);
		}
	},

};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
