import ApiService from "@/api/ApiService";
import axios from "axios";

class KioskRepository {
	constructor() {
		this.apiService = ApiService;
		this.cancelTokenSource = null;
		this.mock = 'http://localhost:3000/api';
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getKioskDevice(params) {
		this.createCancelToken()
		return await this.apiService.get(`/school/kiosk/devices`, {params});
	}

	async GetKioskDeviceGenerateToken(deviceId) {
		return await this.apiService.get(`/school/kiosk/devices/${deviceId}/get-token`);
	}

	async postKioskDevice(formData) {
		return await this.apiService.post(`/school/kiosk/devices`, formData);
	}

	async deleteForceLogoutKioskDevice(deviceId) {
		return await this.apiService.delete(`/school/kiosk/devices/${deviceId}/force-logout`);
	}

	/**
	 * IMAGE
	 * */
	async getImageKioskByDevice(deviceId, params) {
		return await this.apiService.get(`/school/kiosk/devices/${deviceId}/images`, {params});
	}

	async postImageKioskByDevice(deviceId, formData) {
		const config = {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		};
		return await this.apiService.post(`/school/kiosk/devices/${deviceId}/images`, formData, config);
	}

	async putImageKioskByDevice(deviceId, fileImageId, formData) {
		return await this.apiService.put(`/school/kiosk/devices/${deviceId}/images/${fileImageId}`, formData);
	}

	async deleteImageKioskByDevice(deviceId, fileImageId) {
		return await this.apiService.delete(`/school/kiosk/devices/${deviceId}/images/${fileImageId}`);
	}

	/**
	 * VIDEO
	 * */
	async getVideoKioskByDevice(deviceId, params) {
		return await this.apiService.get(`/school/kiosk/devices/${deviceId}/videos`, {params});
	}

	async postVideoKioskByDevice(deviceId, formData) {
		const config = {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		};
		return await this.apiService.post(`/school/kiosk/devices/${deviceId}/videos`, formData, config);
	}

	async putVideoKioskByDevice(deviceId, fileVideoId, formData) {
		return await this.apiService.put(`/school/kiosk/devices/${deviceId}/videos/${fileVideoId}`, formData);
	}

	async deleteVideoKioskByDevice(deviceId, fileVideoId) {
		return await this.apiService.delete(`/school/kiosk/devices/${deviceId}/videos/${fileVideoId}`);
	}

	/**
	 * WEBSITE
	 * */
	async getWebsiteKioskByDevice(deviceId, params) {
		return await this.apiService.get(`/school/kiosk/devices/${deviceId}/websites`, {params});
	}

	async getThumbnailWebsiteKioskByDevice(deviceId) {
		return await this.apiService.get(`/school/kiosk/devices/${deviceId}/websites/thumbnail`);
	}

	async postThumbnailWebsiteKioskByDevice(deviceId, formData) {
		const config = {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		}
		return await this.apiService.post(`/school/kiosk/devices/${deviceId}/websites/thumbnail`, formData, config);
	}

	async postWebsiteKioskByDevice(deviceId, formData) {
		return await this.apiService.post(`/school/kiosk/devices/${deviceId}/websites`, formData);
	}

	async putWebsiteKioskByDevice(deviceId, fileWebsiteId, formData) {
		return await this.apiService.put(`/school/kiosk/devices/${deviceId}/websites/${fileWebsiteId}`, formData);
	}

	async deleteWebsiteKioskByDevice(deviceId, fileWebsiteId) {
		return await this.apiService.delete(`/school/kiosk/devices/${deviceId}/websites/${fileWebsiteId}`);
	}

}

export default new KioskRepository();
