import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import {NO_INTERNET_MESSAGE} from "@/services/messageService";
import DanaPartisipasiService from "@/services/DanaPartisipasiService";
import {_} from "vue-underscore";
import Vue from "vue";

		//selectClass: ["ALUMNI 10", "Kelas 10 CCTV", "Kelas 11 C1", "kelas A", "KELAS ALUMNI", "X RPL 2", "X RPL 1", "XI RPL 1", "XII RPL 1"],
const state = {
	data: {
		isFormDirty: true,
		showSettingProgram: false,
		showDetailPartisipan: false,
		loading: false,
		errorDataItems: null,
		selectClass: ["XII RPL 1"],
		filterQ: '',
		page: 1,
		limit: 50,
		filterClassName: '',
		formDataProgramErrors: [],
		formDataProgram: {
			id: "",
			bill_id: "",
			bill_name: "testing",
			is_active: true,
			expired_date: "2024-12-31",
			target_nominal: 1000,
			wallet_id: "",
			listOld: [],
			list: [],
			listEdit: [],
		},
		rulesFormData: {
			bill_name: [
				{
					required: true,
					message: "Nama program tidak boleh kosong",
					trigger: "blur"
				}
			],
			target_nominal: [
				{
					required: true,
					message: "Biaya partisipasi tidak boleh kosong",
					trigger: "blur"
				},
				{
					validator: (rule, value, callback) => {
						const formatValue = Number(value);
						if (value || formatValue === 0) {
							if (formatValue < 1000 || formatValue > 5000000) {
								callback(new Error("Nominal partisipasi harus antara 1.000 dan 5.000.000"));
							} else {
								callback();
							}
						}
					},
					trigger: "change",
				},
			],
			expired_date: [
				{
					required: true,
					message: "Tanggal tidak boleh kosong",
					trigger: "blur"
				}
			]
		},
		columns: [
			{
				prop: "name",
				label: "Nama Pelajar",
				minWidth: "200",
				sortable: true,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "nis",
				label: "NIS / NISN",
				minWidth: "160",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "grade",
				label: "Grade",
				minWidth: 60,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "classes",
				label: "Kelas",
				width: "140",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "jurusan",
				label: "Jurusan",
				width: "140",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "partisipasi",
				label: "Partisipasi",
				width: "160",
				"class-name": "body-14 col-black-2",
			},
		],
		listStudent: {
			loading: false,
			itemsStudent: [
				{
					user_id: 207872,
					user_uuid: "48556034198440ee829c6d48e3263c3c",
					wallet_id: null,
					nisn: "0081414758",
					name: "ACHMAD ROYHAN",
					foto: null,
					kelas: "X RPL 1",
					grade: 10,
					jurusan: "REKAYASA PERANGKAT LUNAK"
				}
			],
			itemsGroupClassStudents: {},
			itemsClass: [
				{
					name: '',
					grade: 10,
					count_student: 0,
					count_active_payment: 0,
				}
			],
		}
	}
};

const mutations = {
	changeDanaPartisipasiCreateProgram(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeDanaPartisipasiCreateProgramFormData(state, payload) {
		state.data.formDataProgram = Object.assign({}, state.data.formDataProgram, payload);
	},
	UPDATE_LIST_PARTISIPAN(state, rowData) {
		let allItems = state.data.formDataProgram.list || []
		let index = allItems.findIndex(item => item.user_id === rowData.user_id)

		if (index !== -1) {
			state.data.formDataProgram.list[index] = JSON.parse(JSON.stringify(rowData))
			Vue.set(state.data.formDataProgram.list, index, JSON.parse(JSON.stringify(rowData)));
		}
	},
	BACKUP_LIST_PARTISIPAN(state) {
		state.data.formDataProgram.listOld = JSON.parse(JSON.stringify(state.data.formDataProgram.list))
	},
	changeDanaPartisipasiCreateProgramLoading(state, payload) {
		state.data.loading = payload;
	},

	changeDanaPartisipasiCreateProgramListStudent(state, payload) {
		state.data.listStudent = Object.assign({}, state.data.listStudent, payload);
	},
	changeDanaPartisipasiCreateProgramListStudentLoading(state, payload) {
		state.data.listStudent.loading = payload;
	},

	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
};

const actions = {
	async onShowSettingProgram({commit}, isOpen) {
		commit("changeDanaPartisipasiCreateProgram", {
			showSettingProgram: isOpen
		})
	},

	async onShowDetailPartisipan({commit}, isOpen) {
		commit("changeDanaPartisipasiCreateProgram", {
			showDetailPartisipan: isOpen
		})
	},

	async generateGroupClassStudents({commit}, datases) {

		let classRooms = _.groupBy(datases, "kelas");

		//let listOfSubclass = Object.keys(classRooms);
		commit("changeDanaPartisipasiCreateProgramListStudent", {
			itemsGroupClassStudents: classRooms
		})
	},

	async generateListStudent({commit}, datases) {
		// Use an object to group students by class and grade
		const classMap = {};

		datases.forEach((student) => {
			const key = `${student.kelas}-${student.grade}`;

			if (!classMap[key]) {
				classMap[key] = {
					name: student.kelas,
					grade: student.grade,
					count_student: 0,
					count_active_payment: 0,
				};
			}

			// Increment student count
			classMap[key].count_student++;

			// Increment active payment count if wallet_id is not null
			if (student.wallet_id) {
				classMap[key].count_active_payment++;
			}
		});

		// Convert the grouped object into an array
		//return Object.values(classMap);
		commit("changeDanaPartisipasiCreateProgramListStudent", {
			itemsClass: Object.values(classMap),
		})
	},

	async fetchListStudentAll({commit, state, dispatch}, schoolUuid) {

		if (!schoolUuid || !schoolUuid === 'not found') {
			Message({
				icon: "warning",
				message: "ID Not Found"
			})
			return false
		}

		const {listStudent} = state.data

		if (listStudent.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeDanaPartisipasiCreateProgramListStudentLoading", true)

		try {
			const service = new DanaPartisipasiService()
			const result = await service.detailDanaPartisipasiStudent(schoolUuid)

			const datases = result.data?.data
			if (datases) {
				commit("changeDanaPartisipasiCreateProgramListStudent", {
					itemsStudent: JSON.parse(JSON.stringify(datases)),
				})
				dispatch("generateListStudent", datases)
				dispatch("generateGroupClassStudents", datases)
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {

			console.log(e)

			commit("changeDanaPartisipasiCreateProgramListStudent", {
				itemsStudent: [],
				itemsClass: []
			})

			let m = e.error ? e.error : "Gagal mengambil data"
			if (e.status >= 500) {
				m = SERVER_ERROR_MESSAGE
			} else if (e.code === "ERR_NETWORK") {
				m = NO_INTERNET_MESSAGE
			}

			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeDanaPartisipasiCreateProgramListStudentLoading", false)
		}
	},

};

const getters = {
	countStudentBySelectClassGetter: state => {
		// Menghitung jumlah siswa dari kelas yang dipilih
		const totalSelectedStudents = Object.entries(state.data.listStudent.itemsGroupClassStudents)
			.filter(([kelas]) => state.data.selectClass.includes(kelas))
			.reduce((total, [, classList]) => {
				return total + classList.length
			}, 0);
		return totalSelectedStudents
	},

	countStudentActivateBySelectClassGetter: (state, getters) => {
		// Mengambil daftar siswa dari kelas yang dipilih
		const selectedStudents = getters.listStudentBySelectClassDetailPartisipanGetter;

		// Menghitung jumlah siswa dengan wallet_id yang tidak null
		return selectedStudents.reduce((total, student) => {
			// Tambah 1 ke total jika wallet_id tidak null
			return student.wallet_id ? total + 1 : total;
		}, 0);
	},

	// Getter untuk mendapatkan daftar siswa dari kelas yang dipilih
	listStudentBySelectClassDetailPartisipanGetter: state => {
		// Mengambil daftar siswa dari kelas yang dipilih
		return Object.entries(state.data.listStudent.itemsGroupClassStudents)
			.filter(([kelas]) => state.data.selectClass.includes(kelas)) // Filter kelas yang dipilih
			.reduce((students, [, classList]) => {
				return students.concat(classList); // Gabungkan daftar siswa dari setiap kelas yang dipilih
			}, [])
	},

	itemsStudentDetailPartisipanGetter: (state) => {
		const {filterQ, filterClassName, page, limit} = state.data
		// Mengambil daftar siswa dari kelas yang dipilih
		let selectedStudents = state.data.formDataProgram.list

		// tambahkan logic filterClass
		if (filterQ) {
			selectedStudents = selectedStudents.filter(item => {
				const nisn = item.nisn && item.nisn.toLowerCase()
				return item.name.toLowerCase().includes(filterQ.toLowerCase()) || filterQ.toLowerCase().includes(nisn)
			})
		}
		// tambahkan logic filterStudentName
		if (filterClassName) {
			selectedStudents = selectedStudents.filter(item => {
				return item.kelas.includes(filterClassName)
			})
		}
		// Pagination logic: calculate the start and end index for the current page
		const startIndex = (page - 1) * limit;
		const endIndex = page * limit;

		// Return paginated items
		return selectedStudents.slice(startIndex, endIndex);
	},

	// Getter untuk mendapatkan daftar kelas dari kelas yang dipilih
	listClassBySelectClassV1Getter: state => {
		// Mengambil daftar siswa dari kelas yang dipilih
		const selectedClass = Object.entries(state.data.listStudent.itemsGroupClassStudents)
			.filter(([kelas]) => state.data.selectClass.includes(kelas)) // Filter kelas yang dipilih
			.map(([kelas, classList]) => {
				return {
					name: kelas,
					count_student: classList.length
				};
			});

		return selectedClass;
	},

	// Getter untuk mendapatkan daftar kelas dari kelas yang dipilih
	listClassBySelectClassGetter: state => {
		// Mengambil daftar siswa dari kelas yang dipilih
		return state.data.listStudent.itemsClass
			.filter(kelas => state.data.selectClass.includes(kelas.name));
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
