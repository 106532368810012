import {requirePermission} from "@/router/index";

export default [
	{
		path: "/dana-partisipasi",
		name: "DanaPartisipasi",
		component: () =>
			import(
				/* webpackChunkName: "dana-partisipasi" */
				"../views/DanaPartisipasi"
				),
		meta: {
			title: "Dana Partisipasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		redirect: "/dana-partisipasi/program",
		children: [
			{
				path: "program",
				name: "DanaPartisipasiProgram",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-program" */
						"../views/DanaPartisipasi/Program"
						),
				meta: {
					title: "Dana Partisipasi Program | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "form-program-partisipasi",
				name: "FormProgramPartisipasi",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-form-program" */
						"../views/DanaPartisipasi/Program/FormProgramPartisipasi"
						),
				meta: {
					title: "Dana Partisipasi Program | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "form-program-partisipasi-siswa",
				name: "FormProgramPartisipasiPerSiswa",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-form-program" */
						"../views/DanaPartisipasi/Program/FormProgramPartisipasi/PerSiswa.vue"
						),
				meta: {
					title: "Dana Partisipasi Program Per Siswa | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "form-program-partisipasi/:id",
				name: "EditFormProgramPartisipasi",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-form-program" */
						"../views/DanaPartisipasi/Program/FormProgramPartisipasi"
						),
				meta: {
					title: "Dana Partisipasi Program | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pembayaran",
				name: "DanaPartisipasiPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-pembayaran" */
						"../views/DanaPartisipasi/Pembayaran"
						),
				meta: {
					title: "Dana Partisipasi Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "biaya-admin",
				name: "BiayaAdminDanaPartisipasi",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-admin" */
						"../views/DanaPartisipasi/BiayaAdmin"
						),
				meta: {
					title: "Dana Partisipasi Biaya Admin | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
]
