import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest,
} from "@/utils/Api";
// import moment from "moment";
import { Message } from "element-ui";
import { HandlerNotification } from "@/utils/Helpers";
import Vue from "vue";
import { checkVw } from "@/services/internalService";

const state = {
  data: {
		selectedPrintQrCode: [],
		isPageAction: false,
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    editData: false,
    errors: [],
    columnsDaftarKantin: [
			{
				prop: "id",
				type: "selection",
			},
      {
        prop: "nama_kantin",
        label: "Nama Kantin",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "pemilik",
        label: "Pemilik",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "wallet",
        label: "Wallet",
        width: "80",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "no_telp",
        label: "No Telp",
        width: "150",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "email",
        label: "Email",
        minWidth: "200",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "sharing_profit",
        label: "Sharing Profit",
        width: "170",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "payment",
        label: "SALDO",
        minWidth: "120",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },

      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(140),
        "class-name": "body-14 col-black-2",
      },
    ],
    dataItem: [],
    formDaftarKantin: {
      id: null,
      namaKantin: "",
      pemilik: "",
      noTelp: "",
      email: "",
      profit_share: null,
    },
    errorsForm: {},
    rulesForm: {
      namaKantin: [
        {
          required: true,
          message: "Nama toko wajib diisi",
          trigger: "blur",
        },
      ],
      pemilik: [
        {
          required: true,
          message: "Nama pemilik toko wajib diisi",
          trigger: "blur",
        },
      ],
      noTelp: [
        {
          required: true,
          message: "Nomor telp wajib diisi",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Email wajib diisi",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Alamat email harus benar",
          trigger: ["blur", "change"],
        },
      ],
      // profit_share: [
      //   {
      //     required: true,
      //     message: "Sharing Profit wajib diisi",
      //     trigger: "blur",
      //   },
      // ],
    },
  },
};

const mutations = {
  changeKoperasiDaftarKantin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changekantinLoading(state, payload) {
    state.data.loading = payload;
  },
  ChangeDaftarKantinErrorFormVueSet(state, payload) {
    Vue.set(state.data, "errorsForm", payload);
  },
};

const actions = {
  async getDataTable({ commit, state }, payload) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });

    if (payload) {
      await commit("changeKoperasiDaftarKantin", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };

    const result = await ApiGetRequest(`school/canteen`, params);
    //const result = await ApiGetRequest(`http://localhost:3000/api/school/canteen/error500`, params);

    commit("changeKoperasiDaftarKantin", {
      loading: false,
    });
    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification(500);
        return false;
      }
      Message({
        type: "error",
        message: result.error,
      });
      return false;
    }
    commit("changeKoperasiDaftarKantin", {
      dataItem: result.data.data,
      total: result.data.meta.total,
    });
  },

  async createCanteen({ commit, state }) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });
    commit("ChangeDaftarKantinErrorFormVueSet", {});

    const { data } = state;

    let formData = {
      name: data.formDaftarKantin.pemilik,
      merchant: data.formDaftarKantin.namaKantin,
      phone: data.formDaftarKantin.noTelp,
      email: data.formDaftarKantin.email,
      profit_share: data.formDaftarKantin.profit_share,
    };

    const result = await ApiPostRequest(`school/canteen`, formData);
    /*console.log(formData);
    const result = await ApiGetRequest(
      `http://localhost:3000/api/school/errors400`
    );*/
    commit("changekantinLoading", false);

    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification(500);

        commit("changeKoperasiDaftarKantin", {
          error: false,
          errorsForm: {},
        });
        return false;
      }

      Message({
        type: "error",
        message: "Gagal menambahkan data, periksa kembali data anda",
      });

      if (result.errorList) {
        commit("ChangeDaftarKantinErrorFormVueSet", result.errorList);
      }

      commit("changeKoperasiDaftarKantin", {
        error: result.errorList ? result.errorList : false,
      });

      return false;
    }

    commit("changeKoperasiDaftarKantin", {
      error: false,
      errorsForm: {},
    });

    Message({
      type: "success",
      message: "Data Kantin berhasil ditambahkan",
    });

    return true;
  },

  async showDataCanteen({ commit }, payload) {
    commit("changekantinLoading", true);

    const result = await ApiGetRequest(`school/canteen/${payload.id_canteen}`);
    commit("changekantinLoading", false);

    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification(500);
        return false;
      }
      Message({
        type: "error",
        message: result.error,
      });
      return false;
    }
    commit("changeKoperasiDaftarKantin", {
      formDaftarKantin: {
        id: result.data.data.id,
        namaKantin: result.data.data.merchant,
        pemilik: result.data.data.name,
        noTelp: result.data.data.phone,
        email: result.data.data.email,
        profit_share: result.data.meta.profit_share,
      },
      loading: false,
    });
  },
  async editCanteen({ commit, state }, payload) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });

    const { data } = state;

    let formData = {
      name: data.formDaftarKantin.pemilik,
      merchant: data.formDaftarKantin.namaKantin,
      phone: data.formDaftarKantin.noTelp,
      email: data.formDaftarKantin.email,
      profit_share: data.formDaftarKantin.profit_share,
    };

    const result = await ApiPutRequest(
      `school/canteen/${payload.id_canteen}`,
      formData,
    );

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data, periksa kembali data anda",
      });

      commit("changeKoperasiDaftarKantin", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Data Kantin berhasil diubah",
      });

      return result.data.data;
    }
  },
  async deleteCanteen({ commit }, payload) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });

    const result = await ApiDeleteRequest(
      `school/canteen/${payload.id_canteen}`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });
    } else {
      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });
      Message({
        type: "success",
        message: "Data Kantin berhasil dihapus",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
