// Fungsi untuk memeriksa apakah tanggal telah melewati tanggal saat ini
import moment from "moment";
import fileStorage from "@/store/Pengguna/FileStorage";

function isExpired(dateString) {
  const now = moment();
  const expiredDate = moment(dateString);
  return now.isAfter(expiredDate);
}

// Fungsi untuk memeriksa apakah usage melebihi batas storage
function isOverUsage(usage, storageLimit) {
  return usage > storageLimit;
}

// Fungsi untuk memeriksa apakah saat ini adalah 10 hari sebelum tanggal kadaluwarsa
/**
 * @param dateString
 * */
function isTenDaysBeforeExpiry(dateString) {
  if (!dateString) {
    return false;
  }
  const now = moment();
  const day = fileStorage.state.data.notifikasiPaketLangganan || 10;
  const expiryMinusTenDays = moment(dateString).subtract(day, "days");
  console.log(
    "now.isAfter(expiryMinusTenDays)",
    now.isAfter(expiryMinusTenDays),
  );
  console.log(
    "now.isBefore(moment(dateString))",
    now.isBefore(moment(dateString)),
  );

  return (
    (now.isAfter(expiryMinusTenDays) && now.isBefore(moment(dateString))) ||
    now.isAfter(expiryMinusTenDays)
  );
}

// Fungsi untuk memeriksa apakah penggunaan sudah mencapai 85% atau lebih dari total storage
function isNearFullUsage(usage, storageLimit) {
  return usage / storageLimit >= 0.85;
}

function gbToBytes(sizeGb) {
  if (sizeGb) {
    return sizeGb * 1024 ** 3;
  }
  return 0;
}

function calculateFreeStoragePercent(storagePacket, usage) {
  const bytes = gbToBytes(storagePacket);
  const freeStorage = bytes - parseInt(usage);
  const percentage = (freeStorage / bytes) * 100;

  // Jika persentase lebih dari 100, maka tetapkan sebagai 100.
  // Jika persentase kurang dari 0, maka tetapkan sebagai 0.
  const boundedPercentage = Math.min(Math.max(percentage, 0), 100);

  return Math.round(boundedPercentage); // Bulatkan hasilnya
}

export const defaultFileInputs = {
  id: "",
  category: "",
  nameSelect: "",
  name: "",
  currentNameFile: "",
  image: null,
  imgPreview: null,
  type: null,
  size: null,
  status: "published",
};

export const sleepPromise = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatNameFileDownload = (category, userName, no) => {
  return `${category}_${userName}_${no}`;
};

/**
 * rowDetailKlasdrive {path,klasdrive_user{nis_nik,name,klasdrive_name} }
 * */
export const downloadFileEventHandler = async (
  callFunc,
  rowDetailKlasdrive,
) => {
  try {
    return await callFunc({
      path: rowDetailKlasdrive.path,
      year: moment(rowDetailKlasdrive.created_at).year(),
      fileName: rowDetailKlasdrive.klasdrive_name,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export default {
  isExpired,
  isOverUsage,
  isTenDaysBeforeExpiry,
  gbToBytes,
  calculateFreeStoragePercent,
  isNearFullUsage,
};
