import { ApiGetRequest, ApiNotification, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";
import { _ } from "vue-underscore";
import { PAYMENT_SERVER_ERROR_MESSAGE } from "@/services/messageService";
import { checkVw } from "@/services/internalService";

const state = {
  data: {
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    editData: false,
    errors: [],
    columnsDanaKantin: [
      {
        prop: "wallet",
        label: "Wallet",
        minWidth: "80",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },

      {
        prop: "nominal",
        label: "Nominal",
        minWidth: "150",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "created_at",
        label: "Waktu",
        width: "180",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "status",
        label: "Status",
        width: "150",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(145),
        "class-name": "body-14 col-black-2",
      },
    ],
    dataItem: [],
    dataCanteen: {},
    formDaftarKantin: {
      id: null,
      namaKantin: "",
      pemilik: "",
      noTelp: "",
      email: "",
    },
    rulesForm: {
      namaKantin: [
        {
          required: true,
          message: "Nama toko wajib diisi",
          trigger: "blur",
        },
      ],
      pemilik: [
        {
          required: true,
          message: "Nama pemilik toko wajib diisi",
          trigger: "blur",
        },
      ],
      noTelp: [
        {
          required: true,
          message: "Nomor telp wajib diisi",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Email wajib diisi",
          trigger: "blur",
        },
      ],
    },
  },
};
const mutations = {
  changeKoperasiDanaKantin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKoperasiDanaKantinLoading(state, payload) {
    state.data.loading = payload;
  },
};

const actions = {
  async getDataTableWithdraw({ commit, state }, payload) {
    commit("changeKoperasiDanaKantinLoading", true);

    if (payload) {
      await commit("changeKoperasiDanaKantin", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      pageSize: data.limit ? data.limit : undefined,
      page: data.page ? data.page : undefined,
      sort_field: data.sortBy ? data.sortBy : undefined,
      sort_dir: data.sortType ? data.sortType : undefined,
      q: data.filter ? data.filter : undefined,
    };

    const result = await ApiGetRequest(`payment/canteen/withdraw`, params);
    //const result = await ApiMockGetRequest(`payment/canteen/withdraw200`, params);
    commit("changeKoperasiDanaKantinLoading", false);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeKoperasiDanaKantin", {
        dataItem: [],
      });
      return false;
    }

    commit("changeKoperasiDanaKantin", {
      dataItem: result.data.data,
    });
    return true;
  },

  async approveWithdraw({ commit }, transaction_id) {
    commit("changeKoperasiDanaKantinLoading", true);
    const result = await ApiPostRequest(
      `payment/canteen/withdraw/${transaction_id}/approved`,
    );
    //const result = await ApiMockGetRequest("payment/canteen/withdraw-approved200");
    commit("changeKoperasiDanaKantinLoading", false);

    if (result.code >= 400) {
      commit("changeKoperasiDanaKantin", {
        error: true,
      });

      if (result.code >= 500) {
        ApiNotification(
          "error",
          `${result.code} ${PAYMENT_SERVER_ERROR_MESSAGE}`,
        );
        return false;
      }
      Message({
        type: "error",
        message: `Gagal Setujui data. ${result.code} ${result.error}`,
      });
      return false;
    }

    Message({
      type: "success",
      message: "Penarikan Berhasil disetujui",
    });
    commit("changeKoperasiDanaKantin", {
      error: false,
    });

    return result.data.data;
  },

  async declineWithdraw({ commit }, transaction_id) {
    commit("changeKoperasiDanaKantinLoading", true);
    const result = await ApiPostRequest(
      `payment/canteen/withdraw/${transaction_id}/decline`,
    );
    //const result = await ApiMockGetRequest("payment/canteen/withdraw-decline500");
    commit("changeKoperasiDanaKantinLoading", false);

    if (result.code >= 400) {
      commit("changeKoperasiDanaKantin", {
        error: true,
      });

      if (result.code >= 500) {
        ApiNotification(
          "error",
          `${result.code} ${PAYMENT_SERVER_ERROR_MESSAGE}`,
        );
        return false;
      }
      Message({
        type: "error",
        message: "Gagal Membatalkan Penarikan data",
      });
      return false;
    }

    Message({
      type: "success",
      message: "Pembatalan Penarikan Berhasil",
    });
    commit("changeKoperasiDanaKantin", {
      error: false,
    });

    return result.data.data;
  },

  async getDataDetail({ commit }, wallet_id) {
    commit("changeKoperasiDanaKantinLoading", true);

    // const { data } = state;

    const params = {
      limit: 100,
    };

    const result = await ApiGetRequest(`school/canteen`, params);
    commit("changeKoperasiDanaKantinLoading", false);

    console.log(result);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKoperasiDanaKantin", {
        loading: false,
      });

      return false;
    }
    let dataFilter = _.findWhere(result.data.data, {
      wallet_id: wallet_id,
    });
    console.log(dataFilter);

    commit("changeKoperasiDanaKantin", {
      dataCanteen: {
        data: dataFilter,
      },
      loading: false,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
