// SuratMasukImplement.js
import moment from "moment";
import {
  handler401ApiService,
  handlerTimeOutApiService,
} from "@/utils/ApiService";
import { handlerServerErrorAxios } from "@/services/internalService";

moment.locale("id");

const ENDPOINT = "/school/shared-agenda";

export default class SuratMasukImplement {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async suratMasuk(data) {
    try {
      const params = this.paramsFetchSuratMasuk(data);
      if (!params) {
        throw new Error("Invalid parameters for suratMasuk.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/error500`, { params });
      return await this.apiService.get(`${ENDPOINT}`, { params });
    } catch (error) {
      console.error("Error in SuratMasukImplement.suratMasuk:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async duplicateToDocumentSchoolMutation(suratMasukId, { name, category }) {
    try {
      if (!suratMasukId) {
        throw new Error("Invalid suratMasukId parameters for suratMasuk.");
      }
      if (!name) {
        throw new Error("Invalid formData.name for suratMasuk.");
      }
      if (!category) {
        throw new Error("Invalid formData.category for suratMasuk.");
      }
      const formData = {
        name,
        category: category.replace(" ", "_"),
      };
      //return await this.apiService.get(`http://localhost:3000/api/error401`, { params: {} });
      return await this.apiService.post(
        `${ENDPOINT}/${suratMasukId}/mutation`,
        formData,
      );
    } catch (error) {
      console.error(
        "Error in SuratMasukImplement.duplicateToDocumentSchoolMutation:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  truncateString(value, key) {
    const max = {
      purpose: 100,
      subject: 240,
    };
    if (!value) {
      return "";
    }
    return value.length > max[key] ? value.substring(0, max[key]) : value;
  }

  paramsFetchSuratMasuk(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama employee
    }

    if (data.category) {
      params.category = data.category; // ops | nama employee
    }

    return params;
  }

  validatePage({ page }, params) {
    if (page) {
      if (!isNaN(page) && page !== 0) {
        params.page = parseInt(page);
      } else {
        throw new Error("Error: 'page' must be a numeric value");
      }
    }
  }

  validateLimit({ limit }, params) {
    const maxLimit = 500;
    if (limit) {
      const numericLimit = parseInt(limit, 10);
      if (isNaN(numericLimit) || numericLimit > maxLimit) {
        throw new Error(
          `Invalid limit value: '${limit}'. The limit must be a number and cannot exceed ${maxLimit}.`,
        );
      }
      params.limit = numericLimit;
    }
  }

  validateSortField(
    { sort_field },
    params,
    validSortFields = ["name", "created_at", "agenda_number"],
  ) {
    if (sort_field && !validSortFields.includes(sort_field)) {
      throw new Error(
        `Invalid sort_field value: '${sort_field}'. Valid values are ${validSortFields.join(
          ", ",
        )}.`,
      );
    }
    params.sort_field = sort_field;
  }

  validateSortDir({ sort_dir }, params) {
    if (typeof sort_dir === "boolean") {
      params.sort_dir = sort_dir ? "desc" : "asc";
    } else {
      const validSortDirections = ["asc", "desc"];
      if (sort_dir && !validSortDirections.includes(sort_dir)) {
        throw new Error(
          `Invalid sort_dir value: '${sort_dir}'. Valid values are ${validSortDirections.join(
            ", ",
          )}.`,
        );
      }
      params.sort_dir = sort_dir;
    }
  }

  validateType({ type }, params) {
    const validTypes = ["sd", "smp", "sma", "smk", "slb"];
    if (type && !validTypes.includes(type.toLowerCase())) {
      throw new Error(
        `Invalid type value: '${type}'. Valid values are ${validTypes.join(
          ", ",
        )}.`,
      );
    }
    params.type = type && type.toUpperCase();
  }
}
