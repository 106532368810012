import {
  ApiGetRequest,
  ApiPostRequest,
  ApiDeleteRequest,
  ApiPutRequest,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    majors: [],
    majorsAll: [],
    limit: 10,
    page: 1,
    sortBy: "",
    formData: {
      id: "",
      name: "",
      major_global_id: null,
    },
    sortType: "desc",
    filter: "",
    status: "active",
    loading: false,
    majorList: [],
  },
};

const mutations = {
  changeJurusan(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  // async getJurusan({ commit, state }, payload) {
  //   commit("changeJurusan", {
  //     loading: true,
  //   });
  //   if (payload) {
  //     let sortData = payload.sortParams.length > 0 ? payload.sortParams[0] : "";
  //     sortData = sortData.split("|");

  //     commit("changeJurusan", {
  //       page: payload.page || 1,
  //       sortBy: sortData.length > 0 ? sortData[0] : "",
  //       sortType: sortData.length > 0 ? sortData[1] : "",
  //     });
  //   }

  //   const { data } = state;

  //   let params = {
  //     limit: data.limit,
  //     page: data.page,
  //     show: data.status,
  //   };

  //   if (data.filter && data.filter.length > 0) {
  //     params["q"] = data.filter;
  //   }

  //   if (data.status && data.status.length > 0) {
  //     params["show"] = data.status;
  //   }

  //   const result = await ApiGetRequest(`school/major`, params);

  //   if (result.error) {
  //     Message({
  //       type: "error",
  //       message: result.error,
  //     });
  //     commit("changeJurusan", {
  //       loading: false,
  //     });
  //   } else {
  //     commit("changeJurusan", {
  //       majors: result.data.data,
  //       total: result.data.meta.total,
  //       loading: false,
  //     });

  //     return {
  //       data: result.data.data,
  //       total: result.data.meta.total,
  //     };
  //   }
  // },

  async fetchJurusanAktifSemua({ commit, state }) {

    let params = {
      limit: 1000,
      page: 1,
      sort_field: 'name',
      sort_dir: 'asc',
      show: 'active',
    };

    const result = await ApiGetRequest(`school/major`, params);

    if (result.error) {
      commit("changeJurusan", {
				majorsAll: JSON.parse(JSON.stringify(state.data.majorsAll)),
      });

			return {}
    }

      commit("changeJurusan", {
				majorsAll: result.data.data,
      });

      return {
        data: result.data.data,
        total: result.data.meta ? result.data.meta.total : result.data.data,
      };
  },

  async getJurusan({ commit, state }, payload) {
    commit("changeJurusan", {
      loading: true,
    });

    if (payload) {
      await commit("changeJurusan", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    if (data.status) {
      params.show = data.status;
    }

    const result = await ApiGetRequest(`school/major`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeJurusan", {
        loading: false,
      });
    } else {
      commit("changeJurusan", {
        majors: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });

      return {
        data: result.data.data,
        total: result.data.meta ? result.data.meta.total : result.data.data,
      };
    }
  },

  async deleteJurusan(context, payload) {
    const { id } = payload;
    const result = await ApiDeleteRequest(`school/major/${id}`);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Jurusan telah dinonaktifkan",
      });

      return true;
    }
  },

  async restoreJurusan(context, payload) {
    const { id } = payload;
    const result = await ApiPostRequest(`school/major/${id}/restore`, {});
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      Message({
        type: "success",
        message: "Jurusan telah diaktifkan kembali",
      });

      return true;
    }
  },

  async submitJurusan({ commit, state }) {
    const { data } = state;

    commit("changeJurusan", {
      loading: true,
    });

    const formData = {
      // name: data.formData.name,
      major_global_id: data.formData.major_global_id,
    };

    let result = "";

    if (data.formData.id) {
      // result = await ApiPutRequest(`school/major/${data.form.id}`, formData);
      result = await ApiPutRequest(
        `school/major/store-from-major-global/${data.form.id}`,
        formData,
      );
    } else {
      // result = await ApiPostRequest(`school/major`, formData);
      result = await ApiPostRequest(
        `school/major/store-from-major-global`,
        formData,
      );
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeJurusan", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      // Message({
      //     type: 'success',
      //     message: 'Data telah tersimpan.',
      // });
      commit("changeJurusan", {
        majors: [],
        limit: 10,
        page: 1,
        sortBy: "",
        formData: {
          id: "",
          name: "",
        },
        sortType: "desc",
        filter: "",
        status: "active",
        loading: false,
      });

      return true;
    }
  },

  async getGlobalJurusan({ commit }) {
    commit("changeJurusan", {
      loading: true,
    });

    const result = await ApiGetRequest(`school/major-global`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeJurusan", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeJurusan", {
        majorList: result.data.data,
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
