export const CONTENT_TYPE_TRANSLATE = {
  banner: "slide",
  greeting: "Sambutan",
  vision: "Visi",
  mission: "Misi",
  excellence: "",
  about: "Tentang Kami",
  testimonial: "Testimoni",
  purpose: "Tujuan",
  history: "Sejarah",
  organization: "ManajemenMutu",
  email: "Email",
  phone: "Nomor Telpon",
  social_facebook: "Link Facbook",
  social_instagram: "Link Instagram",
  social_twitter: "Link Twitter",
  social_linkedin: "Link Linkedin",
  social_youtube: "Link Youtube",
  address: "Alamat",
  logo: "Logo Sekolah",
  maps: "Maps",
  foreword: "Kata Pengantar",
  news: "Berita",
  information: "Informasi",
  gallery: "Galeri",
  footer_desc: "Deskripsi Website SEO Google",
  majors: "Jurusan",
  majors_contact: "Informasi Kontak Kejuruan",
  extracurricular: "Ekstrakurikuler",
  profile_school: "Profil Sekolah",
  profile_school_file: "Profil Sekolah File",
  vision_mission_file: "Visi Misi File",
  google_site_verification: "Google Analytics",
  ppdb: "PPDB",
  school_paid: "Biaya Sekolah",
};


export const CONTENT_TYPE_ID = {
	ppdb: 38,
	school_paid: 39,
	ppdb_template: 40,
}


export const CONTENT_STATUS = {
	published: 'published',
	unpublished: 'unpublished',
	draft: 'draft',
}
