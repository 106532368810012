import {
  ApiDeleteRequest,
  ApiGetRequest,
  ApiNotification,
  ApiPostRequest,
  ApiPutRequest,
} from "@/utils/Api";
import moment from "moment";
import { HandlerResult } from "@/utils/Helpers";
import store from "@/store";
import { tentukanTahunPelajaran } from "@/services/internalService";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";
//import router from "@/router";

const state = {
  data: {
    error: false,
    loading: false,
    search: "",
    items: [],
    limit: 10,
    page: 1,
    pageKehadiran: 1,
    totalKehadiran: 0,
    total: 0,
    typefilter: "bulan",
    filter_date: moment().format("YYYY-MM-DD"),
    current_year: moment().format("YYYY"),
    current_month: moment().format("MM"),
    current_date: moment().format("DD"),
    currentMonth: [],
    tglActive: "",
    month: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    classroom: [],
    subject: [],
    classes_select: [],
    attendances: [],
    filter_matapelajaran: "",
    filter_kelas: "",
    kbm: {
      loading: false,
      currentDate: moment().format("YYYY-MM-DD"),
      page: 1,
      limit: 20,
      total: 1,
      filter_kelas: "",
      items: [],
      itemsKelas: [],
      formData: {
        date: new Date(),
        school_time_plot_id: [],
        school_subject_id: 0,
        school_class_id: 0,
        learning_objective: "",
      },
      formDataError: {},
      detailPresensi: {},
      student: {
        items: [],
        total: 0,
        limit: 50,
        loading: false,
      },
      dates: [
        {
          key: "today",
          highlight: true,
          dates: new Date().toISOString().split("T")[0],
        },
      ],
      column: [
        {
          prop: "plot",
          label: "Plot Waktu",
          sortable: false,
          width: "120",
        },
        {
          prop: "detail",
          label: "Detail",
          sortable: false,
          minWidth: "200",
        },
        {
          fixed: "right",
          label: "Aksi",
          width: "70",
          "class-name": "body-14 col-black-2",
        },
      ],
    },
    columns_kelas: [
      {
        prop: "mata_pelajaran",
        label: "Mata Pelajaran ",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "kelas",
        label: "Kelas ",
        sortable: false,
        minWidth: "80",
      },
      {
        prop: "plot_waktu",
        label: "Plot Waktu",
        sortable: false,
        minWidth: "140",
      },
      {
        prop: "guru",
        label: "Guru",
        sortable: false,
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "status_kelas",
        label: "Status Kelas",
        sortable: false,
        minWidth: "120",
      },
      {
        prop: "mulai_berakhir",
        label: "Mulai - Berakhir",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "kehadiran",
        label: "Kehadiran",
        sortable: false,
        minWidth: "110",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_detail_kehadiran: [
      {
        prop: "nama_lengkap",
        label: "Nama Lengkap ",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "nisn_nis",
        label: "NIS/NISN",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "kelas",
        label: "Kelas",
        sortable: false,
        minWidth: "90",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "start_time",
        label: "Kelas Dimulai",
        sortable: false,
        minWidth: "110",
      },
      {
        prop: "end_time",
        label: "Kelas Berakhir",
        sortable: false,
        minWidth: "110",
      },
      {
        prop: "status",
        label: "Status",
        sortable: false,
        minWidth: "90",
      },
    ],
  },
};

const mutations = {
  changePresensiKelas(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changePresensiKbm(state, payload) {
    state.data.kbm = Object.assign({}, state.data.kbm, payload);
  },
  changePresensiKbmStudent(state, payload) {
    state.data.kbm.student = Object.assign({}, state.data.kbm.student, payload);
  },

  /**
   * proses merubah attend_at pada absensi
   * */
  changePresensiKbmStudentItems(state, { id, newValue }) {
    if (typeof state.data.kbm.student.items === "object") {
      let index = state.data.kbm.student.items.findIndex(
        (item) => parseInt(item.student.id) === parseInt(id),
      );
      if (index !== -1) {
        let attend_at = "";
        if (newValue) {
          attend_at = moment().format("h:mm:ss");
        }
        state.data.kbm.student.items[index].attend_at = attend_at;
      }
    }
  },
};

const actions = {
  async getAbsensiKelas({ commit, state }, payload = null) {
    commit("changePresensiKelas", {
      loading: true,
    });

    if (payload) {
      commit("changePresensiKelas", {
        page: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page || 1,
      limit: data.limit,
      q: data.search,
    };

    if (data.filter_kelas) {
      params.class = data.filter_kelas;
    }

    if (data.filter_matapelajaran) {
      params.subject = data.filter_matapelajaran;
    }

    const getResult = await ApiGetRequest(
      `school/presence/attendance/${data.filter_date}/class`,
      params,
    );
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan data presensi kelas");
    } else {
      commit("changePresensiKelas", {
        loading: false,
        items: getResult.data.data,
        page: (getResult.data.meta && getResult.data.meta.current_page) || 1,
        total: getResult.data.meta && getResult.data.meta.total,
      });
    }
  },
  async showKelas({ commit, state }, id = null) {
    commit("changePresensiKelas", {
      loading: true,
      infoUser: false,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/presence/attendance/${data.filter_date}/class/${id || ""}`,
    );
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      commit("changePresensiKelas", {
        loading: false,
        error: getResult.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail absensi kelas");
    } else {
      commit("changePresensiKelas", {
        loading: false,
        infoUser: getResult.data.data,
        error: false,
      });
    }
  },
  async showAttendance({ commit, state }, id = null) {
    commit("changePresensiKelas", {
      loading: true,
    });

    const { data } = state;

    let params = {
      page: data.pageKehadiran || 1,
      limit: data.limit,
    };

    const result = await ApiGetRequest(
      `school/presence/attendance-class-list/${id || ""}`,
      params,
    );

    if (result.error) {
      commit("changePresensiKelas", {
        loading: false,
      });
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      commit("changePresensiKelas", {
        loading: false,
        error: result.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail kehadiran");
    } else {
      commit("changePresensiKelas", {
        attendances: result.data.data,
        totalKehadiran: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        pageKehadiran: (result.data.meta && result.data.meta.current_page) || 1,
        loading: false,
      });
    }
  },
  async getClassRoom({ commit }) {
    commit("changePresensiKelas", {
      loading: true,
    });

    const getResult = await ApiGetRequest(`school/class`);
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat",
      );
    } else {
      commit("changePresensiKelas", {
        loading: false,
        classroom: getResult.data.data,
      });
    }
  },
  async getSubject({ commit }) {
    commit("changePresensiKelas", {
      loading: true,
    });

    const getResult = await ApiGetRequest(`school/subject`);
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat",
      );
    } else {
      commit("changePresensiKelas", {
        loading: false,
        subject: getResult.data.data,
      });
    }
  },
  async getAbsenKelas({ commit }) {
    commit("changePresensiKelas", {
      loading: true,
    });

    const getResult = await ApiGetRequest(
      `school/subject-schedule/{subject_schedule}/attendance`,
    );
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat",
      );
    } else {
      commit("changePresensiKelas", {
        loading: false,
        items: getResult.data.data,
        page: getResult.data.meta.current_page,
        total: getResult.data.meta.total,
      });
    }
  },
  async getStudentPresensiKbm({ state, commit }) {
    // validasi form data
    const { data } = state;
    const formData = data.kbm.formData;
    const formDataError = data.kbm.formDataError;
    if (!formData.school_time_plot_id.length) {
      let msg = "Pilihan Waktu Jampelajaran Belum diisi";
      commit("changePresensiKbm", {
        formDataError: {
          ...formDataError,
          learning_objective: msg,
        },
      });
      ApiNotification("error", msg);
      return false;
    }
    if (!formData.school_subject_id) {
      ApiNotification("error", "Pilihan Mapel Belum diisi");
      return false;
    }
    if (!formData.school_class_id) {
      ApiNotification("error", "Pilihan Kelas Belum diisi");
      return false;
    }
    commit("changePresensiKbmStudent", {
      loading: true,
    });

    const params = {};
    params.school_subject_id = state.data.kbm.formData.school_subject_id;
    params.school_class_id = state.data.kbm.formData.school_class_id;
    const schoolTimePlotIdParams = formData.school_time_plot_id
      .map((id) => `school_time_plot_id[]=${id}`)
      .join("&");

    const result = await ApiGetRequest(
      `school/presence/class/student?${schoolTimePlotIdParams}`,
      params,
    );
    if (result.error) {
      commit("changePresensiKbmStudent", {
        loading: false,
      });
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      //router.push({name: "PresensiKelasEdit", params: {id: jurnalId}})
      //router.push({ name: "PresensiKelas" })
      if (result.code === 307) {
        ApiNotification("error", "Waktu Sudah Anda Gunakan!");
        let redirectUrl;

        if (
          result.data &&
          result.data.data &&
          result.data.data.subject_schedule_id_squence
        ) {
          redirectUrl = `/presensi/presensi-kelas/absen/${result.data.data.subject_schedule_id_squence}/edit`;
        } else {
          redirectUrl = "/presensi/presensi-kelas";
        }

        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 1500);

        return false;
      }

      ApiNotification("error", result.error);
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changePresensiKbmStudent", { loading: false });
      return false;
    }
    try {
      let konversiData = result.data.data.map((item) => {
        return {
          ...item,
          attend_at: moment().format("h:mm:ss"),
        };
      });
      commit("changePresensiKbmStudent", {
        loading: false,
        items: konversiData,
        page: 1,
        total: result.data.data.length,
      });
    } catch (e) {
      commit("changePresensiKbmStudent", {
        loading: false,
        items: [],
        page: 1,
        total: 0,
      });
    }
  },
  updateFormDataTimePlot({ commit, state }, payload) {
    commit("changePresensiKbm", {
      formData: {
        ...state.data.kbm.formData,
        ...payload,
      },
    });
  },
  updatePresensiStudentItem({ commit }, { id, newValue }) {
    commit("changePresensiKbmStudentItems", {
      id,
      newValue,
    });
  },
  async postPresensiKelasJurnal({ commit, state }) {
    const validFormData = state.data.kbm.formData;
    const formDataError = state.data.kbm.formDataError;
    if (!validFormData.learning_objective) {
      let msg = "Tujuan Pembelajaran belum diisi!";
      commit("changePresensiKbm", {
        formDataError: {
          ...formDataError,
          learning_objective: msg,
        },
      });
      ApiNotification("error", msg);
      return false;
    }
    if (!validFormData.school_time_plot_id.length) {
      ApiNotification("error", "Pilihan Waktu Jampelajaran Belum diisi");
      return false;
    }
    if (!validFormData.school_subject_id) {
      ApiNotification("error", "Pilihan Mapel Belum diisi");
      return false;
    }
    if (!validFormData.school_class_id) {
      ApiNotification("error", "Pilihan Kelas Belum diisi");
      return false;
    }
    if (!state.data.kbm.student.items.length) {
      ApiNotification(
        "error",
        "Peserta Didik Tidak ditemukan, Silahkan Refresh Halaman!",
      );
      setTimeout(function () {
        location.reload();
      }, 1500);
      return false;
    }
    commit("changePresensiKbmStudent", {
      loading: true,
    });

    const formData = {
      school_time_plot_id: validFormData.school_time_plot_id,
      school_subject_id: validFormData.school_subject_id,
      school_class_id: validFormData.school_class_id,
      learning_objective: validFormData.learning_objective,
    };

    formData.students = state.data.kbm.student.items.map((item) => {
      return {
        id: item.student.id,
        attend_at: item.attend_at ? 1 : 0,
      };
    });
    const result = await ApiPostRequest(
      `school/presence/school-subject-schedule`,
      formData,
    );
    commit("changePresensiKbmStudent", {
      loading: false,
    });
    if (result.error) {
      ApiNotification("error", result.error);
      return false;
    }
    ApiNotification("success", "Berhasil diproses!");
    return true;
  },
  async putPresensiKelasJurnal({ commit, state }, payload) {
    const validFormData = state.data.kbm.formData;
    const formDataError = state.data.kbm.formDataError;
    if (!validFormData.learning_objective) {
      let msg = "Tujuan Pembelajaran belum diisi!";
      commit("changePresensiKbm", {
        formDataError: {
          ...formDataError,
          learning_objective: msg,
        },
      });
      ApiNotification("error", msg);
      return false;
    }
    if (!state.data.kbm.student.items.length) {
      ApiNotification(
        "error",
        "Peserta Didik Tidak ditemukan, Silahkan Refresh Halaman!",
      );
      setTimeout(function () {
        location.reload();
      }, 2500);
      return false;
    }
    commit("changePresensiKbmStudent", {
      loading: true,
    });

    const formData = {
      learning_objective: validFormData.learning_objective,
    };

    formData.students = state.data.kbm.student.items.map((item) => {
      return {
        id: item.student.id,
        attend_at: item.attend_at ? 1 : 0,
      };
    });
    const result = await ApiPutRequest(
      `school/presence/school-subject-schedule/${payload.id}`,
      formData,
    );
    commit("changePresensiKbmStudent", {
      loading: false,
    });
    if (result.error) {
      ApiNotification("error", result.error);
      return false;
    }
    ApiNotification("success", "Berhasil diproses!");
    return true;
  },
  async getPresensiKbm({ commit, state }, payload = null) {
    commit("changePresensiKbm", {
      loading: true,
    });

    if (typeof payload === "number") {
      commit("changePresensiKbm", {
        page: payload,
      });
    }
    const { data } = state;
    if (!data.kbm.currentDate) {
      commit("changePresensiKbm", {
        currentDate: moment().format("yyyy-MM-DD"),
      });
    }
    let params = {
      page: data.kbm.page || 1,
      limit: data.kbm.limit,
      date: moment(data.kbm.currentDate).format("yyyy-MM-DD"),
    };

    if (data.kbm.filter_kelas) {
      params.school_class_id = data.kbm.filter_kelas;
    }

    if (data.kbm.filter_me) {
      params.user_id = store.state?.user?.id || 0;
    }
    params.semester = tentukanTahunPelajaran(params.date);

    const result = await ApiGetRequest(
      `school/presence/school-subject-schedule`,
      params,
    );
    if (result.error) {
      commit("changePresensiKbm", {
        loading: false,
      });
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan data presensi kelas");
      return false;
    }
    commit("changePresensiKbm", {
      loading: false,
      itemsKelas: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total: result.data.meta && result.data.meta.total,
    });
  },
  async getPresensiKbmDetail({ commit }, payload = null) {
    commit("changePresensiKbm", {
      loading: true,
      detailPresensi: {},
    });

    let id = 0;
    if (typeof payload === "object") {
      if (!payload.id) {
        ApiNotification("error", "ID Jurnal tidak ditemukan.");
        return false;
      }
      id = payload.id;
    }

    const result = await ApiGetRequest(
      `school/presence/school-subject-schedule/${id}`,
    );
    if (result.error) {
      commit("changePresensiKbm", {
        loading: false,
      });

      ApiNotification("error", "Gagal mengambil data presensi kelas.");
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changePresensiKbm", { loading: false });
      return false;
    }
    const dataResult = result.data.data;
    commit("changePresensiKbm", {
      loading: false,
      detailPresensi: dataResult,
      formData: {
        date: moment(dataResult.created_at).format("yyyy-MM-DD"),
        school_time_plot_id: [],
        school_subject_id: dataResult.subject_name,
        school_class_id: dataResult.class_name,
        learning_objective: dataResult.learning_objective,
      },
    });
    commit("changePresensiKbmStudent", {
      items: dataResult.student_attendances,
    });
    return true;
  },
  async deletePresensiKbm({ commit }, payload = null) {
    commit("changePresensiKbm", {
      loading: true,
    });

    let id = 0;
    if (typeof payload === "object") {
      if (!payload.id) {
        ApiNotification("error", "ID Jurnal tidak ditemukan.");
        return false;
      }
      id = payload.id;
    }
    const formData = {
      ids: [id],
    };

    console.log(formData);
    // Generate query parameters dynamically from formData
    const queryParams = Object.keys(formData)
      .map((key) => {
        if (Array.isArray(formData[key])) {
          // If the value is an array, create multiple query parameters
          return formData[key].map((value) => `${value}`).join("&");
        }
        return `${formData[key]}`;
      })
      .join("&");
    const url = `school/presence/school-subject-schedule/${queryParams}`;
    const result = await ApiDeleteRequest(url);
    if (result.error) {
      commit("changePresensiKbm", {
        loading: false,
      });

      ApiNotification("error", result.error);
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changePresensiKbm", { loading: false });
      return false;
    }
    ApiNotification("success", "Berhasil diproses!");
    commit("changePresensiKbm", {
      loading: false,
    });
    return true;
  },
};

const getters = {
  totalStudentItemsOn: (state) => {
    let data = state.data.kbm.student.items.filter(
      (item) => item.attend_at !== "",
    );
    if (!data) return 0;
    return data.length;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
