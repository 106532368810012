import {
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiPostMultipart,
  ApiDeleteRequest,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns: [
      {
        prop: "image",
        label: "Gambar",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "title",
        label: "Judul",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "body",
        label: "Isi Pengumuman",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "ditujukan",
        label: "Ditujukan untuk",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "created_at_label",
        label: "Tanggal Posting",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    ruleForm: {
      checkkelas: false,
      checkprofesi: false,
      user_roles: [],
      school_classes: [],
      title: "",
      body: "",
      selectedFile: null,
      imagePreview: null,
    },
    rules: {
      title: [
        {
          required: true,
          message: "Anda harus menginputkan judul pengumuman",
          trigger: "blur",
        },
      ],
      body: [
        {
          required: true,
          message: "Anda harus menginputkan text pengumuman",
          trigger: "blur",
        },
      ],
      imagePreview: [
        {
          required: true,
          message: "Anda harus menginputkan gambar pengumuman",
          trigger: "blur",
        },
      ],
      user_roles: [
        {
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (
              (!value || value.length === 0) &&
              state.data.ruleForm.school_classes.length === 0
            ) {
              callback(new Error("Anda harus menginputkan kelas atau profesi"));
            } else {
              callback();
            }
          },
        },
      ],
      school_classes: [
        {
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (
              (!value || value.length === 0) &&
              state.data.ruleForm.user_roles.length === 0
            ) {
              callback(new Error("Anda harus menginputkan kelas atau profesi"));
            } else {
              callback();
            }
          },
        },
      ],
    },
    id: "",
    detail: false,
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "created_at",
    sortType: "desc",
    filter: "",
    errors: false,
    loading: false,
  },
};

const mutations = {
  changePengumuman(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getPengumuman({ commit, state }, payload) {
    commit("changePengumuman", {
      loading: true,
      id: "",
    });

    if (payload) {
      await commit("changePengumuman", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
      show: data.currentStatus,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };

    const result = await ApiGetRequest(`school/announcement`, params);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data pengumuman",
      });

      commit("changePengumuman", {
        loading: false,
      });

      return [];
    } else {
      await commit("changePengumuman", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getPengumumanById({ commit }, pengumuman_id = null) {
    commit("changePengumuman", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/announcement/${pengumuman_id || ""}`,
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengambil detail data pengumuman",
      });

      commit("changePengumuman", {
        loading: false,
      });

      return false;
    } else {
      const detail = result.data.data;
      let listClasses = detail.school_classes.map((item) => item.id);
      let listRoles = detail.user_roles.map((item) => item.id);

      commit("changePengumuman", {
        loading: false,
        id: pengumuman_id,
        ruleForm: {
          title: detail.title || "",
          body: detail.body.replace(/(<([^>]+)>)/gi, "") || "",
          imagePreview: detail.image || "",
          school_classes:
            (listClasses && listClasses.length > 0 && listClasses) || [],
          checkkelas:
            detail.school_classes && detail.school_classes.length > 0
              ? true
              : false,
          user_roles: (listRoles && listRoles.length > 0 && listRoles) || [],
          checkprofesi:
            detail.user_roles && detail.user_roles.length > 0 ? true : false,
        },
      });

      return true;
    }
  },

  async createPengumuman({ commit, state, dispatch }) {
    commit("changePengumuman", {
      loading: true,
    });

    const { data } = state;
    const formData = {
      title: data.ruleForm.title,
      body: data.ruleForm.body,
      user_role_id: data.ruleForm.user_roles,
      school_class_id: data.ruleForm.school_classes,
    };

    let dataFormData = new FormData();
    dataFormData.append("image", data.ruleForm.selectedFile);

    const result = await ApiPostRequest("school/announcement", formData);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan pengumuman, mohon periksa kembali",
      });

      commit("changePengumuman", {
        errors: result.errorList,
        loading: false,
      });

      return false;
    } else {
      if (data.ruleForm.selectedFile) {
        await dispatch("insertImagePengumuman", {
          pengumuman_id: result.data.data.id,
          formData: dataFormData,
        });
      }

      commit("changePengumuman", {
        loading: false,
        errors: false,
        id: "",
        ruleForm: {
          checkkelas: false,
          checkprofesi: false,
          user_roles: [],
          school_classes: [],
          title: "",
          body: "",
          selectedFile: null,
          imagePreview: null,
        },
      });

      Message({
        type: "success",
        message: "Data pengumuman berhasil ditambahkan",
      });

      return true;
    }
  },

  async updatePengumuman({ commit, state, dispatch }) {
    commit("changePengumuman", {
      loading: true,
    });

    const { data } = state;
    const formData = {
      title: data.ruleForm.title,
      body: data.ruleForm.body,
      user_role_id: data.ruleForm.user_roles || [],
      school_class_id: data.ruleForm.school_classes || [],
    };

    let dataFormData = new FormData();
    dataFormData.append("image", data.ruleForm.selectedFile);

    const result = await ApiPutRequest(
      `school/announcement/${data.id || ""}`,
      formData,
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data pengumuman, mohon periksa kembali",
      });

      commit("changePengumuman", {
        errors: result.errorList,
        loading: false,
      });

      return false;
    } else {
      if (data.ruleForm.selectedFile) {
        await dispatch("insertImagePengumuman", {
          pengumuman_id: data.id,
          formData: dataFormData,
        });
      }

      commit("changePengumuman", {
        loading: false,
        errors: false,
        id: "",
        ruleForm: {
          checkkelas: false,
          checkprofesi: false,
          user_roles: [],
          school_classes: [],
          title: "",
          body: "",
          selectedFile: null,
          imagePreview: null,
        },
      });

      Message({
        type: "success",
        message: "Data pengumuman berhasil diubah",
      });

      return true;
    }
  },

  async deletePengumuman({ commit }, payload = null) {
    if (payload && payload.id) {
      commit("changePengumuman", {
        loading: true,
      });

      const result = await ApiDeleteRequest(
        `school/announcement/${payload.id}`,
      );
      if (result.error) {
        Message({
          type: "error",
          message: "Gagal menghapus data pengumuman",
        });

        commit("changePengumuman", {
          loading: false,
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data pengumuman berhasil dihapus",
        });

        commit("changePengumuman", {
          loading: false,
        });

        return true;
      }
    }
  },

  async insertImagePengumuman(context, payload = null) {
    if (payload.pengumuman_id && payload.pengumuman_id !== "") {
      const result = await ApiPostMultipart(
        `school/announcement/${payload.pengumuman_id}/image`,
        payload.formData,
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });

        return false;
      } else {
        return true;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
