import store from "@/store";
import {
  ApiDeleteRequest,
  ApiGetRequest,
  ApiNotification,
  ApiPostMultipart,
  ApiPostRequest,
  ApiPutRequest,
} from "@/utils/Api";
import { HandlerNotification, HandlerResult } from "@/utils/Helpers";
import storageService, {
  defaultFileInputs,
} from "@/services/storagePackageService";
import { Message } from "element-ui";
import FileStorageImplement from "@/api/FileStorageImplement";
import ApiService from "@/utils/ApiService";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";
import { checkVw } from "@/services/internalService";

const state = {
  data: {
    itemPreviewImages: [],
    items: [],
    itemAll: [],
    limit: 100,
    page: 1,
    total: 0,
    maxFiles: 10,
    totalFileStorage: null,
    notifikasiPaketLangganan: process.env.VUE_APP_SHOW_PRODUCT,
    loading: false,
    loadingDownload: false,
    showModalAdd: false,
    showModalAddSchool: false,
    showModalDetail: false,
    showModalUpdateName: false,
    isUser: true,
    viewMode: "cards",
    valueViewMode: {
      tables: "tables",
      cards: "cards",
    },
    wherecomponent: "drive",
    prosesHandleFile: "click",
    formData: {},
    formDataDetail: {},
    imageDetail: {},
    messageError: null,
    sort_dir: null,
    sort_field: null,
    uuid: 0,
    currentTab: "umum",
    currentTabSchool: "umum",
    subMenuListFileStorage: [
      {
        name: "SearchFileStorage",
        label: "Dokumen Perkelas",
        lock: false,
        url: "/pengguna/files",
      },
      {
        name: "UploadFotoProfilePelajar",
        label: "Upload Dokumen Serentak",
        lock: false,
        url: "/pengguna/files/upload",
      },
    ],
    subMenuUpload: [
      {
        name: "UploadDokumenFileStorage",
        label: "Deteksi Nilai",
        lock: false,
        url: "/pengguna/files/upload",
      },
      /*{
        name: 'UploadDokumenFotoKelasFileStorage',
        label: 'Sambungkan',
        lock: false,
        url: '/pengguna/files/upload/kelas',
      },*/
      {
        name: "UploadFotoProfilePelajar",
        label: "Foto Profil",
        lock: false,
        url: "/pengguna/pelajar/upload/foto",
      },
    ],
    stafGuru: {
      items: [],
      limit: 10,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      loading: false,
      errors: false,
    },
    pelajar: {
      items: [],
      limit: 10,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      loading: false,
      errors: false,
    },
    bin: {
      userSelected: [],
      columns: [
        {
          prop: "klasdrive_user",
          label: "Nama Pelajar",
          minWidth: 180,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "klasdrive_name",
          sortable: true,
          label: "Nama Dokumen",
          minWidth: 220,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "category",
          label: "Kategori",
          width: 120,
          sortable: true,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "type",
          label: "Tipe",
          sortable: true,
          width: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "size",
          label: "Ukuran",
          sortable: true,
          width: "120",
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          prop: "updated_at",
          label: "Tgl Hapus",
          minWidth: 130,
          width: "170",
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          fixed: "right",
          label: "Aksi",
          width: checkVw(130),
          "class-name": "body-14 col-black-2",
        },
      ],
      columnsSchool: [
        {
          prop: "klasdrive_name",
          sortable: true,
          label: "Nama Dokumen",
          minWidth: 220,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "category",
          label: "Kategori",
          width: 120,
          sortable: true,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "type",
          label: "Tipe",
          sortable: true,
          width: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "size",
          label: "Ukuran",
          sortable: true,
          width: "120",
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          prop: "updated_at",
          label: "Tgl Hapus",
          minWidth: 130,
          width: "170",
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          fixed: "right",
          label: "Aksi",
          width: checkVw(130),
          "class-name": "body-14 col-black-2",
        },
      ],
      itemAll: [],
      items: [],
      limit: 100,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      loading: false,
      errors: false,
    },
    byClass: {
      subColumns: [
        {
          prop: "no",
          label: "No",
          w: 7,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "name",
          label: "Nama Berkas",
          w: 23,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "kategori",
          label: "Kategori",
          w: 15,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "tipe",
          label: "Tipe",
          w: 10,
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          prop: "size",
          label: "Ukuran",
          w: 10,
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          prop: "tglBuat",
          label: "Tgl Buat",
          w: 15,
          "class-name": "body-14 col-black-2 text-center",
        },
        {
          prop: "Aksi",
          label: "Aksi",
          w: 20,
          "class-name": "body-14 col-black-2",
        },
      ],
      columns: [
        {
          prop: "name",
          label: "Nama Pelajar",
          minWidth: 180,
          sortable: true,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "nisn",
          label: "NISN/NIS",
          width: "200",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "classes",
          label: "Kelas",
          minWidth: 160,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "major",
          label: "Jurusan",
          minWidth: 120,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "dokumen",
          label: "Dokumen",
          width: 100,
          "class-name": "body-14 col-black-2",
        },
        {
          fixed: "right",
          label: "Aksi",
          width: 80,
          "class-name": "body-14 col-black-2",
        },
      ],
      itemAll: [],
      items: [],
      limit: 35,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      filterCollecteCcertificate: "",
      checkRecapIjazah: false,
      currentClass: null,
      loading: false,
      errors: false,
    },
    columns: [
      {
        prop: "klasdrive_name",
        label: "Nama Dokumen",
        minWidth: 250,
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "type",
        label: "Tipe",
        sortable: true,
        width: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "size",
        label: "Ukuran",
        sortable: true,
        width: "120",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "created_at",
        label: "Tanggal",
        sortable: true,
        width: "170",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(180),
        "class-name": "body-14 col-black-2",
      },
    ],
    status: [
      { value: "all", label: "Semua status" },
      { value: "active", label: "Aktif" },
      { value: "inactive", label: "Non Aktif" },
    ],
    listKategori: [
      {
        active: false,
        name: "umum",
        label: "UMUM",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
      {
        active: false,
        name: "semester 1",
        label: "SEMESTER 1",
        type: ["SD", "SMP", "SMA", "SMK"],
      },
      {
        active: false,
        name: "semester 2",
        label: "SEMESTER 2",
        type: ["SD", "SMP", "SMA", "SMK"],
      },
      {
        active: false,
        name: "semester 3",
        label: "SEMESTER 3",
        type: ["SD", "SMP", "SMA", "SMK"],
      },
      {
        active: false,
        name: "semester 4",
        label: "SEMESTER 4",
        type: ["SD", "SMP", "SMA", "SMK"],
      },
      {
        active: false,
        name: "semester 5",
        label: "SEMESTER 5",
        type: ["SD", "SMP", "SMA", "SMK"],
      },
      {
        active: false,
        name: "semester 6",
        label: "SEMESTER 6",
        type: ["SD", "SMP", "SMA", "SMK"],
      },
      {
        active: false,
        name: "semester 7",
        label: "SEMESTER 7",
        type: ["SD", "SMK"],
      },
      {
        active: false,
        name: "semester 8",
        label: "SEMESTER 8",
        type: ["SD", "SMK"],
      },
      { active: false, name: "semester 9", label: "SEMESTER 9", type: ["SD"] },
      {
        active: false,
        name: "semester 10",
        label: "SEMESTER 10",
        type: ["SD"],
      },
      {
        active: false,
        name: "semester 11",
        label: "SEMESTER 11",
        type: ["SD"],
      },
      {
        active: false,
        name: "semester 12",
        label: "SEMESTER 12",
        type: ["SD"],
      },
      {
        active: false,
        name: "lulusan",
        label: "LULUSAN",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
    ],
    fileInputs: [defaultFileInputs],
    resultFilterListKategori: [
      {
        active: true,
        name: "umum",
        label: "AWAL",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
    ],
    resultFilterListKategoriSchool: [
      {
        active: true,
        name: "umum",
        label: "UMUM",
      },
      {
        active: false,
        name: "SERTIFIKAT AKREDITASI",
        label: "SERTIFIKAT AKREDITASI",
      },
      {
        active: false,
        name: "ijin pendirian",
        label: "Ijin Pendirian",
      },
      {
        active: false,
        name: "mutasi masuk",
        label: "mutasi masuk",
      },
      {
        active: false,
        name: "mutasi keluar",
        label: "mutasi keluar",
      },
      {
        active: false,
        name: "sertifikat npsn",
        label: "sertifikat npsn",
      },
      {
        active: false,
        name: "penghargaan",
        label: "penghargaan",
      },
      {
        active: false,
        name: "ijasah belum diambil",
        label: "ijasah belum diambil",
      },
      {
        active: false,
        name: "kriteria kelulusan",
        label: "kriteria kelulusan",
      },
    ],
    resultFilterListKategoriTeacher: [
      {
        active: true,
        name: "umum",
        label: "AWAL",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
    ],
    jenisDokumen: [
      {
        active: true,
        name: "RAPOR",
        label: "RAPOR",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
      {
        active: true,
        name: "AKTE",
        label: "AKTE",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
      {
        active: true,
        name: "IJAZAH SD",
        label: "IJAZAH SD",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
      {
        active: true,
        name: "IJAZAH SMP",
        label: "IJAZAH SMP",
        type: ["SMA", "SMK", "SMP"],
      },
      {
        active: true,
        name: "IJAZAH SMA",
        label: "IJAZAH SMA",
        type: ["SMA"],
      },
      {
        active: true,
        name: "IJAZAH SMK",
        label: "IJAZAH SMK",
        type: ["SMK"],
      },
      {
        active: true,
        name: "FOTO",
        label: "FOTO",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
      {
        active: true,
        name: "SKL",
        label: "SKL",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
      {
        active: true,
        name: "LAINNYA",
        label: "LAINNYA",
        type: ["SMA", "SMK", "SD", "SMP"],
      },
    ],
    detailUsageStorage: {
      usage: 0,
      package: {
        installment_fee: 0,
        expired_at: null,
        storage: 0,
        product: {},
      },
      student_usage: 0,
    },
    focus: {
      selectNameDokumen: false,
      inputNameDokumen: false,
    },
  },
};

const mutations = {
  changeFileStorage(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeFileStorageFileInputsIndex(state, payload) {
    state.data.fileInputs[payload.index] = Object.assign(
      {},
      state.data.fileInputs[payload.index],
      payload.payload,
    );
  },
  changeFileStorageBin(state, payload) {
    state.data.bin = Object.assign({}, state.data.bin, payload);
  },
  changeFileStorageByClass(state, payload) {
    state.data.byClass = Object.assign({}, state.data.byClass, payload);
  },
  mergeFileInputs(state, newArray) {
    state.data.fileInputs = newArray;
  },
  changeFileStorageStafGuru(state, payload) {
    state.data.stafGuru = Object.assign({}, state.data.stafGuru, payload);
  },
  changeFileStoragePelajar(state, payload) {
    state.data.pelajar = Object.assign({}, state.data.pelajar, payload);
  },
};

const actions = {
  handlerLoading({ commit }, payload = null) {
    const property = {};
    property.loading = !!(payload === null || payload);
    if (payload && payload.jenis === `guru`) {
      commit("changeFileStorageStafGuru", property);
    } else if (payload && payload.jenis === `pelajar`) {
      commit("changeFileStoragePelajar", property);
    } else {
      commit("changeFileStorage", property);
    }
  },
  filterListKategori({ state, commit }) {
    //const typeSchoolLS = store.state?.user?.school?.type || "0";
    let typeSchoolLS;
    try {
      typeSchoolLS = store.state.user.school.type;
    } catch (error) {
      typeSchoolLS = "0";
    }
    const res = state.data.listKategori.filter((kategori) =>
      kategori.type.includes(typeSchoolLS),
    );
    const results = res.map((kategori) => ({
      ...kategori,
      active: kategori.name === state.data.currentTab,
    }));
    commit("changeFileStorage", { resultFilterListKategori: results });
    return true;
  },
  async filterDokumenKategori({ state, commit }, payload) {
    let dt = state.data.itemAll;
    if (payload && payload.items) {
      dt = payload.items;
    }
    if (!dt.length) return [];

    const res = dt.filter((item) =>
      item.category.includes(state.data.currentTab.toUpperCase()),
    );
    //const results = res.map(kategori => ({
    //  ...kategori,
    //  active: kategori.name === state.data.currentTab
    //}));
    if (!payload) {
      commit("changeFileStorage", { items: res });
    }
    return res;
  },
  removeFileInput({ state, commit }, payload) {
    if (state.data.fileInputs.length > 1 && payload && payload.index >= 0) {
      if (!state.data.fileInputs[payload.index]) {
        return false;
      }
      state.data.fileInputs.splice(payload.index, 1);
      commit("changeFileStorage", { fileInputs: state.data.fileInputs });
      return true;
    }
    return false;
  },
  removeFileInputGlondongan({ state, commit }, payload) {
    let data = state.data.fileInputs;
    const targetIndex = data.findIndex((item) => item.id === payload.id);
    if (targetIndex >= 0) {
      data.splice(targetIndex, 1);
      commit("changeFileStorage", { fileInputs: data });
      return true;
    }
    return false;
  },

  async postDataFileStorage({ dispatch, state }, payload) {
    let formData = new FormData();
    formData.append("_method", "post");
    //formData.append("file", state.data.fileInputs[payload.index].image);
    formData.append("file", payload.file);
    formData.append("original_size", payload.original_size);
    formData.append("name", payload.name);
    formData.append("uuid", payload.uuid);
    formData.append("category", payload.category.toUpperCase());
    dispatch("handlerLoading");
    let result = null;
    if (state.data.isUser) {
      if (!payload && !payload.uuid) {
        return `, Ulangi Lagi / Refresh Browser`;
      }
      result = await ApiPostMultipart(
        `school/data/klasdrive/${payload.uuid}`,
        formData,
      );
    } else {
      result = await ApiPostMultipart(`school/data/klasdrive-school`, formData);
    }
    if (result.error) {
      return ", " + result.error || `, Ulangi Lagi.`;
    }
    if (!result) {
      return `, Response Kosong.`;
    }
    return true;
  },

  async getDataFileStorage({ state, dispatch, commit }, payload) {
    commit("changeFileStorage", {
      isUser: true,
    });
    dispatch("handlerLoading");
    const { data } = state;
    let params = {};
    let uuid = data.uuid;
    if (data.sort_field) params.sort_field = data.sort_field;
    if (data.sort_dir) params.sort_dir = data.sort_dir;
    if (payload) {
      if (payload.sort_field) params.sort_field = payload.sort_field;
      if (payload.sort_dir) params.sort_dir = payload.sort_dir;
      uuid = payload.uuid || data.uuid;
    }
    params.category = data.currentTab;
    params.limit = (payload && payload.limit) || data.limit;
    params.page = 1;
    const result = await ApiGetRequest(
      `school/data/klasdrive/dataStudent/${uuid}`,
      params,
    );
    dispatch("handlerLoading", false);
    if (result.error) {
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      HandlerNotification("error", result.error);
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changeFileStorage", { loading: false });
      return false;
    }
    const filterItems = await dispatch("filterDokumenKategori", {
      items: result.data.data,
    });
    commit("changeFileStorage", {
      loading: false,
      itemAll: result.data.data,
      items: filterItems,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },
  /**
   * @payload: object { student: optional }
   * */
  async getFileStorageUsage({ dispatch, commit }, payload) {
    dispatch("handlerLoading");
    const params = {};
    if (payload && payload.student) {
      params.student = payload.student;
    }
    const result = await ApiGetRequest(`school/data/klasdrive/usage`, params);
    dispatch("handlerLoading", false);
    if (result.error) {
      commit("changeFileStorage", {
        messageError: result.error,
        totalFileStorage: null,
      });
      HandlerNotification("error", result.error);
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changeFileStorage", { loading: false });
      return false;
    }
    commit("changeFileStorage", {
      messageError: null,
      loading: false,
      totalFileStorage: (result.data && result.data.usage) || 0,
      detailUsageStorage: result.data,
    });
    return true;
  },
  async getDataFileStorageBin({ state, commit }, payload) {
    commit("changeFileStorage", {
      isUser: true,
    });
    commit("changeFileStorageBin", { loading: true, userSelected: [] });
    const { data } = state;
    let params = {};
    params.limit = (payload && payload.limit) || data.limit;
    params.page = 1;
    const result = await ApiGetRequest(
      `school/data/klasdrive/recyclebin`,
      params,
    );
    commit("changeFileStorageBin", { loading: false });
    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changeFileStorageBin", { loading: false });
      return false;
    }
    commit("changeFileStorageBin", {
      itemAll: result.data.data,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },

  async getDataFileStorageBinSchool({ state, commit }, payload) {
    commit("changeFileStorage", {
      isUser: false,
    });
    commit("changeFileStorageBin", { loading: true, userSelected: [] });
    const { data } = state;
    let params = {};
    params.limit = (payload && payload.limit) || data.limit;
    params.page = 1;
    const result = await ApiGetRequest(
      `school/data/klasdrive-school/recyclebin`,
      params,
    );
    commit("changeFileStorageBin", { loading: false });
    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changeFileStorageBin", { loading: false });
      return false;
    }
    commit("changeFileStorageBin", {
      itemAll: result.data.data,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },

  async getDataFileStorageByClass({ state, commit }, payload) {
    const { data } = state;
    let params = {};
    params.limit = (payload && payload.limit) || data.limit;
    params.page = 1;
    let idClass = data.byClass.currentClass;
    if (!idClass) {
      commit("changeFileStorageByClass", {
        itemAll: [],
        items: [],
        page: 1,
        total: 0,
      });
      return false;
    }
    const { filterCollecteCcertificate, checkRecapIjazah } = data.byClass;

    if (checkRecapIjazah) {
      params.status_certificate =
        filterCollecteCcertificate === "" ? "all" : filterCollecteCcertificate;
    }

    commit("changeFileStorageByClass", { loading: true });
    const result = await ApiGetRequest(
      `school/data/klasdrive/dataClass/${idClass}`,
      params,
    );
    commit("changeFileStorageByClass", { loading: false });
    if (result.error) {
      HandlerNotification("error", result.error);
      commit("changeFileStorageByClass", {
        itemAll: [],
        items: [],
        page: 1,
        total: 0,
      });
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changeFileStorageByClass", { loading: false });
      return false;
    }
    // Membuat array baru dengan transformasi menggunakan map()
    const itemsFormat = await result.data.data.map((obj) => {
      let resDetailDrive = {
        sum: 0,
        total: 0,
      };
      if (obj.details_klasdrive.length) {
        // Menghitung total ukuran untuk setiap objek
        resDetailDrive.sum = obj.details_klasdrive.reduce(
          (total, driveObj) => total + parseInt(driveObj.size),
          0,
        );
        resDetailDrive.total = obj.details_klasdrive.length;
      }

      // Mengembalikan objek dengan penambahan kunci baru 'detailDrive'
      return {
        ...obj,
        detailDrive: resDetailDrive,
      };
    });

    commit("changeFileStorageByClass", {
      itemAll: result.data.data,
      items: itemsFormat,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },
  async putDataFileStorage({ state, dispatch, commit }, payload) {
    dispatch("handlerLoading");
    const { data } = state;
    let params = {};
    let idKlasDrive = data.formData.id;
    if (payload) {
      idKlasDrive = payload.id || data.formData.id;
    }

    params.category = data.formData.category;
    let result = null;
    if (state.data.isUser) {
      params.name =
        data.formData.name === `LAINNYA`
          ? data.formData.name
          : data.formData.nameSelect;
      result = await ApiPutRequest(
        `school/data/klasdrive/${idKlasDrive}`,
        params,
      );
    } else {
      params.name = data.formData.name;
      result = await ApiPutRequest(
        `school/data/klasdrive-school/${idKlasDrive}`,
        params,
      );
    }
    dispatch("handlerLoading", false);
    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }
    if (!HandlerResult(result)) {
      commit("changeFileStorage", { loading: false });
      return false;
    }
    HandlerNotification();
    if (state.data.isUser) {
      dispatch("getDataFileStorage");
    } else {
      dispatch("getDataFileStorageSchool");
    }
    commit("changeFileStorage", {
      loading: false,
      formDataDetail: {
        name: data.formData.name,
        category: data.formData.category,
        id: data.formData.id,
      },
    });
    return true;
  },

  /**
   * keterangan: action deleteDataFileStorage sangat dinamis, akan mengarah ke 2 jenis endpoin ke user dan ke school untuk fileStoragenya
   * */
  async deleteDataFileStorage({ dispatch, commit }, payload) {
    dispatch("handlerLoading");

    if (!payload || !payload.id) {
      HandlerNotification("warning", "ID Tidak ditemukan.");
      return false;
    }

    const id = payload.id;
    const basePath = state.data.isUser
      ? `school/data/klasdrive`
      : `school/data/klasdrive-school`;
    let apiPath;

    switch (payload.typeProccess) {
      case "trash":
        apiPath = `${basePath}/${id}`;
        break;
      case "restore":
        apiPath = `${basePath}/restore/${id}`;
        break;
      default:
        apiPath = `${basePath}/forcedelete/${id}`;
        break;
    }

    const requestMethod =
      payload.typeProccess === "restore" ? ApiPostRequest : ApiDeleteRequest;

    let result;
    try {
      result = await requestMethod(apiPath);
    } catch (error) {
      HandlerNotification("error", error.message);
      return false;
    } finally {
      dispatch("handlerLoading", false);
    }

    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }

    if (!payload.skipNotif) {
      HandlerNotification();
    }

    console.log(payload.typeProccess);
    console.log(state.data.isUser);
    console.log(state.data.wherecomponent);

    if (payload.typeProccess === "trash") {
      if (state.data.isUser) {
        if (state.data.wherecomponent === "drive") {
          dispatch("getDataFileStorage");
        } else {
          dispatch("getDataFileStorageByClass");
        }
      } else {
        dispatch("getDataFileStorageSchool");
      }
    } else if (payload.typeProccess === "restore") {
      if (state.data.isUser) {
        dispatch("getDataFileStorageBin");
      } else {
        dispatch("getDataFileStorageBinSchool");
      }
    }

    commit("changeFileStorage", {
      showModalDetail: false,
    });

    return true;
  },

  downloadFile({ state, dispatch, commit }, payload) {
    if (state.data.loadingDownload) return false;
    dispatch("handlerLoading", false);
    commit("changeFileStorage", {
      loadingDownload: true,
    });
    if (!payload) {
      HandlerNotification("info", "Dokumen lokasi tidak ditemukan.");
      return false;
    }
    //payload.path = `https://cdn.pixabay.com/photo/2023/05/11/03/34/white-cockatoo-7985434_1280.jpg`;
    fetch(payload.path)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const nisNik =
          (payload.klasdrive_user && payload.klasdrive_user.nis_nik) ||
          "nisnik";
        const nameUser =
          ((payload.klasdrive_user && payload.klasdrive_user.name) ||
            "nama siswa") + ` __${nisNik}`;
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${
          payload.klasdrive_name || "berkas siswa"
        } - ${nameUser}`;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
        commit("changeFileStorage", {
          loadingDownload: false,
        });
      })
      .catch((error) => {
        commit("changeFileStorage", {
          loadingDownload: false,
        });
        HandlerNotification(
          "info",
          "Gambar Tidak bisa di download Otomatis. Lakukan menggunakan Simpan Gambar/Save Image dengan cara lihat gambar lalu klik kanan.",
        );
        console.error(error); // Log the specific error message for debugging
      });
  },

  updateNameDokumen({ state, commit }, payload) {
    // payload -> nilai row
    if (!payload.id) {
      HandlerNotification("info", "Id Dokumen Tidak ditemukan");
      return false;
    }
    if (!payload.klasdrive_name) {
      HandlerNotification("info", "Nama Dokumen Tidak ditemukan");
      return false;
    }

    const foundItem = state.data.jenisDokumen.find(
      (item) => item.name === payload.klasdrive_name,
    );
    commit("changeFileStorage", {
      showModalUpdateName: true,
      formData: {
        id: payload.id,
        nameSelect: foundItem ? foundItem.name : `LAINNYA`,
        name: payload.klasdrive_name,
        category: payload.category,
      },
      formDataDetail: {
        id: payload.id,
        nameSelect: foundItem ? foundItem.name : `LAINNYA`,
        name: payload.klasdrive_name,
        category: payload.category,
      },
    });
  },
  infoPageDetailDokumen({ commit }, payload) {
    commit("changeFileStorage", {
      showModalDetail: true,
      formDataDetail: payload,
    });
  },
  changePage({ commit, dispatch }, payload) {
    commit("changeFileStorage", { page: payload ?? 1 });
    dispatch("getDataFileStorage");
    return true;
  },
  changePageBin({ commit, dispatch }, payload) {
    commit("changeFileStorageBin", { page: payload ?? 1 });
    dispatch("getDataFileStorageBin");
    return true;
  },

  async getDataFileStorageSchool({ state, dispatch, commit }, payload) {
    commit("changeFileStorage", {
      isUser: false,
    });
    dispatch("handlerLoading");
    const { data } = state;
    let params = {};
    if (data.sort_field) params.sort_field = data.sort_field;
    if (data.sort_dir) params.sort_dir = data.sort_dir;
    if (payload) {
      if (payload.sort_field) params.sort_field = payload.sort_field;
      if (payload.sort_dir) params.sort_dir = payload.sort_dir;
    }
    params.category = data.currentTabSchool;
    params.limit = (payload && payload.limit) || data.limit;
    params.page = 1;

    const result = await ApiGetRequest(`school/data/klasdrive-school`, params);
    commit("changeFileStorage", { loading: false });

    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }

    if (!HandlerResult(result)) {
      return false;
    }

    commit("changeFileStorage", {
      loading: false,
      itemAll: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },

  /**
   * Proses call server.
   *
   * @returns {Boolean}.
   *
   */
  async postStudentGetIjazahInSchool({ commit }, uuid) {
    commit("changeFileStorage", { loadingGetIjazah: true });
    try {
      const fileStorageService = new FileStorageImplement(ApiService);
      const response = await fileStorageService.studentGetIjazahInSchool(uuid);
      console.log(response);
      commit("changeFileStorage", { loadingGetIjazah: false });
      Message({
        type: "success",
        message: "Berhasil",
      });
      return true;
    } catch (error) {
      //console.error("___>", error.response?.data||"0000");
      commit("changeFileStorage", { loadingGetIjazah: false });
      const errorMessage = error.response?.data?.error || "Gagal Memproses";
      Message({ type: "error", message: errorMessage });
      return false; // { success: false, error: errorMessage };
    }
  },
};

const getters = {
  getPathInClassStudent: (state) => {
    return state.data.byClass.items.reduce((merged, item) => {
      const withParrent = item.details_klasdrive.map((detail) => ({
        ...detail,
        klasdrive_user: { name: item.name },
      }));
      return merged.concat(withParrent);
      // merged.concat(item.details_klasdrive);
    }, []);
  },
  listFileInputs: (state) => {
    if (!state.data.fileInputs.length) {
      return [];
    }
    if (state.data.currentTab.toUpperCase() === `SEMUA`) {
      return state.data.fileInputs;
    }
    return state.data.fileInputs.filter((item) =>
      item.category.includes(state.data.currentTab.toUpperCase()),
    );
  },
  isTenDaysBeforeExpiry: (state) =>
    storageService.isTenDaysBeforeExpiry(
      state.data.detailUsageStorage.package?.expired_at,
    ),
  calculateFreeStoragePercent: (state) =>
    storageService.calculateFreeStoragePercent(
      state.data.detailUsageStorage.package?.installment_fee || 0,
      state.data.detailUsageStorage.usage,
    ),
  listKategoriSchoolGetters: (state) => {
    const { resultFilterListKategoriSchool, currentTabSchool } = state.data;
    const lowerCurrentTabSchool = currentTabSchool.toLowerCase();

    return resultFilterListKategoriSchool.map((item) => {
      const isActive = item.name.toLowerCase() === lowerCurrentTabSchool;
      if (isActive || item.active) {
        return {
          ...item,
          active: isActive,
        };
      }
      return item;
    });
  },
  itemsFileStorageSchoolGetters: (state) => {
    const { itemAll, currentTabSchool } = state.data;
    const lowerCurrentTabSchool = currentTabSchool.toLowerCase();
    if (itemAll.length) {
      return itemAll.filter(
        (item) => item.category.toLowerCase() === lowerCurrentTabSchool,
      );
    }
    return [];
  },
  studentGetIjazahSchool: (state) => {
    const { itemAll } = state.data;
    return !!itemAll;
  },

  detailPackageApp: (state) => {
    try {
      return state.data.detailUsageStorage.package;
    } catch (e) {
      return {
        installment_fee: 0,
        expired_at: null,
        storage: 0,
        product: {},
      };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
