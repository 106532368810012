import axios from "axios";
import store from "@/store";
import { HandlerAlertUnauthorized } from "@/utils/Helpers";
import { Message } from "element-ui";
import { messageRequestTimeout } from "@/services/internalService";

const ApiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "Authorization",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  mode: "no-cors",
  credentials: true,
  crossdomain: true,
});

ApiService.interceptors.request.use(
  (config) => {
    const LocalDataVuex = JSON.parse(
      window.localStorage.getItem("storediskola"),
    );
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${
        (LocalDataVuex && LocalDataVuex.userToken) || ""
      }`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response Interceptor
ApiService.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // Handle 401 errors - Unauthorized access
      // You might want to log the user out or redirect to a login page
      alert("OOK");
    }
    return Promise.reject(error);
  },
);

export async function handler401ApiService(error) {
  if (error.response && error.response.status === 401) {
    if (store.state.stopRequest) {
      return {
        error: "Silahkan Login Ulang.",
        data: {
          error: "Silahkan Login Ulang.",
        },
        response: {
          data: {
            error: "Silahkan Login Ulang.",
          },
          status: 500,
        },
      };
    } else {
      await store.commit("updateState", {
        stopRequest: true,
      });
      await HandlerAlertUnauthorized();
      return {
        error: "Silahkan Login Ulang.",
        data: {
          error: "Silahkan Login Ulang.",
        },
        response: {
          data: {
            error: "Silahkan Login Ulang.",
          },
          status: 500,
        },
      };
    }
  }
  return false;
}

export function handlerTimeOutApiService(error) {
  if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
    // Handle timeout error
    console.log("Timeout error: Request took too long to complete");
    //return true;
    Message({
      type: "error",
      message: messageRequestTimeout,
    });
    return {
      error: messageRequestTimeout,
      data: {
        error: messageRequestTimeout,
      },
      response: {
        status: 508, // HTTP status code untuk timeout | sebenarnya 408 namun dikasih 508 agar tidak menampilkan message dari layer call server
      },
    };
  }
  return false;
}

export function GET_ApiService(url, data = {}) {
  ApiService.get(url, data)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson;
    })
    .catch(async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (store.state.stopRequest) {
            return {
              error: "Silahkan Login Ulang.",
            };
          } else {
            await store.commit("updateState", {
              stopRequest: true,
            });
            await HandlerAlertUnauthorized();
            return {
              error: "Silahkan Login Ulang.",
            };
          }
        }
        const response = {
          code: error.response.status || 500,
          error:
            (error.response.data.error
              ? error.response.data.error
              : error.response.data.message) || error,
          errorList: error.response.data && error.response.data.errors,
        };
        if (error.response.status === 307) {
          response.data = error.response.data;
        }
        return Promise.reject(response);
      } else if (error.request) {
        console.log("error request");
        return Promise.reject(error);
      }

      return Promise.reject(error);
    });
}

export default ApiService;
