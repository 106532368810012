import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import {NO_INTERNET_MESSAGE} from "@/services/messageService";
import DanaPartisipasiService from "@/services/DanaPartisipasiService";
import {checkVw} from "@/services/internalService";

/*let detailProgramDefault = {
	"bill_id": "",
	"bill_name": "",
	"parent_bill_id": null,
	"paid_nominal": 0,
	"target_nominal": 0,
	"wallet_id": "",
	"expired_date": "",
	"created_date": "",
	"updated_date": "",
	"is_active": true,
	"list_child": []
}
console.log(detailProgramDefault)*/

const state = {
	data: {
		filterNamePartisipasi: "",
		filterStatus: "aktif",
		showModal: false,
		loading: false,
		items: [],
		limit: 2,
		page: 1,
		total: 0,
		sortField: "",
		sortDir: "",
		itemsMessageTable: null,
		errorDataItems: null,
		columns: [
			{
				prop: "programName",
				label: "Nama Program",
				minWidth: "200",
				sortable: false,
				"class-name": "body-14 col-black-2"
			},

			{
				prop: "danaPartisipasi",
				label: "Estimasi",
				width: "130",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "perolehan",
				label: "Perolehan",
				sortable: false,
				width: "130",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "berakhir",
				label: "Berakhir",
				sortable: false,
				width: "180",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "active",
				label: "Status",
				width: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				fixed: "right",
				label: "Aksi",
				width: checkVw(120),
				"class-name": "body-14 col-black-2"
			}
		],
		detailProgram: {
			"bill_id": "1b6b550d-d493-4394-9d91-608aed5bef4d",
			"bill_name": "kelebihan top up",
			"parent_bill_id": null,
			"paid_nominal": 1050000,
			"target_nominal": 1050000,
			"wallet_id": "SC1483",
			"expired_date": "2024-09-30T00:00:00.000Z",
			"created_date": "2024-09-03T01:43:48.875Z",
			"updated_date": "2024-09-03T01:43:48.875Z",
			"is_active": true,
			"list_child": [
				{
					"bill_id": "6b82d357-5c39-4332-a3b0-723145cd0f16",
					"parent_bill_id": "1b6b550d-d493-4394-9d91-608aed5bef4d",
					"bill_name": "kelebihan top up",
					"paid_nominal": 1050000,
					"target_nominal": 1050000,
					"expired_date": "2024-09-30T00:00:00.000Z",
					"created_date": "2024-09-03T01:43:48.894Z",
					"updated_date": "2024-09-03T01:43:48.894Z",
					"is_active": true,
					"name": "DEWANGGA PRATAMA PUTRA",
					"expired_t": "1727654400000",
					"is_expired": true
				}
			]
		}
	}
};

const mutations = {
	changeDanaPartisipasiProgram(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeDanaPartisipasiProgramLoading(state, payload) {
		state.data.loading = payload;
	},

	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
};

const actions = {
	async onShowModal({commit}, isOpen) {
		commit("changeDanaPartisipasiProgram", {
			showModal: isOpen
		})
	},

	async fetchListProgramDanaPartisipasi({commit, state}) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeDanaPartisipasiProgramLoading", true)

		const params = {
			page: data.page,
			limit: data.limit,
			sort_field: data.sortField,
			sort_dir: data.sortDir,
		};

		try {
			const service = new DanaPartisipasiService()
			const result = await service.listBillGet(params)

			const datases = result.data?.data?.list_bill
			if (datases) {
				let m = null
				if (!datases.length) {
					m = "Belum Terdapat Data"
				}
				commit("setMessageItemsTable", m)

				commit("changeDanaPartisipasiProgram", {
					items: datases,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {

			console.log(e)

			commit("changeDanaPartisipasiProgram", {
				items: [],
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("setDataItemsError", NO_INTERNET_MESSAGE)
				commit("setMessageItemsTable", NO_INTERNET_MESSAGE)
				return false
			}

			let m = e.error ? e.error : "Gagal mengambil data"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeDanaPartisipasiProgramLoading", false)
		}
	},

};

const filterItems = (items, filterNamePartisipasi, filterStatus) => {
	let filteredItems = items;

	// Apply name filter if filterNamePartisipasi is provided
	if (filterNamePartisipasi) {
		const filterLowerCase = filterNamePartisipasi.toLowerCase();
		filteredItems = filteredItems.filter(item =>
			item.bill_name.toLowerCase().includes(filterLowerCase)
		);
	}

	// Apply status filter if filterStatus is provided
	if (filterStatus) {
		filteredItems = filteredItems.filter(item =>
			(filterStatus === 'aktif' && item.is_active) || (filterStatus === 'berakhir' && !item.is_active)
		);
	}

	return filteredItems;
};

const getters = {
	dataItemsGetter: state => {
		const { items, filterNamePartisipasi, filterStatus, page, limit } = state.data;

		// Get filtered items
		const filteredItems = filterItems(items, filterNamePartisipasi, filterStatus);

		// Pagination logic: calculate the start and end index for the current page
		const startIndex = (page - 1) * limit;
		const endIndex = page * limit;

		// Return paginated items
		return filteredItems.slice(startIndex, endIndex);
	},

	totalDataItemsGetter: state => {
		const { items, filterNamePartisipasi, filterStatus } = state.data;

		// Get filtered items
		const filteredItems = filterItems(items, filterNamePartisipasi, filterStatus);

		// Return the total count of filtered items
		return filteredItems.length;
	},

	isDataNotFoundGetter: state => {
		const {filterNamePartisipasi, loading, items} = state.data;
		return !filterNamePartisipasi && !loading && !items.length
	}

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
