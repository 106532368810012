import ApiService from "@/api/ApiService";
import axios from "axios";

const PATH_FIRST = "school/presence";

class PresensiRepository {
	constructor() {
		this.apiService = ApiService;
		this.cancelTokenSource = null;
		this.mock = "http://localhost:3000/api"
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getNvrProxy(params) {
		return await this.apiService.get(`${PATH_FIRST}/nvr`, { params });
	}

	async getNvrProxyById(nvrId) {
		this.createCancelToken();
		return await this.apiService.get(`${PATH_FIRST}/nvr/${nvrId}`, { params: {} });
	}

	async postNvrProxy(formData) {
		return await this.apiService.post(`${PATH_FIRST}/nvr`, formData);
	}

	async putNvrProxy(nvrId, formData) {
		return await this.apiService.put(`${PATH_FIRST}/nvr/${nvrId}`, formData);
	}

	async deleteNvrProxy(nvrId) {
		return await this.apiService.delete(`${PATH_FIRST}/nvr/${nvrId}`);
	}

	async getDevice(params) {
		return await this.apiService.get(`${PATH_FIRST}/device`, { params });
	}

	async getDeviceById(deviceId) {
		return await this.apiService.get(`${PATH_FIRST}/device/${deviceId}`, { params: {} });
	}

	async postDevice(formData) {
		return await this.apiService.post(`${PATH_FIRST}/device`, formData);
	}

	async putDevice(deviceId, formData) {
		return await this.apiService.put(`${PATH_FIRST}/device/${deviceId}`, formData);
	}

	async deleteDevice(deviceId) {
		return await this.apiService.delete(`${PATH_FIRST}/device/${deviceId}`);
	}

	async getUserInKlastime(params) {
		return await this.apiService.get(`${PATH_FIRST}/user`, { params });
	}

	async getUserSumaryKlastimeCctv(params) {
		return await this.apiService.get(`${PATH_FIRST}/user/photo/summary`, { params });
	}

	async getUserPhotosCctv(uuid) {
		return await this.apiService.get(`${PATH_FIRST}/user/${uuid}/photo`, { params: {} });
	}

	async postUserPhotosCctv(uuid, formData) {
		const config = {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		};
		return await this.apiService.post(`${PATH_FIRST}/user/${uuid}/photo`, formData, config);
	}

	async updateUserPhotosCctv(uuid, id, formData) {
		const config = {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		};
		return await this.apiService.post(`${PATH_FIRST}/user/${uuid}/photo/${id}`, formData, config);
	}

	async getUserPhotosCctvDetail(uuid, id) {
		return await this.apiService.get(`${PATH_FIRST}/user/${uuid}/photo/${id}`);
	}

	async deleteUserPhotosCctv(uuid, id) {
		return await this.apiService.delete(`${PATH_FIRST}/user/${uuid}/photo/${id}`);
	}

	async postCreatePresenceStudent(uuid, formData) {
		return await this.apiService.post(`school/presence/attendance-report-student/${uuid}`, formData);
	}
}

export default new PresensiRepository();
