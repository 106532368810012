import { ApiGetRequest, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    classrooms: [],
  },
};

const mutations = {
  changeClassroom(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getClassRoom({ commit }, payload) {
    const params = {};
    if (typeof payload === "object") {
      if (payload.noAlumnus) params.noAlumnus = payload.noAlumnus;
      if (payload.wherepage) params.wherepage = payload.wherepage;
    }
    const result = await ApiGetRequest(`school/class`, params);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return [];
    } else {
      commit("changeClassroom", {
        classrooms: result.data.data,
      });

      return result.data.data;
    }
  },
  async importExcelPelajarKelas({ commit }, payload) {
    commit("changeClassroom", {
      loading: true,
    });

    const result = await ApiPostRequest(
      `school/class/${payload.id}/student`,
      payload.formData,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeClassroom", {
        loading: false,
      });

      return false;
    } else {
      commit("changeClassroom", {
        loading: false,
      });

      const items = result.data.data;

      // check kalo return respone ada error
      const errorItems = await items.filter((item) => {
        if (item.error) {
          return item;
        }
      });

      if (errorItems.length > 0) {
        commit("changeClassroom", {
          jsonData: errorItems,
        });

        let message = "";
        if (errorItems.length != payload.formData.length) {
          message = "Beberapa data gagal ditambahkan ke server";
        } else {
          message = "Data gagal ditambahkan ke server";
        }

        Message({
          type: "error",
          message: message,
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data Pelajar berhasil ditambahkan",
        });

        return true;
      }
    }
  },
};

const getters = {
  /**
   * */
  classNoAlumnus: (state) => {
    if (!state.data.classrooms.length) {
      return [];
    }
    return state.data.classrooms.filter(
      (item) => !item.name.includes("ALUMNI"),
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
