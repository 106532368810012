// InternalImplement.js
import moment from "moment";
import { handlerServerErrorAxios } from "@/services/internalService";
import { handlerTimeOutApiService } from "@/utils/ApiService";

moment.locale("id");

const ENDPOINT = "/school";

export default class InternalImplement {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async purposes() {
    try {
      return await this.apiService.get(`${ENDPOINT}/agenda-purposes`, {
        params: { limit: 0 },
      });
    } catch (error) {
      console.error("Error in InternalImplement.purposes:", error);
      throw error;
    }
  }

  async sender() {
    try {
      return await this.apiService.get(`${ENDPOINT}/letter/sender`, {
        params: { limit: 0 },
      });
      //return await this.apiService.get(`http://localhost:3000/api/error500`, { params: {limit: 0} });
    } catch (error) {
      console.error("Error in InternalImplement.sender:", error);
      throw error;
    }
  }

  async createOtp(phone) {
    try {
      const formData = {
        phone,
      };
      return await this.apiService.post(`${ENDPOINT}/send-otp`, formData);
    } catch (error) {
      console.error("Error in InternalImplement.createOtp:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      if (handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async verifyOtp(otp, files) {
    try {
      const formData = new FormData();
      formData.append("files[0]", files[0].file, files[0].fileName);
      formData.append("files[1]", files[1].file, files[1].fileName);
      formData.append("otp", otp);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      return await this.apiService.post(
        `${ENDPOINT}/verif-otp`,
        formData,
        config,
      );
    } catch (error) {
      console.error("Error in InternalImplement.verifyOtp:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      if (handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  prepareParams(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama employee
    }

    if (data.creator_id) {
      params.creator_id = data.creator_id; // ops | nama employee
    }

    if (data.year) {
      // Check if data.page is a numeric value
      if (!isNaN(data.year) && data.year !== 0) {
        params.year = parseInt(data.year);
      } else {
        // Handle the case where data.page is not a number
        throw new Error("Error: 'year' must be a numeric value");
      }
    }
    return params;
  }

  validatePage({ page }, params) {
    if (page) {
      if (!isNaN(page) && page !== 0) {
        params.page = parseInt(page);
      } else {
        throw new Error("Error: 'page' must be a numeric value");
      }
    }
  }

  validateLimit({ limit }, params) {
    const maxLimit = 500;
    if (limit) {
      const numericLimit = parseInt(limit, 10);
      if (isNaN(numericLimit) || numericLimit > maxLimit) {
        throw new Error(
          `Invalid limit value: '${limit}'. The limit must be a number and cannot exceed ${maxLimit}.`,
        );
      }
      params.limit = numericLimit;
    }
  }

  validateSortField(
    { sort_field },
    params,
    validSortFields = ["name", "created_at", "agenda_number"],
  ) {
    if (sort_field && !validSortFields.includes(sort_field)) {
      throw new Error(
        `Invalid sort_field value: '${sort_field}'. Valid values are ${validSortFields.join(
          ", ",
        )}.`,
      );
    }
    params.sort_field = sort_field;
  }

  validateSortDir({ sort_dir }, params) {
    if (typeof sort_dir === "boolean") {
      params.sort_dir = sort_dir ? "desc" : "asc";
    } else {
      const validSortDirections = ["asc", "desc"];
      if (sort_dir && !validSortDirections.includes(sort_dir)) {
        throw new Error(
          `Invalid sort_dir value: '${sort_dir}'. Valid values are ${validSortDirections.join(
            ", ",
          )}.`,
        );
      }
      params.sort_dir = sort_dir;
    }
  }

  validateType({ type }, params) {
    const validTypes = ["sd", "smp", "sma", "smk", "slb"];
    if (type && !validTypes.includes(type.toLowerCase())) {
      throw new Error(
        `Invalid type value: '${type}'. Valid values are ${validTypes.join(
          ", ",
        )}.`,
      );
    }
    params.type = type && type.toUpperCase();
  }
}
