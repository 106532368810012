import { MessageBox } from "element-ui";
import {ERROR_NETWORK, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";

class ErrorService {
  handleError(error) {
		console.error(error)
    if (error.status >= 500) {
      // ketika error >= 500
      MessageBox.alert(SERVER_ERROR_MESSAGE, "Error", {
        autofocus: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "OK",
        callback: (action) => console.log(action),
      });
      throw {
        code: "ERR_BAD_RESPONSE",
        status: 500,
        error: "Layanan Sedang tidak tersedia! coba lagi nanti",
      };
    }

    if (error.status >= 400 && error.status < 500) {
      // ketika error 400 - 499
      throw {
        code: "ERR_BAD_REQUEST",
        status: error.status,
        error: error.error || "",
        errorList: error.errorList,
      };
    }

    MessageBox.alert(ERROR_NETWORK, "Cors Origin", {
      autofocus: true,
      dangerouslyUseHTMLString: true,
      confirmButtonText: "OK",
      callback: (action) => console.log(action),
    });
    // ketika error area client atau tidak terduga
    throw {
      code: error.code || "ERR_UNKNOWN",
      status: 0,
      error: ERROR_NETWORK,
    };
  }
}

export default new ErrorService();
