import { ApiGetRequest, ApiNotification } from "@/utils/Api";
import { Message } from "element-ui";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    status: [
      { value: "all", label: "Semua status" },
      { value: "active", label: "Aktif" },
      { value: "inactive", label: "Non Aktif" },
    ],
    currentStatus: "all",
    roles: [],
    rolesSelected: "",
    loading: false,
    jsonData: [],
    provinces: [],
    cities: [],
    districts: [],
    goldarah: ["A", "AB", "B", "O"],
    agama: ["Islam", "Protestan", "Katolik", "Hindu", "Budha", "Konghucu"],
    dataForm: {
      nis_nik: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      roles: "",
      // soft_deleted: false,
      date_of_birth: "",
      place_of_birth: "",
      province_id: "",
      city_id: "",
      sub_district_id: "",
      address: "",
      gender: "",
      blood_type: "",
      religion: "",
    },
    errors: false,
  },
};

const mutations = {
  changeGuruStaff(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getDistricts({ commit }, city_id = null) {
    if (city_id && city_id !== "") {
      const result = await ApiGetRequest(
        `school/sub-district?city_id=${city_id}`,
      );
      if (result.error) {
        if (result.code >= 500) {
          ApiNotification("error", SERVER_ERROR_MESSAGE);
          return false;
        }
        Message({
          type: "error",
          message: result.error,
        });

        return [];
      } else {
        commit("changeGuruStaff", {
          districts: result.data.data,
        });

        return result.data.data;
      }
    } else {
      return [];
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
