<template>
  <div id="app">
    <div v-if="isLoadingTokenDanaPartisipasi" class="loading-overlay">
      <div class="loading-spinner"></div>
      <p class="loading-text">
        Tunggu sebentar, data sedang diambil...
      </p>
    </div>
    <transition>
      <router-view/>
    </transition>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState({
      isLoadingTokenDanaPartisipasi: (state) => state.isLoadingTokenDanaPartisipasi,
      isLoadingTokenKoperasi: (state) => state.isLoadingTokenKoperasi,
    }),
  }
};
</script>


<style scoped lang="css">
/* Styles for the loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 9999; /* Ensure the loading overlay appears on top of other elements */
  /* Styles for the loading spinner */
  .loading-spinner {
    border: 4px solid #f3f3f3; /* Light grey border for the spinner */
    border-top: 4px solid #3498db; /* Blue border for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Add animation to the spinner */
  }

  .loading-text {
    color: #fff;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
  }
}

/* Animation for the spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
