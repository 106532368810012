import {requirePermission} from "@/router/index";

export default [

	{
		path: "/darurat-spp",
		name: "DaruratSPP",
		component: () =>
			import(/* webpackChunkName: "darurat-spp" */ "../views/DaruratSPP"),
		meta: {
			title: "Darurat | "
		}
	},
	{
		path: "/invoice-recap",
		name: "InvoiceRecap",
		component: () =>
			import(
				/* webpackChunkName: "invoice-recap" */
				"../views/PembayaranSpp/InvoiceRecap"
				),
		meta: {
			title: "Rekap Invoice | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/recap-tunggakan",
		name: "RecapTunggakan",
		component: () =>
			import(
				/* webpackChunkName: "recap-tunggakan" */
				"../views/PembayaranSpp/RecapTunggakan"
				),
		meta: {
			title: "Rekap Tunggakan | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/invoice-student/:id",
		name: "InvoiceStudentById",
		component: () =>
			import(
				/* webpackChunkName: "invoice-student" */
				"../views/PembayaranSpp/TunggakanPelajar/CetakInvoice"
				),
		meta: {
			title: "Invoice Student | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/invoice-bayar-student/:id",
		name: "InvoiceBayarStudent",
		component: () =>
			import(
				/* webpackChunkName: "invoice-bayar-student" */
				"../views/PembayaranSpp/RiwayatPembayaran/CetakInvoice"
				),
		meta: {
			title: "Invoice Student | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},

	{
		path: "/pembayaran-spp",
		name: "PembayaranSPP",
		redirect: "/pembayaran-spp/tunggakan-pelajar",
		component: () =>
			import(
				/* webpackChunkName: "pembayaran-spp" */
				"../views/PembayaranSpp"
				),
		meta: {
			title: "Pembayaran SPP | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		children: [
			{
				path: "tunggakan-pelajar",
				name: "TunggakanPelajar",
				component: () =>
					import(
						/* webpackChunkName: "spp-tunggakan-pelajar" */
						"../views/PembayaranSpp/TunggakanPelajar"
						),
				meta: {
					title: "Tunggakan Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "detail-tunggakan-pelajar/:id",
				name: "DetailTunggakanPelajar",
				component: () =>
					import(
						/* webpackChunkName: "detail-tunggakan-pelajar" */
						"../views/PembayaranSpp/TunggakanPelajar/DetailTunggakan"
						),
				meta: {
					title: "FormIpCctv Tunggakan Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pembayaran-kelas",
				name: "PembayaranKelas",
				component: () =>
					import(
						/* webpackChunkName: "spp-pembayaran-kelas" */
						"../views/PembayaranSpp/PembayaranKelas"
						),
				meta: {
					title: "Pembayaran Kelas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "riwayat-pembayaran",
				name: "RiwayatPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "spp-riwayat-pembayaran" */
						"../views/PembayaranSpp/RiwayatPembayaran"
						),
				meta: {
					title: "Riwayat Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "detail-pelajar/:id",
				name: "DetailPelajarRiwayatPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "spp-riwayat-detail-pelajar" */
						"../views/PembayaranSpp/RiwayatPembayaran/DetailPelajar"
						),
				meta: {
					title: "Riwayat Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pengaturan",
				name: "PengaturanPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "spp-pengaturan" */
						"../views/PembayaranSpp/Pengaturan"
						),
				meta: {
					title: "Pengaturan Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "penerima-diskon/:id",
				name: "PengaturanPenerimaDiskon",
				component: () =>
					import(
						/* webpackChunkName: "spp-penerima-diskon" */
						"../views/PembayaranSpp/Pengaturan/PenerimaDiskon"
						),
				meta: {
					title: "Pengaturan Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "biaya-admin",
				name: "BiayaAdminSPP",
				component: () =>
					import(
						/* webpackChunkName: "spp-biayadmin" */
						"../views/PembayaranSpp/BiayaAdmin"
						),
				meta: {
					title: "Biaya Admin SPP | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},

]
