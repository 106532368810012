import {
  ApiGetRequest,
  // ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest,
  ApiPostMultipart,
  ApiNotification,
} from "@/utils/Api";
import { Message } from "element-ui";
import moment from "moment";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";
// import { _ } from "vue-underscore";

const state = {
  data: {
    labelMenu: "Tugas",
    page: 1,
    limit: 10,
    total: 0,
    sortBy: "updated_at",
    sortType: "desc",
    filter: "",
    school_class: "",
    school_subject: "",
    loading: false,
    loadingPagePenilaian: false,
    paramsId: "",
    search: "",
    errors: [],
    status: "",
    detailHeaderPenilaian: {
      done: null,
      undone: null,
      title: "",
      subject: "",
      class: {},
      class_room: "",
    },
    days: [
      {
        id: 1,
        name: "SENIN",
      },
      {
        id: 2,
        name: "SELASA",
      },
      {
        id: 3,
        name: "RABU",
      },
      {
        id: 4,
        name: "KAMIS",
      },
      {
        id: 5,
        name: "JUMAT",
      },
      {
        id: 6,
        name: "SABTU",
      },
      {
        id: 7,
        name: "MINGGU",
      },
    ],
    ruleForm: {
      id: "",
      title: "",
      description: "",
      school_class_id: "",
      day_name: "",
      school_subject_schedule_id: "",
      school_subject_schedule_id_load: "",
      uploaded: false,
      checked: false,
      downloaded: false,
      end_at: "",
      file: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
      link: [{ value: "" }],
    },
    rules: {
      title: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      description: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      school_class_id: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      school_subject_schedule_id: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      end_at: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      file: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
    },
    columns_tugas: [
      {
        prop: "assignment_name",
        label: "Judul Tugas",
        minWidth: 180,
        sortable: false,
        "class-name": "body-12 col-black-2",
      },
      {
        prop: "assignment_subject",
        label: "Mapel",
        width: 160,
        sortable: false,
        "class-name": "body-12 col-black-2",
      },
      {
        prop: "assignment_class",
        label: "Kelas",
        minWidth: 160,
        sortable: false,
        "class-name": "body-12 col-black-2",
      },
      {
        prop: "assignment_uploaded",
        label: "Diupload",
        width: 95,
        sortable: false,
        "class-name": "body-12 col-black-2",
      },
      {
        prop: "assignment_ended",
        label: "Berakhir",
        width: 96,
        sortable: false,
        "class-name": "body-12 col-black-2",
      },
      {
        prop: "assignment_done",
        label: "Dikerjakan",
        width: 120,
        sortable: false,
        "class-name": "body-12 col-black-2",
      },
      // {
      //   prop: "assignment_rated",
      //   label: "Dinilai",
      //   minWidth: "80",
      //   sortable: false,
      //   "class-name": "body-12 col-black-2",
      // },
      {
        prop: "assignment_status",
        label: "Status",
        minWidth: "130",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "uri",
        label: "Link",
        width: 120,
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: 100,
        "class-name": "body-14 col-black-2",
      },
    ],
    assignment: [],
    examle_data: [
      {
        id: "1",
        assignment_name: "Fisika_Tugas dasar-7 (10 B.).pdf",
        assignment_subject: "Fisika",
        assignment_class: `XI IPA 2`,
        assignment_uploaded: `9 Apr 2021`,
        assignment_ended: `15 Apr 2021`,
        assignment_done: `32/32`,
        assignment_rated: `32/32`,
        assignment_status: "Dikerjakan",
      },
    ],
    columns_detail_tugas: [
      {
        prop: "assignment_student_name",
        label: "Nama",
        minWidth: "130",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "assignment_student_status",
        label: "Status",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "assignment_student_uploaded",
        label: "Tugas Diupload",
        minWidth: "100",
        sortable: false,
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "assignment_student_file",
        label: "File Siswa",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: window.innerWidth < 768 ? undefined : "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Penilaian",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    example_data_detail: [
      {
        id: "1",
        assignment_student_name: "Vania Sisca Wulandari",
        assignment_student_status: "sudah dikerjakan",
        assignment_student_uploaded: `13 Apr 2021, 18:36`,
        assignment_student_file: `Tugas Vania_Biologi_Tugas dasar 7 (12 IPA 4.)doc`,
        assignment_student_score: 40,
      },
    ],
    subjectAssignment: [],
    listScoreAssignment: [],
  },
};

const mutations = {
  changeTugas(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeTugasDetailHeaderPenilaian(state, payload) {
    state.data.detailHeaderPenilaian = Object.assign(
      {},
      state.data.detailHeaderPenilaian,
      payload,
    );
  },
  changeTugasLoading(state, payload) {
    state.data.loading = payload;
  },
  changeTugasPagePenilaianLoading(state, payload) {
    state.data.loadingPagePenilaian = payload;
  },
};

const actions = {
  async getListTugas({ commit }, payload = null) {
    commit("changeTugas", {
      loading: true,
    });

    if (payload) {
      await commit("changeTugas", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      school_subject: data.school_subject,
      school_class: data.school_class,
      q: data.filter,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };

    console.log(`=params`, data.sortBy);

    const result = await ApiGetRequest(`school/assignment`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.errors
          ? result.error
          : "Gagal mengambil data list tugas",
      });

      commit("changeTugas", {
        loading: false,
      });

      return null;
    } else {
      commit("changeTugas", {
        assignment: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getTugasDetail({ commit }, payload = null) {
    const result = await ApiGetRequest(
      `school/assignment/${payload.school_subject_assignment_id}`,
    );

    if (result.error) {
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      commit("changeTugasDetailHeaderPenilaian", {
        done: null,
        undone: null,
        title: "",
        subject: "",
        class: "",
      });
      return null;
    }
    commit("changeTugasDetailHeaderPenilaian", result.data.data);
    return result.data;
  },

  async submitTugas({ commit, state }) {
    commit("changeTugas", {
      loading: true,
    });

    const { data } = state;
    const formData = new FormData();

    formData.append("_method", "post");
    formData.append("title", data.ruleForm.title);
    formData.append("description", data.ruleForm.description);
    formData.append(
      "school_subject_schedule_id",
      data.ruleForm.school_subject_schedule_id,
    );
    formData.append("school_class_id", data.ruleForm.school_class_id);
    formData.append("uploaded", data.ruleForm.uploaded ? 1 : 0);
    formData.append("checked", data.ruleForm.checked ? 1 : 0);
    formData.append("downloaded", data.ruleForm.downloaded ? 1 : 0);
    // formData.append("end_at", data.ruleForm.end_at);

    if (data.ruleForm.link && data.ruleForm.link.length > 0) {
      data.ruleForm.link.map((itemL, indexL) => {
        if (itemL.value && itemL.value !== "") {
          formData.append(`link[${indexL}]`, itemL.value);
        }
      });
    }

    formData.append(
      "end_at",
      moment(data.ruleForm.end_at).format("YYYY-MM-DD HH:mm:ss"),
    );

    if (typeof data.ruleForm.file != "string" && data.ruleForm.file != null) {
      formData.append("file", data.ruleForm.file);
    }

    if (data.ruleForm.title != "") {
      formData.set("title", data.ruleForm.title);
    }

    if (data.ruleForm.description != "") {
      formData.set("description", data.ruleForm.description);
    }

    if (data.ruleForm.school_subject_schedule_id != "") {
      formData.set(
        "school_subject_schedule_id",
        data.ruleForm.school_subject_schedule_id,
      );
    }

    if (data.ruleForm.end_at != "") {
      // formData.set("end_at", data.ruleForm.end_at);
      formData.set(
        "end_at",
        moment(data.ruleForm.end_at).format("YYYY-MM-DD HH:mm:ss"),
      );
    }

    if (data.ruleForm.id) {
      formData.set("_method", "put");
    }

    let result = "";
    if (data.ruleForm.id) {
      result = await ApiPostMultipart(
        `school/assignment/${data.ruleForm.id}`,
        formData,
      );
    } else {
      console.log(`===formData`, formData);

      result = await ApiPostMultipart(`school/assignment`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          if (value && value[0]) {
            errors.push({
              message: value[0],
              path: [key],
            });
          }
        }
        commit("changeTugas", {
          errors: errors && errors.length > 0 ? errors : false,
          loading: false,
        });
      }

      return false;
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
      commit("changeTugas", {
        errors: [],
        ruleForm: {
          id: "",
          title: "",
          description: "",
          school_class_id: "",
          day_name: "",
          uploaded: false,
          checked: false,
          downloaded: false,
          school_subject_schedule_id: "",
          school_subject_schedule_id_load: "",
          end_at: "",
          file: "",
          file_name: "",
          file_size: "",
          file_path: "",
          file_type: "",
          link: [{ value: "" }],
        },
        loading: false,
      });

      return true;
    }
    // commit("changeTugas", {
    //   loading: true,
    // });

    // const result = await ApiPostMultipart(
    //   `school/assignment`,
    //   payload.formData
    // );

    // if (result.error) {
    //   console.log(result, "data error");
    //   Message({
    //     type: "error",
    //     message: result.error ? result.error : "Gagal menambahkan data tugas",
    //   });

    //   commit("changeTugas", {
    //     error: result.errorList ? result.errorList : false,
    //   });

    //   return false;
    // } else {
    //   Message({
    //     type: "success",
    //     message: "Data tugas baru berhasil ditambahkan",
    //   });

    //   commit("changeTugas", {
    //     error: false,
    //   });

    //   return true;
    // }
  },

  async deleteTugas({ commit }, payload = null) {
    commit("changeTugas", {
      loading: true,
    });

    const result = await ApiDeleteRequest(`school/assignment/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data tugas gagal dihapus",
      });

      commit("changeTugas", {
        loading: false,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data tugas berhasil dihapus",
      });

      commit("changeTugas", {
        loading: false,
      });

      return true;
    }
  },

  async getScheduleAssignment({ commit, state }) {
    commit("changeTugas", {
      loading: true,
      subjectAssignment: [],
    });

    // let subject = [];
    const { data } = state;
    let params = {};
    if (data.ruleForm.school_class_id) {
      params["class[]"] = data.ruleForm.school_class_id;
    }

    if (data.ruleForm.day_name) {
      params["name_of_day[]"] = data.ruleForm.day_name;
    }

    const result = await ApiGetRequest(`school/subject-schedule`, params);

    commit("changeTugas", {
      loading: false,
      subjectAssignment: result.data.data,
    });

    // if (!result.error) {
    //   for (let i = 0; i < result.data.data.length; i++) {
    //     if (result.data.data[i].subject) {
    //       let checkSubject = _.findWhere(subject, {
    //         id: result.data.data[i].subject.id,
    //       });
    //       if (!checkSubject) {
    //         subject.push(result.data.data[i].subject);
    //       }
    //     }
    //   }

    //   commit("changeTugas", {
    //     subjectAssignment: subject,
    //   });
    // }
  },

  async getScoreAssignment({ commit }, payload = null) {
    const result = await ApiGetRequest(
      `school/assignment/${payload.school_subject_assignment_id}/result`,
    );

    if (result.error) {
      commit("changeTugas", {
        loading: false,
      });

      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      Message({
        type: "error",
        message: result.errors
          ? result.error
          : "Gagal mengambil data list tugas pelajar",
      });

      return null;
    } else {
      return result.data;
    }
  },

  async detailScoreListAssignment({ commit, state }, payload) {
    if (payload && typeof payload !== "object") {
      await commit("changeTugas", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      page: data.page,
      status: data.status || undefined,
    };

    if (data.filter) {
      params["q"] = data.filter;
    }

    commit("changeTugas", {
      loading: true,
      changeTugasPagePenilaianLoading: true,
    });
    const result = await ApiGetRequest(
      `school/assignment/${data.paramsId}/result`,
      params,
    );
    commit("changeTugas", {
      loading: false,
      changeTugasPagePenilaianLoading: false,
    });

    if (result.error) {
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      Message({
        type: "error",
        message: result.errors
          ? result.error
          : "Gagal mengambil data list tugas pelajar",
      });

      return null;
    } else {
      commit("changeTugas", {
        listScoreAssignment: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        page: (result.data.meta && result.data.meta.current_page) || 1,
        loading: false,
        detailHeader: {
          done: result.data.summary.done,
          undone: result.data.summary.undone,
        },
      });
    }
  },

  async submitDataScore({ commit }, payload) {
    // commit("changeTugas", {
    //   loading: true,
    // });

    const formData = {
      score: payload.score_assignment,
    };

    console.log(`----formData`, formData);
    let result = await ApiPostRequest(
      `school/student-assignment/${payload.student_assignment}/update-score`,
      formData,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeTugas", {
          errors: errors,
          // loading: false,
        });
      }
    } else {
      // Message({
      //   type: "success",
      //   message: "Data nilai telah tersimpan.",
      // });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
