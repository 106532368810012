import ApiService from "@/api/ApiService";
import axios from "axios";

class RequestUserRepository {
	constructor() {
		this.apiService = ApiService;
		this.cancelTokenSource = null;
		this.mock = 'http://localhost:3000/api';
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getRequestUserSummary() {
		this.createCancelToken()
		return await this.apiService.get(`/school/requested-user/summary`);
	}

	async getRequestUserStudent(params) {
		return await this.apiService.get(`/school/requested-user/student`, { params });
	}

	async postRequestUserStudentApprove(formData) {
		return await this.apiService.post(`/school/requested-user/student/approve`, formData);
	}

	async postRequestUserStudentReject(formData) {
		return await this.apiService.post(`/school/requested-user/student/reject`, formData);
	}

	async deleteRequestUserStudent(id) {
		return await this.apiService.delete(`/school/requested-user/student/delete/${id}`);
	}

	async getRequestUserTeacher(params) {
		return await this.apiService.get(`/school/requested-user/teacher`, { params });
	}

	async postRequestUserTeacherApprove(formData) {
		return await this.apiService.post(`/school/requested-user/teacher/approve`, formData);
	}

	async postRequestUserTeacherReject(formData) {
		return await this.apiService.post(`/school/requested-user/teacher/reject`, formData);
	}

	async deleteRequestUserTeacher(id) {
		return await this.apiService.delete(`/school/requested-user/teacher/delete/${id}`);
	}

}

export default new RequestUserRepository();
