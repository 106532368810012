import { ApiGetRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    provinces: [],
  },
};

const mutations = {
  changeProvinces(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getProvinces({ commit }) {
    const result = await ApiGetRequest(`school/province`);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return [];
    } else {
      commit("changeProvinces", {
        provinces: result.data.data,
      });

      return result.data.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
