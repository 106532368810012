import KioskService from "@/services/KioskService"
import {Message} from "element-ui"
import {SERVER_ERROR_MESSAGE} from "@/utils/messageUtils"
import {DUPLICATE_CALL_SERVER_MESSAGE, NO_INTERNET_MESSAGE} from "@/services/messageService";
import {checkVw} from "@/services/internalService";
import {isServerErrorOrNoInternet} from "@/utils/Helpers";
import Vue from "vue";
import {KIOSK_STATUS} from "@/services/kioskComposable";
import moment from "moment";

const state = {
	data: {
		showModalForm: false,
		loading: false,
		errorDataItems: false,
		itemsMessageTable: null,
		items: [],
		total: 0,
		limit: 50,
		formDataArray: [],
		columns: [
			{
				prop: "file",
				label: "File",
				minWidth: 200,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "status",
				label: "Status",
				width: 140,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "created_at",
				label: "Tanggal",
				width: 180,
				"class-name": "body-14 col-black-2",
			},
			{
				fixed: "right",
				prop: "action",
				label: "Aksi",
				width: checkVw(100),
				"class-name": "body-14 col-black-2",
			},
		],
	},
}

const mutations = {
	changeControlKioskImage(state, payload) {
		state.data = Object.assign({}, state.data, payload)
	},
	changeControlKioskImageLoading(state, payload) {
		state.data.loading = payload
	},
	setItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
	SetItemsMessageTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	UPDATE_ITEMS(state, payload) {
		const index = state.data.items.findIndex((item) => item.id === payload.id);

		if (index !== -1) {
			//state.data.items[index] = payload;
			Vue.set(state.data.items, index, payload);
		}
	},
	ADD_ITEMS(state, dataArray) {
		const modifiedDataArray = dataArray.map(item => ({
			...item,
			created_at: moment().format("YYYY-MM-DD")+"T03:05:10.000000Z",
			updated_at: moment().format("YYYY-MM-DD")+"T03:05:10.000000Z",
		}));

		state.data.items.push(...modifiedDataArray);
	},


	DELETE_ITEMS(state, itemsId) {
		const index = state.data.items.findIndex((item) => item.id === itemsId);
		if (index !== -1) {
			state.data.items.splice(index, 1);
		}
	},
}

const actions = {

	async onShowModal({commit}, isOpen) {
		commit("changeControlKioskImage", {
			showModalForm: isOpen
		})
	},

	async fetchImageControlKiosk({commit, state}, paramsDeviceId) {

		const {
			limit, loading
		} = state.data

		if (loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

		const params = {
			limit: limit,
			page: 1,
		}

		try {
			commit("setItemsError", null)
			commit("changeControlKioskImageLoading", true)
			const service = new KioskService()
			const result = await service.imagesKioskByDeviceGet(paramsDeviceId, params)

			if (result.data && result.data.data) {
				commit("changeControlKioskImage", {
					items: result.data.data,
					itemsMessageTable: null,
					total: result.data.data.length
				})
			}
		} catch (e) {
			commit("changeControlKioskImage", {
				itemsMessageTable: "Gagal Mengambil Data",
				items: [],
				total: 0
			})

			if (e.status >= 500) {
				commit("setItemsError", "Data Banner: " + SERVER_ERROR_MESSAGE)
				commit("SetItemsMessageTable", SERVER_ERROR_MESSAGE)
			} else if (e.code === "ERR_NETWORK") {
				commit("setItemsError", "Data Banner: CORS Error")
				commit("SetItemsMessageTable", NO_INTERNET_MESSAGE)
			} else if (e.status >= 400) {
				commit("SetItemsMessageTable", e.error)
				Message({
					type: "error",
					message: e.error,
				})
			}
		} finally {
			commit("changeControlKioskImageLoading", false)
		}
	},

	async postImageControlKiosk({commit}, payload) {

		const {deviceId} = payload

		try {
			commit("changeControlKioskImageLoading", true)
			const service = new KioskService()
			const result = await service.bulkImageKioskByDevicePost(deviceId, state.data.formDataArray)
			commit("ADD_ITEMS", result.data.data)
			commit("changeControlKioskImage", {
				formDataArray: []
			})
			return true
		} catch (e) {
			console.log(e)
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					commit("SetItemsMessageTable", e.error)
					Message({
						type: "error",
						message: e.error,
					})
				}
			}
			return false
		} finally {
			commit("changeControlKioskImageLoading", false)
		}
	},

	async putStatusImageControlKiosk({commit}, payload) {

		const {fileImageId, deviceId, status} = payload

		try {
			commit("setItemsError", null)
			commit("changeControlKioskImageLoading", true)
			const service = new KioskService()
			const result = await service.imageKioskByDevicePut(deviceId, fileImageId, status)
			// update index
			commit("UPDATE_ITEMS", result.data.data)
		} catch (e) {
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					commit("SetItemsMessageTable", e.error)
					Message({
						type: "error",
						message: e.error,
					})
				}
			}
		} finally {
			commit("changeControlKioskImageLoading", false)
		}
	},

	async deleteImageControlKiosk({commit}, payload) {

		const {fileImageId, deviceId} = payload

		try {
			commit("changeControlKioskImageLoading", true)
			const service = new KioskService()
			await service.imageKioskByDeviceDelete(deviceId, fileImageId)
			// hapus index
			commit("DELETE_ITEMS", fileImageId)
		} catch (e) {
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					commit("SetItemsMessageTable", e.error)
					Message({
						type: "error",
						message: e.error,
					})
				}
			}
		} finally {
			commit("changeControlKioskImageLoading", false)
		}
	},
}

const getters = {
	countPublishedImage: state => {
		return state.data.items.filter(item => item.status===KIOSK_STATUS.published).length
	},
	countUnPublishedImage: state => {
		return state.data.items.filter(item => item.status===KIOSK_STATUS.unpublished).length
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
