import { ApiGetRequest, ApiPutRequest, ApiPostMultipart } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_kartu_pelajar: [
      {
        prop: "name",
        label: "Nama",
        minWidth: "160",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nis_nik",
        label: "NISN/NIS",
        width: "150",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "classes",
        label: "Kelas",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "updated_at",
        label: "Update data",
        width: "140",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "90",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_cetak: [
      {
        prop: "id",
        type: "selection",
      },
      {
        prop: "nis_nik",
        label: "NISN / NIS",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "user_avatar_image",
        label: "Foto",
        minWidth: "40",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "tempat_tgl_lahir",
        label: "Tempat Tgl Lahir",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "address",
        label: "Alamat",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    template: {
      theme_1: {
        id: "1",
        name: "Template 1",
        file: "template_1_depan.png",
        color: "black",
        title: "#002FAA",
        mode: "landscape",
      },
      theme_2: {
        id: "2",
        name: "Template 2",
        file: "template_2.jpg",
        color: "black",
        title: "#2C51B0",
        mode: "landscape",
      },
      theme_3: {
        id: "3",
        name: "Template 3",
        file: "template_3.png",
        color: "black",
        title: "#0D6CDC",
        mode: "landscape",
      },
      theme_4: {
        id: "4",
        name: "Template 4",
        file: "template_4.jpg",
        color: "black",
        title: "#002FAA",
        mode: "landscape",
      },
      theme_5: {
        id: "5",
        name: "Template 5",
        file: "template_5.jpg",
        color: "black",
        title: "#FFFF",
        mode: "landscape",
      },
      theme_6: {
        id: "6",
        name: "Template 6",
        file: "template_6.jpg",
        color: "black",
        title: "#E47900",
        mode: "landscape",
      },
      theme_7: {
        id: "7",
        name: "Template 7",
        file: "template_7.jpg",
        color: "white",
        title: "#FFD200",
        mode: "landscape",
      },
      theme_8: {
        id: "8",
        name: "Template 8",
        file: "template_8.jpg",
        color: "white",
        title: "#00FFF0",
        mode: "landscape",
      },
      theme_9: {
        id: "9",
        name: "Template 9",
        file: "template_9.jpg",
        color: "white",
        title: "#FF2E2E",
        mode: "landscape",
      },
      theme_10: {
        id: "10",
        name: "Template 10",
        file: "template_10.jpg",
        color: "white",
        title: "#FFE81D",
        mode: "landscape",
      },
      theme_11: {
        id: "11",
        name: "Template 11",
        file: "template_11.jpg",
        color: "white",
        title: "#FFE978",
        mode: "landscape",
      },
      theme_12: {
        id: "12",
        name: "Template 12",
        file: "template_12.jpg",
        color: "white",
        title: "#FFE978",
        mode: "landscape",
      },
      theme_13: {
        id: "13",
        name: "Template 13",
        file: "template_4.jpg",
        color: "black",
        title: "#002FAA",
        mode: "landscape",
      },
      theme_14: {
        id: "14",
        name: "Template 14",
        file: "template_14.png",
        color: "black",
        title: "#61407E",
        mode: "landscape",
      },
      // theme_15: {
      //   id: "15",
      //   name: "Template 15",
      //   file: "template_15.png",
      //   color: "white",
      //   title: "#fff",
      // },
      theme_15: {
        id: "15",
        name: "Template 15",
        file: "template_16.png",
        color: "black",
        title: "#002FAA",
        mode: "landscape",
      },

      theme_23: {
        id: "23",
        name: "Template 16 Custom",
        file: "template_16_custom_depan.jpg",
        color: "black",
        title: "#002FAA",
        mode: "landscape",
      },
      theme_24: {
        id: "24",
        name: "Template 17 PGRI",
        file: "template_pgri.jpg",
        color: "black",
        title: "#002FAA",
        mode: "landscape",
      },

      theme_16: {
        id: "16",
        name: "Template Potrait 1",
        file: "template_potrait_1.png",
        color: "black",
        title: "#002FAA",
        mode: "potrait",
      },
      theme_17: {
        id: "17",
        name: "Template Potrait 2",
        file: "template_potrait_2.png",
        color: "black",
        title: "#000",
        mode: "potrait",
      },
      theme_25: {
        id: "25",
        name: "Template 18",
        file: "template_3.jpg",
        color: "black",
        title: "#0D6CDC",
        mode: "landscape",
      },
      theme_18: {
        id: "18",
        name: "Template Potrait 3",
        file: "template_potrait_3.png",
        color: "black",
        title: "#000",
        mode: "potrait",
      },
      theme_19: {
        id: "19",
        name: "Template Potrait 4",
        file: "template_potrait_4.png",
        color: "black",
        title: "#000",
        mode: "potrait",
      },
      theme_20: {
        id: "20",
        name: "Template Potrait 5",
        file: "template_potrait_5.png",
        color: "black",
        title: "#000",
        mode: "potrait",
      },

      theme_21: {
        id: "21",
        name: "Template Potrait 6",
        file: "template_potrait_6.png",
        color: "black",
        title: "#000",
        mode: "potrait",
      },

      theme_22: {
        id: "22",
        name: "Template Potrait 7",
        file: "template_potrait_7.png",
        color: "black",
        title: "#000",
        mode: "potrait",
      },

      // theme_17: {
      //   id: "17",
      //   name: "Template 17",
      //   file: "template_17.png",
      //   color: "white",
      //   title: "#fff",
      // },
      theme_26: {
        id: "26",
        name: "Template 26",
        file: "template_26.png",
        color: "black",
        title: "rgb(246,0,17)",
        mode: "landscape",
      },
      theme_27: {
        id: "27",
        name: "Template 27",
        file: "template_27.png",
        color: "black",
        title: "rgb(0,0,0)",
        mode: "landscape",
      },
      theme_28: {
        id: "28",
        name: "Template 28",
        file: "template_28.png",
        color: "black",
        title: "rgb(0,0,0)",
        mode: "landscape",
      },
      theme_29: {
        id: "29",
        name: "Template 29",
        file: "template_29.png",
        color: "black",
        title: "rgb(0,0,0)",
        mode: "landscape",
      },
    },
    position_card: true,
    position_card_view: "landscape",
    selectPrint: [],
    idSekolah: false,
    settingKartu: {
      template: "theme_1",
      image: "",
      imageSignature: "",
      instruction: "",
      title: "",
      description: "",
      email: "",
      phone: "",
      name: "",
      address: "",
      city_name: "",
      province_name: "",
      timezone: "",
      type: "",
    },
    selectedFile: false,
    imagePreview: false,
    signSelectedFile: false,
    signImagePreview: false,
    themes: {},
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    errors: false,
  },
};

const mutations = {
  changeKartu(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getPelajar({ commit, state }, payload) {
    commit("changeKartu", {
      loading: true,
    });

    if (payload) {
      await commit("changeKartu", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
      show: data.currentStatus,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };

    if (
      data.majorSelected &&
      data.majorSelected !== "" &&
      data.majorSelected > 0
    ) {
      params.school_major_id = data.majorSelected;
    }

    if (
      data.currentKelas &&
      data.currentKelas !== "" &&
      data.currentKelas > 0
    ) {
      params.school_class_id = data.currentKelas;
    }

    const result = await ApiGetRequest(`school/student`, params);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengambil data pelajar",
      });

      commit("changeKartu", {
        loading: false,
      });
    } else {
      commit("changeKartu", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });

      return {
        data: result.data.data,
        total: result.data.meta.total,
      };
    }
  },

  async getPelajarById({ commit }, payload) {
    commit("changeKartu", {
      loading: true,
    });

    const result = await ApiGetRequest(`school/student/${payload.id}`);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengambil data pelajar",
      });

      commit("changeKartu", {
        loading: false,
      });
    } else {
      return result.data.data;
    }
  },

  async getPengaturan({ commit }) {
    commit("changeKartu", {
      loading: true,
      error: false,
    });

    const result = await ApiGetRequest(`school/student-card`);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengambil data pengaturan kartu",
      });

      commit("changeKartu", {
        loading: false,
      });

      return false;
    } else {
      commit("changeKartu", {
        loading: false,
        themes:
          result.data.data && result.data.data.length > 0
            ? result.data.data[0]
            : {},
      });

      return true;
    }
  },

  async updatePelajar({ commit }, payload = null) {
    commit("changeKartu", {
      loading: true,
      error: false,
    });

    let formData = {
      name: payload.dataForm.name,
      nis: payload.dataForm.nis,
      place_of_birth: payload.dataForm.place_of_birth,
      date_of_birth: payload.dataForm.date_of_birth,
      gender: payload.dataForm.gender,
      address: payload.dataForm.address,
    };

    if (formData && formData.nis) {
      formData.nis = formData.nis.replace("diskola-", "");
    }

    const result = await ApiPutRequest(
      `school/student/${payload.id}`,
      formData,
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data, periksa kembali data anda",
      });

      commit("changeKartu", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changeKartu", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Data Pelajar berhasil diubah",
      });

      return true;
    }
  },

  async updatePengaturan({ commit, state }) {
    commit("changeKartu", {
      loading: true,
      error: false,
    });

    const { data } = state;
    let formData = new FormData();
    formData.append("theme", data.settingKartu.template || "theme_1");
    if (data.signSelectedFile) {
      formData.append("sign_file", data.signSelectedFile);
    } else {
      formData.append("sign_file", data.settingKartu.imageSignature || "");
    }

    formData.append("back_title", data.settingKartu.title || "");
    formData.append("back_content", data.settingKartu.description || "");
    formData.append("instruction", data.settingKartu.instruction || "");
    const result = await ApiPostMultipart(`school/student-card`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKartu", {
        loading: false,
        errors: result.errorList || [],
      });

      return false;
    } else {
      commit("changeKartu", {
        loading: false,
        signSelectedFile: false,
        signImagePreview: false,
      });
    }
  },

  async updateSekolah({ commit, state, dispatch }) {
    commit("changeKartu", {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiPutRequest(
      `school/profile-school/${data.idSekolah || ""}`,
      data.settingKartu,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKartu", {
        loading: false,
        errors: result.errorList || [],
      });

      return false;
    } else {
      let resultUploadImage = await dispatch("updateProfileImage");
      if (resultUploadImage.error) {
        Message({
          type: "error",
          message: resultUploadImage.error,
        });

        commit("changeKartu", {
          loading: false,
          errors: resultUploadImage.errorList,
        });

        return false;
      } else {
        commit("changeKartu", {
          loading: false,
          errors: false,
        });

        Message({
          type: "success",
          message: "Pengaturan data sekolah berhasil diubah",
        });

        if (!resultUploadImage) {
          return result.data.data;
        } else {
          return resultUploadImage;
        }
      }
    }
  },

  async updateProfileImage({ state, commit }) {
    const { data } = state;
    if (data.selectedFile) {
      let formData = new FormData();
      formData.append("_method", "post");
      formData.append("image", data.selectedFile);

      const result = await ApiPostMultipart(
        `school/profile-school/image`,
        formData,
      );

      if (result.error) {
        commit("changeKartu", {
          selectedFile: false,
          imagePreview: false,
        });

        return result;
      } else {
        commit("changeKartu", {
          selectedFile: false,
          imagePreview: false,
        });

        return result.data.data;
      }
    } else {
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
