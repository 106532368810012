import { ApiNotification, ApiGetRequest } from "@/utils/Api";
import moment from "moment";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    error: false,
    loading: false,
    search: "",
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    perPage: 10,
    typefilter: "bulan",
    filter_date: moment().format("YYYY-MM-DD"),
    current_year: moment().format("YYYY"),
    current_month: moment().format("MM"),
    current_date: moment().format("DD"),
    currentMonth: [],
    tglActive: "",
    month: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    classroom: [],
    subject: [],
    classes_select: [],
    filter_matapelajaran: "",
    filter_kelas: "",
    columns_pelajar: [
			{
				prop: "avatar",
				label: "Foto",
				sortable: false,
				width: 80,
			},
      {
        prop: "jam_masuk",
        label: "Jam Masuk ",
        sortable: false,
        width: 150,
      },
      {
        prop: "nama_lengkap",
        label: "Nama Lengkap",
        sortable: false,
        minWidth: "160",
      },
      {
        prop: "ketetapan_waktu",
        label: "Ketetapan",
        sortable: false,
        width: "150",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "jam_kerja",
        label: "Jam Sekolah",
        sortable: false,
        minWidth: "130",
      },
      {
        prop: "kelas",
        label: "Kelas",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "jam_keluar",
        label: "Jam Keluar",
        sortable: false,
        minWidth: "130",
      },
      {
        prop: "status",
        label: "Status ",
        sortable: false,
        width: "150",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
  },
};

const mutations = {
  changePresensiPelajar(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getAbsensiPelajar({ commit, state }, payload = null) {
    commit("changePresensiPelajar", {
      loading: true,
    });

    if (payload) {
      commit("changePresensiPelajar", {
        page: payload,
      });
    }

    const { data } = state;
    const dataClasses = [];

    if (data.classes_select && data.classes_select.length > 0) {
      data.classes_select.map((item) => dataClasses.push(`classes[]=${item}`));
    }

    let reqClasses = dataClasses.length > 0 ? `&${dataClasses.join("&")}` : "";
    const getResult = await ApiGetRequest(
      `school/presence/attendance/${data.filter_date}/student?page=${
        data.page
      }&limit=${data.limit}&q=${data.search}${reqClasses}`,
    );
    if (getResult.error) {
      commit("changePresensiPelajar", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan absensi pelajar");
    } else {
      commit("changePresensiPelajar", {
        loading: false,
        infoUser: false,
        items: getResult.data.data,
        page: getResult.data.meta && getResult.data.meta.current_page,
        total: getResult.data.meta && getResult.data.meta.total,
      });
    }
  },
  async showPelajar({ commit, state }, id = null) {
    commit("changePresensiPelajar", {
      loading: true,
      infoUser: false,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/presence/attendance/${data.filter_date}/student/${id || ""}`,
    );
    if (getResult.error) {
      commit("changePresensiPelajar", {
        loading: false,
        error: getResult.errorList,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan detail data pelajar");
    } else {
      commit("changePresensiPelajar", {
        loading: false,
        infoUser: getResult.data.data,
        error: false,
      });
    }
  },
  async getAbsensiKelas({ commit, state }) {
    commit("changePresensiPelajar", {
      loading: true,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/presence/attendance/${data.filter_date}/class?page=${
        data.page || 1
      }&limit=${data.limit}&q=${data.search}${
        data.filter_kelas ? `&class=${data.filter_kelas}` : ""
      }${
        data.filter_matapelajaran ? `&subject=${data.filter_matapelajaran}` : ""
      }`,
    );
    if (getResult.error) {
      commit("changePresensiPelajar", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan data absensi kelas");
    } else {
      commit("changePresensiPelajar", {
        loading: false,
        items: getResult.data.data,
        page: (getResult.data.meta && getResult.data.meta.current_page) || 1,
        total: getResult.data.meta && getResult.data.meta.total,
      });
    }
  },
  async showKelas({ commit, state }, id = null) {
    commit("changePresensiPelajar", {
      loading: true,
      infoUser: false,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/presence/attendance/${data.filter_date}/class/${id || ""}`,
    );
    if (getResult.error) {
      commit("changePresensiPelajar", {
        loading: false,
        error: getResult.errorList,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan detail absensi kelas");
    } else {
      commit("changePresensiPelajar", {
        loading: false,
        infoUser: getResult.data.data,
        error: false,
      });
    }
  },

  async getClassRoom({ commit }) {
    commit("changePresensiPelajar", {
      loading: true,
    });

    const getResult = await ApiGetRequest(`school/class`);
    if (getResult.error) {
      commit("changePresensiPelajar", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat",
      );
    } else {
      commit("changePresensiPelajar", {
        loading: false,
        classroom: getResult.data.data,
      });
    }
  },
  async getSubject({ commit }) {
    commit("changePresensiPelajar", {
      loading: true,
    });

    const getResult = await ApiGetRequest(`school/subject`);
    if (getResult.error) {
      commit("changePresensiPelajar", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat",
      );
    } else {
      commit("changePresensiPelajar", {
        loading: false,
        subject: getResult.data.data,
      });
    }
  },
  async getAbsenKelas({ commit }) {
    commit("changePresensiPelajar", {
      loading: true,
    });

    const getResult = await ApiGetRequest(
      `school/subject-schedule/{subject_schedule}/attendance`,
    );
    if (getResult.error) {
      commit("changePresensiPelajar", {
        loading: false,
      });
      if (getResult.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat",
      );
    } else {
      commit("changePresensiPelajar", {
        loading: false,
        items: getResult.data.data,
        page: getResult.data.meta.current_page,
        total: getResult.data.meta.total,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
