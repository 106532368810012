import { ApiPostRequest, ApiPutRequest, ApiGetRequest } from "@/utils/Api";
import {
  GlobalHandleErrorApi,
  HandlerNotification,
  HandlerPageStartEnd,
  HandlerResult,
} from "@/utils/Helpers";

const state = {
  data: {
    columns: [
      {
        prop: "date",
        label: "Tutup Buku",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "created_at",
        label: "TglBuat",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "updated_at",
        label: "TglUbah",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        width: "120",
        "class-name": "body-14 col-black-2 text-center",
      },
    ],
    formData: {},
    formDataDetail: {},
    items: [],
    itemsAll: [],
    filter: "",
    errors: [],
    limit: 10,
    page: 1,
    total: 0,
    creator: null,
    updater: null,
    labelTutupBuku: "",
    sort_field: "score",
    sort_dir: "asc",
    currentStatus: "Active",
    processAction: "read",
    isProcess: false,
    showmodalAddForm: false,
    modalTutupBuku: false,
    modalFormTutupBuku: false,
    loading: false,
  },
};

const mutations = {
  changeKlasKonselingTutupBuku(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKlasKonselingTutupBukuFormData(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeKlasKonselingTutupBukuFormDataDetail(state, payload) {
    state.data.formDataDetail = Object.assign(
      {},
      state.data.formDataDetail,
      payload,
    );
  },
};

const actions = {
  handlerLoading({ commit }, payload = null) {
    const property = {};
    property.loading = payload === null || payload ? true : false;
    commit("changeKlasKonselingTutupBuku", property);
  },
  async getDataTutupBuku({ commit, dispatch, state }, payload) {
    dispatch("handlerLoading");
    const params = {};
    const { data } = state;
    params.limit = payload ? payload.limit ?? data.limit : data.limit;
    let resPage = HandlerPageStartEnd(data.page, data.items.length, data.limit);
    if (data.processAction === "update") {
      resPage = data.page;
    }
    params.page = payload ? payload.page || resPage : resPage;
    //const result = await import("@/db/TutupBuku.json");
    const result = await ApiGetRequest(`klaskonseling/clossing-period`, params);
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    commit("changeKlasKonselingTutupBuku", {
      loading: false,
    });
    HandlerResult(result, `Tutup Buku`);
    if (payload && payload.itemsAll) {
      await commit("changeKlasKonselingTutupBuku", {
        itemsAll: result.data.data,
      });
      dispatch("handlerTutupBukuAktif");
    } else {
      commit("changeKlasKonselingTutupBuku", {
        items: result.data.data,
        page: (result.data.meta && result.data.meta.current_page) || 1,
        total:
          (result.data.meta && result.data.meta.total) ||
          result.data.data.length,
      });
    }
  },
  async postDataTutupBuku({ commit, state, dispatch }, payload) {
    const { data } = state;
    let result;
    if (payload && payload.id) {
      if (!payload.status) {
        return false;
      }
      dispatch("handlerLoading");
      result = await ApiPutRequest(
        `klaskonseling/clossing-period/${payload.id}`,
        {
          status: payload.status,
        },
      );
      dispatch("getDataTutupBuku", { itemsAll: true, limit: 0 });
    } else {
      if (await dispatch("validasiFormData")) {
        return;
      }
      dispatch("handlerLoading");
      const formDataRequest = {};
      result = await ApiPostRequest(
        `klaskonseling/clossing-period?date=${data.formData.date}`,
        formDataRequest,
      );
    }
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return false;
      }
      if (result.errors) {
        let resError = GlobalHandleErrorApi(result.errors, "string");
        HandlerNotification("error", resError);
        return false;
      }
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerNotification();
    dispatch("getDataTutupBuku");
    dispatch("resetFormData");
    dispatch("handlerLoading", false);
    commit("changeKlasKonselingTutupBuku", {
      modalFormTutupBuku: false,
    });
    return true;
  },
  changePage({ commit, dispatch }, payload) {
    commit("changeKlasKonselingTutupBuku", { page: payload ?? 1 });
    dispatch("getDataTutupBuku");
  },
  handlerOnCloseModal({ commit }) {
    commit("changeKlasKonselingTutupBuku", {
      showmodalAddForm: false,
      processAction: `read`,
    });
    commit("changeKlasKonselingTutupBukuFormDataDetail", {
      id: "",
    });
  },
  resetFormData({ commit }, payload = null) {
    if (!payload) {
      commit("changeKlasKonselingTutupBukuFormData", {});
    } else {
      commit("changeKlasKonselingTutupBukuFormDataDetail", {});
    }
  },
  validasiFormData({ state }) {
    let d = state.data.formData;
    let noValidate = 0;
    let msg = "";
    if (d.date === "") {
      noValidate++;
      msg += "Tanggal Tutup Buku Belum Diisi";
    }
    if (!noValidate) {
      return false;
    }
    HandlerNotification("error", msg.replace(/,\s*$/, ""));
    return true;
  },
  handlerTutupBukuAktif({ state, commit }) {
    const { data } = state;
    if (data.itemsAll.length) {
      const activeData = data.itemsAll.filter(
        (item) => item.status === "active",
      );
      let res = ``;
      if (activeData.length) {
        let tanggalTutupBuku = activeData[0].date;
        res = `Data Tutup Buku ${tanggalTutupBuku}`;
      }
      commit("changeKlasKonselingTutupBuku", {
        labelTutupBuku: res,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
