import { Message, MessageBox } from "element-ui";
import moment from "moment";

/**
 * */
export const sortColumnTable2 = (column, nameField, order) => {
  console.log(column, nameField, order);
};

export const messageServerError =
  "Terjadi Kesalahan pada layanan. Coba lagi nanti!";

export const messageRequestTimeout =
  "Permintaan terlalu lama. Silakan coba lagi.";

export const handlerServerErrorAxios = (statusCode) => {
  if (statusCode >= 500) {
    Message({
      type: "error",
      message: messageServerError,
    });
  }
  return statusCode >= 500;
};

/**
 * Fungsi untuk mengonversi data.errors menjadi array errors.
 *
 * @param {Object} errorsData - Objek yang berisi properti errors.
 * @return {Array} Array dari objek errors yang diformat.
 */
export function convertErrorsToArray(errorsData) {
  let errors = [];
  for (const [key, value] of Object.entries(errorsData)) {
    errors.push({
      message: value[0],
      path: [key],
    });
  }
  return errors;
}

export function tentukanTahunPelajaran(dateNow = null) {
  let saatIni = moment();
  if (dateNow) {
    saatIni = moment(dateNow);
  }
  const tahunSekarang = saatIni.year();
  const bulanSekarang = saatIni.month() + 1; // Bulan di Moment.js dimulai dari 0

  let tahunPelajaran;
  if (bulanSekarang >= 1 && bulanSekarang <= 6) {
    tahunPelajaran = `${tahunSekarang - 1}-2`;
  } else {
    tahunPelajaran = `${tahunSekarang}-1`;
  }

  return tahunPelajaran;
}

import alfa from "@/assets/img/icons/payment/alfa.svg";
import indomaret from "@/assets/img/icons/payment/indomaret.svg";
import bca from "@/assets/img/icons/payment/bca.svg";
import bni from "@/assets/img/icons/payment/bni.svg";
import bri from "@/assets/img/icons/payment/bri.svg";
import linkaja from "@/assets/img/icons/payment/linkaja.svg";
import shopee from "@/assets/img/icons/payment/shopee.svg";
import {
  CALL_CENTER_MESSAGE,
  SERVER_ERROR_MESSAGE,
} from "@/services/messageService";
import router from "@/router";
/*import bankuob from "@/assets/img/icons/payment/bank-uob.svg"
import brisyariah from "@/assets/img/icons/payment/brisyariah.svg"
import mandiri from "@/assets/img/icons/payment/mandiri.svg"
import siplah from "@/assets/img/icons/payment/siplah.svg"
import gopay from "@/assets/img/icons/payment/gopay.svg"
import permata from "@/assets/img/icons/payment/permata.svg"*/
export const channelDetail = {
  SHOPEEPAY: { payment_logo: shopee },
  LINKAJA: { payment_logo: linkaja },
  linkaja: { payment_logo: linkaja },
  ALFAMART: { payment_logo: alfa },
  alfamart: { payment_logo: alfa },
  INDOMARET: { payment_logo: indomaret },
  indomaret: { payment_logo: indomaret },
  "BANK BRI": {
    payment_logo: bri,
  },
  "BANK BCA": {
    payment_logo: bca,
  },
  "BANK BNI": {
    payment_logo: bni,
  },
  "BANK MANDIRI": {
    payment_logo: bni,
  },
  "BANK BSI": {
    payment_logo: bni,
  },
};

export function handleErrorInCooperative(code) {
  let message = "";
  if (code === 404) {
    message = `Wallet Koperasi Sekolah anda masih belum terdaftar! <br>${CALL_CENTER_MESSAGE}`;
  } else {
    message = SERVER_ERROR_MESSAGE;
  }

  MessageBox({
    message: message,
    title: "Pemberitahuan",
    dangerouslyUseHTMLString: true,
  })
    .then(() => {
      if (code === 404) {
        router.push({ name: "Dashboard" });
      }
    })
    .catch(() => {
      if (code === 404) {
        router.push({ name: "Dashboard" });
      }
    });
}

/**
 * Format a number using a localized number formatter.
 * @param num The number to format
 * @param locale The locale to pass to the Intl.NumberFormat constructor
 * @param options Number format options
 */
export function formatNumberKoma(num, locale, options) {
  return new Intl.NumberFormat(locale, options).format(num);
}

export function checkVw(b = 100) {
  const a = window.innerWidth;
  if (a < 768) {
    return 90;
  }
  return b;
}

export function checkMobileRemoveFixedVw(b) {
  const a = window.innerWidth;
  if (a < 768) {
    return {};
  }
  return {
    fixed: b,
  };
}

export const tableOfFixed = {
  mobileRemoveFixedAndShowRight: checkMobileRemoveFixedVw("right"),
};
export const tableOfWidth = {
  major: {
    width: {
      width: 200,
    },
    minWidth: {
      minWidth: 160,
    },
  },
  class: {
    width: {
      width: 200,
    },
    minWidth: {
      minWidth: 160,
    },
  },
  grade: {
    width: {
      width: 90,
    },
    minWidth: {
      minWidth: 90,
    },
  },
  status: {
    width: {
      width: 90,
    },
    minWidth: {
      minWidth: 90,
    },
  },
  subject: {
    width: {
      width: 200,
    },
    minWidth: {
      minWidth: 200,
    },
  },
};

import repository from "@/repositories/internalRepository";

export const logoutDelete = async () => {
  try {
    await repository.deleteLogout();
  } catch (error) {
    console.log(error);
  }
};
