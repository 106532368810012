export const PAYMENT_SERVER_ERROR_MESSAGE =
  "Layanan sedang terkendala. Coba lagi nanti!";
export const SERVER_ERROR_MESSAGE =
  "Terjadi Kesalahan pada layanan. Coba lagi nanti!";
export const NO_INTERNET_MESSAGE =
  "Jaringan Bermasalah!";

export const REQUEST_TIMEOUT_MESSAGE =
  "Permintaan terlalu lama. Silakan coba lagi.";
export const DUPLICATE_CALL_SERVER_MESSAGE = "call enpoint duplicate: STOP";
export const CALL_CENTER_CONTACT = "6281779205008";
export const CALL_CENTER_MESSAGE = `Hubungi Pihak Call Center ${CALL_CENTER_CONTACT}`;
export const LABEL_PRELOADING = "Mohon Tunggu...";
export const TEXT_EXPORT_FILE = "File Diskola_";
export const DEV_STOP_CALL_SERVER = "Duplicate Call Server";
