import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest,
  ApiPostMultipart,
} from "@/utils/Api";
import { Message, MessageBox } from "element-ui";
import { _ } from "vue-underscore";
import { checkVw } from "@/services/internalService";
import router from "@/router";

const state = {
  data: {
    items: [],
    teachers: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    currentStatus: "all",
    roles: [],
    rolesSelected: "",
    loading: false,
    jsonData: [],
    provinces: [],
    cities: [],
    districts: [],
    goldarah: ["A", "AB", "B", "O"],
    agama: ["Islam", "Protestan", "Katolik", "Hindu", "Budha", "Konghucu"],
    errors: false,
    columns: [
      {
        prop: "nis_nik",
        label: "NIP/NUPTK",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "role",
        label: "Jabatan",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "soft_deleted",
        label: "Status",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(150),
        "class-name": "body-14 col-black-2",
      },
    ],
    status: [
      { value: "all", label: "Semua status" },
      { value: "active", label: "Aktif" },
      { value: "inactive", label: "Non Aktif" },
    ],
    dataForm: {
      nis_nik: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      roles: "",
      // soft_deleted: false,
      date_of_birth: "",
      place_of_birth: "",
      province_id: "",
      city_id: "",
      sub_district_id: "",
      address: "",
      gender: "",
      blood_type: "",
      religion: "",
    },
    rules: {
      nis_nik: [
        {
          required: true,
          message: "NIP / NUPTK wajib diisi",
          trigger: "blur",
        },
        {
          validator(rule, value, callback) {
            if (Number.isInteger(Number(value))) {
              callback();
            } else {
              callback(new Error("NIP / NUPTK harus angka"));
            }
          },
          trigger: "blur",
        },
      ],
      name: [
        {
          required: true,
          message: "Nama wajib diisi",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "Kata sandi wajib diisi",
          trigger: "blur",
        },
        { min: 4, message: "Minimal 4 karakter", trigger: "blur" },
      ],
      roles: [
        {
          required: true,
          message: "Jabatan wajib diisi",
          trigger: "blur",
        },
      ],
      email: [
        {
          type: "email",
          message: "Alamat email tidak sesuai",
          trigger: "change",
        },
      ],
      phone: [
        {
          validator(rule, value, callback) {
            if (Number.isInteger(Number(value))) {
              callback();
            } else {
              callback(new Error("Telp tidak sesuai"));
            }
          },
          trigger: "blur",
        },
      ],
    },
    selectTeacher: {
      isPageAction: false,
      loading: false,
      page: 1,
      limit: 25,
      total: 0,
      filter: "",
      currentStatus: "all",
      selectPrint: [],
      itemsAll: [],
      items: [],
      columns: [
        {
          prop: "id",
          type: "selection",
        },
        {
          prop: "nis_nik",
          label: "NIP/NUPTK",
          width: "150",
          sortable: false,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "name",
          label: "Nama",
          minWidth: "200",
          sortable: false,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "role",
          label: "Jabatan",
          minWidth: "150",
          "class-name": "body-14 col-black-2",
        },
      ],
    },
  },
};

const mutations = {
  changeGuruStaff(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeSelectTeacher(state, payload) {
    state.data.selectTeacher = Object.assign(
      {},
      state.data.selectTeacher,
      payload,
    );
  },
};

const actions = {
  async getGuruStaff({ commit, state }, payload) {
    commit("changeGuruStaff", {
      loading: true,
    });

    if (payload) {
      if (typeof payload === "object" && typeof payload.limit !== "undefined") {
        await commit("changeGuruStaff", {
          limit:
            typeof payload.limit !== "undefined" ? payload.limit : data.limit,
        });
      } else {
        await commit("changeGuruStaff", {
          page: payload || data.page,
        });
      }
    }

    const { data } = state;

    const params = {
      limit: data.limit,
      page: data.page,
      show: data.currentStatus,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
      role: data.rolesSelected,
    };

    const result = await ApiGetRequest(`school/user`, params);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data Guru & Tendik",
      });

      commit("changeGuruStaff", {
        loading: false,
      });

      return false;
    } else {
      const send = {
        loading: false,
      };
      send.items = result.data.data;
      if (typeof payload === "object") {
        commit("changeGuruStaff", send);
        return {
          data: result.data.data,
        };
      } else {
        send.data = result.data.data;
        send.total = result.data.meta
          ? result.data.meta.total
          : result.data.data.length;
        commit("changeGuruStaff", send);
        return {
          data: result.data.data,
          total: result.data
            ? result.data.meta
              ? result.data.meta.total
              : result.data.data.length
            : result.data.data.length,
        };
      }
    }
  },

  async getGuruStaffById({ commit, state, dispatch }, payload) {
    commit("changeGuruStaff", {
      loading: true,
    });

    const { data } = state;
    const result = await ApiGetRequest(`school/user/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengambil detail data guru",
      });

      commit("changeGuruStaff", {
        loading: false,
      });
      return false;
    }
    let newDataForm = data.dataForm;
    newDataForm = Object.assign({}, newDataForm, result.data.data);
    if (result.data.data.role && result.data.data.role.length > 0) {
      let dataRoles = [];
      result.data.data.role.map((item) => {
        dataRoles.push(item.name);
      });

      newDataForm.roles = dataRoles;
    }

    if (newDataForm.phone != null) {
      let Phone = newDataForm.phone.substr(1);
      newDataForm.phone = "62" + Phone;
    }

    let dataCities = await dispatch(
      "cities/getCities",
      newDataForm.province_id,
      { root: true },
    );

    let dataDistricts = await dispatch(
      "districts/getDistricts",
      newDataForm.city_id,
      { root: true },
    );

    console.log("proses call file storage");
    dispatch(
      "fileStorage/getDataFileStorage",
      { uuid: payload.id },
      { root: true },
    );

    commit("changeGuruStaff", {
      dataForm: newDataForm,
      cities: dataCities,
      districts: dataDistricts,
      id: payload.id,
      loading: false,
    });
    return true;
  },

  async getGuru({ commit, state }, payload) {
    commit("changeGuruStaff", {
      loading: true,
    });

    if (payload) {
      await commit("changeGuruStaff", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    const params = {
      role: 9,
    };
    const result = await ApiGetRequest(`school/user`, params);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data Guru & Tendik",
      });

      commit("changeGuruStaff", {
        loading: false,
      });

      return false;
    } else {
      commit("changeGuruStaff", {
        teachers: result.data.data,
        loading: false,
      });

      // return {
      //   data: result.data.data,
      //   total: result.data.meta.total,
      // };
    }
  },

  async resetAkunGuruStaff(context, payload) {
    const { formData, id } = payload;
    const result = await ApiPutRequest(`school/user/${id}/password`, formData);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal reset akun guru / staff",
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data password berhasil di reset",
      });

      return true;
    }
  },

  async deleteAkunGuruStaff(context, payload) {
    const { id } = payload;
    const result = await ApiDeleteRequest(`school/user/${id}`);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Akun telah dinonaktifkan",
      });

      return true;
    }
  },

  async restoreAkunGuruStaff(context, payload) {
    const { id } = payload;
    const result = await ApiPostRequest(`school/user/${id}/restore`, {});
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengaktifkan akun guru / staff ",
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Akun telah diaktifkan kembali",
      });
      return true;
    }
  },

  async importExcelGuruStaff({ commit }, payload) {
    commit("changeGuruStaff", {
      loading: true,
    });

    const result = await ApiPostRequest(`school/teacher`, payload.formData);
    commit("changeGuruStaff", {
      loading: false,
    });

    if (result.error) {
      MessageBox({
        type: "error",
        message: result.error,
      });

      return false;
    }

    const items = result.data.data;

    // check kalo return respone ada error
    const errorItems = await items.filter((item) => {
      if (item.error) {
        return item;
      }
    });

    if (errorItems.length > 0) {
      commit("changeGuruStaff", {
        jsonData: errorItems,
      });

      let message = "";

      if (errorItems.length !== payload.formData.length) {
        message = "Beberapa data gagal ditambahkan ke server";
      } else {
        message = `Sebanyak ${errorItems.length} Data gagal ditambahkan ke server karena kemungkinan duplikasi data`;
      }

      MessageBox({
        type: "error",
        message: message,
      });

      return false;
    }
    Message({
      type: "success",
      message: "Data Guru / Staff berhasil ditambahkan",
    });
    router.push("/pengguna/guru-staff");

    return true;
  },

  async createGuruStaff({ commit, state }) {
    commit("changeGuruStaff", {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiPostRequest(`school/user`, data.dataForm);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan data, periksa kembali data anda",
      });

      commit("changeGuruStaff", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changeGuruStaff", {
        loading: false,
        error: false,
      });

      Message({
        type: "success",
        message: "Data Guru / Staff berhasil ditambahkan",
      });

      return result.data.data;
    }
  },

  async updateGuruStaff({ commit, state }) {
    commit("changeGuruStaff", {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiPutRequest(`school/user/${data.id}`, data.dataForm);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data, periksa kembali data anda",
      });

      commit("changeGuruStaff", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changeGuruStaff", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Data Guru / Staff berhasil diubah",
      });

      return true;
    }
  },

  async updateImageUser({ commit }, payload) {
    commit("changeGuruStaff", {
      loading: true,
    });

    const result = await ApiPostMultipart(
      `school/user/${payload.id}/profile-image`,
      payload.dataForm,
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah gambar profil",
      });

      commit("changeGuruStaff", {
        loading: false,
        error: result.errorList || false,
      });

      return false;
    } else {
      commit("changeGuruStaff", {
        loading: false,
        error: false,
      });
      return true;
    }
  },

  async getGuruSelect({ commit, state }, payload) {
    commit("changeSelectTeacher", {
      loading: true,
    });

    if (typeof payload === "number" && payload) {
      commit("changeSelectTeacher", {
        page: payload,
        isPageAction: true,
      });
    }
    const params = {
      role: 9,
      limit: state.data.selectTeacher.limit,
      page: state.data.selectTeacher.page,
    };
    if (state.data.selectTeacher.filter) {
      params.q = state.data.selectTeacher.filter;
    }
    const result = await ApiGetRequest(`school/user`, params);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data Guru",
      });
      commit("changeSelectTeacher", {
        loading: false,
        page: 1,
      });
      return false;
    }
    //  itemsAll: this.$_.uniq([...state.data.selectTeacher.itemsAll, ...result.data.data], item => item.id),
    commit("changeSelectTeacher", {
      items: result.data.data,
      itemsAll: _.uniq(
        [...state.data.selectTeacher.itemsAll, ...result.data.data],
        (item) => item.id,
      ),
      total: result.data?.meta?.total || result.data.data.length,
      loading: false,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
