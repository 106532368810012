import { ApiGetRequest, ApiNotification, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    columns: [
      {
        prop: "created_at",
        label: "Tanggal",
        "class-name": "body-14 col-black-2",
        sortable: true,
        minWidth: "100",
      },
      {
        prop: "transaction_type",
        label: "Produk",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "periode",
        label: "Periode",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "total",
        label: "Tagihan",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "expired_at",
        label: "Berakhir",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        "class-name": "body-14 col-black-2",
      },
      {
        label: "Aksi",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    errors: [],
    loading: false,
    columnsDetail: [
      {
        prop: "reff_id",
        label: "Kode",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "transaction_type",
        label: "Produk",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "created_at",
        label: "Periode",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "installment_fee",
        label: "Instalasi",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "fee",
        label: "Biaya",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "total_fee",
        label: "Sub Total",
        "class-name": "body-14 col-black-2",
      },
    ],
    itemsDetail: [],
    columnsHistory: [
      {
        prop: "code",
        label: "No. invoice",
        "class-name": "body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "paid_at",
        label: "Pembayaran",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Produk",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "periode",
        label: "Periode",
        "class-name": "body-14 col-black-2",
        minWidth: "100",
      },
      {
        prop: "nominal_instalasi",
        label: "Instalasi",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nominal",
        label: "Biaya",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        "class-name": "body-14 col-black-2",
      },
    ],
    itemsHistory: [
      {
        code: "TT0002",
        paid_at: "17 Sept 2020",
        name: "Klasite",
        periode: "Jan 2020 - Des 2020",
        nominal_instalasi: 0,
        nominal: 5500000,
        status: 1,
      },
      {
        code: "TT0003",
        paid_at: "17 Sept 2020",
        name: "Klasite",
        periode: "Jan 2020 - Des 2020",
        nominal_instalasi: 7500000,
        nominal: 5500000,
        status: 1,
      },
    ],
    formSiplah: {
      siplah_reff_num: "",
    },
    formSiplahRules: {
      siplah_reff_num: [
        {
          required: true,
          message: "Nomor Pemesanan tidak boleh kosong",
          trigger: "blur",
        },
      ],
    },
    formPayment: {
      id: 1,
      transactionId: "105858712",
      paymentMethod: 1,
      end_at: "2021-06-12 12:17:55",
      virtual_account: "88820513268752",
      nominal: 5500000,
      fee: 0,
    },
  },
};

const mutations = {
  changeBerandaTagihan(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getTagihan({ commit, state }, payload) {
    commit("changeBerandaTagihan", {
      loading: true,
    });

    if (payload) {
      commit("changeBerandaTagihan", {
        page: payload || data.page,
      });
    }
    const { data } = state;

    const params = {
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };

    const result = await ApiGetRequest(`school/invoice`, params);

    if (result.error) {
      commit("changeBerandaTagihan", {
        loading: false,
      });
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTagihan", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      commit("changeBerandaTagihan", {
        loading: false,
        items: result.data.data,
        page: result.data.meta && result.data.meta.current_page,
        total: result.data.meta && result.data.meta.total,
      });
    }
  },

  async getTagihanDetail({ commit }, payload) {
    commit("changeBerandaTagihan", {
      itemsDetail: [],
    });

    if (payload) {
      const result = await ApiGetRequest(`school/invoice/${payload}`);

      if (result.error) {
        if (result.code >= 500) {
          ApiNotification("error", SERVER_ERROR_MESSAGE);
          return false;
        }

        Message({
          type: "error",
          message: result.error,
        });
        if (result.errorList) {
          let errors = [];
          for (const [key, value] of Object.entries(result.errorList)) {
            errors.push({
              message: value[0],
              path: [key],
            });
          }
          commit("changeBerandaTagihan", {
            errors: errors,
          });
        }
      } else {
        let dataDetail = [];
        dataDetail.push(result.data.data);
        commit("changeBerandaTagihan", {
          errors: [],
          itemsDetail: dataDetail,
        });
      }
    }
  },

  async submitPaymentSiplah({ commit, state }) {
    const { data } = state;
    let formData = {
      payment_method: "SIPLAH",
      transaction_id: data.itemsDetail[0].id,
      siplah_reff_num: data.formSiplah.siplah_reff_num,
    };

    const result = await ApiPostRequest(`school/invoice/pay`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTagihan", {
          errors: errors,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
