import KioskService from "@/services/KioskService"
import {Message} from "element-ui"
import {SERVER_ERROR_MESSAGE} from "@/utils/messageUtils"
import {DUPLICATE_CALL_SERVER_MESSAGE, NO_INTERNET_MESSAGE} from "@/services/messageService";
import {checkVw} from "@/services/internalService";
import {isServerErrorOrNoInternet} from "@/utils/Helpers";
import Vue from "vue";
import {KIOSK_STATUS} from "@/services/kioskComposable";

const state = {
	data: {
		showModalForm: false,
		loading: false,
		errorDataItems: false,
		itemsMessageTable: null,
		errors: [],
		items: [],
		total: 0,
		limit: 50,
		isDetailThumbnailNotFound: false,
		isDetailThumbnailRequestError: false,
		detailThumbnail: {
			file_path: null
		},
		formData: {
			"menu": "",
			"url": "",
			"sequence": 1,
			"status": KIOSK_STATUS.published
		},
		rulesFormData: {
			menu: [
				{
					required: true,
					message: "label menu tidak boleh kosong",
					trigger: "blur"
				}
			],
			url: [
				{
					required: true,
					message: "link url tidak boleh kosong",
					trigger: "blur"
				},
				{
					validator: (rule, value, callback) => {
						const urlPattern = /^https:\/\/.+/;
						if (value && !urlPattern.test(value)) {
							callback(new Error("URL harus diawali dengan https://"));
						} else {
							callback();
						}
					},
					trigger: "blur"
				}
			],
		},
		columns: [
			{
				prop: "menu",
				label: "Menu",
				minWidth: 200,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "url",
				label: "Link",
				minWidth: 140,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "status",
				label: "Status",
				width: 140,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "sequence",
				label: "Urutan",
				width: 120,
				"class-name": "body-14 col-black-2",
			},
			{
				fixed: "right",
				prop: "action",
				label: "Aksi",
				width: checkVw(100),
				"class-name": "body-14 col-black-2",
			},
		],
	},
}

const mutations = {
	changeControlKioskWebsiteFormData(state, payload) {
		const {id, menu, url, status, sequence} = payload
		state.data.formData = {
			"id": id,
			"menu": menu,
			"url": url,
			"status": status,
			"sequence": sequence
		}
	},
	changeControlKioskWebsite(state, payload) {
		state.data = Object.assign({}, state.data, payload)
	},
	changeControlKioskWebsiteLoading(state, payload) {
		state.data.loading = payload
	},
	setItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
	SetItemsMessageTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	ADD_ITEMS(state, payload) {
		state.data.items.push(payload)
	},
	UPDATE_ITEMS(state, payload) {
		const index = state.data.items.findIndex((item) => item.id === payload.id);

		if (index !== -1) {
			//state.data.items[index] = payload;
			Vue.set(state.data.items, index, payload);
		}
	},

	DELETE_ITEMS(state, itemsId) {
		const index = state.data.items.findIndex((item) => item.id === itemsId);
		if (index !== -1) {
			state.data.items.splice(index, 1);
		}
	},
}

const actions = {

	async onShowModal({commit}, isOpen) {
		commit("changeControlKioskWebsite", {
			showModalForm: isOpen
		})
	},

	async fetchThumbnailWebsiteControlKiosk({commit}, deviceId) {

		try {
			const service = new KioskService()
			const result = await service.thumbnailWebsiteKioskByDeviceGet(deviceId)
			commit("changeControlKioskWebsite", {
				detailThumbnail: result.data.data,
				isDetailThumbnailNotFound: false,
				isDetailThumbnailRequestError: false,
			})
			return true
		} catch (e) {
			commit("changeControlKioskWebsite", {
				isDetailThumbnailNotFound: false,
				isDetailThumbnailRequestError: true,
				detailThumbnail: null
			})

			if (!isServerErrorOrNoInternet(e)) {
				if (e.status === 404) {
					commit("changeControlKioskWebsite", {
						isDetailThumbnailNotFound: true,
						isDetailThumbnailRequestError: false,
					})
				}else if (e.status >= 400 && e.status < 500) {
					Message({
						type: "error",
						message: "Thumbnail Website: " + e.error,
					})
				}
			}
			return false
		}
	},

	async postThumbnailWebsiteControlKiosk({commit}, payload) {

		const {deviceId, fileBinary} = payload

		try {
			const service = new KioskService()
			const result = await service.websiteThumbnailKioskByDevicePost(deviceId, fileBinary)
			commit("changeControlKioskWebsite", {
				isDetailThumbnailNotFound: false,
				isDetailThumbnailRequestError: false,
				detailThumbnail: result.data.data
			})
			Message({
				type: "success",
				message: "Berhasil Diproses!",
			});
			return true
		} catch (e) {
			commit("changeControlKioskWebsite", {
				detailThumbnail: null
			})
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error,
					})
				}
			}
			return false
		}
	},

	async fetchWebsiteControlKiosk({commit, state}, paramsDeviceId) {

		const {
			limit, loading
		} = state.data

		if (loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

		const params = {
			limit: limit,
			page: 1,
		}

		try {
			commit("setItemsError", null)
			commit("changeControlKioskWebsiteLoading", true)
			const service = new KioskService()
			const result = await service.websiteKioskByDeviceGet(paramsDeviceId, params)

			if (result.data && result.data.data) {
				commit("changeControlKioskWebsite", {
					items: result.data.data,
					itemsMessageTable: null,
					total: result.data.data.length
				})
			}
		} catch (e) {
			commit("changeControlKioskWebsite", {
				itemsMessageTable: "Gagal Mengambil Data",
				items: [],
				total: 0
			})

			if (e.status >= 500) {
				commit("setItemsError", "Data Banner: " + SERVER_ERROR_MESSAGE)
				commit("SetItemsMessageTable", SERVER_ERROR_MESSAGE)
			} else if (e.code === "ERR_NETWORK") {
				commit("setItemsError", "Data Banner: CORS Error")
				commit("SetItemsMessageTable", NO_INTERNET_MESSAGE)
			} else if (e.status >= 400) {
				commit("SetItemsMessageTable", e.error)
				Message({
					type: "error",
					message: e.error,
				})
			}
		} finally {
			commit("changeControlKioskWebsiteLoading", false)
		}
	},

	async postWebsiteControlKiosk({commit}, payload) {

		const {deviceId} = payload

		try {
			commit("changeControlKioskWebsiteLoading", true)
			const service = new KioskService()
			const result = await service.websiteKioskByDevicePost(deviceId, state.data.formData)
			commit("ADD_ITEMS", result.data.data)

			Message({
				type: "success",
				message: "Berhasil Diproses!",
			});
			return true
		} catch (e) {
			if (!isServerErrorOrNoInternet(e)) {
				Message({
					type: "error",
					message: e.error || 'Gagal tambah data',
				})
			}
			return false
		} finally {
			commit("changeControlKioskWebsiteLoading", false)
		}
	},

	async putWebsiteControlKiosk({commit, state}, payload) {

		const {deviceId} = payload

		try {
			commit("setItemsError", null)
			commit("changeControlKioskWebsiteLoading", true)
			const service = new KioskService()
			const result = await service.websiteKioskByDevicePut(deviceId, state.data.formData.id, state.data.formData)
			// update index
			commit("UPDATE_ITEMS", result.data.data)
			Message({
				type: "success",
				message: "Berhasil Diproses!",
			});
			return true
		} catch (e) {
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error || 'Gagal update data',
					})
				}
			}
			return false
		} finally {
			commit("changeControlKioskWebsiteLoading", false)
		}
	},

	async putStatusWebsiteControlKiosk({commit, state}, payload) {

		const {fileWebsiteId, deviceId, status} = payload

		const formData = {
			...state.data.formData,
			status: status,
		}

		try {
			commit("setItemsError", null)
			commit("changeControlKioskWebsiteLoading", true)
			const service = new KioskService()
			const result = await service.websiteKioskByDevicePut(deviceId, fileWebsiteId, formData)
			// update index
			commit("UPDATE_ITEMS", result.data.data)
		} catch (e) {
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error,
					})
				}
			}
		} finally {
			commit("changeControlKioskWebsiteLoading", false)
		}
	},

	async deleteWebsiteControlKiosk({commit}, payload) {

		const {fileWebsiteId, deviceId} = payload

		try {
			commit("changeControlKioskWebsiteLoading", true)
			const service = new KioskService()
			await service.websiteKioskByDeviceDelete(deviceId, fileWebsiteId)
			// hapus index
			commit("DELETE_ITEMS", fileWebsiteId)
			Message({
				type: "success",
				message: "Berhasil Dihapus!",
			});
		} catch (e) {
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error,
					})
				}
			}
		} finally {
			commit("changeControlKioskWebsiteLoading", false)
		}
	},
}

const getters = {
	countPublishedWebsite: state => {
		return state.data.items.filter(item => item.status === KIOSK_STATUS.published).length
	},
	countUnPublishedWebsite: state => {
		return state.data.items.filter(item => item.status === KIOSK_STATUS.unpublished).length
	},
	listMenuWebsiteGettter: state => {
		return state.data.items.sort((a, b) => a.sequence - b.sequence)
	},
	selectSequenceGetter: state => {
		const sequences = state.data.items
			.map(item => item.sequence)   // Ambil semua sequence
			.sort((a, b) => a - b);       // Urutkan dari kecil ke besar

		// Untuk mengatasi duplikat, kita bisa memastikan sequence menjadi unik
		const uniqueSequences = [];
		let currentSequence = 1;  // Mulai dari sequence 1

		//eslint-disable-next-line no-unused-vars
		sequences.forEach((item) => {
			// Jika sequence sudah ada di uniqueSequences, tambahkan 1 agar menjadi unik
			if (uniqueSequences.includes(currentSequence)) {
				currentSequence++;
			}
			uniqueSequences.push(currentSequence); // Simpan sequence unik
			currentSequence++;  // Increment untuk next sequence
		})

		return uniqueSequences.push(uniqueSequences.length + 1)
		//return uniqueSequences;
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
