const state = {
  data: {
    hobby: [
      {
        id_hobby: "-1",
        nm_hobby: "(belum diisi)",
        create_date: "2015-07-30 01:47:22",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "1",
        nm_hobby: "Olah Raga",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "2",
        nm_hobby: "Kesenian",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "3",
        nm_hobby: "Membaca",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "4",
        nm_hobby: "Menulis",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "5",
        nm_hobby: "Traveling",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "6",
        nm_hobby: "Lainnya",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "11",
        nm_hobby: "Fotografi",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "12",
        nm_hobby: "Fitness",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "13",
        nm_hobby: "Belanja",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "14",
        nm_hobby: "Menggambar",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "15",
        nm_hobby: "Bermain Musik",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "16",
        nm_hobby: "mendaki",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "17",
        nm_hobby: "Jogging",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "18",
        nm_hobby: "Bermain Gitar",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "19",
        nm_hobby: "Bermain Bola",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "20",
        nm_hobby: "Bermain Bulu Tangkis",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "21",
        nm_hobby: "Bermain Bola Tenis",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "22",
        nm_hobby: "Bermain Biola",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "23",
        nm_hobby: "Bermain Piano",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "24",
        nm_hobby: "Berlari",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "25",
        nm_hobby: "Berkemah",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "26",
        nm_hobby: "Memancing",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "27",
        nm_hobby: "Berselancar",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "28",
        nm_hobby: "Bermain Gitar",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "29",
        nm_hobby: "Bermain Boneka",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "30",
        nm_hobby: "Makan",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "31",
        nm_hobby: "Menjahit",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "32",
        nm_hobby: "Main Puzzle",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_hobby: "33",
        nm_hobby: "Mewarnai",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
    ],
    citacita: [
      {
        id_cita: "-1",
        nm_cita: "(belum diisi)",
        create_date: "2015-07-30 01:47:22",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "1",
        nm_cita: "PNS",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "2",
        nm_cita: "TNI/Polri",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "3",
        nm_cita: "Guru/Dosen",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "4",
        nm_cita: "Dokter",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "5",
        nm_cita: "Politikus",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "6",
        nm_cita: "Wiraswasta",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "7",
        nm_cita: "Seni/Lukis/Artis/Sejenis",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "8",
        nm_cita: "Lainnya",
        create_date: "2015-07-30 13:32:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "11",
        nm_cita: "Penghafal Al-Qur'an",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "12",
        nm_cita: "Atlet E-Sport Profesional",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "13",
        nm_cita: "Atlet",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "14",
        nm_cita: "Content Creator",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "15",
        nm_cita: "Vloger",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "16",
        nm_cita: "Koki",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "17",
        nm_cita: "Pendeta",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "18",
        nm_cita: "Perawat",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "19",
        nm_cita: "Pilot",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "20",
        nm_cita: "Pembalap",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "21",
        nm_cita: "Atlit Olahraga",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "22",
        nm_cita: "Pengacara",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "23",
        nm_cita: "Da'i / Ustadz",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "24",
        nm_cita: "Entertainer / Pekerja Seni",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "25",
        nm_cita: "Wartawan",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "26",
        nm_cita: "Pengusaha / Bisnismen",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "27",
        nm_cita: "Penulis",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "28",
        nm_cita: "Penyiar Radio",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "29",
        nm_cita: "Pembawa Acara / Master Ceremony",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "30",
        nm_cita: "Polisi",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "31",
        nm_cita: "Pemadam Kebakaran",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "32",
        nm_cita: "Astronot",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "33",
        nm_cita: "Masinis Kereta Api",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "34",
        nm_cita: "Perawat / Suster",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "35",
        nm_cita: "Bidan",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "36",
        nm_cita: "Presiden",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "37",
        nm_cita: "Pegawai Negeri Sipil / PNS",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "38",
        nm_cita: "Translator",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "39",
        nm_cita: "Designer",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "40",
        nm_cita: "Pelaut",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        id_cita: "41",
        nm_cita: "Arsitek",
        create_date: "2021-01-29 02:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
    ],
    jenjangPendidikan: [
      {
        jenjang_pendidikan_id: "0",
        nama: "Tidak sekolah",
        jenjang_lembaga: "0",
        jenjang_orang: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "1",
        nama: "PAUD",
        jenjang_lembaga: "1",
        jenjang_orang: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "2",
        nama: "TK / sederajat",
        jenjang_lembaga: "1",
        jenjang_orang: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "3",
        nama: "Putus SD",
        jenjang_lembaga: "0",
        jenjang_orang: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "4",
        nama: "SD / sederajat",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "5",
        nama: "SMP / sederajat",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "6",
        nama: "SMA / sederajat",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "7",
        nama: "Paket A",
        jenjang_lembaga: "1",
        jenjang_orang: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "8",
        nama: "Paket B",
        jenjang_lembaga: "1",
        jenjang_orang: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "9",
        nama: "Paket C",
        jenjang_lembaga: "1",
        jenjang_orang: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "20",
        nama: "D1",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "21",
        nama: "D2",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "22",
        nama: "D3",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-14 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "23",
        nama: "D4",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-14 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "30",
        nama: "S1",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-14 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "31",
        nama: "Profesi",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2017-03-07 09:50:58",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "32",
        nama: "Sp-1",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2014-06-04 13:11:32",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "35",
        nama: "S2",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-14 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "36",
        nama: "S2 Terapan",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2017-05-04 09:20:18",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "37",
        nama: "Sp-2",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2014-06-04 13:12:23",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "40",
        nama: "S3",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2013-05-14 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "41",
        nama: "S3 Terapan",
        jenjang_lembaga: "1",
        jenjang_orang: "1",
        create_date: "2017-05-04 09:20:27",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "90",
        nama: "Non formal",
        jenjang_lembaga: "1",
        jenjang_orang: "0",
        create_date: "2013-05-14 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "91",
        nama: "Informal",
        jenjang_lembaga: "1",
        jenjang_orang: "0",
        create_date: "2013-05-14 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
      {
        jenjang_pendidikan_id: "99",
        nama: "Lainnya",
        jenjang_lembaga: "1",
        jenjang_orang: "0",
        create_date: "2013-05-14 00:00:00",
        last_update: "2022-07-01 22:04:43",
        expired_date: null,
        last_sync: "2022-07-01 22:04:43",
      },
    ],
    layakPip: [
      {
        id_layak_pip: "-3",
        alasan_layak_pip: "Dilarang Pemda karena menerima bantuan serupa",
        create_date: "2016-07-22 06:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "-2",
        alasan_layak_pip: "Sudah Mampu",
        create_date: "2016-07-14 22:56:54",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "-1",
        alasan_layak_pip: "Menolak",
        create_date: "2016-07-14 22:56:54",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "1",
        alasan_layak_pip: "Pemegang PKH/KPS/KKS",
        create_date: "2016-01-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "3",
        alasan_layak_pip: "Yatim Piatu/Panti Asuhan/Panti Sosial",
        create_date: "2016-01-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "4",
        alasan_layak_pip: "Dampak Bencana Alam",
        create_date: "2016-01-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "5",
        alasan_layak_pip: "Pernah Drop Out",
        create_date: "2016-01-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "6",
        alasan_layak_pip: "Siswa Miskin/Rentan Miskin",
        create_date: "2016-01-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "7",
        alasan_layak_pip: "Daerah Konflik",
        create_date: "2016-01-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "8",
        alasan_layak_pip: "Keluarga Terpidana / Berada di LAPAS",
        create_date: "2016-01-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        id_layak_pip: "9",
        alasan_layak_pip: "Kelainan Fisik",
        create_date: "2016-01-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
    ],
    agama: [
      {
        agama_id: 1,
        nama: "Islam",
        create_date: "2013-05-13 06:51:30",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        agama_id: 2,
        nama: "Kristen",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        agama_id: 3,
        nama: "Katholik",
        create_date: "2013-05-13 06:51:30",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        agama_id: 4,
        nama: "Hindu",
        create_date: "2013-05-13 06:51:30",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        agama_id: 5,
        nama: "Budha",
        create_date: "2013-05-13 06:51:30",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        agama_id: 6,
        nama: "Khonghucu",
        create_date: "2013-05-13 06:51:30",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        agama_id: 7,
        nama: "Kepercayaan kpd Tuhan YME",
        create_date: "2017-01-31 01:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        agama_id: 99,
        nama: "lainnya",
        create_date: "2013-05-13 06:51:30",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
    ],
    jenis_tinggal: [
      {
        jenis_tinggal_id: "1",
        nama: "Bersama orang tua",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        jenis_tinggal_id: "2",
        nama: "Wali",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        jenis_tinggal_id: "3",
        nama: "Kost",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        jenis_tinggal_id: "4",
        nama: "Asrama",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        jenis_tinggal_id: "5",
        nama: "Panti asuhan",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        jenis_tinggal_id: "10",
        nama: "Pesantren",
        create_date: "2017-07-21 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        jenis_tinggal_id: "99",
        nama: "Lainnya",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
    ],
    jenisTransportasi: [
      {
        alat_transportasi_id: "1",
        nama: "Jalan kaki",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "3",
        nama: "Angkutan umum/bus/pete-pete",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "4",
        nama: "Mobil/bus antar jemput",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "5",
        nama: "Kereta api",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "6",
        nama: "Ojek",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "7",
        nama: "Andong/bendi/sado/dokar/delman/becak",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "8",
        nama: "Perahu penyeberangan/rakit/getek",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "11",
        nama: "Kuda",
        create_date: "2013-10-07 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "12",
        nama: "Sepeda",
        create_date: "2013-10-07 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "13",
        nama: "Sepeda motor",
        create_date: "2013-10-07 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "14",
        nama: "Mobil pribadi",
        create_date: "2013-10-07 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        alat_transportasi_id: "99",
        nama: "Lainnya",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
    ],
    jenisPendaftaran: [
      {
        jenis_pendaftaran_id: "1",
        nama: "Siswa baru",
        daftar_sekolah: "1",
        daftar_rombel: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        jenis_pendaftaran_id: "2",
        nama: "Pindahan",
        daftar_sekolah: "1",
        daftar_rombel: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        jenis_pendaftaran_id: "3",
        nama: "Naik kelas",
        daftar_sekolah: "0",
        daftar_rombel: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        jenis_pendaftaran_id: "5",
        nama: "Mengulang",
        daftar_sekolah: "0",
        daftar_rombel: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        jenis_pendaftaran_id: "6",
        nama: "Lanjutan semester",
        daftar_sekolah: "0",
        daftar_rombel: "1",
        create_date: "2013-05-13 00:00:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
      {
        jenis_pendaftaran_id: "7",
        nama: "Kembali bersekolah",
        daftar_sekolah: "1",
        daftar_rombel: "0",
        create_date: "2015-09-03 17:30:00",
        last_update: "2022-07-01 22:04:45",
        expired_date: null,
        last_sync: "2022-07-01 22:04:45",
      },
    ],
    pekerjaan: [
      {
        pekerjaan_id: 1,
        nama: "Tidak bekerja",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 2,
        nama: "Nelayan",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 3,
        nama: "Petani",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 4,
        nama: "Peternak",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 5,
        nama: "PNS/TNI/Polri",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 6,
        nama: "Karyawan Swasta",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 7,
        nama: "Pedagang Kecil",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 8,
        nama: "Pedagang Besar",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 9,
        nama: "Wiraswasta",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 10,
        nama: "Wirausaha",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 11,
        nama: "Buruh",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 12,
        nama: "Pensiunan",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-05-13 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 13,
        nama: "Tenaga Kerja Indonesia",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2016-02-14 10:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 90,
        nama: "Tidak dapat diterapkan",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2015-07-30 12:05:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 98,
        nama: "Sudah Meninggal",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-09-04 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 99,
        nama: "Lainnya",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2013-09-04 00:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
      {
        pekerjaan_id: 14,
        nama: "Karyawan BUMN",
        a_wirausaha: "0",
        a_pejabat_publik: "0",
        create_date: "2021-11-03 12:00:00",
        last_update: "2023-07-10 20:54:28",
        expired_date: null,
        last_sync: "2023-07-10 20:54:28",
      },
    ],
    penghasilan: [
      {
        penghasilan_id: 0,
        nama: "0",
        batas_bawah: 0,
        batas_atas: 0,
        create_date: "2013-05-25 00:00:00",
        last_update: "2020-06-12 06:56:38",
        expired_date: null,
        last_sync: "2020-06-12 06:56:38",
      },
      {
        penghasilan_id: 11,
        nama: "Kurang dari Rp. 500,000",
        batas_bawah: 499999,
        batas_atas: 0,
        create_date: "2013-07-05 00:00:00",
        last_update: "2020-06-12 06:56:38",
        expired_date: null,
        last_sync: "2020-06-12 06:56:38",
      },
      {
        penghasilan_id: 12,
        nama: "Rp. 500,000 - Rp. 999,999",
        batas_bawah: 999999,
        batas_atas: 500000,
        create_date: "2013-07-05 00:00:00",
        last_update: "2020-06-12 06:56:38",
        expired_date: null,
        last_sync: "2020-06-12 06:56:38",
      },
      {
        penghasilan_id: 13,
        nama: "Rp. 1,000,000 - Rp. 1,999,999",
        batas_bawah: 1999999,
        batas_atas: 1000000,
        create_date: "2013-07-05 00:00:00",
        last_update: "2020-06-12 06:56:38",
        expired_date: null,
        last_sync: "2020-06-12 06:56:38",
      },
      {
        penghasilan_id: 14,
        nama: "Rp. 2,000,000 - Rp. 4,999,999",
        batas_bawah: 4999999,
        batas_atas: 2000000,
        create_date: "2013-07-05 00:00:00",
        last_update: "2020-06-12 06:56:38",
        expired_date: null,
        last_sync: "2020-06-12 06:56:38",
      },
      {
        penghasilan_id: 15,
        nama: "Rp. 5,000,000 - Rp. 20,000,000",
        batas_bawah: 20000000,
        batas_atas: 5000000,
        create_date: "2013-07-05 00:00:00",
        last_update: "2020-06-12 06:56:38",
        expired_date: null,
        last_sync: "2020-06-12 06:56:38",
      },
      {
        penghasilan_id: 16,
        nama: "Lebih dari Rp. 20,000,000",
        batas_bawah: 0,
        batas_atas: 20000001,
        create_date: "2013-07-05 00:00:00",
        last_update: "2020-06-12 06:56:38",
        expired_date: null,
        last_sync: "2020-06-12 06:56:38",
      },
      {
        penghasilan_id: 99,
        nama: "Tidak Berpenghasilan",
        batas_bawah: 0,
        batas_atas: 0,
        create_date: "2017-07-31 17:26:10",
        last_update: "2020-06-12 06:56:38",
        expired_date: null,
        last_sync: "2020-06-12 06:56:38",
      },
    ],
  },
};

const mutations = {
  changeDapodik(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};
const getters = {
  sortedHobbyByNama: (state) => {
    return state.data.hobby
      .slice()
      .sort((a, b) => a.nm_hobby.localeCompare(b.nm_hobby));
  },
  sortedCitaCitaByNama: (state) => {
    return state.data.citacita
      .slice()
      .sort((a, b) => a.nm_cita.localeCompare(b.nm_cita));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
