import GlobalService from "@/services/GlobalService";
import {Message} from "element-ui";

const state = {
	data: {
		itemsVerficationCode: [],
		isLoading: false,
		isLoadingGenerate: false,
		isError: false,
		error: null
	},
};

const mutations = {
	changeVerificationCode(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeLoading(state, payload) {
		state.data.isLoading = payload;
	},
	changeLoadingGenerate(state, payload) {
		state.data.isLoadingGenerate = payload;
	},
};

const actions = {

	async setUpdateItemsVerificationCode({commit, state}, newData) {
		if (typeof newData === 'object') {
			const dataOld = state.data.itemsVerficationCode
			const updateData = dataOld.map(item =>
				item.id === newData.id ? { ...item, ...newData } : item
			);
			commit("changeVerificationCode", {
				itemsVerficationCode: updateData
			});
		}else {
			console.info('params newData tidka sesuai')
		}
	},

	async fetchListVerificationCode({commit, state}) {

		if (state.data.isLoading) return;

		try {
			commit("changeLoading", true)

			const globalService = new GlobalService()
			const result = await globalService.listVerificationCodeGet()

			commit("changeVerificationCode", {
				itemsVerficationCode: result.data.data
			})
		} catch (e) {
			console.log(e)
		} finally {
			commit("changeLoading", false)
		}

	},

	async postGenerateVerificationCode({dispatch, commit, state}, typeCode) {

		if (state.data.isLoadingGenerate) return;

		try {
			commit("changeLoadingGenerate", true)

			const globalService = new GlobalService()

			let result = {}
			if (typeCode === "student") {
				result = await globalService.generateStudentVerificationCodePost()
			} else if (typeCode === "teacher") {
				result = await globalService.generateTeacherVerificationCodePost()
			} else {
				Message({
					type: "info",
					message: "typeCode not found"
				});
				return;
			}

			dispatch('setUpdateItemsVerificationCode', result.data.data)
			Message({
				type: "success",
				message: "Berhasil!"
			});
		} catch (e) {
			console.log('====>',e)

			Message({
				type: "error",
				message: e.error || "Error Generate code"
			});
		} finally {
			commit("changeLoadingGenerate", false)
		}

	}

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
