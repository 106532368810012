import { ApiGetRequest, ApiNotification } from "@/utils/Api";
// import moment from "moment";
import { Message } from "element-ui";
import { HandlerNotification } from "@/utils/Helpers";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    page: 1,
    total: 0,
    limit: 0,
    loading: false,
    sortBy: "",
    sortType: "",
    filterYearMonth: [],
    filter: "",
    dateStart: "",
    dateEnd: "",
    git: "",
    filterDate: null,
    editData: false,
    prosesCallKantin: false,
    showModalCetakLaporan: false,
    selectKantin: [],
    itemsLaporan: [],
    dataItem: [],
    detailKantin: {},
    detailKantinPenjualanDateRange: {
      showModalDetail: false,
      page: 1,
      total: 0,
      limit: 10,
      loading: false,
      item: {
        detail: [],
      },
      riwayatPenjualan: {
        columns: [
          {
            prop: "created_date",
            label: "Tanggal",
            width: 180,
            "class-name": "body-14 col-black-2",
          },
          {
            prop: "pembeli",
            label: "Pembeli",
            minWidth: 200,
            sortable: false,
            "class-name": "body-14 col-black-2",
          },
          {
            prop: "harga",
            label: "Total Biaya",
            width: 160,
            sortable: false,
            "class-name": "body-14 col-black-2 text-right",
          },
        ],
        page: 1,
        total: 0,
        limit: 10,
        loading: false,
        items: [],
      },
      columns: [
        {
          prop: "created_date",
          label: "Tanggal",
          width: 150,
          sortable: true,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "tp",
          label: "Total Penjualan",
          minWidth: 150,
          sortable: false,
          "class-name": "body-14 col-black-2 text-right",
        },
        {
          prop: "ps",
          label: "Sekolah",
          minWidth: 130,
          sortable: false,
          "class-name": "body-14 col-black-2 text-right",
        },
        {
          prop: "kantin",
          label: "Kantin",
          minWidth: 130,
          sortable: false,
          "class-name": "body-14 col-black-2 text-right",
        },
        {
          fixed: "right",
          prop: "aksi",
          label: "Aksi",
          width: 60,
          sortable: false,
          "class-name": "body-14 col-black-2",
        },
      ],
    },
    errors: [],
    listKantin: [
      {
        name: "Kantin 1 Lengkap",
        pemilik: "Bu Icha",
        pendapatan: "2,000,000",
      },
    ],
    dataPenjualan: [
      {
        uuid: 1,
        date: "12-12-2022",
        totalPenjualan: 2000000,
        totalPenjualanPrety: "2,000,000",
        pendapatanSekolah: 500000,
        pendapatanSekolahPrety: "500,000",
      },
    ],
  },
};

const mutations = {
  changeKoperasiPenjualanKantin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKoperasiPenjualanKantinLoading(state, payload) {
    state.data.loading = payload;
  },
  changeKoperasiPenjualanKantinDateRange(state, payload) {
    state.data.detailKantinPenjualanDateRange = Object.assign(
      {},
      state.data.detailKantinPenjualanDateRange,
      payload,
    );
  },
  changeRiwayatPenjualan(state, payload) {
    state.data.detailKantinPenjualanDateRange.riwayatPenjualan = Object.assign(
      {},
      state.data.detailKantinPenjualanDateRange.riwayatPenjualan,
      payload,
    );
  },
};

const actions = {
  async getDataTable({ commit, state }, payload) {
    if (state.data.dataItem.length && !state.data.prosesCallKantin) {
      console.log("ini");
      return false;
    }

    commit("changeKoperasiPenjualanKantin", {
      prosesCallKantin: false,
    });

    if (payload) {
      await commit("changeKoperasiPenjualanKantin", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
    };
    if (data.sortBy) {
      params.sort_field = data.sortBy;
    }
    if (data.sortType) {
      params.sort_dir = data.sortType;
    }
    if (data.filter) {
      params.q = data.filter;
    }

    commit("changeKoperasiPenjualanKantinLoading", true);
    const result = await ApiGetRequest(`school/canteen`, params);
    commit("changeKoperasiPenjualanKantinLoading", false);

    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification(500);
        return false;
      }
      Message({
        type: "error",
        message: result.error,
      });
      return false;
    }
    commit("changeKoperasiPenjualanKantin", {
      dataItem: result.data.data,
      total: result.data.meta.total,
    });
  },

  async getKantinDetail({ commit, state }, payload) {
    if (state.data.detailKantin.id && !state.data.prosesCallKantin) {
      return false;
    }
    commit("changeKoperasiPenjualanKantin", {
      loading: true,
      prosesCallKantin: false,
    });

    if (payload && !payload.id) {
      Message({
        type: "error",
        message: `payload membutuhkan id`,
      });
      commit("changeKoperasiPenjualanKantin", {
        loading: false,
      });
      return false;
    }

    commit("changeKoperasiPenjualanKantinLoading", true);
    const result = await ApiGetRequest(`school/canteen/${payload.id}`);
    commit("changeKoperasiPenjualanKantinLoading", false);

    if (result.error) {
      if (result.code >= 500) {
        HandlerNotification(500);
        return false;
      }
      Message({
        type: "error",
        message: result.error,
      });
      return false;
    }
    commit("changeKoperasiPenjualanKantin", {
      detailKantin: result.data.data,
    });
    return true;
  },

  async getKantinPenjualanDetail({ commit }, payload) {
    commit("changeKoperasiPenjualanKantin", {
      prosesCallKantin: false,
    });
    if (payload && !payload.id) {
      console.log("payload.id isRequired");
      return false;
    }
    if (!state.data.filterDate.length) {
      Message({
        type: "error",
        message: "Filter Range Tanggal Harap diisi.",
      });
      return false;
    }
    let params = {
      start_date: state.data.filterDate[0],
      end_date: state.data.filterDate[1],
    };

    commit("changeKoperasiPenjualanKantinDateRange", { loading: true });
    const result = await ApiGetRequest(
      `payment/canteen/profit/${payload.id}`,
      params,
    );
    commit("changeKoperasiPenjualanKantinDateRange", { loading: false });

    if (result.error) {
      if (result.code >= 500) {
        ApiNotification("error", `${result.code} ${SERVER_ERROR_MESSAGE}`);
        return false;
      }
      Message({
        type: "error",
        message: result.error,
      });
      return false;
    }
    console.log();
    commit("changeKoperasiPenjualanKantinDateRange", {
      item: result.data,
      loading: false,
    });
    return true;
  },

  async getLaporanBulanan({ commit, state }) {
    if (!state.data.filterYearMonth || !state.data.selectKantin.length) {
      return false;
    }
    commit("changeKoperasiPenjualanKantin", {
      prosesCallKantin: false,
      loading: true,
    });
    let params = {
      year: state.data.filterYearMonth.split("-")[0],
      month: state.data.filterYearMonth.split("-")[1],
    };
    const queryString = state.data.selectKantin
      .map((item) => `walletCanteen[]=${encodeURIComponent(item)}`)
      .join("&");

    const result = await ApiGetRequest(
      `payment/canteen/profit/report?${queryString}`,
      params,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeKoperasiPenjualanKantin", {
        itemsLaporan: [],
        loading: false,
      });
      return false;
    }
    commit("changeKoperasiPenjualanKantin", {
      itemsLaporan: result.data.data,
      loading: false,
    });
    return true;
  },
};

const getters = {
  filterSelectKantin: (state) => (id) => {
    return state.data.selectKantin.includes(id);
  },
  filterKantinByParamsSelect: (state) => {
    return state.data.dataItem.filter((item) =>
      state.data.selectKantin.includes(item.wallet_id),
    );
  },
  filterHasilPenjualanKantinByParamsSelect: (state) => {
    const result = state.data.itemsLaporan;
    return Object.keys(result)
      .filter((date) => date !== "total")
      .map((date) => {
        return result[date];
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
