import {requirePermission} from "@/router/index";

export default [
	{
		path: "psb",
		name: "WebsitePsb",
		component: () =>
			import(/* webpackChunkName: "psb-website" */"../views/WebSchool/WebPsb"),
		meta: {
			title: "Website PSB | ",
			allowedRole: [
				"Head Master",
				"Ppdb Committee",
				"School Admin"
			],
		},
		beforeEnter: requirePermission,
	},
]
