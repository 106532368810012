import { Message } from "element-ui";
import AgendaSuratImplement from "@/api/AgendaSuratImplement";
import ApiService from "@/utils/ApiService";
import { checkVw, convertErrorsToArray } from "@/services/internalService";
import moment from "moment";
import Vue from "vue";
import InternalImplement from "@/api/InternalImplement";

const state = {
  data: {
    id: "",
    loading: false,
    loadingPurposeAll: false,
    showmodal: false,
    filter: "",
    filterCreatorId: "",
    filterYear: moment().year(),
    sortBy: "agenda_number",
    sortType: "desc",
    items: [],
    itemsAll: [],
    itemsPurposeAll: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    typeAction: "read",
    detailFormData: {},
    listLimit: [10, 25, 50, 100],
    columns: [
      {
        prop: "agenda_number",
        label: "Agenda",
        width: 110,
        fixed: "left",
        sortable: true,
      },
      {
        prop: "date",
        label: "Tanggal",
        width: 180,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "purpose",
        label: "Tujuan",
        minWidth: 220,
      },
      {
        prop: "subject",
        label: "Perihal",
        minWidth: 250,
      },
      {
        prop: "officer",
        label: "Petugas",
        width: 180,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(160),
        "class-name": "body-14 col-black-2",
      },
    ],
    formData: {
      id: null,
      uuid: null,
      agenda_number: null,
      date: moment().format("DD-MM-YYYY HH:mm:ss"),
      purpose: null,
      subject: null,
      officer: null,
      department: null,
      creator: {},
      updated_at: null,
      created_at: null,
    },
    menu: [
      {
        name: "AgendaSuratData",
        label: "Umum",
        lock: false,
        url: "/agenda-surat/data",
      },
      {
        name: "AgendaSuratSuratMasukSekolah",
        label: "Surat Masuk Sekolah",
        lock: false,
        url: "/agenda-surat/surat-masuk-sekolah",
      },
      {
        name: "AgendaSuratSuratMasuk",
        label: "Surat Masuk (Cabdin)",
        lock: false,
        url: "/agenda-surat/surat-masuk",
      },
    ],
  },
};

const mutations = {
  changeAgendaSurat(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },

  changeFormDataAgendaSuratObjectAssign(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeFormDataAgendaSurat(state, payload) {
    state.data.formData = {
      id: payload.id,
      agenda_number: payload.agenda_number,
      date: payload.date,
      officer: payload.officer,
      purpose: payload.purpose,
      subject: payload.subject,
    };
  },

  changeLoading(state, payload) {
    state.data.loading = payload;
  },

  changeLoadingPurposeAll(state, payload) {
    state.data.loadingPurposeAll = payload;
  },

  ADD_ITEM_PUPOSEALL(state, purposeItem) {
    if (!state.data.itemsPurposeAll.includes(purposeItem)) {
      state.data.itemsPurposeAll.push(purposeItem);
    }
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  ADD_ITEMS(state, payload) {
    state.data.items.unshift(payload);
  },

  /**
   * Proses pembaruan item di dalam state.items.
   *
   * @function
   * @param {Object} state - State saat ini.
   * @param {Object} payload - Harus berupa objek yang mengandung id item yang akan diperbarui dan data baru.
   */
  UPDATE_ITEMS(state, payload) {
    // Mencari index item yang akan diperbarui berdasarkan id atau properti unik lainnya.
    const index = state.data.items.findIndex((item) => item.id === payload.id);

    // Jika item ditemukan, lakukan pembaruan.
    if (index !== -1) {
      state.data.items[index] = payload;
      Vue.set(state.data.items, index, payload);
    }
  },

  /**
   * Proses dimana melakukan penambahan data ke items.
   *
   * @function
   * @param {Object} payload - Harus berupa objek. Data yang akan ditambahkan ke items.
   * @param {Object} state - State saat ini.
   * @returns {void}
   */
  DELETE_ITEMS(state, payload) {
    const { detailFormData } = state.data;
    let id = detailFormData.id;
    if (payload.id) {
      id = payload.id;
    }
    const index = state.data.items.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.data.items.splice(index, 1);
    }
  },
};

const actions = {
  actionRead({ commit }) {
    commit("changeAgendaSurat", { typeAction: "read" });
  },
  actionCreate({ commit }) {
    commit("changeAgendaSurat", {
      formData: {
        date: moment().format("DD-MM-YYYY HH:mm:ss"),
      },
      detailFormData: {},
    });
    commit("changeAgendaSurat", { typeAction: "create" });
  },
  actionEdit({ commit }) {
    commit("changeAgendaSurat", { typeAction: "edit" });
  },
  actionUpload({ commit }) {
    commit("changeAgendaSurat", { typeAction: "upload" });
  },
  actionDelete({ commit }) {
    commit("changeAgendaSurat", { typeAction: "delete" });
  },

  async getAgendaSurat({ commit, state }, payload = {}) {
    if (!isNaN(payload)) {
      commit("changeAgendaSurat", { page: payload });
    }
    if (payload.page) {
      commit("changeAgendaSurat", { page: payload.page });
    }

    if (payload.sortBy) {
      commit("changeAgendaSurat", {
        sortBy: payload.sortBy,
        sortType: payload.sortType,
      });
    }

    const {
      filterYear,
      filterCreatorId,
      filter,
      page,
      limit,
      sortBy,
      sortType,
      loading,
    } = state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    const params = {
      q: filter,
      sort_field: sortBy,
      sort_dir: sortType || "asc",
      year: parseInt(filterYear),
      limit: limit,
      page: parseInt(page),
      creator_id: filterCreatorId,
    };

    try {
      commit("changeLoading", true);
      const agendaSuratService = new AgendaSuratImplement(ApiService);
      const result = await agendaSuratService.agendaSurat(params);
      commit("changeLoading", false);

      commit("changeAgendaSurat", {
        items: result?.data?.data || [],
        total: result?.data?.meta?.total || result?.data?.data.length,
        page: result?.data?.meta?.current_page || 1,
        lastPage: result?.data?.meta?.last_page || 1,
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);
      commit("changeAgendaSurat", {
        items: [],
        total: 0,
        page: 1,
        lastPage: 1,
      });
      const statusCode = error?.response?.status || 500;
      console.log("===>", error.response);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        Message({
          type: "error",
          message: data.error || "Gagal Mengambil data",
        });
      }
      return false;
    }
  },

  async getPurposeAll({ commit }) {
    try {
      commit("changeLoadingPurposeAll", true);
      const internalService = new InternalImplement(ApiService);
      const result = await internalService.purposes();
      commit("changeLoadingPurposeAll", false);
      commit("changeAgendaSurat", {
        itemsPurposeAll: result?.data?.data || [],
      });
      return true;
    } catch (error) {
      commit("changeAgendaSurat", {
        itemsPurposeAll: [],
      });
      commit("changeLoadingPurposeAll", false);
      return false;
    }
  },

  async postAgendaSurat({ commit, state }) {
    const { loading } = state.data;
    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      commit("changeLoading", true);
      const { subject, purpose } = state.data.formData;
      const formData = {
        subject,
        purpose,
      };
      const agendaSuratService = new AgendaSuratImplement(ApiService);
      const result = await agendaSuratService.addAgendaSurat(formData);
      commit("changeLoading", false);
      commit("ADD_ITEM_PUPOSEALL", formData.purpose);
      commit("ADD_ITEMS", result.data.data);
      commit("changeAgendaSurat", { formData: {} });
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        if (data.errors) {
          let errors = await convertErrorsToArray(data.errors);
          commit("changeAgendaSurat", {
            errors: errors,
          });
          return false;
        }
        Message({
          type: "error",
          message: data.error || "Gagagal diproses",
        });
      }
      return false;
    }
  },

  async putAgendaSurat({ commit, state }) {
    const { loading } = state.data;
    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      commit("changeLoading", true);
      const { subject, purpose, id } = state.data.formData;
      const formData = {
        subject,
        purpose,
      };
      const agendaSuratService = new AgendaSuratImplement(ApiService);
      const result = await agendaSuratService.updateAgendaSurat(id, formData);
      commit("changeLoading", false);
      commit("ADD_ITEM_PUPOSEALL", formData.purpose);
      commit("UPDATE_ITEMS", result.data.data);
      commit("changeAgendaSurat", { formData: {} });
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        if (data.errors) {
          let errors = await convertErrorsToArray(data.errors);
          commit("changeAgendaSurat", {
            errors: errors,
          });
          return false;
        }
        if (statusCode === 403) {
          Message({
            type: "error",
            message:
              "Maaf Hanya dapat diubah Oleh Petugas yang membuat data tersebut",
          });
          return false;
        }
        Message({
          type: "error",
          message: data.error || "Gagagal diproses",
        });
      }
      return false;
    }
  },

  async postFileAgendaSurat({ commit }) {
    try {
      const { file, id } = state.data.formData;
      const formData = {
        file,
        id,
      };
      commit("changeLoading", true);
      const agendaSuratService = new AgendaSuratImplement(ApiService);
      const result = await agendaSuratService.uploadFileAgendaSurat(formData);
      commit("changeLoading", false);
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      commit("UPDATE_ITEMS", result.data.data);
      commit("changeAgendaSurat", { formData: {} });
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        Message({
          type: "error",
          message: data.error || "Gagagal diproses",
        });
      }
      return false;
    }
  },
  async deleteAgendaSurat({ commit }, payload) {
    if (!payload.id) {
      Message({
        type: "error",
        message: "ID tidak ditemukan",
      });
      return false;
    }
    try {
      commit("changeLoading", true);
      const agendaSuratService = new AgendaSuratImplement(ApiService);
      await agendaSuratService.delete(payload.id);
      commit("changeLoading", false);
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      commit("changeAgendaSurat", { formData: {} });
      commit("DELETE_ITEMS", { id: payload.id });
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        if (statusCode === 403) {
          Message({
            type: "error",
            message:
              "Maaf Hanya dapat dihapus Oleh Petugas yang membuat data tersebut",
          });
          return false;
        }

        Message({
          type: "error",
          message: data.error || "Gagagal diproses",
        });
      }
      return false;
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
