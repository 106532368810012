import { HandlerNotification } from "@/utils/Helpers";
import FileStorageImplement from "@/api/FileStorageImplement";
import ApiService from "@/utils/ApiService";
import moment from "moment";

const state = {
  data: {
    formData: {},
    formDataDetail: {},
    showModalUpdateName: false,
    showModalDetail: false,
    showModalAdd: false,
    loadingDownload: false,
    loading: false,
    total: 0,
    page: 1,
    filterYear: moment().year(),
    limit: 10,
    items: [],
    itemAll: [],
    category: 0,
    sort_dir: 0,
    sort_field: 0,
    error: "",
    currentTab: "Kriteria Kelulusan",
    resultFilterListKategori: [
      {
        active: true,
        name: "Kriteria Kelulusan",
        label: "Kriteria Kelulusan",
      },
    ],

    bin: {
      userSelected: [],
      itemAll: [],
      items: [],
      limit: 100,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      loading: false,
      errors: false,
    },
  },
};

const mutations = {
  changeGraduation(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeLoading(state, isTrue) {
    state.data.loading = isTrue;
  },
  changeLoadingBin(state, isTrue) {
    state.data.bin.loading = isTrue;
  },
  changeGraduationBin(state, payload) {
    state.data.bin = Object.assign({}, state.data.bin, payload);
  },
};

const actions = {
  infoPageDetailDokumen({ commit }, payload) {
    commit("changeGraduation", {
      showModalDetail: true,
      formDataDetail: payload,
    });
  },

  async getDataFileStorage({ state, commit }, payload) {
    const {
      currentTab,
      limit,
      page,
      sort_dir,
      sort_field,
      loading,
      filterYear,
    } = state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    let params = {};
    if (sort_field) params.sort_field = sort_field;
    if (sort_dir) params.sort_dir = sort_dir;
    if (payload) {
      if (payload.sort_field) params.sort_field = payload.sort_field;
      if (payload.sort_dir) params.sort_dir = payload.sort_dir;
    }
    params.category = currentTab;
    params.limit = (payload && payload.limit) || limit;
    params.page = page;
    params.year = filterYear;

    try {
      commit("changeLoading", true);
      const fileStorageService = new FileStorageImplement(ApiService);
      const result = await fileStorageService.fileStorageGraduation(params);
      commit("changeLoading", false);

      commit("changeGraduation", {
        itemAll: result.data.data,
        page: (result.data.meta && result.data.meta.current_page) || 1,
        total:
          (result.data.meta && result.data.meta.total) ||
          result.data.data.length,
      });
    } catch (error) {
      commit("changeLoading", false);
      const statusCode = error?.response?.status || 500;
      console.log("===>", error.response);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        HandlerNotification("error", data.error || "Gagal Mengambil data");
      }
      return false;
    }
  },

  async postFileGraduation({ state, commit }, payload) {
    const { loading } = state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      commit("changeLoading", true);
      const fileStorageService = new FileStorageImplement(ApiService);
      await fileStorageService.addFileStorageGraduation(payload);
      commit("changeLoading", false);
      return true;
    } catch (error) {
      commit("changeLoading", false);
      const statusCode = error?.response?.status || 500;
      console.log("===>", error.response);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        HandlerNotification("error", data.error || "Gagal Mengambil data");
      }
      return false;
    }
  },

  async putFileGraduation({ state, commit, dispatch }) {
    const { loading, formData } = state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      commit("changeLoading", true);
      const fileStorageService = new FileStorageImplement(ApiService);
      await fileStorageService.updateNameFileStorageGraduation(
        formData.id,
        formData,
      );
      commit("changeLoading", false);
      commit("changeGraduation", {
        showModalUpdateName: false,
      });
      dispatch("getDataFileStorage");
      return true;
    } catch (error) {
      commit("changeLoading", false);
      const statusCode = error?.response?.status || 500;
      console.log("===>", error);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        HandlerNotification("error", data.error || "Gagal Mengambil data");
      }
      return false;
    }
  },

  async deleteToArsipGraduation({ state, commit, dispatch }, payload) {
    const { loading } = state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      const klasdrive_id = payload.id;
      commit("changeLoading", true);
      const fileStorageService = new FileStorageImplement(ApiService);
      await fileStorageService.archiveFileStorageGraduation(klasdrive_id);
      commit("changeLoading", false);
      commit("changeGraduation", {
        showModalDetail: false,
        formDataDetail: {},
      });
      dispatch("getDataFileStorage");
      return true;
    } catch (error) {
      commit("changeLoading", false);
      const statusCode = error?.response?.status || 500;
      console.log("===>", error.response);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        HandlerNotification("error", data.error || "Gagal Mengambil data");
      }
      return false;
    }
  },

  async postToRestoreGraduation({ state, commit, dispatch }, payload) {
    const { loading } = state.data.bin;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      const klasdrive_id = payload.id;
      commit("changeLoading", true);
      const fileStorageService = new FileStorageImplement(ApiService);
      await fileStorageService.restoreFileStorageGraduation(klasdrive_id);
      commit("changeLoading", false);
      commit("changeGraduation", {
        showModalDetail: false,
        formDataDetail: {},
      });
      dispatch("getDataFileStorageBinGraduation");
      return true;
    } catch (error) {
      commit("changeLoading", false);
      const statusCode = error?.response?.status || 500;
      console.log("===>", error.response);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        HandlerNotification("error", data.error || "Gagal Mengambil data");
      }
      return false;
    }
  },

  /**
   * payload {skipNotif|optional, typeProcessor|optional,id|isRequired}
   * */
  async deletePermanenFileBinGraduation({ state, commit }, payload) {
    const { loading } = state.data.bin;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      const klasdrive_id = payload.id;
      commit("changeLoading", true);
      const fileStorageService = new FileStorageImplement(ApiService);
      await fileStorageService.deletePermanenFileStorageGraduation(
        klasdrive_id,
      );
      commit("changeLoading", false);
      return true;
    } catch (error) {
      commit("changeLoading", false);
      const statusCode = error?.response?.status || 500;
      console.log("===>", error.response);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        if (!payload.skipNotif) {
          HandlerNotification("error", data.error || "Gagal Mengambil data");
        }
      }
      return false;
    }
  },

  updateNameDokumen({ commit }, payload) {
    // payload -> nilai row
    if (!payload.id) {
      HandlerNotification("info", "Id Dokumen Tidak ditemukan");
      return false;
    }
    if (!payload.klasdrive_name) {
      HandlerNotification("info", "Nama Dokumen Tidak ditemukan");
      return false;
    }

    commit("changeGraduation", {
      showModalUpdateName: true,
      formData: {
        id: payload.id,
        nameSelect: `LAINNYA`,
        name: payload.klasdrive_name,
        category: payload.category,
      },
      formDataDetail: {
        id: payload.id,
        nameSelect: `LAINNYA`,
        name: payload.klasdrive_name,
        category: payload.category,
      },
    });
  },

  async getDataFileStorageBinGraduation({ state, commit }, payload) {
    const { limit, page, sortBy, sortType, loading } = state.data.bin;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    let params = {
      sort_field: sortBy,
      sort_dir: sortType,
      limit: (payload && payload.limit) || limit,
      page: page,
    };
    if (payload) {
      if (payload.sort_field) params.sort_field = payload.sort_field;
      if (payload.sort_dir) params.sort_dir = payload.sort_dir;
    }

    try {
      commit("changeGraduationBin", {
        userSelected: [],
      });
      commit("changeLoadingBin", true);
      const fileStorageService = new FileStorageImplement(ApiService);
      const result =
        await fileStorageService.fileStorageGraduationRecyclebin(params);
      commit("changeLoadingBin", false);

      commit("changeGraduationBin", {
        itemAll: result.data.data,
        items: result.data.data,
        page: (result.data.meta && result.data.meta.current_page) || 1,
        total:
          (result.data.meta && result.data.meta.total) ||
          result.data.data.length,
      });
    } catch (error) {
      commit("changeLoadingBin", false);
      const statusCode = error?.response?.status || 500;
      console.log("===>", error.response);
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        HandlerNotification("error", data.error || "Gagal Mengambil data");
      }
      return false;
    }
  },

  /**
   * payload {path, nisNik, fileName, userName}
   * */
  downloadFile({ state, commit }, payload) {
    if (state.data.loadingDownload) return false;
    commit("changeLoading", false);
    commit("changeGraduation", {
      loadingDownload: true,
    });
    const { path, fileName, year } = payload;
    if (!path || !fileName) {
      HandlerNotification("info", "Dokumen lokasi tidak ditemukan.");
      return false;
    }
    //payload.path = `https://cdn.pixabay.com/photo/2023/05/11/03/34/white-cockatoo-7985434_1280.jpg`;
    fetch(payload.path)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${fileName}_${year}`;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
        commit("changeGraduation", {
          loadingDownload: false,
        });
      })
      .catch((error) => {
        commit("changeGraduation", {
          loadingDownload: false,
        });
        HandlerNotification(
          "info",
          "Gambar Tidak bisa di download Otomatis. Lakukan menggunakan Simpan Gambar/Save Image dengan cara lihat gambar lalu klik kanan.",
        );
        console.error(error); // Log the specific error message for debugging
      });
  },
};

const getters = {
  listKategoriGetters: (state) => {
    const { resultFilterListKategori, currentTab } = state.data;
    const lowerCurrentTabSchool = currentTab.toLowerCase();

    return resultFilterListKategori.map((item) => {
      const isActive = item.name.toLowerCase() === lowerCurrentTabSchool;
      if (isActive || item.active) {
        return {
          ...item,
          active: isActive,
        };
      }
      return item;
    });
  },

  itemsFileStorageGetters: (state) => {
    const { itemAll, currentTab } = state.data;
    const lowerCurrentTabSchool = currentTab.toLowerCase();
    if (itemAll.length) {
      return itemAll.filter(
        (item) => item.category.toLowerCase() === lowerCurrentTabSchool,
      );
    }
    return [];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
