import repository from "@/repositories/KeuanganSekolahRepository";
import StrukturErrorService from "@/services/StrukturErrorService";
//import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers";

export default class KeuanganSekolahService {
	constructor() {
		this.dataRepository = repository;
	}

	async schoolMutationGet(payload) {
		const {
			filterDateStart,
			filterDateEnd,
			page, limit,
			sort_field, sort_dir
		} = payload

		try {
			const params = {
				date_start: filterDateStart || undefined,
				date_end: filterDateEnd || undefined,

				limit: limit || undefined,
				page: page || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
			}

			return await this.dataRepository.getSchoolMutation(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async schoolMutationRecapGet(payload) {
		const {
			month_end, year_end,
			month_start, year_start,
			page, limit, filterQ,
			sort_field, sort_dir
		} = payload

		try {
			const params = {
				month_start: month_start || undefined,
				year_start: year_start || undefined,

				month_end: month_end || undefined,
				year_end: year_end || undefined,

				limit: limit ?? undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
			}

			return await this.dataRepository.getSchoolMutationRecap(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

}
