import {
  ApiGetRequest,
  ApiPutRequest,
  ApiPostRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
import { Message } from "element-ui";
// import moment from "moment";

const state = {
  data: {
    tabMenu: [
      {
        name: "Group",
        active: true,
      },
      {
        name: "Jadwal",
        active: false,
      },
      {
        name: "Report Magang",
        active: false,
      },
      // {
      //   name: "Siswa",
      //   active: false,
      // },
    ],
    columns_magang: [
      {
        prop: "name_corporate",
        label: "Perusahaan",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "address_corporate",
        label: "Alamat",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "coordinate_corporate",
        label: "Titik Koordinat",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "pic_name",
        label: "Nama PIC",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "pic_telp",
        label: "Telp",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "pic_comment",
        label: "Radius (M)",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },

      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],

    formDataMagang: {
      corporate_name: "",
      latitude: "",
      longitude: "",
      corporate_address: "",
      pic: "",
      phone: "",
      information: "",
    },
    editdata: false,
    timesDefault: [
      {
        day: 1,
        check: false,
        label: "Senin",
        start_at: "",
        end_at: "",
      },
      {
        day: 2,
        check: false,
        label: "Selasa",
        start_at: "",
        end_at: "",
      },
      {
        day: 3,
        check: false,
        label: "Rabu",
        start_at: "",
        end_at: "",
      },
      {
        day: 4,
        check: false,
        label: "Kamis",
        start_at: "",
        end_at: "",
      },
      {
        day: 5,
        check: false,
        label: "Jumat",
        start_at: "",
        end_at: "",
      },
      {
        day: 6,
        check: false,
        label: "Sabtu",
        start_at: "",
        end_at: "",
      },
      {
        day: 7,
        check: false,
        label: "Minggu",
        start_at: "",
        end_at: "",
      },
    ],
    usertimes: [],
    rowuser: {
      name: "",
      custom_users: [],
      times: [],
    },
    limitMagang: 10,
    pageMagang: 1,
    totalMagang: 0,
    sortByMagang: "",
    sortTypeMagang: "",
    filterMagang: "",
    loadingMagang: false,
    searchMagang: "",
    errorsMagang: [],
    dataMagang: [],
    activeTabMenu: "Group",
  },
};

const mutations = {
  changeMagang(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getDataInternship({ commit, state }, payload) {
    commit("changeMagang", {
      loadingMagang: true,
    });
    const { data } = state;

    if (payload) {
      await commit("changeMagang", {
        pageMagang: payload || data.pageMagang,
      });
    }

    let params = {
      q: data.searchMagang,
      limit: data.limitMagang,
      page: data.pageMagang,
      sort_field: data.sortByMagang,
      sort_dir: data.sortTypeMagang,
    };
    const result = await ApiGetRequest(`school/internship`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagang", {
        loadingMagang: false,
      });
    } else {
      commit("changeMagang", {
        dataMagang: result.data.data,
        totalMagang: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loadingMagang: false,
      });
    }
  },

  async editDataInternship({ commit, state }, payload) {
    commit("changeMagang", {
      loadingMagang: true,
    });
    const { data } = state;
    let bodyForm = {
      company_name: data.formDataMagang.corporate_name,
      company_address: data.formDataMagang.corporate_address,
      company_latitude: data.formDataMagang.latitude,
      company_longitude: data.formDataMagang.longitude,
      pic_name: data.formDataMagang.pic,
      pic_phone: data.formDataMagang.phone,
      note: data.formDataMagang.information,
    };

    const result = await ApiPutRequest(
      `school/internship/${payload.id}`,
      bodyForm,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagang", {
        loadingMagang: false,
      });
    } else {
      commit("changeMagang", {
        loadingMagang: false,
      });
      Message({
        type: "success",
        message: "Data berhasil diubah.",
      });
    }
  },

  async submitDataInternship({ commit, state }) {
    commit("changeMagang", {
      loadingMagang: true,
    });
    const { data } = state;

    let bodyForm = {
      company_name: data.formDataMagang.corporate_name,
      company_address: data.formDataMagang.corporate_address,
      company_latitude: data.formDataMagang.latitude,
      company_longitude: data.formDataMagang.longitude,
      pic_name: data.formDataMagang.pic,
      pic_phone: data.formDataMagang.phone,
      note: data.formDataMagang.information,
    };

    const result = await ApiPostRequest(`school/internship`, bodyForm);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagang", {
        loadingMagang: false,
      });
    } else {
      commit("changeMagang", {
        loadingMagang: false,
      });
      Message({
        type: "success",
        message: "Data berhasil disimpan.",
      });
    }
  },

  async deleteDataInternship({ commit }, payload) {
    commit("changeMagang", {
      loadingMagang: true,
    });

    const result = await ApiDeleteRequest(`school/internship/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagang", {
        loadingMagang: false,
      });
    } else {
      commit("changeMagang", {
        loadingMagang: false,
      });
      Message({
        type: "success",
        message: "Data berhasil dihapus.",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
