const state = {
	data: {
		tabMenu: [
			{
				name: "Jurusan",
				label: "Jurusan",
				lock: false,
				url: "/pembelajaran/jurusan",
				roles: ["Head Master", "Vice Principal", "School Admin"],
			},
			{
				name: "RuangKelas",
				label: "Kelas",
				lock: false,
				url: "/pembelajaran/ruang-kelas",
				roles: ["Head Master", "Vice Principal", "School Admin"],
			},
			{
				name: "MataPelajaran",
				label: "Mapel",
				lock: false,
				url: "/pembelajaran/mata-pelajaran",
				roles: ["Head Master", "Vice Principal", "School Admin"],
			},
			{
				name: "JadwalPelajaran",
				label: "Jadwal KBM",
				lock: false,
				url: "/pembelajaran/jadwal",
				roles: ["Head Master", "Vice Principal", "School Admin"],
			},
			{
				name: "Materi",
				label: "Materi",
				lock: false,
				url: "/pembelajaran/materi",
				roles: ["Head Master", "Vice Principal", "School Admin", "Teacher"],
			},
			{
				name: "Tugas",
				label: "Tugas",
				lock: false,
				url: "/pembelajaran/tugas",
				roles: ["Teacher"],
			},
			{
				name: "Magang",
				label: "Magang",
				lock: true,
				url: "/pembelajaran/magang",
				roles: ["Head Master", "Vice Principal", "School Admin"],
			},
		],
		tabMenuAsesmen: [
			{
				name: "Ujian",
				label: "Ujian",
				lock: false,
				url: "/asesmen/ujian",
			},
			{
				name: "JadwalUjian",
				label: "Jadwal Ujian",
				lock: false,
				url: "/asesmen/jadwal-ujian",
			},
		],
	},
};

const mutations = {
	changePembelajaran(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
};

const actions = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
