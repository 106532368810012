import ApiService from "@/api/ApiService";
import axios from "axios";

class UserTemporaryRepository {
	constructor() {
		this.apiService = ApiService;
		this.cancelTokenSource = null;
		this.mock = 'http://localhost:3000/api';
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getUserTemporarySummary() {
		this.createCancelToken()
		return await this.apiService.get(`/school/user-temporary/summary`);
	}

	async getUserTemporary(params) {
		return await this.apiService.get(`/school/user-temporary`, { params });
	}

}

export default new UserTemporaryRepository();
