import { ApiGetRequest, ApiNotification } from "@/utils/Api";
import { Message } from "element-ui";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    roles: [],
    translate: {
      "Head Master": "Kepala Sekolah",
      "Vice Principal": "Wakil Kepala Sekolah",
      Cooperative: "Koperasi",
      "School Finance": "Keuangan",
      "School Admin": "Admin",
      Teacher: "Guru",
      Counseling: "Konseling",
      Libraryan: "Pustakawan",
      SuperAdmin: "Super Admin",
      Laboratory: "Laboran",
      Security: "Keamanan",
      "Health Workers": "UKS",
      Janitor: "Petugas Kebersihan",
    },
  },
};

const mutations = {
  changeRoles(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getRoles({ commit, state }) {
    const result = await ApiGetRequest(`school/user/roles`);

    if (result.error) {
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      Message({
        type: "error",
        message: result.error,
      });

      return [];
    } else {
      const { data } = state;
      let newRole = result.data.data.map((item) => {
        if (data.translate[item.name]) {
          item.translate = data.translate[item.name];
        } else {
          item.translate = item.name;
        }

        return item;
      });

      commit("changeRoles", {
        loading: false,
        roles: newRole,
      });

      return newRole || [];
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
