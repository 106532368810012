import ApiService from "@/api/ApiService";
import axios from "axios";
import ApiCooperativeService from "@/api/ApiCooperativeService";

class CanteenRepository {
	constructor() {
		this.apiService = ApiService;
		this.apiCooperativeService = ApiCooperativeService;
		this.cancelTokenSource = null;
		this.mock = 'http://localhost:3000/api';
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getCanteen(params) {
		return await this.apiService.get(`/school/canteen`, { params });
	}

	async getValueQrCode(params) {
		return await this.apiService.get(`/payment/canteen/qrmerchant/print`, { params });
	}

}

export default new CanteenRepository();
