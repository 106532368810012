import { ApiPostMultipart, ApiDeleteRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    exam_template_id: "",
    instruction_id: "",
    form: {
      index: null,
      id: null,
      exam_template_id: null,
      exam_instruction_id: null,
      type: 1,
      type_old: 1,
      format: "",
      image: null,
      imagePreview: null,
      question: "",
      questionText: "",
      answer: [],
      answer2: [],
      tagInput: "",
      tags: [],
    },
    formEdit: [],
    formatQuestion: [
      {
        value: 1,
        label: "Pilihan ganda (pilih jawaban benar)",
      },
      {
        value: 2,
        label: "Pilihan ganda (pilih jawaban dengan gambar)",
      },
      {
        value: 3,
        label: "Pilihan ganda (pilih gambar benar)",
      },
    ],
    formRules: {},
    errors: [],
    loading: false,
  },
};

const mutations = {
  changeUjianSoal(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async submitQuestion({ commit, state }) {
    const { data } = state;

    const formData = new FormData();
    formData.append("_method", "post");
    formData.append("exam_template_id", data.form.exam_template_id);
    formData.append("exam_instruction_id", data.form.exam_instruction_id);
    formData.append("question", data.form.question);
    if (typeof data.form.image != "string" && data.form.image != null) {
      formData.append("image", data.form.image);
    }

    let endPoint = `/school/exam/akm`;
    if (parseInt(data.form.type) === 1) {
      endPoint += `/multiple-choice/question`;
    } else if (parseInt(data.form.type) === 2) {
      // formData.append("score", 0);
      if (parseInt(data.form.format) === 1) {
        endPoint += `/essay`;
      } else if (parseInt(data.form.format) === 2) {
        endPoint += `/short-essay-num`;
        formData.append("type", "num");
      } else if (parseInt(data.form.format) === 3) {
        endPoint += `/short-essay-word`;
        formData.append("type", "word");
      } else {
        endPoint += `/short-essay`;
      }
    } else if (parseInt(data.form.type) === 3) {
      endPoint += `/statement/question`;
    } else if (parseInt(data.form.type) === 4) {
      endPoint += `/pair/question`;
    }

    if (data.form.id) {
      formData.set("_method", "put");
      endPoint += `/${data.form.id}`;
      if (data.form.image === null) {
        formData.append("remove_image", true);
      }
      if (parseInt(data.form.type) === 2) {
        if (parseInt(data.form.format) === 2) {
          formData.append("answer", parseInt(data.form.answer[0].answer));
        } else if (parseInt(data.form.format) === 3) {
          formData.append("answer", data.form.answer[0].answer.toUpperCase());
        }
      }
    } else {
      for (let i = 0; i < data.form.answer.length; i++) {
        // ANSWER MULTIPLE CHOICE
        if (parseInt(data.form.type) === 1) {
          formData.append(
            "choices[" + i + "][is_true]",
            data.form.answer[i].is_true ? 1 : 0,
          );
          formData.append("choices[" + i + "][index]", i);
          formData.append("choices[" + i + "][answer]", "");

          if (parseInt(data.form.format) != 3) {
            formData.set(
              "choices[" + i + "][answer]",
              data.form.answer[i].answer,
            );
          }

          if (parseInt(data.form.format) != 1) {
            formData.append(
              "choices[" + i + "][image]",
              data.form.answer[i].image,
            );
          }
        }

        // ANSWER STATEMENT
        if (parseInt(data.form.type) === 3) {
          formData.append(
            "statements[" + i + "][is_true]",
            data.form.answer[i].is_true ? 1 : 0,
          );
          formData.append("statements[" + i + "][index]", i);
          formData.append(
            "statements[" + i + "][answer]",
            data.form.answer[i].is_true ? "benar" : "salah",
          );
          formData.append("statements[" + i + "][show_false]", 0);

          if (parseInt(data.form.format) === 2) {
            formData.set(
              "statements[" + i + "][answer]",
              data.form.answer[i].answer,
            );
            formData.set("statements[" + i + "][show_false]", 1);
          } else if (parseInt(data.form.format) === 3) {
            //formData.append("statements[" + i + "][answer]"data.form.answer[i].is_true ? "benar" : "salah");
            formData.append("statements[" + i + "][answer]", "");
            formData.append(
              "statements[" + i + "][image]",
              data.form.answer[i].image,
            );
            formData.append("statements[" + i + "][show_false]", 1);
          } else if (parseInt(data.form.format) === 4) {
            formData.set(
              "statements[" + i + "][answer]",
              data.form.answer[i].answer,
            );
          }
        }

        // ANSWER PAIR
        if (parseInt(data.form.type) === 4) {
          formData.append(
            "pairs[" + i + "][statement_1]",
            data.form.answer[i].answer,
          );
          formData.append("pairs[" + i + "][index]", i);
          formData.append(
            "pairs[" + i + "][statement_2]",
            data.form.answer2[i].answer,
          );
          if (parseInt(data.form.format) === 2) {
            formData.set("pairs[" + i + "][statement_1]", "");
            formData.set("pairs[" + i + "][statement_2]", "");
            formData.append(
              "pairs[" + i + "][image_1]",
              data.form.answer[i].image,
            );
            formData.append(
              "pairs[" + i + "][image_2]",
              data.form.answer2[i].image,
            );
          }
        }

        // ANSWER ESSAY
        if (parseInt(data.form.type) === 2) {
          if (parseInt(data.form.format) === 2) {
            formData.append("answer", parseInt(data.form.answer[i].answer));
          } else if (parseInt(data.form.format) === 3) {
            formData.append("answer", data.form.answer[i].answer.toUpperCase());
          }
        }
      }
    }

    let result = await ApiPostMultipart(`${endPoint}`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeUjianSoal", {
          errors: errors,
          loading: false,
        });
      }
      return false;
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
      commit("changeUjianSoal", {
        errors: [],
        loading: false,
        formEdit: result.data.data,
      });

      return result.data.data;
    }
  },

  async submitAnswer({ commit }, payload) {
    let formData = new FormData();
    formData.append("_method", "post");
    formData.append("exam_question_id", payload.question.id);
    if (payload.item.id) {
      formData.set("_method", "put");
    }

    let endPoint = `/school/exam/akm`;

    // ANSWER MULTIPLE CHOICE
    if (parseInt(payload.question.type) === 1) {
      endPoint += `/multiple-choice/answer`;
      if (payload.item.id) {
        endPoint += `/${payload.item.id}`;
      }

      formData.append("is_true", payload.item.is_true ? 1 : 0);
      formData.append("answer", "");

      if (payload.question.format != 3) {
        formData.set("answer", payload.item.answer);
      }

      if (payload.question.format != 1) {
        if (
          typeof payload.item.image != "string" &&
          payload.item.image != null
        ) {
          formData.append("image", payload.item.image);
        }
      }
    }

    // ANSWER STATEMENT
    if (parseInt(payload.question.type) === 3) {
      endPoint += `/statement/answer`;
      if (payload.item.id) {
        endPoint += `/${payload.item.id}`;
      }

      formData.append("is_true", payload.item.is_true ? 1 : 0);
      formData.append("answer", "");
      formData.append("show_false", 0);

      if (payload.question.format === 2) {
        formData.set("answer", payload.item.answer);
        formData.set("show_false", 1);
      } else if (payload.question.format === 3) {
        formData.append("answer", payload.item.is_true ? "benar" : "salah");
        if (
          typeof payload.item.image != "string" &&
          payload.item.image != null
        ) {
          formData.append("image", payload.item.image);
        }
      } else if (payload.question.format === 4) {
        formData.set("answer", payload.item.answer);
      }
    }

    // ANSWER PAIR
    if (parseInt(payload.question.type) === 4) {
      endPoint += `/pair/answer`;
      if (payload.item.id) {
        endPoint += `/${payload.item.id}`;
      }

      formData.append("statement_1", payload.item.answer);
      formData.append("statement_2", payload.item2.answer);
      if (payload.question.format === 2) {
        formData.set("statement_1", "");
        formData.set("statement_2", "");
        if (
          typeof payload.item.image != "string" &&
          payload.item.image != null
        ) {
          formData.append("image_1", payload.item.image);
        }
        if (
          typeof payload.item2.image != "string" &&
          payload.item2.image != null
        ) {
          formData.append("image_2", payload.item2.image);
        }
      }
    }

    let result = await ApiPostMultipart(`${endPoint}`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeUjianSoal", {
          errors: errors,
          loading: false,
        });
      }
      return false;
    } else {
      commit("changeUjianSoal", {
        errors: [],
        loading: false,
      });

      return result.data.data;
    }
  },

  async deleteQuestionAnswer({ commit }, payload) {
    let result = await ApiDeleteRequest(`${payload.endPoint}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeUjianSoal", {
          errors: errors,
          loading: false,
        });
      }
      return false;
    } else {
      Message({
        type: "success",
        message: "Jawaban berhasil dihapus",
      });

      commit("changeUjianSoal", {
        errors: [],
        loading: false,
      });

      return true;
    }
  },

  async updateScore({ commit }, payload) {
    let result = await ApiPostMultipart(
      `${payload.endPoint}`,
      payload.formData,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeUjianSoal", {
          errors: errors,
          loading: false,
        });
      }
      return false;
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
      commit("changeUjianSoal", {
        errors: [],
        loading: false,
      });

      return result.data.data;
    }
  },

  async deleteQuestion({ commit }, payload) {
    let result = await ApiDeleteRequest(`${payload.endPoint}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeUjianSoal", {
          errors: errors,
          loading: false,
        });
      }
      return false;
    } else {
      Message({
        type: "success",
        message: "Soal berhasil dihapus",
      });

      commit("changeUjianSoal", {
        errors: [],
        loading: false,
      });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
