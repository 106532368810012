import {
  ApiDeleteRequest,
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
} from "@/utils/Api";
import {
  GlobalHandleErrorApi,
  HandlerNotification,
  HandlerPageStartEnd,
} from "@/utils/Helpers";

const state = {
  data: {
    columns: [
      {
        prop: "name",
        label: "Jenis Pemanggilan",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "created_at",
        label: "TglBuat",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "updated_at",
        label: "TglUbah",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    formData: {
      id: "",
      name: "",
    },
    dataAll: {
      items: [],
      loading: false,
    },
    formDataDetail: {},
    items: [],
    filter: "",
    errors: [],
    limit: 10,
    page: 1,
    total: 0,
    creator: null,
    updater: null,
    sort_field: "created_at",
    sort_dir: "desc",
    currentStatus: "Active",
    processAction: "read",
    isProcess: false,
    showmodalAddForm: false,
    loading: false,
  },
};

const mutations = {
  changeKlasKonselingPengaturan(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKlasKonselingPengaturanFormData(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeKlasKonselingPengaturanFormDataDetail(state, payload) {
    state.data.formDataDetail = Object.assign(
      {},
      state.data.formDataDetail,
      payload,
    );
  },
  changeKlasKonselingPengaturanDataAll(state, payload) {
    state.data.dataAll = Object.assign({}, state.data.dataAll, payload);
  },
};

const actions = {
  handlerLoading({ commit }, payload = null) {
    const property = {};
    property.loading = payload === null || payload ? true : false;
    commit("changeKlasKonselingPengaturan", property);
  },
  async getDataHandling({ commit }, payload) {
    commit("changeKlasKonselingPengaturan", { loading: true });
    const params = {};
    const { data } = state;
    if (data.filter) {
      params.q = data.filter;
    }
    params.limit = payload ? payload.limit ?? data.limit : data.limit;
    let resPage = HandlerPageStartEnd(data.page, data.items.length, data.limit);
    if (data.processAction === "update") {
      resPage = data.page;
    }
    params.page = payload ? payload.page || resPage : resPage;
    const result = await ApiGetRequest(`klaskonseling/school-handling`, params);
    // const result = await import("@/db/handling.json");
    if (result.error) {
      commit("changeKlasKonselingPengaturan", { loading: false });
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    if (result.data === "") {
      HandlerNotification(
        "error",
        "Response Jenis Pemanggilan Tidak ditemukan, Hubungi admin.",
      );
      commit("changeKlasKonselingPengaturan", {
        loading: false,
      });
      return;
    }
    commit("changeKlasKonselingPengaturan", {
      loading: false,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },
  async postDataPengaturan({ state, dispatch, commit }) {
    if (await dispatch("validasiFormData")) {
      return;
    }
    dispatch("handlerLoading");
    const { data } = state;
    let result;
    const formDataRequest = {
      name: data.formData.name,
    };
    if (data.formDataDetail.id) {
      result = await ApiPutRequest(
        `klaskonseling/school-handling/${data.formDataDetail.id}`,
        formDataRequest,
      );
    } else {
      result = await ApiPostRequest(
        `klaskonseling/school-handling`,
        formDataRequest,
      );
    }
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      if (result.errors) {
        let resError = GlobalHandleErrorApi(result.errors, "string");
        HandlerNotification("error", resError);
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    HandlerNotification();
    dispatch("getDataHandling");
    dispatch("resetFormData");
    dispatch("handlerLoading", false);
    if (data.processAction === "update") {
      commit("changeKlasKonselingPengaturan", {
        showmodalAddForm: false,
      });
    }
  },
  async deleteDataPengaturan({ state, dispatch }) {
    const { data } = state;
    if (!data.formDataDetail.id) {
      HandlerNotification("id");
      return;
    }
    dispatch("handlerLoading");
    let result;
    result = await ApiDeleteRequest(
      `klaskonseling/school-handling/${data.formDataDetail.id}`,
    );
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      if (result.code === 512) {
        HandlerNotification("permanen");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    HandlerNotification();
    await dispatch("getDataHandling");
    dispatch("handlerLoading", false);
  },
  changePage({ commit, dispatch }, payload) {
    commit("changeKlasKonselingPengaturan", { page: payload ?? 1 });
    dispatch("getDataHandling");
  },
  handlerOnCloseModal({ commit }) {
    commit("changeKlasKonselingPengaturan", {
      showmodalAddForm: false,
      processAction: `read`,
    });
    commit("changeKlasKonselingPengaturanFormDataDetail", {
      id: "",
    });
  },
  resetFormData({ commit }, payload = null) {
    if (!payload) {
      commit("changeKlasKonselingPengaturanFormData", {
        id: "",
        name: "",
      });
    } else {
      commit("changeKlasKonselingPengaturanFormDataDetail", {});
    }
  },
  validasiFormData({ state }) {
    let d = state.data.formData;
    let noValidate = 0;
    let msg = "";
    if (d.name === "") {
      noValidate++;
      msg += "Jenis Pemanggilan Belum Diisi";
    }
    if (!noValidate) {
      return false;
    }
    HandlerNotification("error", msg.replace(/,\s*$/, ""));
    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
