import KioskService from "@/services/KioskService";
import {DUPLICATE_CALL_SERVER_MESSAGE, ERROR_NETWORK, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import {Message} from "element-ui";
import Vue from "vue";
import {isServerErrorOrNoInternet} from "@/utils/Helpers";

const state = {
	data: {
		paramsDeviceId: null,
		detailDevice: {
			"id": "",
			"name": "",
			"token": "",
			"image_published": 0,
			"image_unpublish": 0,
			"video_published": 0,
			"video_unpublish": 0,
			"count_menu": 0,
			"isOnline": false
		},
		showModalForm: false,
		loading: false,
		errorDataItems: null,
		items: [],
		total: 0,
		loadingGenerateToken: false,
		loadingForceLogout: false,
		formData: {
			id: "",
			name: ""
		},
		formDataRules: {
			name: [
				{
					required: true,
					message: "Nama alat harus diisi!",
					trigger: "blur",
				},
			],
		},
		subMenuTabs: [
			{
				name: "GAMBAR",
				active: true,
			},
			{
				name: "VIDEO",
				active: false,
			},
			{
				name: "WEBSITE",
				active: false,
			},
		],
	},
}

const mutations = {
	SET_DEVICE_ID(state, payload) {
		state.data.paramsDeviceId = payload
	},
	SET_DETAIL_DEVICE(state, payload) {
		state.data.detailDevice = payload
	},
	changeControlKioskFormData(state, payload) {
		state.data.formData = Object.assign({}, state.data.formData, payload)
	},
	changeControlKiosk(state, payload) {
		state.data = Object.assign({}, state.data, payload)
	},
	changeControlKioskLoading(state, payload) {
		state.data.loading = payload
	},
	changeControlKioskLoadingGenerateToken(state, payload) {
		state.data.loadingGenerateToken = payload
	},
	changeControlKioskLoadingForceLogout(state, payload) {
		state.data.loadingForceLogout = payload
	},
	changeControlKioskItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
	UPDATE_ITEMS_DEVICE(state, rowData) {
		let allItems = state.data.items || []
		let index = allItems.findIndex(item => item.id === rowData.id)

		if (index !== -1) {
			state.data.items[index] = JSON.parse(JSON.stringify(rowData))
			Vue.set(state.data.items, index, JSON.parse(JSON.stringify(rowData)))
		}
	},
	UPDATE_ITEMS_DEVICE_ISONLINE(state, data) {
		let allItems = state.data.items || []
		let index = allItems.findIndex(item => item.id === data.deviceId)
		if (index !== -1) {
			Vue.set(state.data.items, index, {
				...state.data.items[index],
				isOnline: data.value
			})
		}
	},
	ADD_ITEMS_DEVICE(state, newItem) {
		// Pastikan state.data.items sudah ada
		if (!state.data.items) {
			state.data.items = [];
		}

		// Tambahkan item baru ke dalam array items
		state.data.items.push(JSON.parse(JSON.stringify(newItem)));
	}
}

const actions = {

	async setSubMenuTab({commit, state}, routeName) {

		let tabName = ""
		if (routeName === "ControlKioskDeviceImages") {
			tabName = "GAMBAR"
		} else if (routeName === "ControlKioskDeviceVideos") {
			tabName = "VIDEO"
		} else if (routeName === "ControlKioskDeviceWebsite") {
			tabName = "WEBSITE"
		}

		const subMenuTabs = state.data.subMenuTabs

		commit("changeControlKiosk", {
			subMenuTabs: subMenuTabs.map(item => ({
				...item,
				active: item.name === tabName
			}))
		})
	},

	setDetailDeviceById({commit, state}) {
		const {paramsDeviceId, items} = state.data
		const result = items.find((item) => item.id === paramsDeviceId)
		if (result) {
			commit("SET_DETAIL_DEVICE", result)
		} else {
			commit("SET_DETAIL_DEVICE", {
				"id": "",
				"name": "",
				"token": "",
				"image_published": 0,
				"image_unpublish": 0,
				"video_published": 0,
				"video_unpublish": 0,
				"count_menu": 0,
				"isOnline": false
			})
		}
	},

	isDeviceNameExist({state}, deviceName) {
		if (!deviceName) {
			return false
		}
		const names = state.data.items.map(item => item.name.toLowerCase());
		return names.includes(deviceName.toLowerCase());
	},

	isDeviceIdExist({state}, deviceId) {
		if (!deviceId) {
			return false
		}

		const names = state.data.items.map(item => item.id);
		return names.includes(deviceId);
	},

	onShowModalForm({commit}, isOpen) {
		commit("changeControlKiosk", {
			showModalForm: isOpen
		})
	},

	async fetchDeviceControlKiosk({commit, state}) {

		if (state.data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		try {
			commit("changeControlKioskItemsError", null)
			commit("changeControlKioskLoading", true)
			const service = new KioskService()
			const result = await service.kioskDeviceGet()

			if (result.data && result.data.data) {
				commit("changeControlKiosk", {
					items: result.data.data
				})
			}
		} catch (e) {
			commit("changeControlKiosk", {
				items: []
			})

			if (e.status >= 500) {
				commit("changeControlKioskItemsError", "Data KiosK: " + SERVER_ERROR_MESSAGE)
			} else if (e.code === "ERR_NETWORK") {
				commit("changeControlKioskItemsError", `Data KiosK: ${ERROR_NETWORK}`)
			} else if (e.status >= 400) {
				commit("changeControlKioskItemsError", `Data KiosK: ${e.error}`)
				Message({
					type: "error",
					message: e.error,
				})
			}
		} finally {
			commit("changeControlKioskLoading", false)
		}
	},

	async requestGenerateTokenDeviceControlKiosk({commit, state}, deviceId) {

		if (state.data.loadingGenerateToken) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		try {
			commit("changeControlKioskLoadingGenerateToken", true)
			const service = new KioskService()
			const result = await service.kioskDeviceGenerateTokenGet(deviceId)

			commit("UPDATE_ITEMS_DEVICE", result.data.data)
		} catch (e) {
			let message = e.error
			if (e.status >= 500) {
				message = SERVER_ERROR_MESSAGE
			} else if (e.code === "ERR_NETWORK") {
				message = ERROR_NETWORK
			}
			Message({
				type: "error",
				message: message,
			})
		} finally {
			commit("changeControlKioskLoadingGenerateToken", false)
		}
	},

	async requestForceLogoutDeviceControlKiosk({commit, state}, deviceId) {

		if (state.data.loadingForceLogout) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		try {
			commit("changeControlKioskLoadingForceLogout", true)
			const service = new KioskService()
			await service.deleteForceLogoutKioskDeviceDelete(deviceId)
			commit("UPDATE_ITEMS_DEVICE_ISONLINE", {deviceId, value: false})
		} catch (e) {
			if (isServerErrorOrNoInternet(e)) {
				return false
			}

			let message = e.error
			Message({
				type: "error",
				message: message,
			})
		} finally {
			commit("changeControlKioskLoadingForceLogout", false)
		}
	},

	async postDeviceControlKiosk({commit, state, dispatch}) {

		const checkDeviceName = await dispatch("isDeviceNameExist", state.data.formData.name)
		console.log(checkDeviceName)
		if (checkDeviceName) {
			Message({
				type: "warning",
				message: "Nama sudah digunakan!",
			})
			return
		}

		if (state.data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		try {
			commit("changeControlKioskLoading", true)
			const service = new KioskService()
			const result = await service.kioskDevicePost(state.data.formData)

			commit("ADD_ITEMS_DEVICE", result.data.data)
			commit("changeControlKioskFormData", {
				"id": "",
				"name": "",
				"token": "",
				"image_published": 0,
				"image_unpublish": 0,
				"video_published": 0,
				"video_unpublish": 0,
				"count_menu": 0,
				"isOnline": false
			})
			return true
		} catch (e) {
			let message = e.error
			if (e.status >= 500) {
				message = SERVER_ERROR_MESSAGE
			} else if (e.code === "ERR_NETWORK") {
				message = ERROR_NETWORK
			}
			Message({
				type: "error",
				message: message,
			})
			return false
		} finally {
			commit("changeControlKioskLoading", false)
		}
	},

}

const getters = {
	listDeviceGetter: state => {
		if (!state.data.items.length) {
			return []
		}

		return state.data.items.sort((a, b) => a.name.localeCompare(b.name))
	},
	detailDeviceIsExistGetter: state => {
		const {paramsDeviceId, items} = state.data
		if (!items.length) {
			return false
		}

		const result = items.findIndex((item) => item.id === paramsDeviceId)
		return result !== -1;
	},
	detailDeviceNameGetter: state => {
		const {detailDevice, paramsDeviceId} = state.data
		if (!detailDevice.id) {
			return `ID ${paramsDeviceId} not found`
		}

		let online = detailDevice.isOnline ? "Online" : "Offline"
		return `${detailDevice.name} ${online}`
	},
	detailDeviceIdGetter: state => {
		const {detailDevice} = state.data
		if (!detailDevice.id) {
			return ``
		}

		return `#${detailDevice.id} `
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
