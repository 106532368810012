import {
  ApiGetRequest,
  ApiPostRequest,
  // ApiDeleteRequest,
  // ApiPutRequest,
  // ApiGetRequestDepoPayment,
  ApiNotification,
} from "@/utils/Api";
import { Message } from "element-ui";
import moment from "moment";
import { _ } from "vue-underscore";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    prosesSubmitTryout: false,
    pageTableStudent: 1,
    pageTableChooseStudent: 1,
    page_history_submission_mapel: 1,
    page_history_submission_akm: 1,
    totalTableStudent: 0,
    totalTableChooseStudent: 0,
    total_history_submission_mapel: 0,
    total_history_submission_akm: 0,
    limit: 10,
    limit_history_mapel: 10,
    limit_history_akm: 10,
    search: "",
    filter_jenjang: "",
    filter_matapelajaran: "",
    filter_status: "",
    filter_jurusan: "",
    filter_kelas: "",
    check_voucher: false,
    show_modal_detail_voucher: false,
    student_quota: null,
    voucher_list: [],
    subject_from_grade: [],
    detail_voucher: [],
    formMapel: {
      is_voucher: "",
      name: "",
      email: "",
      type: "Tryout Mapel",
      no_telp: "",
      submission_date: "",
      jam: "",
      jenjang: null,
      subject_id: null,
      student: [],
      total_student: 0,
      school_name: "",
      profession: "",
    },
    formAKM: {
      is_voucher: "",
      name: "",
      email: "",
      type: "Tryout Akm",
      no_telp: "",
      submission_date: "",
      jam: "",
      jenjang: null,
      student: [],
      total_student: 0,
      school_name: "",
      profession: "",
    },
    ruleFormMapel: {
      name: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      no_telp: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      submission_date: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      subject_id: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      jenjang: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      total_student: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      jam: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
    },
    ruleFormAKM: {
      name: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      no_telp: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      submission_date: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      jenjang: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      total_student: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      jam: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
    },
    multipleSelection: [],
    checkAll: false,
    data_nisn: [],
    columns_table_student: [
      {
        type: "selection",
        prop: "select",
      },
      {
        prop: "student",
        label: "Peserta",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "nisn",
        label: "NIS/NISN",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "classroom",
        label: "Kelas",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_table_choose_student: [
      {
        prop: "nisn",
        label: "NIS/NISN",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "classroom",
        label: "Kelas",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_submission_detail_akm: [
      {
        prop: "tipe",
        label: "Tipe",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "jenjang",
        label: "Jenjang",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },

      {
        prop: "jumlah_kelas",
        label: "Jumlah Kelas",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "pelajar",
        label: "Pelajar",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "waktu",
        label: "Waktu Pengajuan",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
    ],
    columns_submission_detail_mapel: [
      {
        prop: "tipe",
        label: "Tipe",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "jenjang",
        label: "Jenjang",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "mapel",
        label: "Mapel",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "jumlah_kelas",
        label: "Jumlah Kelas",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "pelajar",
        label: "Pelajar",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "waktu",
        label: "Waktu Pengajuan",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
    ],
    columns_submission_history: [
      {
        prop: "pemesanan",
        label: "Pemesan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "telepon",
        label: "Telepon",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "email",
        label: "Email",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "sekolah",
        label: "Sekolah",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "waktu",
        label: "Waktu Pengajuan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
    ],
    columns_submission_history_two: [
      {
        prop: "tipe",
        label: "Tipe",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "jenjang",
        label: "Jenjang",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "mapel",
        label: "Mapel",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "jumlah_kelas",
        label: "Jumlah Kelas",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "pelajar",
        label: "Pelajar",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
    ],
    columns_submission_history_akm: [
      {
        prop: "pemesanan",
        label: "Pemesan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "telepon",
        label: "Telepon",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "email",
        label: "Email",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "sekolah",
        label: "Sekolah",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "waktu",
        label: "Waktu Pengajuan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
    ],
    columns_submission_history_akm_two: [
      {
        prop: "tipe",
        label: "Tipe",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "jenjang",
        label: "Jenjang",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "jumlah_kelas",
        label: "Jumlah Kelas",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "pelajar",
        label: "Pelajar",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
    ],
    columns_submission_history_mapel: [
      {
        prop: "tanggal",
        label: "Tanggal",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "pelajar",
        label: "Peserta",
        minWidth: "80",
        "class-name": "text-center body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "jenjang",
        label: "Jenjang",
        minWidth: "70",
        "class-name": "text-center body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "mapel",
        label: "Mapel",
        minWidth: "140",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "pengaju",
        label: "Pengaju",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "profesi",
        label: "Profesi",
        minWidth: "80",
        "class-name": "text-center body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "140",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "130",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_submission_akm: [
      {
        prop: "tanggal",
        label: "Tanggal",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "pelajar",
        label: "Peserta",
        minWidth: "70",
        "class-name": "text-center body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "jenjang",
        label: "Jenjang",
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "pengaju",
        label: "Pengaju",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "profesi",
        label: "Profesi",
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    history_submission_succes_mapel: [],
    history_submission_succes_mapel_two: [],
    submission_history_mapel: [],
    submission_history_akm: [],
    submission_history_akm_two: [],
    data_history_akm: [],
    item_choose_student: [
      // {
      //   id: 1,
      //   name: "Surya Rakhmat Fadhillah",
      //   nisn: "15410100096/0594287207",
      //   major: "Biologi",
      //   classroom: "X IPA 1",
      // },
    ],
    all_items_user: [],
    item_choose_student_nopaging: [],
    errors: [],
    loading: false,
    pengajuanMapel: {
      is_voucher: "1231amapelgratis",
      type: "Tryout Mapel",
      orderer_name: "Paijo",
      email: "balabal@gmail.com",
      no_telp: "08121212121",
      submission_date: "22/02/2021",
      jenjang: 10,
      subject_id: 1,
      school_name: "",
      student: [
        {
          id: 1,
          kelas: "X - IPA 1",
        },
        {
          id: 2,
          kelas: "X - IPA 1",
        },
      ],
    },
    pengajuanAKM: {
      is_voucher: "1231amapelgratis",
      type: "Tryout AKM",
      orderer_name: "Paijo",
      email: "balabal@gmail.com",
      no_telp: "08121212121",
      submission_date: "22/02/2021",
      jenjang: 10,
      school_name: "",
      student: [
        {
          id: 1,
          kelas: "X - IPA 12222",
        },
        {
          id: 2,
          kelas: "X - IPA 10000",
        },
      ],
    },
    data_mata_pelajaran: [
      {
        type: "SD",
        grade: "4",
        subject: [8, 11, 12, 61, 62],
      },
      {
        type: "SD",
        grade: "5",
        subject: [8, 11, 12, 61, 62],
      },
      {
        type: "SD",
        grade: "6",
        subject: [8, 11, 12, 61, 62],
      },
      {
        type: "SMP",
        grade: "7",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [14, 15, 19, 12, 82, 6, 1, 8, 11, 7, 61, 62],
      },
      {
        type: "SMP",
        grade: "8",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [14, 15, 19, 12, 82, 6, 1, 8, 11, 7, 61, 62],
      },
      {
        type: "SMP",
        grade: "9",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [14, 15, 19, 12, 82, 6, 1, 8, 11, 7, 61, 62],
      },
      {
        type: "SMA",
        grade: "10",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [8, 7, 5, 13, 10, 3, 39, 4, 9, 63, 64],
      },
      {
        type: "SMA",
        grade: "11",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [8, 7, 5, 13, 10, 3, 39, 4, 9, 63, 64],
      },
      {
        type: "SMA",
        grade: "12",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [8, 7, 5, 13, 10, 3, 39, 4, 9, 63, 64],
      },
      {
        type: "SMK",
        grade: "10",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [8, 7, 66, 67, 68],
      },
      {
        type: "SMK",
        grade: "11",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [8, 7, 66, 67, 68],
      },
      {
        type: "SMK",
        grade: "12",
        subject: [389],
        // subject: [389,390,391,392],
        // subject: [8, 7, 66, 67, 68],
      },
    ],
  },
};

const mutations = {
  changeBerandaTryout(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeFormMapel(state, payload) {
    state.data.formMapel = Object.assign({}, state.data.formMapel, payload);
  },
};

const actions = {
  resetFormMapel({ commit }) {
    commit("changeFormMapel", {
      is_voucher: "",
      type: "Tryout Mapel",
      submission_date: "",
      jam: "",
      jenjang: null,
      subject_id: null,
      student: [],
      total_student: 0,
    });
  },
  async getStudentList({ commit, state }, payload = null) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    if (payload) {
      commit("changeBerandaTryout", {
        pageTableStudent: payload,
      });
    }

    const { data } = state;

    let getMapel = await ApiGetRequest(
      `school/training/request/tryout/subject?ids[]=${data.formMapel.subject_id}`,
    );

    let params = {
      page: data.pageTableStudent || 1,
      limit: data.limit,
      q: data.search,
      type: "mapel",
      grade: data.formMapel.jenjang,
      // school_major_id: data.filter_jurusan,
      // school_subject_id: data.formMapel.subject_id,
    };

    if (data.filter_kelas) {
      params["school_class_id"] = data.filter_kelas;
    }

    if (data.filter_jurusan) {
      params["school_major_id"] = data.filter_jurusan;
    }

    let result = await ApiGetRequest(`school/training/student`, params);
    if (result.error) {
      commit("changeBerandaTryout", {
        loading: false,
      });
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      if (!result.data) {
        Message({
          type: "error",
          message: "CROS Error",
        });
        return;
      }

      commit("changeBerandaTryout", {
        loading: false,
        item_choose_student: result.data.data,
        pageTableStudent:
          (result.data.meta && result.data.meta.current_page) || 1,
        totalTableStudent: result.data.meta && result.data.meta.total,
        infoDetail: {
          subject: getMapel.data.data[0].subject,
          jenjang: data.formMapel.jenjang,
        },
      });
    }
  },
  async getStudentListMapelWithoutPagination({ commit, state }) {
    const { data } = state;

    let params = {
      // page: data.pageTableStudent || 1,
      // limit: 0,
      q: data.search,
      type: "mapel",
      // school_major_id: data.filter_jurusan,
      // school_class_id: data.filter_kelas,
      grade: data.formMapel.jenjang,
      // school_subject_id: data.formMapel.subject_id,
    };

    if (data.filter_kelas) {
      params["school_class_id"] = data.filter_kelas;
    }

    if (data.filter_jurusan) {
      params["school_major_id"] = data.filter_jurusan;
    }

    //ketika di ceklis all
    let result = await ApiGetRequest(`school/training/student`, params);
    if (result.error) {
      commit("changeBerandaTryout", {
        loading: false,
      });
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }

      ApiNotification("error", "Gagal menampilkan data absensi kelas");
      return false;
    } else {
      commit("changeBerandaTryout", {
        loading: false,
      });
      return result.data.data;
    }
  },
  async getStudentListAKMWithoutPagination({ commit, state }) {
    const { data } = state;
    let params = {
      q: data.search,
      type: "mapel",
      grade: data.formAKM.jenjang,
    };
    if (data.filter_kelas) {
      params["school_class_id"] = data.filter_kelas;
    }

    if (data.filter_jurusan) {
      params["school_major_id"] = data.filter_jurusan;
    }

    let result = await ApiGetRequest(`school/training/student`, params);
    if (result.error) {
      commit("changeBerandaTryout", {
        loading: false,
      });

      ApiNotification("error", "Gagal menampilkan data absensi kelas");
      return false;
    } else {
      return result.data.data;
    }
  },
  async getStudentListAKM({ commit, state }, payload = null) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    if (payload) {
      commit("changeBerandaTryout", {
        pageTableStudent: payload,
      });
    }
    const { data } = state;

    let params = {
      page: data.pageTableStudent || 1,
      limit: data.limit,
      q: data.search,
      type: "mapel",
      grade: data.formAKM.jenjang,
    };
    if (data.filter_kelas) {
      params["school_class_id"] = data.filter_kelas;
    }

    if (data.filter_jurusan) {
      params["school_major_id"] = data.filter_jurusan;
    }

    let result = await ApiGetRequest(`school/training/student`, params);
    if (result.error) {
      commit("changeBerandaTryout", {
        loading: false,
      });

      ApiNotification("error", "Gagal menampilkan data absensi kelas");
    } else {
      commit("changeBerandaTryout", {
        loading: false,
        item_choose_student: result.data.data,
        pageTableStudent:
          (result.data.meta && result.data.meta.current_page) || 1,
        totalTableStudent: result.data.meta && result.data.meta.total,
      });
    }
  },
  async getDetailStudent({ commit }, payload = null) {
    commit("changeBerandaTryout", {
      loading: true,
    });
    const result = await ApiGetRequest(`school/student/${payload.id || ""}`);
    if (result.error) {
      commit("changeBerandaTryout", {
        loading: false,
        error: result.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail");
    } else {
      commit("changeBerandaTryout", {
        infoDetailUser: result.data.data,
        loading: false,
      });
    }
  },
  async submitPengajuanMapel({ commit, state }) {
    commit("changeBerandaTryout", {
      loading: true,
      history_submission_succes_mapel_two: [],
      history_submission_succes_mapel: [],
    });

    const { data } = state;

    const getDataSubject = await ApiGetRequest(
      `school/training/request/tryout/subject?ids[]=${data.formMapel.subject_id}`,
    );
    moment(data.formMapel.submission_date).format("DD MMM YYYY");
    console.log(`getDataSubject`, getDataSubject.data.data[0].subject);
    const formData = {
      detail: {
        orderer_name: data.formMapel.name,
        email: data.formMapel.email,
        type: "Tryout Mapel",
        is_voucher: data.formMapel.is_voucher,
        no_telp: data.formMapel.no_telp,
        submission_date: data.formMapel.submission_date,
        date: moment(data.formMapel.submission_date).format("YYYY-MM-DD"),
        start: moment(data.formMapel.jam[0]).format("HH:mm"),
        end: moment(data.formMapel.jam[1]).format("HH:mm"),
        total_student: data.formMapel.student.length,
        jenjang: data.formMapel.jenjang,
        subject: {
          id: data.formMapel.subject_id,
          subject_name: getDataSubject.data.data[0].subject,
        },
        subject_id: data.formMapel.subject_id,
        // subject_name: getDataSubject.data.data[0].subject,
        student: data.formMapel.student,
        profession: this.state.user.profession,
      },
    };

    let result = "";

    result = await ApiPostRequest(`school/training/request/tryout`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      if (!result.data) {
        Message({
          type: "error",
          message: "CROS Error",
        });
        commit("changeBerandaTryout", {
          loading: false,
          prosesSubmitTryout: false,
        });
        return false;
      }
      data.history_submission_succes_mapel.push({
        pemesanan: data.formMapel.name,
        telepon: data.formMapel.no_telp,
        email: data.formMapel.email,
        sekolah: data.formMapel.school_name,
        waktu: moment(data.formMapel.submission_date).format("DD MMM YYYY"),
      });

      let data_kelas = [];
      let student_selection = data.formMapel.student || [];
      student_selection.map((item) => {
        if (item && item.current_class) {
          if (item.current_class.class_room) {
            if (!data_kelas.includes(item.current_class.class_room.name)) {
              data_kelas.push(item.current_class.class_room.name);
            }
          }
        }
      });

      data.history_submission_succes_mapel_two.push({
        tipe: data.formMapel.type,
        jenjang: data.formMapel.jenjang,
        mapel: result.data.data.details.subject,
        jumlah_kelas: data_kelas.length,
        pelajar: data.formMapel.total_student || 0,
      });
      commit("changeBerandaTryout", {
        prosesSubmitTryout: true,
        loading: false,
      });

      return true;
    }
  },
  async submitPengajuanAKM({ commit, state }) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    const { data } = state;

    const formData = {
      detail: {
        orderer_name: data.formAKM.name,
        email: data.formAKM.email,
        type: "Tryout Akm",
        is_voucher: data.formAKM.is_voucher,
        no_telp: data.formAKM.no_telp,
        submission_date: data.formAKM.submission_date,
        date: moment(data.formAKM.submission_date).format("YYYY-MM-DD"),
        start: moment(data.formAKM.jam[0]).format("HH:mm"),
        end: moment(data.formAKM.jam[1]).format("HH:mm"),
        jenjang: data.formAKM.jenjang,
        student: data.formAKM.student,
        total_student: data.formAKM.student.length,
        profession: this.state.user.profession,
      },
    };

    let result = "";
    result = await ApiPostRequest(`school/training/request/tryout`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      return true;
    }
  },
  async getDataStaticMapelSukses({ commit, state }) {
    commit("changeBerandaTryout", {
      loading: true,
      history_submission_succes_mapel_two: [],
      history_submission_succes_mapel: [],
    });

    const { data } = state;
    let result = await ApiGetRequest(
      `school/subject/${data.formMapel.subject_id}`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      data.history_submission_succes_mapel.push({
        pemesanan: data.formMapel.name,
        telepon: data.formMapel.no_telp,
        email: data.formMapel.email,
        sekolah: data.formMapel.school_name,
        waktu: moment(data.formMapel.submission_date).format("DD MMM YYYY"),
      });
      let data_kelas = [];
      let student_selection = data.formMapel.student || [];

      student_selection.map((item) => {
        if (item.student && item.student.current_class) {
          if (item.student.current_class.class_room) {
            if (
              !data_kelas.includes(item.student.current_class.class_room.name)
            ) {
              data_kelas.push(item.student.current_class.class_room.name);
            }
          }
        }
      });

      data.history_submission_succes_mapel_two.push({
        tipe: data.formMapel.type,
        jenjang: data.formMapel.jenjang,
        mapel: result.data.data.details.subject,
        jumlah_kelas: data_kelas.length,
        pelajar: data.formMapel.total_student || 0,
      });

      return true;
    }
  },
  async getDataStaticAKMSukses({ commit, state }) {
    commit("changeBerandaTryout", {
      loading: true,
      submission_history_akm: [],
      submission_history_akm_two: [],
    });

    const { data } = state;
    let result = "";
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      data.submission_history_akm.push({
        pemesanan: data.formAKM.name,
        telepon: data.formAKM.no_telp,
        email: data.formAKM.email,
        sekolah: data.formAKM.school_name,
        waktu: moment(data.formAKM.submission_date).format("DD MMM YYYY"),
      });

      let data_kelas = [];
      let student_selection = data.formAKM.student || [];

      student_selection.map((item) => {
        if (item && item.current_class) {
          if (item.current_class.class_room) {
            if (!data_kelas.includes(item.current_class.class_room.name)) {
              data_kelas.push(item.current_class.class_room.name);
            }
          }
        }
      });

      data.submission_history_akm_two.push({
        tipe: data.formAKM.type,
        jenjang: data.formAKM.jenjang,
        jumlah_kelas: data_kelas.length,
        pelajar: data.formAKM.student.length || 0,
      });

      return true;
    }
  },
  async getDataHistorySubmissionMapel({ commit, state }, payload = null) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    if (payload) {
      commit("changeBerandaTryout", {
        page_history_submission_mapel: payload,
      });
    }

    const { data } = state;

    let params = {
      page: data.page_history_submission_mapel || 1,
      limit: data.limit_history_mapel,
      q: data.search,
      type: "mapel",
    };

    let result = await ApiGetRequest(`school/training/request/tryout`, params);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      commit("changeBerandaTryout", {
        loading: false,
        submission_history_mapel: result.data.data,
        page_history_submission_mapel:
          (result.data.meta && result.data.meta.current_page) || 1,
        total_history_submission_mapel:
          result.data.meta && result.data.meta.total,
      });
    }
  },
  async getDataHistorySubmissionMapelById({ commit }, payload) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/training/request/${payload.id || ""}`,
    );

    if (result.error) {
      commit("changeBerandaTryout", {
        loading: false,
        error: result.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail");
    } else {
      commit("changeBerandaTryout", {
        example_submission_detail_mapel: [
          {
            id: 1,
            tipe: result.data.data.details.type,
            mapel: result.data.data.details.subject,
            jenjang: result.data.data.details.jenjang,
            waktu: result.data.data.details.submission_date,
            pelajar:
              result.data.data.details.total_student != null
                ? result.data.data.details.total_student
                : "-",
          },
        ],
        infoDetailModalMapel: result.data.data,
        loading: false,
      });
    }
  },
  async getDataHistorySubmissionAKM({ commit, state }, payload = null) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    if (payload) {
      commit("changeBerandaTryout", {
        page_history_submission_akm: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page_history_submission_akm,
      limit: data.limit_history_akm,
      q: data.search,
      type: "akm",
    };

    let result = await ApiGetRequest(`school/training/request/tryout`, params);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      commit("changeBerandaTryout", {
        loading: false,
        data_history_akm: result.data.data,
        page_history_submission_akm:
          result.data.meta && result.data.meta.current_page,
        total_history_submission_akm:
          result.data.meta && result.data.meta.total,
      });
    }
  },
  async getDataHistorySubmissionAKMById({ commit }, payload) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/training/request/${payload.id || ""}`,
    );
    if (result.error) {
      commit("changeBerandaTryout", {
        loading: false,
        error: result.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail");
    } else {
      commit("changeBerandaTryout", {
        example_submission_detail_akm: [
          {
            id: 1,
            tipe: result.data.data.details.type,
            jenjang: result.data.data.details.jenjang,
            waktu: result.data.data.details.submission_date,
            pelajar:
              result.data.data.details.total_student != null
                ? result.data.data.details.total_student
                : "-",
          },
        ],
        infoDetailModalAKM: result.data.data,
        loading: false,
      });
    }
  },
  async getVoucherTicket({ commit }) {
    commit("changeBerandaTryout", {
      loading: true,
    });
    let result = await ApiGetRequest(`school/training/voucher`);
    if (result.error) {
      commit("changeBerandaTryout", {
        loading: false,
      });
      ApiNotification("error", "Gagal menampilkan data voucher");
    } else {
      commit("changeBerandaTryout", {
        loading: false,
        voucher_list: result.data.data,
      });
    }
  },
  async getCheckingVoucher({ commit }, payload) {
    if (payload.voucher == "") {
      Message({
        type: "error",
        message: "Tidak ditemukan ",
      });
      return;
    }
    commit("changeBerandaTryout", {
      loading: true,
    });

    let result = await ApiGetRequest(
      `school/training/voucher/${payload.voucher}`,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTryout", {
          errors: errors,
          loading: false,
          check_voucher: false,
        });
        ApiNotification("error", this.errors[0].payment);
      }
    } else {
      commit("changeBerandaTryout", {
        loading: false,
        check_voucher: true,
        student_quota: result.data.quota,
      });
    }
  },
  async getSubjectFromGrade({ commit, state }, payload) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    const { data } = state;

    let params = {};

    let filterMapelByType = _.where(data.data_mata_pelajaran, {
      type: payload.type,
    });

    let filterMapelByGrade = _.findWhere(filterMapelByType, {
      grade: state.data.formMapel.jenjang.toString(),
    });
    if (!filterMapelByGrade) {
      Message({
        type: "error",
        message: "Subject Tidak ditemukan",
      });
      commit("changeBerandaTryout", {
        loading: false,
        subject_from_grade: [],
      });
      commit("changeFormMapel", {
        subject_id: null,
      });
      return;
    }

    if (state.data.formMapel.jenjang) {
      params["ids"] = filterMapelByGrade.subject;
    }

    const result = await ApiGetRequest(
      `school/training/request/tryout/subject`,
      params,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      commit("changeBerandaTryout", {
        loading: false,
        subject_from_grade: result.data.data,
      });
    }
  },
  async getDetailVoucher({ commit }, payload) {
    commit("changeBerandaTryout", {
      loading: true,
    });

    let result = await ApiGetRequest(
      `school/training/voucher/${payload.code}/claimed`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      commit("changeBerandaTryout", {
        loading: false,
        detail_voucher: result.data.data,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
