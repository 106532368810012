import CanteenService from "@/services/CanteenService";

const state = {
	data: {
		isLoading: false,
		walletIdArray: [],
		itemListKantinActiveAll: [],
		detailValueQrCode: {
			walletId: null,
			value: null,
		},
		itemsValueQrCode: [
			{
				walletId: null,
				qrValue: null
			}
		],
		errors: [],
	},
};

const mutations = {
	changeQrCodeKantin(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeLoading(state, payload) {
		state.data.isLoading = payload;
	},
};

const actions = {

	async setValueQrCode({commit, state}, value) {
		commit("changeQrCodeKantin", {
			itemsValueQrCode: [
				...state.data.itemsValueQrCode,
				value
			]
		})
	},

	async fetchValueQrCode({state}, walletId) {

		if (state.data.isLoading) return;

		try {
			const canteenService = new CanteenService()
			const result = await canteenService.valueQrCodeGet(walletId)
			return result.data.data
		} catch (e) {
			console.log("fetchValueQrCode --->", e)
			return null
		}

	},

	async fetchCanteenAll({commit, state}) {

		if (state.data.isLoading) return;

		try {
			commit("changeLoading", true)

			const params = {
				limit: 50,
				page: 1,
				sorField: "name",
				sorDir: "asc"
			}

			const canteenService = new CanteenService()
			const result = await canteenService.canteenGet(params)
			commit("changeQrCodeKantin", {
				itemListKantinActiveAll: result.data.data
			})
		} catch (e) {
			console.log("fetchCanteenAll --->", e)
			return null
		} finally {
			commit("changeLoading", false)
		}

	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
