import store from "@/store/index";

export const KIOSK_STATUS = {
	published: "published",
	unpublished: "unpublished",
}

export const getThumbnailVideo = async (datases) => {
	try {
		// Gunakan Promise.all untuk menunggu semua thumbnail dihasilkan
		const thumbnailPromises = datases
			.map(async (item) => {
				const thumbnail = await getThumbnailFromVideoURL(item.file_path, 1);
				store.commit("controlKioskVideos/UPDATE_LIST_THUMBNAIL", {
					key: item.file_path,
					value: thumbnail,
				});
			});

		// Tunggu hingga semua thumbnail dihasilkan
		await Promise.all(thumbnailPromises);
	} catch (error) {
		console.error("Error while generating thumbnails:", error);
	}
}

export const getThumbnailFromVideoURL = (videoURL, secondThumbnail = 1) => {
	return new Promise((resolve, reject) => {
		const video = document.createElement("video");
		video.src = videoURL;
		video.crossOrigin = "anonymous"; // Pastikan CORS diaktifkan jika video berada di server lain

		video.onloadedmetadata = () => {
			// Pastikan durasi video lebih panjang dari secondThumbnail
			if (video.duration < secondThumbnail) {
				secondThumbnail = video.duration;
			}
			video.currentTime = secondThumbnail;
		};

		video.ontimeupdate = () => {
			if (video.currentTime >= secondThumbnail) {
				const canvas = document.createElement("canvas");
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				const ctx = canvas.getContext("2d");
				ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
				const thumbnail = canvas.toDataURL("image/png");
				resolve(thumbnail);
				video.ontimeupdate = null; // Hentikan pembaruan waktu untuk mencegah callback terus dipanggil
			}
		};

		video.onerror = (error) => {
			reject("Gagal memuat video atau membuat thumbnail.", error);
		};
	});
}
