import ApiService from "@/utils/ApiService";
import { Message } from "element-ui";
import SuratMasukImplement from "@/api/SuratMasukImplement";
import { checkVw } from "@/services/internalService";

const state = {
  data: {
    id: "",
    loading: false,
    loadingPurposeAll: false,
    showmodal: false,
    filter: "",
    filterCategory: "",
    filterCreatorId: "",
    filterDateRange: [],
    sortBy: "agenda_number",
    sortType: "desc",
    items: [],
    itemsAll: [],
    itemsPurposeAll: [],
    limit: 10,
    page: 1,
    lastPage: 1,
    total: 0,
    errors: [],
    typeAction: "read",
    listLimit: [10, 25, 50, 100],
    listCategory: ["UMUM", "MUTASI MASUK", "MUTASI KELUAR"],
    detailFormData: {
      id: 63,
      uuid: "58d5ece59a7a47dd986d57f3c9fba799",
      agenda_number: "0002",
      date: "2024-01-02 11:08:02",
      purpose: "testing tujuan 1",
      subject: "testing perihal 1",
      officer: "mohammad zuz ubaidillah",
      department: "Kabupaten Sidoarjo",
      file: "https://dev.assets.diskola.id/government/agenda/6lv0m9Vqn0xTbjSkFLDdLrXPEEMDioGhPnvOdtHY.pdf",
      creator: {
        id: 99,
        nip: "20231",
        name: "mohammad zuz ubaidillah",
        email: "mohammadzuzubaidillah@diskola.id",
        phone: "089676041493123",
        roles: ["Admin"],
      },
      school: {
        row_id: 103,
        id: "a5a5c3733ea5455aac987f7d283df8a8",
        province_id: "dd6e854771984bc9a94e334717561d22",
        province_name: "Jawa Timur",
        city_id: "0cb421608fd6409db8e1f9e2abc2273f",
        city_name: "Kabupaten Sidoarjo",
        sub_district_id: "fc50e03cd7034891b0c74e5c737271a5",
        sub_district_name: "Balong Bendo",
        name: "ADEMO 3 SMA Surabaya",
        email: "a@gmail.com",
        is_email_verified: false,
        image: "https://dev.assets.diskola.id/sekolah/default.png",
        address: "Dsn. Tambakberas Timur",
        phone: "45549645",
        type: "SMA",
        type_school: "swasta",
        website: "ademo3sma.com",
        headmaster: {},
        has_sales_id: true,
        admission_start: null,
        admission_end: null,
        total_student: 200,
        first_major: null,
        second_major: null,
        accepted_major: null,
        timezone: "+07:00",
        coordinate: {},
        coordinate_radius: null,
        deleted_at: false,
      },
      updated_at: "2024-01-02T04:08:28.000000Z",
      created_at: "2024-01-02T04:08:02.000000Z",
      pivot: {
        school_id: 103,
        government_agenda_id: 63,
        created_at: "2024-01-02T06:33:04.000000Z",
        updated_at: "2024-01-02T06:33:04.000000Z",
        category: null,
        change_category_at: null,
        is_read: 0,
      },
    },
    columns: [
      {
        prop: "agenda_number",
        label: "Surat",
        width: 110,
        fixed: window.innerWidth < 768 ? undefined : "left",
        sortable: true,
      },
      {
        prop: "date",
        label: "Tanggal",
        width: 180,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "purpose",
        label: "Tujuan",
        minWidth: 220,
      },
      {
        prop: "subject",
        label: "Perihal",
        minWidth: 250,
      },
      {
        prop: "from",
        label: "Petugas",
        minWidth: 250,
      },
      {
        prop: "category",
        label: "Kategori",
        width: 180,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(160),
        "class-name": "body-14 col-black-2",
      },
    ],
    formDataDuplicateDocumentSchool: {
      name: "",
      category: "UMUM",
    },
  },
};

const mutations = {
  changeSuratMasuk(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },

  changeLoading(state, payload) {
    state.data.loading = payload;
  },
};

const actions = {
  async getSuratMasuk({ commit, state }, payload = {}) {
    if (!isNaN(payload)) {
      commit("changeSuratMasuk", { page: payload });
    }
    if (payload.page) {
      commit("changeSuratMasuk", { page: payload.page });
    }

    if (payload.sortBy) {
      commit("changeSuratMasuk", {
        sortBy: payload.sortBy,
        sortType: payload.sortType,
      });
    }

    const {
      filterCategory,
      filterDateRange,
      filter,
      page,
      limit,
      sortBy,
      sortType,
      loading,
    } = state.data;

    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    const params = {
      q: filter,
      sort_field: sortBy,
      sort_dir: sortType || "asc",
      limit: limit,
      page: parseInt(page),
      date: filterDateRange,
    };

    if (filterCategory) {
      params.category = filterCategory;
    }

    try {
      commit("changeLoading", true);
      const suratMasukService = new SuratMasukImplement(ApiService);
      const result = await suratMasukService.suratMasuk(params);
      commit("changeLoading", false);

      commit("changeSuratMasuk", {
        items: result?.data?.data || [],
        total: result?.data?.meta?.total || result?.data?.data.length,
        page: result?.data?.meta?.current_page || 1,
        lastPage: result?.data?.meta?.last_page || 1,
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);
      commit("changeSuratMasuk", {
        items: [],
        total: 0,
        page: 1,
        lastPage: 1,
      });
      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;

        Message({
          type: "error",
          message: data.error || "Gagal Mengambil data",
        });
      }
      return false;
    }
  },

  async postDuplicateToDocumentSchool({ commit, state, dispatch }) {
    const { loading, detailFormData } = state.data;
    if (loading) {
      console.log("call enpoint duplicate: STOP");
      return false;
    }

    try {
      commit("changeLoading", true);
      const { name, category } = state.data.formDataDuplicateDocumentSchool;
      const formData = {
        name,
        category,
      };
      const suratMasukService = new SuratMasukImplement(ApiService);
      await suratMasukService.duplicateToDocumentSchoolMutation(
        detailFormData.id,
        formData,
      );
      commit("changeLoading", false);
      commit("changeSuratMasuk", {
        formDataDuplicateDocumentSchool: {},
        detailFormData: {},
      });
      dispatch("getSuratMasuk");
      Message({
        type: "success",
        message: "Berhasil diproses",
      });
      return true;
    } catch (error) {
      commit("changeLoading", false);

      const statusCode = error?.response?.status || 500;
      if (statusCode > 0 && statusCode < 500) {
        const { data } = error.response;
        Message({
          type: "error",
          message: data.error || "Gagagal diproses",
        });
      }
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
