import {requirePermission} from "@/router/index";

export default [
	{
		path: "/konseling",
		name: "produkKonseling",
		component: () =>
			import(
				/* webpackChunkName: "klassign" */
				"../views/Konseling"
				),
		redirect: "/konseling/kamus-poin",
		meta: {
			title: "Konseling | ",
			allowedRole: ["Head Master", "Counseling"],
		},
		children: [
			{
				path: "kamus-poin",
				name: "KlasKonselingKamusPoin",
				component: () => import("../views/Konseling/KamusPoin"),
				meta: {
					title: "Konseling Kamus Poin | ",
					allowedRole: ["Head Master", "Counseling"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "penanganan",
				name: "KlasKonselingPenanganan",
				component: () => import("../views/Konseling/Penanganan"),
				meta: {
					title: "Konseling Penanganan | ",
					allowedRole: ["Head Master", "Counseling"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "rapor",
				name: "KlasKonselingRapor",
				component: () => import("../views/Konseling/Rapor"),
				meta: {
					title: "Konseling Rapor | ",
					allowedRole: ["Head Master", "Counseling"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pengaturan",
				name: "KlasKonselingPengaturan",
				component: () => import("../views/Konseling/Pengaturan"),
				meta: {
					title: "Konseling Pengaturan | ",
					allowedRole: ["Head Master", "Counseling"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "poin-warna",
				name: "KlasKonselingPoinWarna",
				component: () => import("../views/Konseling/PoinWarna"),
				meta: {
					title: "Konseling Poin dan Warna | ",
					allowedRole: ["Head Master", "Counseling"],
				},
				beforeEnter: requirePermission,
			},
		],
	},
	{
		path: "/konseling/rapor/cetak/:user_id",
		name: "KlasKonselingRaporCetak",
		component: () => import("../views/Konseling/Rapor/Cetak"),
		meta: {
			title: "Konseling Rapor Cetak | ",
			allowedRole: ["Head Master", "Counseling"],
		},
		beforeEnter: requirePermission,
	},
]
