import {requirePermission} from "@/router/index";

export default [
	{
		path: "ppdb",
		name: "WebsitePpdb",
		component: () => import(/* webpackChunkName: "ppdb-website" */"../views/WebSchool/WebPpdb"),
		redirect: {name: 'PpdbPamflet'},
		meta: {
			title: "Website PPDB | ",
			allowedRole: [
				"Head Master",
				"Ppdb Committee",
				"School Admin"
			],
		},
		beforeEnter: requirePermission,
		children: [
			{
				path: "pamflet",
				name: "PpdbPamflet",
				component: () => import(/* webpackChunkName: "ppdb-website-pamflet" */"../views/WebSchool/WebPpdb/Pamflet/index.vue"),
				meta: {
					title: "Website PPDB | ",
					allowedRole: [
						"Head Master",
						"Ppdb Committee",
						"School Admin"
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pendaftar",
				name: "PpdbPendaftar",
				component: () => import(/* webpackChunkName: "ppdb-website-pamflet" */"../views/WebSchool/WebPpdb/Pendaftar/index.vue"),
				meta: {
					title: "Website PPDB | ",
					allowedRole: [
						"Head Master",
						"Ppdb Committee",
						"School Admin"
					],
				},
				beforeEnter: requirePermission,
			},
		]
	},
]
