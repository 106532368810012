const state = {
  data: {
    tabMenu: [
      {
        name: "KamusPoin",
        label: "Kamus Poin",
        lock: false,
        url: "/konseling/kamus-poin",
      },
      {
        name: "Penanganan",
        label: "Penanganan",
        lock: false,
        url: "/konseling/penanganan",
      },
      {
        name: "Rapor",
        label: "Rapor",
        lock: false,
        url: "/konseling/rapor",
      },
    ],
  },
};

const mutations = {
  changeKlasKonseling(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
