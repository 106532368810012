import repository from "@/repositories/KioskRepository";
import StrukturErrorService from "@/services/StrukturErrorService";
import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers";
import {KIOSK_STATUS} from "@/services/kioskComposable";

export default class KioskService {
	constructor() {
		this.dataRepository = repository;
		this.allowedStatuses = [KIOSK_STATUS.published, KIOSK_STATUS.unpublished];
	}

	async kioskDeviceGet() {
		try {
			return await this.dataRepository.getKioskDevice({})
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async kioskDeviceGenerateTokenGet(deviceId) {
		if (!deviceId) {
			throw new Error("ID is required");
		}

		try {
			return await this.dataRepository.GetKioskDeviceGenerateToken(deviceId)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async kioskDevicePost(payload) {
		const {name} = payload;

		if (!name) {
			throw new Error("body name is required");
		}

		try {
			const formData = {
				name: name,
			}
			return await this.dataRepository.postKioskDevice(formData)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async deleteForceLogoutKioskDeviceDelete(deviceId) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}

		try {
			return await this.dataRepository.deleteForceLogoutKioskDevice(deviceId)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async imagesKioskByDeviceGet(deviceId, payload) {
		const {limit, page} = payload;
		const params = {
			limit: limit ?? undefined,
			page: page ?? undefined,
		}

		try {
			return await this.dataRepository.getImageKioskByDevice(deviceId, params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async imageKioskByDeviceDelete(deviceId, fileImageId) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}
		if (!fileImageId) {
			throw new Error("file Image ID is required");
		}

		try {
			return await this.dataRepository.deleteImageKioskByDevice(deviceId, fileImageId)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async bulkImageKioskByDevicePost(deviceId, images) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}

		images.forEach(image => {
			if (!this.allowedStatuses.includes(image.status)) {
				throw new Error(`Status is invalid for image index. Allowed statuses are: published, unpublished.`);
			}
		});

		try {
			const formData = new FormData();

			images.forEach((image, index) => {
				formData.append(`images[${index}][file]`, image.file);
				formData.append(`images[${index}][status]`, image.status);
			});

			return await this.dataRepository.postImageKioskByDevice(deviceId, formData)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async imageKioskByDevicePut(deviceId, fileImageId, status) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}
		if (!fileImageId) {
			throw new Error("file Image ID is required");
		}
		if (!status) {
			throw new Error("status is required");
		}

		if (!this.allowedStatuses.includes(status)) {
			throw new Error("Status is invalid. Allowed statuses are: published, unpublished.");
		}

		try {
			const formData = {
				status: status
			}

			return await this.dataRepository.putImageKioskByDevice(deviceId, fileImageId, formData)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	/**
	 * WEBSITE
	 * */
	async videoKioskByDeviceGet(deviceId, payload) {
		const {limit, page} = payload;
		const params = {
			limit: limit ?? undefined,
			page: page ?? undefined,
		}

		try {
			return await this.dataRepository.getVideoKioskByDevice(deviceId, params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async videoKioskByDeviceDelete(deviceId, fileVideoId) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}
		if (!fileVideoId) {
			throw new Error("file Video ID is required");
		}

		try {
			return await this.dataRepository.deleteVideoKioskByDevice(deviceId, fileVideoId)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async videoKioskByDevicePost(deviceId, file) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}

		try {
			const formData = new FormData();

			formData.append(`file`, file);
			formData.append(`status`, KIOSK_STATUS.published);

			return await this.dataRepository.postVideoKioskByDevice(deviceId, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			throw parsedError;
		}
	}

	async videoKioskByDevicePut(deviceId, fileVideoId, status) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}
		if (!fileVideoId) {
			throw new Error("file Video ID is required");
		}
		if (!status) {
			throw new Error("status is required");
		}

		if (!this.allowedStatuses.includes(status)) {
			throw new Error("Status is invalid. Allowed statuses are: published, unpublished.");
		}

		try {
			const formData = {
				status: status
			}

			return await this.dataRepository.putVideoKioskByDevice(deviceId, fileVideoId, formData)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	/**
	 * WEBSITE
	 * */
	async websiteKioskByDeviceGet(deviceId, payload) {
		const {limit, page} = payload;
		const params = {
			limit: limit ?? undefined,
			page: page ?? undefined,
		}

		try {
			return await this.dataRepository.getWebsiteKioskByDevice(deviceId, params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async thumbnailWebsiteKioskByDeviceGet(deviceId) {
		try {
			return await this.dataRepository.getThumbnailWebsiteKioskByDevice(deviceId)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async websiteKioskByDeviceDelete(deviceId, fileWebsiteId) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}
		if (!fileWebsiteId) {
			throw new Error("file Website ID is required");
		}

		try {
			return await this.dataRepository.deleteWebsiteKioskByDevice(deviceId, fileWebsiteId)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async websiteKioskByDevicePost(deviceId, payload) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}

		const {menu, url, sequence, status} = payload
		if (!menu || !url || !sequence || !status) {
			throw new Error("request body not complete");
		}

		try {
			const formData = {
				menu: menu,
				url: url,
				sequence: sequence,
				status: status
			}

			return await this.dataRepository.postWebsiteKioskByDevice(deviceId, formData)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error)
			const parsedError = StrukturErrorService.parseError(error);
			throw parsedError;
		}
	}

	async websiteThumbnailKioskByDevicePost(deviceId, file) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}
		if (!file) {
			throw new Error("file(binary) is required");
		}

		try {
			const formData = new FormData();
			formData.append(`file`, file);

			return await this.dataRepository.postThumbnailWebsiteKioskByDevice(deviceId, formData)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error)
			const parsedError = StrukturErrorService.parseError(error);
			throw parsedError;
		}
	}

	async websiteKioskByDevicePut(deviceId, fileWebsiteId, payload) {
		if (!deviceId) {
			throw new Error("deviceId is required");
		}
		if (!fileWebsiteId) {
			throw new Error("Website ID is required");
		}

		const {menu, url, sequence, status} = payload
		if (!menu || !url || !sequence || !status) {
			throw new Error("request body not complete");
		}

		try {
			const formData = {
				menu: menu,
				url: url,
				sequence: sequence,
				status: status
			}

			return await this.dataRepository.putWebsiteKioskByDevice(deviceId, fileWebsiteId, formData)
		} catch (error) {
			ShowAlertIsServerErrorOrNoInternet(error);
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

}
