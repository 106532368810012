import {
  ApiGetRequest,
  ApiGetRequestDepoPayment,
  ApiNotification,
} from "@/utils/Api";
import { HandlerNotification } from "@/utils/Helpers";
import {
  defaultDetailTransaction,
  STATUSPAYMENT,
} from "@/services/paymentService";
import store from "@/store";
import moment from "moment";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    items: [],
    sortBy: "",
    sortType: "",
    filter: "STORAGEPACKAGE",
    filterDate: null,
    errors: [],
    loading: false,
    loadingTunggakanProduk: false,
    page: 1,
    total: 1,
    limit: 10,
    countTunggakan: [],
    countTunggakanWithNextPlan: {
      monthly: {},
      anually: {},
    },
    columns: [
      {
        prop: "transaction_type",
        label: "Produk",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "paid_at",
        label: "Tanggal",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "expired_at",
        label: "Hingga",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        "class-name": "body-14 col-black-2",
      },
    ],
    pembayaranDepoPayment: {
      items: [],
      sortBy: "",
      sortType: "",
      filter: "",
      filterDate: null,
      filterStatus: "pending",
      errors: [],
      loading: false,
      page: 1,
      total: 1,
      limit: 1000,
      detail: defaultDetailTransaction,
      columns: [
        {
          prop: "created_at",
          label: "Tanggal",
          "class-name": "body-14 col-black-2",
          width: 100,
        },
        {
          prop: "payment_method",
          label: "Pembayaran",
          "class-name": "body-14 col-black-2",
          minWidth: 200,
        },
        {
          prop: "transaction_note",
          label: "Catatan",
          "class-name": "body-14 col-black-2",
          minWidth: 180,
        },
        {
          prop: "transaction_amount",
          label: "Nominal",
          "class-name": "body-14 col-black-2",
          minWidth: 180,
        },
        {
          prop: "expired_date",
          label: "Kedaluwarsa",
          "class-name": "body-14 col-black-2",
          minWidth: 180,
        },
        {
          prop: "status",
          fixed: "right",
          label: "Status",
          "class-name": "body-14 col-black-2",
          width: 170,
        },
        {
          label: "Aksi",
          fixed: "right",
          "class-name": "body-14 col-black-2",
          width: 60,
        },
      ],
    },
    paymentSteps: {
      filterPaymentMethod: "bank",
      filterPaymentMethodTechnique: "atm",
      filterPaymentMethodName: "BANK BCA",
      retail: [
        {
          name: "ALFAMART",
          label: "ALFAMART",
          type: "retail",
          icon: null,
          steps: [
            {
              name: "minimarket",
              label: "Cara Pembayaran melalui Gerai Minimarket",
              labelSort: "Minimarket",
              steps: [
                {
                  text: `Pilih pembayaran melalui Alfamart/ Alfamidi/ Dan+Dan/ Lawson`,
                },
                { text: `Catat atau print Kode pembayaran` },
                {
                  text: `Bawa Kode pembayaran tersebut ke gerai Alfamart/ Alfamidi/ Dan+Dan/ Lawson`,
                },
                {
                  text: `Informasikan kepada kasir pembayaran menggunakan LINKITA`,
                },
                { text: `Berikan Kode pembayaran Ke kasir` },
                { text: `Kasir akan memasukkan Kode pembayaran` },
                { text: `Bayar sesuai nominal` },
                { text: `Ambil tanda terima pembayaran` },
                { text: `Selesai` },
              ],
            },
          ],
        },
        {
          name: "INDOMARET",
          label: "INDOMARET",
          type: "retail",
          icon: null,
          steps: [
            {
              name: "minimarket",
              label: "Cara Pembayaran melalui Gerai Minimarket",
              labelSort: "Minimarket",
              steps: [
                { text: `Pilih pembayaran melalui Indomaret` },
                { text: `Catat atau print Kode pembayaran` },
                { text: `Bawa Kode pembayaran tersebut ke gerai Indomaret` },
                { text: `Informasikan LinKita Ke kasir` },
                { text: `Berikan Kode pembayaran Ke kasir` },
                { text: `Kasir akan memasukkan Kode pembayaran` },
                { text: `Bayar sesuai nominal` },
                { text: `Ambil tanda terima pembayaran` },
                { text: `Selesai` },
              ],
            },
          ],
        },
      ],
      bank: [
        {
          name: "BANK BCA",
          label: "BANK BCA",
          type: "bank",
          icon: null,
          steps: [
            {
              name: "atm",
              label: "Cara Pembayaran melalui ATM",
              labelSort: "ATM",
              steps: [
                { text: `Input kartu ATM dan PINAnda` },
                { text: `Pilih Menu transaksi lainya` },
                { text: `Pilih Transfer` },
                { text: `Input Nomor Virtual Account ,misal 12345678xxxx` },
                { text: `Pilih Benar` },
                { text: `Pilih Iya` },
                { text: `Ambil Bukti bayarAnda` },
                { text: `Selesai` },
              ],
            },
            {
              name: "mobile",
              label: "Cara Pembayaran melalui Mobile Banking",
              labelSort: "Mobile Banking",
              steps: [
                { text: `Login Mobile Banking` },
                { text: `Pilih m-Transfer` },
                { text: `Pilih BCA Virtual Account` },
                { text: `Input Nomor Virtual Account ,misal 12345678xxxx` },
                { text: `Klik Send` },
                { text: `Informasi virtual Account akan ditampilkan` },
                { text: `Klik OK` },
                { text: `Input PIN Mobile banking` },
                { text: `Bukti bayar ditampilkan` },
                { text: `Selesai` },
              ],
            },
            {
              name: "internet",
              label: "Cara Pembayaran melalui Internet Banking",
              labelSort: "Internet Banking",
              steps: [
                { text: `Login Internet Banking` },
                { text: `Pilih Transaksi dana` },
                { text: `Pilih Transfer ke BCA Virtual Account` },
                { text: `Input Nomor Virtual Account ,misal 12345678xxxx` },
                { text: `Klik Lanjutkan` },
                { text: `Input Respon KeyBCA Appli 1` },
                { text: `Klik Kirim` },
                { text: `Bukti bayar ditampilkan` },
                { text: `Selesai` },
              ],
            },
          ],
        },
        {
          name: "BANK BNI",
          label: "BANK BNI",
          type: "bank",
          icon: null,
          steps: [
            {
              name: "atm",
              label: "Cara Pembayaran melalui ATM",
              labelSort: "ATM",
              steps: [
                { text: `Masukkan kartu Anda` },
                { text: `Pilih Bahasa` },
                { text: `Masukkan PIN ATM Anda` },
                { text: `Pilih Menu lainnya` },
                { text: `Pilih Transfer` },
                {
                  text: `Pilih Jenis rekening  yang akan Anda gunakan (Contoh; “Dari Rekening Tabungan”)`,
                },
                { text: `Pilih Virtual Account Billing` },
                {
                  text: `Masukkan Nomor Virtual Account Anda, contoh: 123456789012XXXX`,
                },
                {
                  text: `Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi`,
                },
                {
                  text: `Konfirmasi, apabila telah sesuai, lanjutkan transaksi`,
                },
                { text: `Transaksi Anda telah selesai` },
              ],
            },
            {
              name: "mobile",
              label: "Cara Pembayaran melalui Mobile Banking",
              labelSort: "Mobile Banking",
              steps: [
                {
                  text: `Akses BNI Mobile Banking dari handphone kemudian masukkan User ID dan Password`,
                },
                { text: `Pilih menu Transfer` },
                {
                  text: `Pilih menu Virtual Account Billing kemudian pilih rekening debet`,
                },
                {
                  text: `Masukkan Nomor Virtual Account , misal 70014XXXXXXXXXXX pada menuInput Baru`,
                },
                {
                  text: `Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi`,
                },
                {
                  text: `Konfirmasi transaksi dan masukkan Password Transaksi`,
                },
                { text: `Pembayaran Anda Telah Berhasil` },
              ],
            },
            {
              name: "internet",
              label: "Cara Pembayaran melalui Internet Banking",
              labelSort: "Internet Banking",
              steps: [
                {
                  text: `Ketik alamat https://ibank.bni.co.idkemudian klik Enter`,
                },
                { text: `Masukkan User ID dan Password` },
                { text: `Pilih menu Transfer` },
                { text: `Pilih Virtual Account Billing` },
                {
                  text: `Kemudian masukan Nomor Virtual Account Anda, misalnya 70014XXXXXXXXXXXyang hendak dibayarkan. Lalu pilih rekening debet yang akan digunakan. Kemudian tekan lanjut`,
                },
                {
                  text: `Kemudin tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.`,
                },
                { text: `Masukkan Kode Otentikasi Token` },
                { text: `Pembayaran Anda telah berhasil` },
              ],
            },
            {
              name: "sms",
              label: "Cara Pembayaran melalui Internet Banking",
              labelSort: "Internet Banking",
              steps: [
                { text: `Buka aplikasi SMS Banking BNI` },
                {
                  text: `Pilih menu Transfer`,
                },
                {
                  text: `Pilih menu Trf rekening BNI`,
                },
                {
                  text: `Masukkan Nomor rekening tujuan dengan 16 digit Nomor Virtual Account , contoh: 12345678912XXX`,
                },
                {
                  text: `Masukan Nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses`,
                },
                {
                  text: `Pilih Proses kemudian Setuju`,
                },
                {
                  text: `Reply sms dengan ketik PIN seusuai perintah`,
                },
                {
                  text: `Transaksi Berhasil`,
                },
                {
                  text: `ATAU Dapat juga langsung mengetik sms dengan format: TRF[SPASI]NomorVA[SPASI]NOMINAL dan kemudian kirim ke 3346 Contoh : TRF 123456789012XXXX 44000`,
                },
              ],
            },
          ],
        },
        {
          name: "BANK BRI",
          label: "BANK BRI",
          type: "bank",
          icon: null,
          steps: [
            {
              name: "atm",
              label: "Cara Pembayaran melalui ATM",
              labelSort: "ATM",
              steps: [
                { text: `Input kartu ATM dan PINAnda` },
                { text: `Pilih menu Transaksi lain` },
                { text: `Pilih menu Pembayaran` },
                { text: `Pilih menu Lain-lain` },
                { text: `Pilih menu BRIVA` },
                { text: `Masukkan Nomor Virtual Account ,misal 12345678xxxx` },
                { text: `Pilih Ya` },
                { text: `Ambil Bukti Bayar` },
                { text: `Selesai` },
              ],
            },
            {
              name: "mobile",
              label: "Cara Pembayaran melalui Mobile Banking",
              labelSort: "Mobile Banking",
              steps: [
                { text: `Login BRI Mobile` },
                { text: `Pilih Mobile Banking BRI` },
                { text: `Pilih menu Pembayaran` },
                { text: `Pilih menu BRIVA` },
                {
                  text: `Masukkan Nomor Virtual Account ,misal 88788XXXXXXXXX`,
                },
                { text: `Masukkan nominal, misal 100000` },
                { text: `Klik Kirim` },
                { text: `Masukkan PIN Mobile` },
                { text: `Klik Kirim` },
                { text: `Bukti Bayar akan dikirim melalui sms` },
                { text: `Selesai` },
              ],
            },
            {
              name: "internet",
              label: "Cara Pembayaran melalui Internet Banking",
              labelSort: "Internet Banking",
              steps: [
                { text: `Login Internet Banking` },
                { text: `Pilih Pembayaran` },
                { text: `Pilih BRIVA` },
                { text: `Masukkan Nomor Virtual Account, misal 887XXXXX` },
                { text: `Klik Kirim` },
                { text: `Masukkan Password` },
                { text: `Masukkan mToken` },
                { text: `Klik Kirim` },
                { text: `Bukti Bayar akan ditampilkan` },
                { text: `Selesai` },
              ],
            },
          ],
        },
        {
          name: "BANK MANDIRI",
          label: "BANK MANDIRI",
          type: "bank",
          icon: null,
          steps: [
            {
              name: "atm",
              label: "Cara Pembayaran melalui ATM",
              labelSort: "ATM",
              steps: [
                {
                  text: `Input kartu ATM dan PIN Anda`,
                },
                {
                  text: `Pilih Bayar / Beli`,
                },
                { text: `Pilih Lainnya` },
                {
                  text: `Pilih Multi Payment`,
                },
                {
                  text: `Input Nomor Virtual Account ,misal 146XXXXXXXXXXX`,
                },
                {
                  text: `Pilih Benar`,
                },
                {
                  text: `Pilih Iya`,
                },
                {
                  text: `Ambil Bukti bayar Anda`,
                },
                { text: `Selesai` },
              ],
            },
            {
              name: "mobile",
              label: "Cara Pembayaran melalui Mobile Banking",
              labelSort: "Mobile Banking",
              steps: [
                {
                  text: `Login Mobile Banking`,
                },
                {
                  text: `Pilih Bayar`,
                },
                {
                  text: `Input 70017 (I-PAY) sebagai Penyedia jasa`,
                },
                {
                  text: `Input Nomor Virtual Account , misal 146XXXXXXXXXXX`,
                },
                {
                  text: `Pilih Lanjut`,
                },
                {
                  text: `Input OTP and PIN`,
                },
                {
                  text: `Pilih OK`,
                },
                {
                  text: `Bukti bayar ditampilkan`,
                },
                { text: `Selesai` },
              ],
            },
            {
              name: "internet",
              label: "Cara Pembayaran melalui Internet Banking",
              labelSort: "Internet Banking",
              steps: [
                {
                  text: `Login Internet Banking`,
                },
                {
                  text: `Pilih Bayar`,
                },
                {
                  text: `Pilih Multi payment`,
                },
                {
                  text: `Input 70017 (I-PAY) sebagai Penyedia jasa`,
                },
                {
                  text: `Input Nomor Virtual Account , misal 146XXXXXXXXXXX`,
                },
                {
                  text: `Pilih Lanjut`,
                },
                {
                  text: `Input OTP and PIN`,
                },
                {
                  text: `Pilih OK`,
                },
                {
                  text: `Bukti bayar ditampilkan`,
                },
                { text: `Selesai` },
              ],
            },
            {
              name: "non-bank",
              label: "Cara Pembayaran Selain Bank Mandiri:",
              labelSort: "Selain Bank Mandiri",
              steps: [
                { text: `Input kartu ATM dan PIN Anda` },
                { text: `Pilih Bayar / Beli` },
                { text: `Pilih Lainnya` },
                { text: `Pilih Transfer Bank` },
                { text: `Pilih Bank Mandiri (008)` },
                {
                  text: `Masukkan 88017,diikuti dengan nomor virtual account, misal 881714501000009`,
                },
                { text: `Masukkan Nominal Pembayaran` },
                { text: `Lakukan Pembayaran` },
              ],
            },
          ],
        },
        {
          name: "BANK BSI",
          label: "BANK BSI",
          type: "bank",
          icon: null,
          steps: [
            {
              name: "atm",
              label: "Cara Pembayaran melalui ATM",
              labelSort: "ATM",
              steps: [
                { text: `Pilih Menu Payment/ Pembayaran / Pembelian` },
                { text: `Pilih Institusi / Akademik / Wakaf` },
                {
                  text: `Masukkan kode institusi: 1062 disertai no VA pembayaran Anda. Format 1062xxxxxxxxxxxx (1062 = kode institusi, xxxxxxxxxxxx = nomor pembayaran / virtual account) contoh: 1062123456789123`,
                },
                { text: `Tekan tombol Benar / Selanjutnya` },
                {
                  text: `Kemudian tampil informasi data transaksi anda, pastikan kembali nama tujuan dan jumlah pembayaran sesuai tagihan Anda`,
                },
                { text: `Jika data sudah benar pilih BENAR/YA` },
                { text: `Akan keluar bukti transaksi jika berhasil` },
              ],
            },
            {
              name: "mobile",
              label: "Cara Pembayaran melalui Mobile Banking",
              labelSort: "Mobile Banking",
              steps: [
                { text: `Pilih Menu Payment / Pembayaran` },
                { text: `Pilih Institusi / Akademik / Wakaf` },
                { text: `Masukkan kode institusi: 1062` },
                {
                  text: `Masukkan no VA pembayaran Anda tanpa diikuti kode institusi, lalu klik “setuju”`,
                },
                {
                  text: `Tekan tombol Selanjutnya, Kemudian tampil informasi data transaksi anda, pastikan kembali nama tujuan dan jumlah pembayaran sesuai tagihan Anda, lalu klik Selanjutnya`,
                },
                { text: `Masukan PIN` },
                { text: `Tekan tombol Selanjutnya untuk Submit` },
                { text: `Akan keluar bukti transaksi jika berhasil` },
              ],
            },
            {
              name: "non-bank",
              label: "Cara Pembayaran Selain Bank BSI:",
              labelSort: "Selain Bank BSI",
              steps: [
                { text: `Input kartu ATM dan PIN Anda` },
                { text: `Pilih Bayar / Beli` },
                { text: `Pilih Lainnya` },
                { text: `Pilih Transfer Bank` },
                { text: `Pilih Bank BSI (451)` },
                {
                  text: `Masukkan 9001062, diikuti dengan nomor virtual account, misal 9001062010000000014`,
                },
                { text: `Masukkan Nominal Pembayaran` },
                { text: `Lakukan Pembayaran` },
              ],
            },
          ],
        },
      ],
    },
  },
};
const mutations = {
  changeBerandaPembelian(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeBerandaPembelianNextPlan(state, { nextPlan, data }) {
    state.data.countTunggakanWithNextPlan[nextPlan] = data;
  },
  changeBerandaPembelianDepoPayment(state, payload) {
    state.data.pembayaranDepoPayment = Object.assign(
      {},
      state.data.pembayaranDepoPayment,
      payload,
    );
  },
  changeBerandaPembelianDepoPaymentSteps(state, payload) {
    state.data.paymentSteps = Object.assign(
      {},
      state.data.paymentSteps,
      payload,
    );
  },
};

const actions = {
  async getProductPurchased({ state, commit }, payload) {
    let url = `school/product/history`;
    const params = {};
    params.limit = (payload && payload.limit) || state.data.limit;
    if (payload && payload.q) {
      params.q = payload && payload.q;
    } else if (state.data.filter) {
      params.q = state.data.filter;
    }
    if (payload && payload.filterDate) {
      if (
        typeof payload.filterDate === "object" &&
        payload.filterDate.length === 2
      ) {
        params.start_date = payload.filterDate[0];
        params.end_date = payload.filterDate[1];
      }
    } else if (state.data.filterDate) {
      if (
        typeof state.data.filterDate === "object" &&
        state.data.filterDate.length === 2
      ) {
        params.start_date = state.data.filterDate[0];
        params.end_date = state.data.filterDate[1];
      }
    }
    commit("changeBerandaPembelian", { loading: true });
    const result = await ApiGetRequest(url, params);
    if (result.error) {
      commit("changeBerandaPembelian", { loading: false });
      if (result.code === 403) {
        HandlerNotification("notAkses");
        return;
      }
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      HandlerNotification("error", result.error);
      return;
    }
    commit("changeBerandaPembelian", {
      loading: false,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length,
    });
  },

  async getTransactionPayment({ commit }) {
    commit("changeBerandaPembelianDepoPayment", {
      loading: true,
    });
    const school_id = store.state.user.school.row_id;
    let result = await ApiGetRequestDepoPayment(
      `transaction/filter/user/SC${school_id}`,
    );

    if (result.error) {
      try {
        let message = "";

        if (
          !result.error.response ||
          (result.error.response && result.error.response.status !== 404)
        ) {
          message = result.error;
        }

        HandlerNotification("error", message);

        commit("changeBerandaPembelianDepoPayment", {
          loading: false,
          items: [],
        });
        return false;
      } catch (e) {
        commit("changeBerandaPembelianDepoPayment", {
          loading: false,
          items: [],
        });
        return false;
      }
    }

    const now = moment(new Date());
    let dataResult = [];
    try {
      dataResult = result.data.data.map((item) => {
        if (
          moment(item.expired_date).isBefore(now) &&
          item.status === STATUSPAYMENT.PENDING
        ) {
          item.status = "failed";
        }
        return item;
      });
    } catch (e) {
      console.error("Error processing data:", e);
    }
    commit("changeBerandaPembelianDepoPayment", {
      loading: false,
      items: dataResult,
    });
    return true;
  },
  async getTransactionPaymentDetail({ commit }, payload) {
    if (!payload.transaction_id) {
      alert("development");
      return false;
    }
    commit("changeBerandaPembelianDepoPayment", {
      loading: true,
    });
    let result = await ApiGetRequestDepoPayment(
      `transaction/detail/${payload.transaction_id}`,
    );

    if (result.error) {
      let message = "";

      if (
        !result.error.response ||
        (result.error.response && result.error.response.status !== 404)
      ) {
        message = result.error;
      }

      HandlerNotification("error", message);

      commit("changeBerandaPembelianDepoPayment", {
        loading: false,
        detail: defaultDetailTransaction,
      });
      return false;
    }

    commit("changeBerandaPembelianDepoPayment", {
      loading: false,
      detail: result.data.data || [],
    });
    return true;
  },

  async getTunggakanProduk({ commit }, payload) {
    commit("changeBerandaPembelian", {
      loadingTunggakanProduk: true,
    });
    const school_id = store.state.user.school.row_id;
    const params = {
      school_id,
      next_plan: payload.next_plan,
      next_product: payload.product_id,
    };
    let result = await ApiGetRequestDepoPayment(
      `core/school/check-bill`,
      params,
    );

    if (result.error) {
      try {
        let message = "";

        if (
          !result.error.response ||
          (result.error.response && result.error.response.status !== 404)
        ) {
          message = result.error;
        }

        HandlerNotification("error", message);

        commit("changeBerandaPembelian", {
          loadingTunggakanProduk: false,
          countTunggakan: [],
        });
        return false;
      } catch (e) {
        commit("changeBerandaPembelian", {
          loadingTunggakanProduk: false,
          countTunggakan: [],
        });
        return false;
      }
    }

    commit("changeBerandaPembelian", {
      loadingTunggakanProduk: false,
      countTunggakan: result.data.data,
    });
    return true;
  },

  async getTunggakanProdukByNextPlan({ commit }, { next_plan, product_id }) {
    const school_id = store.state.user.school.row_id;
    const params = {
      school_id,
      next_plan: next_plan,
      next_product: product_id,
    };
    let result = await ApiGetRequestDepoPayment(
      `core/school/check-bill`,
      params,
    );

    if (result.error) {
      return false;
    }

    let dataProduk;
    if (Array.isArray(result.data.data)) {
      dataProduk = [];
    } else {
      dataProduk = result.data.data;
    }

    /*
    {"id":3,"name":"storagePackage 5GB","display_name":"Free","description":"[1,2,3,4]","type":"product","variant_group":"group","variant_grade":1,"is_show":true,"service_fee":0,"monthly_fee":0,"anually_fee":0,"installment_fee":5,"is_recurring":false,"detailable":false,"only_once":true,"has_apply":true,"payment_type":"service","approved_at":"2023-08-31 08:12:38","trial_ended_at":"2023-10-10 23:59:59","expired_at":"2023-03-31 23:59:59","attahced_count":1,"active_user":0}
    * */
    /*{
        "status": "success",
        "data": {
            "products": [
                {
                    "product_name": "storagePackage 30GB",
                    "product_display_name": "BRONZE",
                    "period": "February 2024 - February 2024",
                    "amount": 666000,
                    "overdue_days": 11,
                    "from": "01-02-2024",
                    "to": "02-03-2024",
                    "cycle": "monthly"
                }
            ],
            "remaining_active_days": 19,
            "total_price": 666000,
            "anually_count": 0,
            "monthly_count": 1,
            "each_price": [
                666000
            ],
            "expired_at": "2024-01-31T17:00:00.000Z",
            "date_now": "12-02-2024",
            "subscription_start": "01-02-2024",
            "subscription_end": "02-03-2024"
        },
        "message": "Operation Success"
    }*/
    commit("changeBerandaPembelianNextPlan", {
      nextPlan: next_plan,
      data: dataProduk,
    });
    return true;
  },
};

const getters = {
  dataPembelianDepoPayment: (state) => {
    const { items, filterStatus } = state.data.pembayaranDepoPayment;
    if (typeof items !== "object" || !items.length) {
      return [];
    }
    return items.filter((item) => item.status === filterStatus);
  },
  currentPaymentSteps: () => {
    const { bank, retail, filterPaymentMethodName, filterPaymentMethod } =
      state.data.paymentSteps;
    if (filterPaymentMethod === "bank") {
      const dataBank = bank.find(
        (item) =>
          item.name.toLowerCase() === filterPaymentMethodName.toLowerCase(),
      );
      if (!dataBank) {
        return {
          name: "",
          label: "",
          steps: [],
        };
      }
      return dataBank;
    }
    const dataRetail = retail.find(
      (item) =>
        item.name.toLowerCase() === filterPaymentMethodName.toLowerCase(),
    );
    if (!dataRetail) {
      return {
        name: "",
        label: "",
        steps: [],
      };
    }
    return dataRetail;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
