import {tableOfWidth} from "@/services/internalService";
import SchoolClassService from "@/services/SchoolClassService";

const state = {
	data: {
		labelMenu: "Kelas",
		loading: false,
		itemsGrade: [],
		columns: [
			{
				prop: "grade",
				label: "Jenjang",
				...tableOfWidth.class.minWidth,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "rombel",
				label: "Rombel",
				width: 100,
				"class-name": "body-14 col-black-2 text-center",
			},
			{
				prop: "countL",
				label: "L",
				width: 80,
				"class-name": "body-14 col-black-2 text-center",
			},
			{
				prop: "countP",
				label: "P",
				width: 80,
				"class-name": "body-14 col-black-2 text-center",
			},
			{
				prop: "genderUnknownCount",
				label: "LP?",
				width: 80,
				"class-name": "body-14 col-black-2 text-center",
			},
			{
				prop: "total",
				label: "Total",
				width: 100,
				"class-name": "body-14 col-black-2 text-center",
			},
			{
				prop: "studentNotActive",
				label: "Pelajar Tidak Aktif",
				minWidth: 130,
				"class-name": "body-14 col-black-2",
			},
		],
	},
};

const mutations = {
	changeSummaryClass(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
};

const actions = {

	async fetchSummaryGrades({commit}) {
		try {
			const schoolService = new SchoolClassService()
			const result = await schoolService.summaryGradesGet()
			commit("changeSummaryClass", {
				itemsGrade: result.data?.data || []
			})
		} catch (e) {
			console.log(e)
		}
	}
}

const getters = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
