import { ApiGetRequest, ApiNotification, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";
import {
  DEV_STOP_CALL_SERVER,
  SERVER_ERROR_MESSAGE,
} from "@/services/messageService";

const state = {
  data: {
    loading: false,
    user: {},
    school_subject: [],
    tabMenu: [
      {
        name: "MyProfile",
        label: "Profile Saya",
        lock: false,
        url: "/profile/my-profile",
      },
      {
        name: "MyRole",
        label: "Jabatan",
        lock: false,
        url: "/profile/my-role",
      },
      {
        name: "MyPassword",
        label: "Password",
        lock: false,
        url: "/profile/my-password",
      },
    ],
    fields: [
      {
        prop: "mapel",
        label: "Mapel",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "jurusan",
        label: "Jurusan",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "jenjang",
        label: "Jenjang",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "kelas",
        label: "Kelas",
        "class-name": "body-14 col-black-2",
      },
    ],
    items: [],
    page: 1,
    limit: 10,
  },
};

const mutations = {
  changeProfile(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeProfileLoading(state, payload) {
    state.data.loading = payload;
  },
};

const actions = {
  async getProfile({ commit, state }) {
    if (state.data.loading) {
      console.log(DEV_STOP_CALL_SERVER);
      return false;
    }

    commit("changeProfileLoading", true);
    const result = await ApiGetRequest(`school/profile`);
    commit("changeProfileLoading", false);
    if (result.error) {
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeProfile", {
        user: {},
        school_subject: [],
      });

      return false;
    } else {
      let schhedule = {};
      let list_schedule = result.data.subject_schedules || [];
      let data_result = [];

      list_schedule.map((item) => {
        let subject = item.subject.name;
        let kelas = item.class_room ? item.class_room.name : "";
        let major = item.class_room ? item.class_room.major.name : "";
        let grade = item.class_room ? item.class_room.grade : "";

        if (!schhedule[subject]) {
          schhedule[subject] = {
            jurusan: [major],
            jenjang: [grade],
            kelas: [kelas],
          };
        } else {
          if (!schhedule[subject].jurusan.includes(major)) {
            schhedule[subject].jurusan.push(major);
          }

          if (!schhedule[subject].jenjang.includes(grade)) {
            schhedule[subject].jenjang.push(grade);
          }

          if (!schhedule[subject].kelas.includes(kelas)) {
            schhedule[subject].kelas.push(kelas);
          }
        }
      });

      if (Object.keys(schhedule).length > 0) {
        for (const [key, value] of Object.entries(schhedule)) {
          data_result.push({
            ...value,
            mapel: key,
          });
        }
      }

      commit("changeProfile", {
        user: result.data.data,
        school_subject: result.data.school_subject || [],
        schedule: data_result,
      });

      return true;
    }
  },

  async updatePassword({ state }, payload = null) {
    const { data } = state;
    let result = await ApiPostRequest(
      `school/profile/${data.user ? data.user.id : ""}/update-password`,
      payload.form,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Kata sandi berhasil diubah",
      });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
