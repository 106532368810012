import { ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    error: false,
    formReset: {
      token: "",
      email: "",
      password: "",
      confpassword: "",
    },
    rulesReset: {
      email: [
        {
          required: true,
          message: "Anda wajib mengisi email",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Email tidak valid",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "Anda wajib mengisi password baru",
          trigger: "blur",
        },
      ],
      confpassword: [
        {
          required: true,
          message: "Anda wajib mengisi konfirmasi password",
          trigger: "blur",
        },
        {
          validator: (rule, value, callback) => {
            if (value === "") {
              callback(new Error("Anda wajib mengisi konfirmasi password"));
            } else if (value !== state.data.formReset.password) {
              callback(new Error("Konfirmasi password tidak sama"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    },
    formForgot: {
      email_phone: "",
    },
    rules: {
      email_phone: [
        {
          required: true,
          message: "Anda harus mengisi email",
          trigger: "blur",
        },
      ],
    },
  },
};

const mutations = {
  changeForgot(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async submitForgotPwd({ commit, state }) {
    commit("changeForgot", {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiPostRequest(`school/forgot-password`, {
      email_phone: data.formForgot.email_phone,
    });

    if (result.error) {
      Message({
        type: "error",
        message: "Data anda tidak ditemukan",
      });

      commit("changeForgot", {
        loading: false,
        error: result.errorList || false,
      });

      return false;
    } else {
      Message({
        type: "success",
        message:
          "Link reset password telah dikirim ke email " + result.data.data,
      });

      commit("changeForgot", {
        loading: false,
      });

      return true;
    }
  },

  async resetPassword({ commit, state }) {
    commit("changeForgot", {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiPostRequest(`school/reset-password`, {
      email: data.formReset.email,
      token: data.formReset.token,
      password: data.formReset.password,
    });

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data anda tidak ditemukan",
      });

      commit("changeForgot", {
        loading: false,
        error: result.errorList || false,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Kata sandi berhasil di reset",
      });

      commit("changeForgot", {
        loading: false,
      });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
