import { ApiGetRequestV2, ApiPostRequest, ApiPostRequestV2 } from "@/utils/Api";
import { Message } from "element-ui";
import moment from "moment";

const state = {
  data: {
    produkMenu: [
      {
        name: "List Produk",
        active: true,
      },
      {
        name: "Pembayaran",
        active: false,
      },
      {
        name: "Cara Pembayaran",
        active: false,
      },
    ],
    activeProdukMenu: "List Produk",
    produkAppList: [],
    listLayanan: [
      {
        id: 1,
        name: "Penyimpanan Data Induk",
        label: "Penyimpanan Data Induk",
        isSoon: false,
      },
      {
        id: 2,
        name: "Jurnal Mengajar",
        label: "Jurnal Mengajar",
        isSoon: false,
      },
      {
        id: 3,
        name: "Presensi Siswa dan Monitoring",
        label: "Presensi Siswa dan Monitoring",
        isSoon: true,
      },
      {
        id: 4,
        name: "Kartu Pelajar",
        label: "Kartu Pelajar",
        isSoon: false,
      },
      {
        id: 5,
        name: "Pembelajaran Digital",
        label: "Pembelajaran Digital",
        isSoon: false,
      },
      {
        id: 6,
        name: "Assesmen Test",
        label: "Assesmen Test",
        isSoon: true,
      },
      {
        id: 7,
        name: "Kelas Eligible",
        label: "Kelas Eligible",
        isSoon: true,
      },
      {
        id: 8,
        name: "Konseling",
        label: "Konseling",
        isSoon: false,
      },
    ],
    listLayanan2: [
      {
        id: 1,
        name: "Data Induk",
        label: "Data Induk",
      },
      {
        id: 2,
        name: "Pembayaran Uang Sekolah Digital",
        label: "Pembayaran Uang Sekolah Digital",
      },
      {
        id: 3,
        name: "Kantin Digital",
        label: "Kantin Digital",
      },
      {
        id: 4,
        name: "PPOB Kewirausahaan Sekolah",
        label: "PPOB Kewirausahaan Sekolah",
      },
      {
        id: 5,
        name: "Presensi & Monitoring",
        label: "Presensi & Monitoring",
      },
      {
        id: 6,
        name: "Survey Kepuasan",
        label: "Survey Kepuasan",
      },
      {
        id: 7,
        name: "Konseling (Tidak termasuk Asesment)",
        label: "Konseling (Tidak termasuk Asesment)",
      },
      {
        id: 8,
        name: "LMS Beserta Ujian Sekolah",
        label: "LMS Beserta Ujian Sekolah",
      },
      {
        id: 9,
        name: "Buku Tamu Digital",
        label: "Buku Tamu Digital",
      },
    ],
    paymentType: "tahunan",
    selectedProduct: null,
    selectedPaket: {},
    formPemesanan: {
      name: "",
      phone: "",
      email: "",
      date: "",
      school_name: "",
      school_address: "",
      product_school_id: "",
    },
    add_ons_links: {
      danasekolah: "/pembayaran-spp/biaya-admin",
      kewirausahaan: "/klasbisnis/biaya-admin",
      koperasi: "/koperasi/biaya-admin",
      kantin: "/koperasi/kantin",
      prokes: "/prokes/biaya-admin",
      danapartisipasi: "/dana-partisipasi/biaya-admin",
      ppob: "/ppob/biaya-admin",
      klassign: `/klassign`,
    },
    formPemesananRules: {
      name: [
        { required: true, message: "Nama tidak boleh kosong", trigger: "blur" },
      ],
      phone: [
        {
          required: true,
          message: "Nomer telepon tidak boleh kosong",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Email tidak boleh kosong",
          trigger: "blur",
        },
        { type: "email", message: "Email harus berupa email", trigger: "blur" },
      ],
    },
    errors: [],
    produkLainList: [
      {
        name: "Web School",
        display_name: "Klasite",
        showInfo: true,
        installed: false,
        activeDate: "2020-05-31",
        price: "0",
        icon: "klasite_img.svg",
        colorBadge: "#2C51B0",
        detail:
          "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
        fitures: [
          {
            label: "Gratis pilihan tema",
            checkedIcon: true,
          },
          {
            label: "Gratis domain & hosting",
            checkedIcon: true,
          },
          {
            label: "Full cms website sekolah",
            checkedIcon: true,
          },
          {
            label:
              "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
            checkedIcon: true,
          },
          {
            label: "Integrasi website dengan sosial media",
            checkedIcon: true,
          },
          {
            label: "PPDB dan PSB",
            checkedIcon: true,
          },
        ],
        product: [],
        pending: false,
      },
      {
        name: "Pustaka",
        display_name: "Klas Pustaka",
        showInfo: true,
        installed: false,
        activeDate: "2020-05-31",
        price: "0",
        icon: "klaspustaka_img.svg",
        colorBadge: "#2C51B0",
        detail:
          "Ingin meningkatkan efisiensi kerja pustakawan dan meningkatan minat membaca pelajar?, dengan Klaspustaka anda bisa melakukan pengarsipan, penyimpanan database dan pencatatan stok buku perpustakaan Sekolah Anda, dengan fitur dari Klaspustaka yang terintegrasi dengan ISBN, efisiensi katalog buku menjadi lebih mudah, Fitur Klaspustaka juga mendukung pencatatan dan riwayat peminjaman buku keluar dan masuk, melihat daftar pengunjung, stok dan arsip buku, semuanya cukup mudah hanya dengan Klaspustaka.",
        fitures: [
          {
            label: "Unlimited penyimpanan arsip & list buku",
            checkedIcon: true,
          },
          {
            label: "Gratis web katalog perpustakaan",
            checkedIcon: true,
          },
          {
            label: "Integrasi ISBN",
            checkedIcon: true,
          },
          {
            label: "Fitur pencatatan pengunjung perpustakaan",
            checkedIcon: true,
          },
          {
            label: "Fitur peminjaman buku & riwayat peminjaman",
            checkedIcon: true,
          },
        ],
        product: [],
        pending: false,
      },
      {
        name: "Klastime",
        display_name: "Klastime",
        showInfo: true,
        installed: false,
        activeDate: "2020-05-31",
        price: "0",
        icon: "klastime_img.svg",
        colorBadge: "#2C51B0",
        detail:
          "Kelola absensi untuk pelajar, guru dan karyawan Sekolahmu, dengan Klastime pelacakan waktu dengan mudah hanya dengan satu alat dan terintegrasi dengan mesin absensi. Bebaskan diri Anda dari tugas administrasi yang  tidak efisien dan berulang. Kelola presensi dan ketidakhadiran pelajar dan staff Anda dengan gambaran yang jelas, dapatkan rekap kehadiran dan atur waktu jadwal masuk Sekolahmu.",
        fitures: [
          {
            label: "Pengaturan presensi pelajar, guru & staff",
            checkedIcon: true,
          },
          {
            label: "Pencatatan rekap kehadiran per/kelas",
            checkedIcon: true,
          },
          {
            label: "Pencatatan rekap presensi pelajar, guru & staff",
            checkedIcon: true,
          },
          {
            label: "Pengaturan jam masuk pelajar, guru & staff",
            checkedIcon: true,
          },
          {
            label: "Integrasi mesin absensi dan fingerprint",
            checkedIcon: true,
          },
        ],
        product: [],
        pending: false,
      },
      {
        name: "Monitoring",
        display_name: "Klas Monitoring",
        showInfo: true,
        installed: false,
        activeDate: "2020-05-31",
        price: "0",
        icon: "klasmonitoring_img.svg",
        colorBadge: "#2C51B0",
        detail:
          "Klasmonitoring memungkinkan Anda mengetahui data spesifik aktivitas belajar mengajar di Sekolah Anda, Dengan fitur analitik, bagan, diagram dan peringkat yang membantu Sekolah untuk membuat keputusan dan analisa yang lebih terukur. Klasmonitoring membantu Anda untuk mendapatkan analisa pengguna dan memantau kegiatan di Sekolah , statistik aktivitas, peringkat, persentase kehadiran, peringkat nilai, persentase belajar siswa, informasi kelas dengan metode diagram dan pengukur target. Buat keputusan berdasarkan data dan laporan, dan tingkatkan kesuksesan Sekolah dengan data pengukur terbaik dari Klasmonitoring.",
        fitures: [
          {
            label: "Fitur Analitik Sekolah",
            checkedIcon: true,
          },
          {
            label: "Fitur Peringkat",
            checkedIcon: true,
          },
          {
            label: "Fitur Monitoring",
            checkedIcon: true,
          },
          {
            label: "Integrasi Slide Show",
            checkedIcon: true,
          },
        ],
        product: [],
        pending: false,
      },
    ],
    columns: [
      {
        prop: "name",
        label: "Name",
        minWidth: "100",
      },
      {
        prop: "email",
        label: "Email",
        minWidth: "100",
      },
      {
        prop: "school",
        label: "Sekolah",
        minWidth: "100",
      },
      {
        prop: "date",
        label: "Waktu Pengajuan",
        minWidth: "100",
      },
    ],
    produkTersedia: [],
    produkAddonList: [],
    loading: false,
    typePaymentPackage: "Online",
    loadingCurrent: false,
    history_pengajuan: false,
    items_history: [],
  },
};

const mutations = {
  changeBerandaProduk(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getProductsStoragePackage({ commit }) {
    commit("changeBerandaProduk", { loading: true });
    let result = await ApiGetRequestV2(
      `school/product-storage?limit=10&q=storagePackage`,
    );
    commit("changeBerandaProduk", { loading: false });
    if (result.error) {
      Message({
        type: "error",
        message: "Menampilkan Produk Gagal",
      });
      return false;
    }
    let dataResult =
      result.data.data.sort((a, b) => a.monthly_fee - b.monthly_fee) || [];
    commit("changeBerandaProduk", {
      produkAppList: dataResult,
    });
    return true;
  },
  async getHistoryPengajuan({ commit }, payload = null) {
    let result = await ApiGetRequestV2(
      `school/product/${payload.id}/apply-history`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeBerandaProduk", {
        history_pengajuan: false,
        items_history: [],
      });

      return false;
    } else {
      let data_pengajuan = result.data.data || [];
      let items_history = data_pengajuan.map((item) => {
        let school_request = item.school_request || {};
        return {
          ...school_request,
          school: school_request.school_name,
          date: item.created_at || "",
        };
      });

      commit("changeBerandaProduk", {
        history_pengajuan: result.data.data,
        items_history: items_history,
      });
      return true;
    }
  },

  async submitpengajuan({ commit, state }) {
    const { data } = state;
    data.formPemesanan.date = Date.now();
    commit("changeBerandaProduk", {
      formPemesanan: data.formPemesanan,
    });

    let result = await ApiPostRequest(`school-request`, data.formPemesanan);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaProduk", {
          errors: errors,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Form pengajuan telah terkirim.",
      });
      return true;
    }
  },

  async submitProdukRequest({ commit, state }) {
    const { data } = state;
    let formData = {
      payment_type: data.paymentType === "bulanan" ? "monthly" : "anually",
    };
    let result = await ApiPostRequestV2(
      `school/product/${data.formPemesanan.product_school_id}/apply`,
      formData,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaProduk", {
          errors: errors,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Produk telah diajukan.",
      });
      return result.data.data;
    }
  },

  async getProdukApp({ commit }) {
    commit("changeBerandaProduk", {
      produkTersedia: [],
    });

    const result = await ApiGetRequestV2(`school/product/item`);

    if (!result.error) {
      await commit("changeBerandaProduk", {
        produkTersedia: result.data.data,
      });
    }
  },

  async getProdukUpgrade({ commit, state }, payload) {
    let produkTersedia = state.data.produkTersedia;

    const params = {
      product_id: payload.id,
    };
    const result = await ApiGetRequestV2(`school/product-upgrade/item`, params);

    if (!result.error) {
      for (let i = 0; i < result.data.data.length; i++) {
        produkTersedia.push(result.data.data[i]);
      }

      commit("changeBerandaProduk", {
        produkTersedia: produkTersedia,
      });
    }
  },

  async getProdukAddon({ commit }) {
    commit("changeBerandaProduk", {
      produkAddonList: [],
      loading: true,
    });

    const result = await ApiGetRequestV2(`school/addons`);

    if (!result.error) {
      let addonData = [];
      for (let i = 0; i < result.data.data.length; i++) {
        let icon = "spp_img.svg";
        let tempAddon = {
          name: result.data.data[i].name,
          display_name: result.data.data[i].display_name
            ? result.data.data[i].display_name
            : result.data.data[i].name,
          showInfo: true,
          installed: false,
          colorBadge: "#2C51B0",
          icon: icon,
          detail: result.data.data[i].description,
          fitures: [],
          product: result.data.data[i],
          pending: false,
          active: result.data.data[i].status === "Activated" ? true : false,
          is_show: result.data.data[i].is_show,
        };
        addonData.push(tempAddon);
      }

      await commit("changeBerandaProduk", {
        produkAddonList: addonData,
        loading: false,
      });

      await commit(
        "updateState",
        {
          menuAddOns: {
            menu: result.data.data,
            last_check: moment().format("YYYY-MM-DD HH:mm"),
          },
        },
        { root: true },
      );
    } else {
      commit("changeBerandaProduk", {
        loading: false,
      });
    }
  },

  async getProfileSchool({ commit, state }, payload) {
    if (state.data.loadingCurrent && !payload) {
      return true;
    }
    await commit("changeBerandaProduk", {
      loadingCurrent: true,
    });
    const result = await ApiGetRequestV2(`school/current`);
    await commit("changeBerandaProduk", {
      loadingCurrent: false,
    });
    if (await result.error) {
      return false;
    }
    result.data.data.is_default_password = false;
    result.data.data.is_phone_verified = false;
    return result.data.data;
  },

  async submitProdukAddonRequest({ commit, state }) {
    const { data } = state;
    let formData = {};
    let result = await ApiPostRequestV2(
      `school/addons/${data.formPemesanan.product_school_id}/activate`,
      formData,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaProduk", {
          errors: errors,
        });
      }

      return false;
    } else {
      Message({
        type: "success",
        message: "Add On telah diajukan.",
      });
      return result.data.data;
    }
  },

  async activeAddon({ commit }, payload) {
    const result = await ApiPostRequest(`school/addons/${payload.id}/activate`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaProduk", {
          errors: errors,
        });
      }

      return [];
    } else {
      Message({
        type: "success",
        message: "Add On telah diaktifkan kembali",
      });
      return true;
    }
  },

  async deactivateAddon({ commit }, payload) {
    const result = await ApiPostRequest(
      `school/addons/${payload.id}/deactivate`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeBerandaProduk", {
          errors: errors,
        });
      }

      return [];
    } else {
      Message({
        type: "success",
        message: "Add On telah dinonaktifkan",
      });
      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
