// AgendaSuratImplement.js
import moment from "moment";
import {
  handler401ApiService,
  handlerTimeOutApiService,
} from "@/utils/ApiService";
import { handlerServerErrorAxios } from "@/services/internalService";

moment.locale("id");

const ENDPOINT = "/school/agenda";

export default class AgendaSuratImplement {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async agendaSurat(data) {
    try {
      const params = this.prepareParams(data);
      if (!params) {
        throw new Error("Invalid parameters for agendaSurat.");
      }
      //return await this.apiService.get(`http://localhost:3000/api/error401`, { params });
      return await this.apiService.get(`${ENDPOINT}`, { params });
    } catch (error) {
      console.error("Error in AgendaSuratImplement.agendaSurat:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  truncateString(value, key) {
    const max = {
      purpose: 100,
      subject: 240,
    };
    if (!value) {
      return "";
    }
    return value.length > max[key] ? value.substring(0, max[key]) : value;
  }

  async addAgendaSurat({ purpose, subject }) {
    try {
      if (!subject) {
        throw new Error("FormData.subject isRequired for addAgendaSurat.");
      }
      // purpose opsional
      const truncatedPurpose = this.truncateString(purpose, "purpose");
      const truncatedSubject = this.truncateString(subject, "subject");
      const formData = {
        purpose: truncatedPurpose,
        subject: truncatedSubject,
      };

      return await this.apiService.post(`${ENDPOINT}`, formData);
    } catch (error) {
      console.error("Error in AgendaSuratImplement.addAgendaSurat:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async updateAgendaSurat(id, { purpose, subject }) {
    try {
      if (!id) {
        throw new Error("Invalid id isRequired for updateAgendaSurat.");
      }
      if (!subject) {
        throw new Error("FormData.subject isRequired for updateAgendaSurat.");
      }
      const truncatedPurpose = this.truncateString(purpose, "purpose");
      const truncatedSubject = this.truncateString(subject, "subject");
      const formData = {
        purpose: truncatedPurpose,
        subject: truncatedSubject,
      };

      return await this.apiService.put(`${ENDPOINT}/${id}`, formData);
    } catch (error) {
      console.error("Error in AgendaSuratImplement.updateAgendaSurat:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async uploadFileAgendaSurat({ file, id }) {
    try {
      if (!file) {
        throw new Error("FormData.file isRequired for uploadFileAgendaSurat.");
      }
      if (!id) {
        throw new Error("FormData.id isRequired for uploadFileAgendaSurat.");
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("agenda_id", id);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      return await this.apiService.post(`${ENDPOINT}/file`, formData, config);
    } catch (error) {
      console.error(
        "Error in AgendaSuratImplement.uploadFileAgendaSurat:",
        error,
      );
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  async delete(id) {
    try {
      if (!id) {
        throw new Error("FormData.id isRequired for deleteAgendaSurat.");
      }

      //return await this.apiService.get("http://localhost:3000/api/error400");
      return await this.apiService.delete(`${ENDPOINT}/${id}`);
    } catch (error) {
      console.error("Error in AgendaSuratImplement.deleteAgendaSurat:", error);
      const timeoutError = await handlerTimeOutApiService(error);
      if (timeoutError) {
        throw timeoutError;
      }
      const unauth = await handler401ApiService(error);
      if (unauth) {
        throw unauth;
      }
      if (await handlerServerErrorAxios(error?.response?.status || 500)) {
        throw error;
      }
      throw error;
    }
  }

  prepareParams(data) {
    const params = {};
    this.validateSortField(data, params);
    this.validateSortDir(data, params);
    this.validateLimit(data, params);
    this.validatePage(data, params);

    if (data.q) {
      params.q = data.q; // ops | nama employee
    }

    if (data.creator_id) {
      params.creator_id = data.creator_id; // ops | nama employee
    }

    if (data.year) {
      // Check if data.page is a numeric value
      if (!isNaN(data.year) && data.year !== 0) {
        params.year = parseInt(data.year);
      } else {
        // Handle the case where data.page is not a number
        throw new Error("Error: 'year' must be a numeric value");
      }
    }
    return params;
  }

  validatePage({ page }, params) {
    if (page) {
      if (!isNaN(page) && page !== 0) {
        params.page = parseInt(page);
      } else {
        throw new Error("Error: 'page' must be a numeric value");
      }
    }
  }

  validateLimit({ limit }, params) {
    const maxLimit = 500;
    if (limit) {
      const numericLimit = parseInt(limit, 10);
      if (isNaN(numericLimit) || numericLimit > maxLimit) {
        throw new Error(
          `Invalid limit value: '${limit}'. The limit must be a number and cannot exceed ${maxLimit}.`,
        );
      }
      params.limit = numericLimit;
    }
  }

  validateSortField(
    { sort_field },
    params,
    validSortFields = ["name", "created_at", "agenda_number"],
  ) {
    if (sort_field && !validSortFields.includes(sort_field)) {
      throw new Error(
        `Invalid sort_field value: '${sort_field}'. Valid values are ${validSortFields.join(
          ", ",
        )}.`,
      );
    }
    params.sort_field = sort_field;
  }

  validateSortDir({ sort_dir }, params) {
    if (typeof sort_dir === "boolean") {
      params.sort_dir = sort_dir ? "desc" : "asc";
    } else {
      const validSortDirections = ["asc", "desc"];
      if (sort_dir && !validSortDirections.includes(sort_dir)) {
        throw new Error(
          `Invalid sort_dir value: '${sort_dir}'. Valid values are ${validSortDirections.join(
            ", ",
          )}.`,
        );
      }
      params.sort_dir = sort_dir;
    }
  }

  validateType({ type }, params) {
    const validTypes = ["sd", "smp", "sma", "smk", "slb"];
    if (type && !validTypes.includes(type.toLowerCase())) {
      throw new Error(
        `Invalid type value: '${type}'. Valid values are ${validTypes.join(
          ", ",
        )}.`,
      );
    }
    params.type = type && type.toUpperCase();
  }
}
