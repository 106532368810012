import WebschoolRepository from "@/repositories/webschoolRepository"
import StrukturErrorService from "@/services/StrukturErrorService"
import {CONTENT_TYPE_ID} from "@/utils/WebsiteSchool"
import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers"

export default class WebschoolService {
	constructor() {
		this.webschoolRepository = new WebschoolRepository()
	}

	async templatePamfletPpdbGet() {
		try {
			const params = {
				limit: 1,
				page: 1,
				content_type: CONTENT_TYPE_ID.ppdb_template
			}

			return await this.webschoolRepository.getWebContent(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async pamfletPpdbGet(payload) {
		const {limit, page, filterQ} = payload

		try {
			const params = {
				limit: limit ?? undefined,
				page: page || undefined,
				q: filterQ || undefined,
				content_type: CONTENT_TYPE_ID.ppdb
			}

			return await this.webschoolRepository.getWebContent(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async webContentGet(payload) {
		const {limit, page, filterQ, content_type} = payload

		if (!content_type) {
			throw new Error("Content_type is required")
		}

		try {
			const params = {
				limit: limit ?? undefined,
				page: page || undefined,
				q: filterQ || undefined,
				content_type: content_type
			}

			return await this.webschoolRepository.getWebContent(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async validationFormDataWebContent(payload) {
		const {title, type, status} = payload

		if (!type) {
			throw new Error("body type is required")
		}

		if (!status) {
			throw new Error("body status is required")
		}

		if (!title) {
			throw new Error("body title is required")
		}

		const allowedStatus = ["draft", "published", "unpublished"]
		if (!allowedStatus.includes(status)) {
			throw new Error(`body status must be one of ${allowedStatus}`)
		}
	}

	async webContentPost(payload) {
		const {title, body, image, type, status, category} = payload

		this.validationFormDataWebContent(payload)

		try {
			// Create a new FormData object
			const formData = new FormData()

			// Add fields to the FormData object
			formData.append("title", title)
			formData.append("body", body)
			if (image) {
				formData.append("image", image) // If 'image' is a File object, FormData will handle it correctly
			}
			formData.append("type", type)
			if (category) {
				formData.append("category", category)
			}
			formData.append("status", status)
			formData.append("_method", "post") // If you need to pass a specific method, e.g., for RESTful APIs

			// Make the POST request using the FormData object
			return await this.webschoolRepository.postWebContent(formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			ShowAlertIsServerErrorOrNoInternet(parsedError)
			console.log(parsedError)
			throw parsedError
		}
	}

	async webContentPut(payload) {
		const {id, title, body, image, type, status} = payload

		if (!id) {
			throw new Error("body type is required")
		}

		this.validationFormDataWebContent(payload)

		try {
			// Create a new FormData object
			const formData = new FormData()

			// Add fields to the FormData object
			formData.append("id", id) // Add 'id' if present, otherwise null
			formData.append("title", title)
			formData.append("body", body)
			if (image) {
				formData.append("image", image)
			}
			formData.append("type", type)
			formData.append("status", status)
			formData.append("_method", "put") // If you need to pass a specific method, e.g., for RESTful APIs

			return await this.webschoolRepository.postWebContentById(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			ShowAlertIsServerErrorOrNoInternet(parsedError)
			throw parsedError
		}
	}

	async webContentDelete(id) {
		if (!id) {
			throw new Error("params id is required")
		}

		try {
			return await this.webschoolRepository.deleteWebContent(id)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			ShowAlertIsServerErrorOrNoInternet(parsedError)
			console.log(parsedError)
			throw parsedError
		}
	}

	async getStudentBkkSummary() {
		try {
			return await this.webschoolRepository.getStudentBkkSummary()
		} catch (error) {
			console.error("--> error:", error)
			if (error.response && error.response.status >= 500) {
				throw error
			}
			throw error
		}
	}

	async getStudentBkk(payload) {
		const {type, q, page, limit} = payload
		if (type && !["MAGANG", "KERJA"].includes(type)) {
			throw new Error("type pastikan 'MAGANG','KERJA'")
		}

		const params = {
			type: type || undefined,
			q: q || undefined,
			limit: limit || undefined,
			page: page || undefined,
		}

		try {
			return await this.webschoolRepository.getStudentBkk(params)
		} catch (error) {
			console.error("--> error:", error)
			if (error.response && error.response.status >= 500) {
				throw error
			}
			throw error
		}
	}

	async postStudentBkk(uuid, payload) {
		const {
			type,
			body,
			graduate,
			next_education,
			program_studies,
			job,
			work_at,
			social_media,
			publish,
		} = payload
		if (!uuid) {
			throw new Error("uuid is required")
		}
		if (type && !["MAGANG", "KERJA"].includes(type)) {
			throw new Error("type pastikan 'MAGANG','KERJA'")
		}

		const formData = {
			type: type, // required
			body: body || undefined, // Optional
			graduate: graduate || undefined, // Optional
			next_education: next_education || undefined, // Optional,
			program_studies: program_studies || undefined, // Optional
			job: job || undefined, // Optional
			work_at: work_at || undefined, // Optional
			social_media: social_media, //optional
			publish: publish || 1, // optional default 1
		}

		try {
			return await this.webschoolRepository.postStudentBkk(uuid, formData)
		} catch (error) {
			console.error("--> error:", error)
			if (error.response && error.response.status >= 500) {
				throw error
			}
			throw error
		}
	}

	async updateStudentBkk(id, payload) {
		const {
			type,
			body,
			graduate,
			next_education,
			program_studies,
			job,
			work_at,
			social_media,
			publish,
		} = payload
		if (!id) {
			throw new Error("id is required")
		}
		if (type && !["MAGANG", "KERJA"].includes(type)) {
			throw new Error("type pastikan 'MAGANG','KERJA'")
		}

		let reqBody = null
		if (body === "") {
			reqBody = ""
		} else if (body === null) {
			reqBody = undefined
		} else {
			reqBody = body || undefined
		}

		const formData = {
			_method: "PUT", // required
			type: type, // required
			body: reqBody, // Optional
			graduate: graduate || undefined, // Optional
			next_education: next_education || undefined, // Optional,
			program_studies: program_studies || undefined, // Optional
			job: job || undefined, // Optional
			work_at: work_at || undefined, // Optional
			social_media: social_media, //optional
			publish: publish || 1, // optional default 1
		}

		try {
			return await this.webschoolRepository.postStudentBkkByUuid(id, formData)
		} catch (error) {
			console.error("--> error:", error)
			if (error.response && error.response.status >= 500) {
				throw error
			}
			throw error
		}
	}

	async postBulkStudentBkk(payload) {
		const {type, students} = payload
		if (typeof students === "object") {
			throw new Error("students is array")
		}

		const formData = {
			type: type,
			students: [
				{
					uuid: "1cc68075065343afb30f1b6c59b5bf03", // Required
					body: "ini body", // Optional
					graduate: "2024", // Optional
					next_education: "its", // Optional,
					program_studies: "software engineering", // Optional
					job: "developer", // Optional
					work_at: "onklas", // Optional
					social_media: {
						facebook: "http://facebook.com/username",
						twitter: "http://twitter.com/username",
						blog: "http://myblog.com",
						instagram: "http://instagram.com/username",
					}, // Optional
					publish: 1, // Optional (default tru)
				},
			],
		}

		try {
			return await this.webschoolRepository.postStudentBkkBulk(formData)
		} catch (error) {
			console.error("--> error:", error)
			if (error.response && error.response.status >= 500) {
				throw error
			}
			throw error
		}
	}

	async updateStatusStudentBkk(uuid, status) {
		if (!uuid) {
			throw new Error("id required")
		}

		const formData = new FormData()
		formData.append("_method", "PUT")
		formData.append("publish", status ? 1 : 0)

		try {
			return await this.webschoolRepository.postStudentBkkByUuid(
				uuid,
				formData,
			)
		} catch (error) {
			console.error("--> error:", error)
			if (error.response && error.response.status >= 500) {
				throw error
			}
			throw error
		}
	}

	async deleteStudentBkk(uuid) {
		if (!uuid) {
			throw new Error("id required")
		}

		try {
			return await this.webschoolRepository.deleteStudentBkkByUuid(uuid)
		} catch (error) {
			console.error("--> error:", error)
			if (error.response && error.response.status >= 500) {
				throw error
			}
			throw error
		}
	}

	async getCompanyStudentBkk(payload) {
		const {type, q, page, limit, sort_field, sort_dir} = payload
		if (type && !["MAGANG", "KERJA"].includes(type)) {
			throw new Error("type pastikan 'MAGANG','KERJA'")
		}

		const params = {
			type: type || undefined,
			q: q || undefined,
			limit: limit || undefined,
			page: page || undefined,
			sort_field: sort_field || undefined,
			sort_dir: sort_dir || undefined,
		}

		try {
			return await this.webschoolRepository.getCompanyStudentBkk(params)
		} catch (error) {
			console.error("--> error:", error)
			if (error.response && error.response.status >= 500) {
				throw error
			}
			throw error
		}
	}
}
