// Fungsi untuk memeriksa apakah tanggal telah melewati tanggal saat ini
import moment from "moment";
import fileStorage from "@/store/Pengguna/FileStorage";
import { Message } from "element-ui";

function isExpired(dateString) {
  const now = moment();
  const expiredDate = moment(dateString);
  return now.isAfter(expiredDate);
}

// Fungsi untuk memeriksa apakah usage melebihi batas storage
function isOverUsage(usage, storageLimit) {
  return usage > storageLimit;
}

// Fungsi untuk memeriksa apakah saat ini adalah 10 hari sebelum tanggal kadaluwarsa
/**
 * @param dateString
 * */
function isTenDaysBeforeExpiry(dateString) {
  if (!dateString) {
    return false;
  }
  const now = moment();
  const day = fileStorage.state.data.notifikasiPaketLangganan || 10;
  const expiryMinusTenDays = moment(dateString).subtract(day, "days");
  return now.isAfter(expiryMinusTenDays) && now.isBefore(moment(dateString));
}

// Fungsi untuk memeriksa apakah penggunaan sudah mencapai 85% atau lebih dari total storage
function isNearFullUsage(usage, storageLimit) {
  return usage / storageLimit >= 0.85;
}

function gbToBytes(sizeGb) {
  if (sizeGb) {
    return sizeGb * 1024 ** 3;
  }
  return 0;
}

function calculateFreeStoragePercent(storagePacket, usage) {
  const bytes = gbToBytes(storagePacket);
  const freeStorage = bytes - parseInt(usage);
  const percentage = (freeStorage / bytes) * 100;

  // Jika persentase lebih dari 100, maka tetapkan sebagai 100.
  // Jika persentase kurang dari 0, maka tetapkan sebagai 0.
  const boundedPercentage = Math.min(Math.max(percentage, 0), 100);

  return Math.round(boundedPercentage); // Bulatkan hasilnya
}

export const handleStatus = (status, get = "label") => {
  const statusInfo = {
    pending: {
      label: "Menunggu Pembayaran",
      type: "",
    },
    success: {
      label: "Terbayar",
      type: "success",
    },
    failed: {
      label: "Gagal",
      type: "danger",
    },
    cancelled: {
      label: "Dibatalkan",
      type: "warning",
    },
    unknown: {
      label: "Tidak Terdeteksi",
      type: "info",
    },
  };

  const statusData = statusInfo[status] || statusInfo.unknown;

  if (get === "label") {
    return statusData.label;
  } else if (get === "type") {
    return statusData.type;
  }
};

export const STATUSPAYMENT = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
  CANCELLED: "cancelled",
  UNKNOWN: "new",
};

export const copyTeksInput = (teks) => {
  var TempText = document.createElement("input");
  TempText.value = teks;
  document.body.appendChild(TempText);
  TempText.select();

  document.execCommand("copy");
  document.body.removeChild(TempText);

  Message({
    type: "success",
    message: "Berhasil dicopy.",
  });
};

export default {
  isExpired,
  isOverUsage,
  isTenDaysBeforeExpiry,
  gbToBytes,
  calculateFreeStoragePercent,
  isNearFullUsage,
};

export const defaultDetailPayment = {
  reff_id: null,
  payment_method: null,
  expired_at: null,
  total: null,
  payment_code: null,
  virtual_account: null,
  order_id: null,
  payment_status_url: null,
  transaction_type: null,
  payment_type: null,
};

export const defaultDetailTransaction = {
  transaction_id: null,
  product_id: null,
  transaction_amount: null,
  source_wallet: null,
  destination_number: null,
  payment_code_primary: null,
  payment_code_secondary: null,
  payment_channel: null,
  payment_method: null,
  invoice: null,
  transaction_reference_id: null,
  transaction_note: null,
  pln_paid_amount: null,
  sn_reff: null,
  admin_fee: null,
  transaction_type: null,
  markup: null,
  cashback: null,
  voucher_discount_amount: null,
  charge_to_merchant: null,
  status: null,
  reff_transaction_id: null,
  reff_transaction_id2: null,
  signature: null,
  transaction_cart: null,
  payment_url: null,
  qris_image_url: null,
  qris_text: null,
  created_at: null,
  updated_at: null,
  expired_date: null,
};
