import {
  ApiGetRequest,
  ApiPutRequest,
  ApiPostRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
import { Message } from "element-ui";
import moment from "moment";

const state = {
  data: {
    columns_group_magang: [
      {
        prop: "teacher",
        label: "Nama Guru",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nip",
        label: "NIP",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "start_date",
        label: "Tanggal Mulai",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "end_date",
        label: "Tanggal Berakhir",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Atur Anggota",
        width: "150",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_magang: [
      {
        prop: "name_corporate",
        label: "Perusahaan",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },

      {
        prop: "pic_name",
        label: "Nama PIC",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "pic_telp",
        label: "Telp",
        minWidth: "60",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },

      {
        prop: "setting_group",
        label: "Setting Group",
        width: "140",
        "class-name": "body-14 col-black-2",
      },
    ],
    formDataMagangSettingGroup: {
      teacher_id: "",
      start_date: "",
      end_date: "",
    },
    editdata: false,
    timesDefault: [
      {
        day: 1,
        check: false,
        label: "Senin",
        start_at: "",
        end_at: "",
      },
      {
        day: 2,
        check: false,
        label: "Selasa",
        start_at: "",
        end_at: "",
      },
      {
        day: 3,
        check: false,
        label: "Rabu",
        start_at: "",
        end_at: "",
      },
      {
        day: 4,
        check: false,
        label: "Kamis",
        start_at: "",
        end_at: "",
      },
      {
        day: 5,
        check: false,
        label: "Jumat",
        start_at: "",
        end_at: "",
      },
      {
        day: 6,
        check: false,
        label: "Sabtu",
        start_at: "",
        end_at: "",
      },
      {
        day: 7,
        check: false,
        label: "Minggu",
        start_at: "",
        end_at: "",
      },
    ],
    usertimes: [],
    rowuser: {
      name: "",
      custom_users: [],
      times: [],
    },
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    search: "",
    errors: [],
    itemsTable: [],
    dataMagang: [],
    activeTabMenu: "Data Perusahaan",

    // Atur Anggota
    userSelect: [],

    colums_table_siswa: [
      {
        prop: "name",
        label: "Nama Lengkap",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nisn_nis",
        label: "NISN/NIS",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "grade",
        label: "Jenjang",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "class",
        label: "Kelas",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2 text-center",
      },
    ],
    columns_siswa: [
      {
        prop: "select",
        label: "Check",
        type: "selection",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nis_nik",
        label: "NISN / NIS",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "classes",
        label: "Kelas",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
    ],
    limitSiswa: 10,
    pageSiswa: 1,
    totalSiswa: 0,
    sortBySiswa: "",
    sortTypeSiswa: "",
    filterSiswa: "",
    loadingSiswa: false,
    searchSiswa: "",
    errorsSiswa: [],
    itemTableAnggota: [],
    currentKelas: "",
    majorSelected: "",
    detailSiswa: null,
    dataSiswa: [],
    detailSiswaList: {},
    currentStatus: "active",
  },
};

const mutations = {
  changeMagangSettingGroup(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getDataInternship({ commit, state }, payload) {
    commit("changeMagangSettingGroup", {
      loading: true,
    });
    const { data } = state;

    if (payload) {
      await commit("changeMagangSettingGroup", {
        page: payload || data.page,
      });
    }

    let params = {
      q: data.search,
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };
    const result = await ApiGetRequest(`school/internship`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagangSettingGroup", {
        loading: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        dataMagang: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async getDataSettingInternship({ commit, state }, payload) {
    commit("changeMagangSettingGroup", {
      loading: true,
    });
    const { data } = state;
    if (payload) {
      await commit("changeMagangSettingGroup", {
        page: data.page,
      });
    }
    let params = {
      q: data.search,
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };
    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group`,
      params,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangSettingGroup", {
        loading: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        itemsTable: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async editDataSettingInternship({ commit, state }, payload) {
    commit("changeMagangSettingGroup", {
      loading: true,
    });

    const { data } = state;

    let StartDate = moment(data.formDataMagangSettingGroup.start_date).format(
      "YYYY-MM-DD",
    );

    let EndDate = moment(data.formDataMagangSettingGroup.end_date).format(
      "YYYY-MM-DD",
    );

    let bodyForm = {
      teacher_id: data.formDataMagangSettingGroup.teacher_id,
      start_date: StartDate,
      end_date: EndDate,
    };
    const result = await ApiPutRequest(
      `school/internship/${payload.id}/group/${payload.group_id}`,
      bodyForm,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangSettingGroup", {
        loading: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        loading: false,
      });
      Message({
        type: "success",
        message: "Data berhasil diubah.",
      });
    }
  },
  async submitDataSettingInternship({ commit, state }, payload) {
    commit("changeMagangSettingGroup", {
      loading: true,
    });
    const { data } = state;

    let StartDate = moment(data.formDataMagangSettingGroup.start_date).format(
      "YYYY-MM-DD",
    );

    let EndDate = moment(data.formDataMagangSettingGroup.end_date).format(
      "YYYY-MM-DD",
    );

    let bodyForm = {
      teacher_id: data.formDataMagangSettingGroup.teacher_id,
      start_date: StartDate,
      end_date: EndDate,
    };
    const result = await ApiPostRequest(
      `school/internship/${payload.id}/group`,
      bodyForm,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangSettingGroup", {
        loading: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        loading: false,
      });
      Message({
        type: "success",
        message: "Data berhasil disimpan.",
      });
    }
  },
  async deleteDataSettingInternship({ commit }, payload) {
    commit("changeMagangSettingGroup", {
      loading: true,
    });
    const result = await ApiDeleteRequest(
      `school/internship/${payload.id}/group/${payload.group_id}`,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangSettingGroup", {
        loading: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        loading: false,
      });
      Message({
        type: "success",
        message: "Data berhasil dihapus.",
      });
    }
  },

  // Atur Anggota

  async getDataAnggota({ commit, state }, payload) {
    commit("changeMagangSettingGroup", {
      loadingSiswa: true,
    });
    const { data } = state;

    if (payload) {
      await commit("changeMagangSettingGroup", {
        pageSiswa: data.pageSiswa,
      });
    }

    let params = {
      q: data.searchSiswa,
      limit: data.limitSiswa,
      page: data.pageSiswa,
      sort_field: data.sortBySiswa,
      sort_dir: data.sortTypeSiswa,
    };
    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/student`,
      params,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagangSettingGroup", {
        loadingSiswa: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        itemTableAnggota: result.data.data,
        totalSiswa: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loadingSiswa: false,
      });
    }
  },
  async deleteDataAnggota({ commit }, payload) {
    commit("changeMagangSettingGroup", {
      loadingSiswa: true,
    });
    const result = await ApiDeleteRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/student/${payload.siswa_id}`,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangSettingGroup", {
        loadingSiswa: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        loadingSiswa: false,
      });
      Message({
        type: "success",
        message: "Data berhasil dihapus.",
      });
    }
  },
  async getDataAnggotaDetail({ commit }, payload) {
    commit("changeMagangSettingGroup", {
      loadingSiswa: true,
    });

    const result = await ApiGetRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/student/${payload.siswa_id}`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagangSettingGroup", {
        loadingSiswa: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        detailSiswa: result.data.data,
        loadingSiswa: false,
      });
    }
  },
  async getPelajar({ commit, state }, payload) {
    commit("changeMagangSettingGroup", {
      loadingSiswa: true,
    });

    if (payload) {
      await commit("changeMagangSettingGroup", {
        pageSiswa: payload || data.pageSiswa,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limitSiswa,
      page: data.pageSiswa,
      show: data.currentStatus,
      sort_field: data.sortBySiswa,
      sort_dir: data.sortTypeSiswa,
      q: data.searchSiswa,
    };

    if (
      data.majorSelected &&
      data.majorSelected !== "" &&
      data.majorSelected > 0
    ) {
      params.school_major_id = data.majorSelected;
    }

    if (
      data.currentKelas &&
      data.currentKelas !== "" &&
      data.currentKelas > 0
    ) {
      params.school_class_id = data.currentKelas;
    }

    const result = await ApiGetRequest(`school/student`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMagangSettingGroup", {
        loadingSiswa: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        dataSiswa: result.data.data,
        totalSiswa: result.data.meta.total,
        loadingSiswa: false,
      });
    }
  },
  async submitSiswaGroup({ commit }, payload) {
    commit("changeMagangSettingGroup", {
      loadingSiswa: true,
    });

    let bodyForm = {
      student_id: payload.student_id,
    };
    const result = await ApiPostRequest(
      `school/internship/${payload.id}/group/${payload.group_id}/student`,
      bodyForm,
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeMagangSettingGroup", {
        loadingSiswa: false,
      });
    } else {
      commit("changeMagangSettingGroup", {
        loadingSiswa: false,
        status: "sukses",
      });
      Message({
        type: "success",
        message: "Data berhasil disimpan.",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
