import {
  ApiDeleteRequest,
  ApiGetRequest,
  ApiPostMultipart,
  ApiPostMultipartImportDapodikExecution,
  ApiPostRequest,
  ApiPutRequest,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    dataForm: {
      id: "3ef7a87548bf44bab31aac3f3a418b90",
      name: "Nama Example",
      user_username: "",
      email: "emailexample@gmail.com",
      is_email_verified: false,
      is_payment_activated: 0,
      nis_nik: "7311010",
      phone: null,
      place_of_birth: "SURABAYA",
      date_of_birth: "2005-09-28",
      gender: "L",
      religion: "Islam",
      blood_type: null,
      address: "Tembok Gede 3/48-B",
      rt: "4",
      rw: "2",
      postal_code: "60174",
      village: "Bubutan",
      sub_district_id: "21c02cecb6b94e05926dcc1160f61c1f",
      sub_district: "Gubeng",
      city_id: "873b291eedbc44db89c5843dc7f4e8a2",
      city: "Kota Surabaya",
      province_id: "dd6e854771984bc9a94e334717561d22",
      province: "Jawa Timur",
      profession: null,
      has_sales_id: false,
      student: {
        id: 188739,
        name: "Nama Example",
        nickname: null,
        nisn: "7311010",
        nis: "t7311010",
        place_of_birth: "SURABAYA",
        date_of_birth: "2005-09-28",
        gender: "L",
        religion: "Islam",
        blood_type: null,
        height: 155,
        weight: 55,
        head_circumference: "45",
        uniform_size: null,
        sport_shirt_size: null,
        previous_school: null,
        previous_class: null,
        class: "2023",
        rombel: "10 MM",
        grade: 10,
        class_name: "X-MM",
        major_name: "MULTIMEDIA",
        major: {
          id: 1060,
          name: "MULTIMEDIA",
          soft_deleted: false,
        },
        current_class: {
          id: 124020,
          class_room: {
            id: 4907,
            name: "X-MM",
            grade: 10,
            total_student: 19,
            active: true,
          },
        },
        school_fee: null,
        school_fee_mitigation: null,
        is_suspended: false,
        nik: "t7311010",
        kk: "3578130301083795",
        type_of_residence: "Bersama orang tua",
        transportation: "Jalan kaki",
        distance_residence: "1",
        commute_time: null,
        skhun: null,
        no_kps: null,
        examinee_number: null,
        certificate_number: null,
        has_kip: "Tidak",
        account_name: null,
        bank_name: null,
        account_number: null,
        no_kip: null,
        name_on_kip: "1",
        no_kks: null,
        is_getting_pip: "Tidak",
        reason: null,
        birth_certificate_number: "17920/P/2/2005",
        special_needs: "Tidak ada",
        child_to: "4",
        number_of_siblings: null,
        step_sibling: null,
        foster_sibling: null,
        count_sister_brother: "3",
        daily_language: null,
        phone_number: null,
        scholarships_total: 0,
        collected_certificate: "2023-11-25 09:16:55",
        scholarships: [
          {
            id: 61,
            school_class: "11",
            student_id: 188739,
            school_year: "2024/2025",
            scholarship_type: "1. SepakBola",
            amount: 20000,
          },
          {
            id: 62,
            school_class: "12",
            student_id: 188739,
            school_year: "2025/2026",
            scholarship_type: "1. Kementrian",
            amount: 20000,
          },
          {
            id: 63,
            school_class: "12",
            student_id: 188739,
            school_year: "2025/2026",
            scholarship_type: "2. Tim SepakBola",
            amount: 1000,
          },
        ],
        physical_states: [
          {
            id: 117,
            school_class: "10",
            school_year: "2023/2024",
            student_id: 188739,
            weight: "100",
            height: "100",
            ilness: "-",
            abnormalities: "-",
            created_at: "2023-12-08T07:06:47.000000Z",
            updated_at: "2023-12-08T07:06:47.000000Z",
            deleted_at: null,
          },
        ],
        user: {
          id: "3ef7a87548bf44bab31aac3f3a418b90",
          name: "ademo3smaNama60dumy10",
          user_username: "",
          email: "agsyarif0@gmail.com",
          is_email_verified: false,
          is_payment_activated: 0,
          nis_nik: "7311010",
          phone: null,
          place_of_birth: "SURABAYA",
          date_of_birth: "2005-09-28",
          gender: "L",
          religion: "Islam",
          blood_type: null,
          address: "Tembok Gede 3/48-B",
          rt: "4",
          rw: "2",
          postal_code: "60174",
          village: "Bubutan",
          sub_district_id: "21c02cecb6b94e05926dcc1160f61c1f",
          sub_district: "Gubeng",
          city_id: "873b291eedbc44db89c5843dc7f4e8a2",
          city: "Kota Surabaya",
          province_id: "dd6e854771984bc9a94e334717561d22",
          province: "Jawa Timur",
          profession: null,
          has_sales_id: false,
          soft_deleted: false,
          updated_at: "2023-12-08T06:25:50.000000Z",
          user_avatar_image:
            "https://dev.assets.diskola.id/avatar/a5a5c3733ea5455aac987f7d283df8a8/wKPdoKOmJzkvf5PLkD3oQadqJtuyTYg1Qt9jxFbL.jpg",
          progress_data: 75,
        },
      },
      parent: {
        father_name: "Farid Ma'ruf",
        father_address: null,
        father_phone: null,
        father_job: "Wiraswasta",
        father_salary: "Rp. 2,000,000 - Rp. 4,999,999",
        father_year: "1965",
        father_education: "SMA / sederajat",
        father_nik: "3578132607650001",
        father_religion: null,
        father_place_of_birth: null,
        father_special_needs: null,
        mother_name: "ISTIQOMAH",
        mother_address: null,
        mother_phone: null,
        mother_job: "Karyawan Swasta",
        mother_salary: "Rp. 1,000,000 - Rp. 1,999,999",
        mother_year: "1968",
        mother_education: "S1",
        mother_nik: "3578136412680001",
        mother_religion: null,
        mother_place_of_birth: null,
        mother_special_needs: null,
        vice_name: "Karimin",
        vice_address: null,
        vice_phone: null,
        vice_job: "Karyawan Swasta",
        vice_salary: "Rp. 1,000,000 - Rp. 1,999,999",
        vice_year: "",
        vice_education: "SMA / sederajat",
        vice_nik: "3578136412680001",
        vice_religion: null,
        vice_place_of_birth: null,
        vice_special_needs: null,
      },
      soft_deleted: false,
      updated_at: "2023-12-08T06:25:50.000000Z",
      user_avatar_image:
        "https://dev.assets.diskola.id/avatar/a5a5c3733ea5455aac987f7d283df8a8/wKPdoKOmJzkvf5PLkD3oQadqJtuyTYg1Qt9jxFbL.jpg",
      progress_data: 75,
    },
    formDataScholarships: {},
    formDataPhysicalStates: {},
    id: null,
    loadingDetailPelajar: false,
    tabMenu: [
      {
        name: "Pelajar",
        label: "Peserta Didik",
        lock: false,
        url: "/pengguna/pelajar",
      },
      {
        name: "GuruStaff",
        label: "Guru & Tendik",
        lock: false,
        url: "/pengguna/guru-staff",
      },
      {
        name: "RegistrationViaSso",
        label: "Registrasi Via SSO Google",
        lock: false,
        url: "/pengguna/registrasi-via-sso",
      },
      {
        name: "RequestUserDataStudent",
        label: "Pengajuan Data",
        lock: false,
        url: "/pengguna/pengajuan-data-user/student",
      },
      {
        name: "ValidasiData",
        label: "Validasi Data",
        lock: false,
        url: "/pengguna/validasi",
      },
    ],
  },
};

const mutations = {
  changePelajar(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeDataImport(state, payload) {
    state.data.dataImport = Object.assign({}, state.data.dataImport, payload);
  },
  setPelajarAll(state, payload) {
    state.data.itemsAll = payload;
    localStorage.setItem("user_all", JSON.stringify(payload)); // Simpan objek dalam bentuk JSON
  },
};

const actions = {
  async getHistoryImports({ commit, state }, payload) {
    commit("changeDataImport", {
      loading: true,
    });
    const { data } = state;

    const params = {
      limit: state.data.limit,
      page: state.data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };
    if (payload && payload.data_simple) {
      params.data_simple = true;
    }
    if (payload && payload.sort_field) {
      params.sort_field = payload.sort_field;
    }
    if (payload && payload.sort_dir) {
      params.sort_dir = payload.sort_dir;
    }
    if (data.filter) {
      params.q = payload.filter;
    }
    const result = await ApiGetRequest(`school/import-history`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeDataImport", {
        loading: false,
      });
      return false;
    }
    const send = {
      loading: false,
    };
    send.listImport = result.data.data;
    commit("changeDataImport", send);
    return {
      data: result.data.data,
    };
  },
  async getPelajar({ commit, state }, payload) {
    commit("changePelajar", {
      loading: true,
    });
    const { data } = state;

    let limit = null;
    if (payload) {
      if (typeof payload === "object" && typeof payload.limit !== "undefined") {
        await commit("changePelajar", {
          limit:
            typeof payload.limit !== "undefined" ? payload.limit : data.limit,
        });
        limit =
          typeof payload.limit !== "undefined" ? payload.limit : data.limit;
      } else {
        await commit("changePelajar", {
          page: payload || data.page,
        });
      }
    }

    const params = {
      limit: limit != null ? limit : state.data.limit,
      page: state.data.page,
      show: data.currentStatus,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };
    //if (data.currentHideAlumni && !data.majorSelected && !data.currentKelas) {
    //  params.hide_alumnus = 1;
    //}
    if (payload && payload.data_simple) {
      params.data_simple = true;
    }

    if (
      data.majorSelected &&
      data.majorSelected !== "" &&
      data.majorSelected > 0
    ) {
      params.school_major_id = data.majorSelected;
    }

    if (
      data.currentKelas &&
      data.currentKelas !== "" &&
      data.currentKelas > 0
    ) {
      params.school_class_id = data.currentKelas;
    }

    const result = await ApiGetRequest(`school/student`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePelajar", {
        loading: false,
      });
    } else {
      const send = {
        loading: false,
      };
      if (payload && payload.data_simple) {
        commit("changePelajar", send);
        commit("setPelajarAll", result.data.data);
        return true;
      }
      send.items = result.data.data;
      if (typeof payload === "object") {
        commit("changePelajar", send);
        return {
          data: result.data.data,
        };
      } else {
        send.total = result.data.meta.total;
        commit("changePelajar", send);
        return {
          data: result.data.data,
          total: result.data.meta.total,
        };
      }
    }
  },

  async getPelajarById({ commit, state, dispatch }, payload) {
    commit("changePelajar", {
      loading: true,
    });

    const { data } = state;
    const result = await ApiGetRequest(`school/student/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePelajar", {
        loading: false,
      });
      return false;
    }

    let newDataForm = data.dataForm;
    newDataForm = Object.assign({}, newDataForm, result.data.data.student);
    newDataForm = Object.assign({}, newDataForm, result.data.data);
    newDataForm = Object.assign({}, newDataForm, result.data.data.parent);
    newDataForm["major_id"] = newDataForm?.student?.major?.id || 0;

    dispatch(
      "fileStorage/getDataFileStorage",
      { uuid: payload.id },
      { root: true },
    );

    let dataCities = await dispatch(
      "cities/getCities",
      newDataForm.province_id,
      { root: true },
    );

    let dataDistricts = await dispatch(
      "districts/getDistricts",
      newDataForm.city_id,
      { root: true },
    );

    state.data.defaultDataForm = Object.assign(
      {},
      newDataForm,
      state.data.defaultDataForm,
    );
    commit("changePelajar", {
      formDataScholarships:
        (result.data.data.student && result.data.data.student.scholarships) ||
        [],
      formDataPhysicalStates:
        (result.data.data.student &&
          result.data.data.student.physical_states) ||
        [],
      dataForm: newDataForm,
      cities: dataCities,
      districts: dataDistricts,
      id: payload.id,
      loading: false,
    });
    return true;
  },

  async getDetailPelajarByIdOnly({ commit, state }, payload) {
    commit("changePelajar", {
      loadingDetailPelajar: true,
    });

    const { data } = state;
    const result = await ApiGetRequest(`school/student/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePelajar", {
        loadingDetailPelajar: false,
      });
      return false;
    }

    let newDataForm = data.dataForm;
    newDataForm = Object.assign({}, newDataForm, result.data.data.student);
    newDataForm = Object.assign({}, newDataForm, result.data.data);
    newDataForm = Object.assign({}, newDataForm, result.data.data.parent);
    newDataForm["major_id"] = newDataForm?.student?.major?.id || 0;

    commit("changePelajar", {
      formDataScholarships:
        (result.data.data.student && result.data.data.student.scholarships) ||
        [],
      formDataPhysicalStates:
        (result.data.data.student &&
          result.data.data.student.physical_states) ||
        [],
      dataForm: newDataForm,
      id: payload.id,
      loadingDetailPelajar: false,
    });
    return true;
  },

  async resetAkunPelajar(context, payload) {
    const { formData, id } = payload;
    const result = await ApiPutRequest(`school/user/${id}/password`, formData);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data password berhasil di reset",
      });

      return true;
    }
  },
  async deleteAkunPelajar(context, payload) {
    const { id } = payload;
    const result = await ApiDeleteRequest(`school/student/${id}`);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Akun telah dinonaktifkan",
      });

      return true;
    }
  },
  async restoreAkunPelajar(context, payload) {
    const { id } = payload;
    const result = await ApiPostRequest(`school/student/${id}/restore`, {});
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      Message({
        type: "success",
        message: "Akun telah diaktifkan kembali",
      });
      return true;
    }
  },
  async importExcelPelajar({ commit }, payload) {
    commit("changePelajar", {
      loading: true,
    });

    const result = await ApiPostRequest(`school/student`, payload.formData);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePelajar", {
        loading: false,
      });

      return false;
    } else {
      commit("changePelajar", {
        loading: false,
      });

      const items = result.data.data;

      // check kalo return respone ada error
      const errorItems = await items.filter((item) => {
        if (item.error) {
          return item;
        }
      });

      if (errorItems.length > 0) {
        commit("changePelajar", {
          jsonData: errorItems,
        });

        let message = "";
        if (errorItems.length !== payload.formData.length) {
          message = "Beberapa data gagal ditambahkan ke server";
        } else {
          message = "Data gagal ditambahkan ke server";
        }

        Message({
          type: "error",
          message: message,
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data Pelajar berhasil ditambahkan",
        });

        return true;
      }
    }
  },
  async createPelajar({ commit, state }, payload) {
    commit("changePelajar", {
      error: false,
    });

    const { data } = state;
    let filteredData = Object.entries(data.dataForm).reduce(
      (obj, [key, value]) => {
        if (value !== "" && value !== null && value !== undefined) {
          obj[key] = value;
        }
        return obj;
      },
      {},
    );
    if (payload && payload.physicals) {
      let dt = {
        physicals: payload.physicals,
      };
      filteredData = { ...filteredData, ...dt };
    }
    if (payload && payload.scholarships) {
      let dtScholarships = {
        scholarships: payload.scholarships,
      };
      filteredData = { ...filteredData, ...dtScholarships };
    }
    const result = await ApiPostRequest(
      `school/student/store-manual`,
      filteredData,
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan data, periksa kembali data anda",
      });

      commit("changePelajar", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    }
    commit("changePelajar", {
      loading: false,
      error: false,
    });

    Message({
      type: "success",
      message: "Data Pelajar berhasil ditambahkan",
    });

    return result.data.data;
  },
  async updatePelajar({ commit, state }, payload) {
    commit("changePelajar", {
      loading: true,
      error: false,
    });

    const { data } = state;
    if (data.dataForm && data.dataForm.nis_nik) {
      data.dataForm.nis_nik = data.dataForm.nis_nik.replace("diskola-", "");
    }

    if (data.dataForm && data.dataForm.nisn) {
      data.dataForm.nisn = data.dataForm.nisn.replace("diskola-", "");
    }

    if (data.dataForm && data.dataForm.nis) {
      data.dataForm.nis = data.dataForm.nis.replace("diskola-", "");
    }

    if (data.dataForm && data.dataForm.student && data.dataForm.student.nis) {
      data.dataForm.student.nis = data.dataForm.student.nis.replace(
        "diskola-",
        "",
      );
    }

    if (data.dataForm && data.dataForm.student && data.dataForm.student.nisn) {
      data.dataForm.student.nisn = data.dataForm.student.nisn.replace(
        "diskola-",
        "",
      );
    }

    if (
      data.dataForm &&
      data.dataForm.student &&
      data.dataForm.student.user &&
      data.dataForm.student.user.nis_nik
    ) {
      data.dataForm.student.user.nis_nik =
        data.dataForm.student.user.nis_nik.replace("diskola-", "");
    }
    let filteredData = Object.keys(state.data.dataForm).reduce(
      (result, key) => {
        if (
          state.data.dataForm[key] !== state.data.defaultDataForm[key] ||
          key === "id" ||
          key === "name"
        ) {
          if (key !== "parent" && key !== "student" && key !== "user") {
            result[key] = state.data.dataForm[key];
          }
        }
        return result;
      },
      {},
    );
    if (payload && payload.physicals) {
      let dt = {
        physicals: payload.physicals,
      };
      filteredData = { ...filteredData, ...dt };
    }
    if (payload && payload.scholarships) {
      let dtScholarships = {
        scholarships: payload.scholarships,
      };
      filteredData = { ...filteredData, ...dtScholarships };
    }
    const result = await ApiPutRequest(
      `school/student/${data.id}`,
      filteredData,
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data, periksa kembali data anda",
      });

      commit("changePelajar", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    }
    if (!result) {
      Message({
        type: "error",
        message: "Response Kosong. Proses gagal dikirim.",
      });

      commit("changePelajar", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });
      return false;
    }
    commit("changePelajar", {
      loading: false,
    });

    Message({
      type: "success",
      message: "Data Pelajar berhasil diubah",
    });

    return true;
  },

  async updateImageUser({ commit }, payload) {
    commit("changePelajar", {
      loading: true,
    });

    const result = await ApiPostMultipart(
      `school/user/${payload.id}/profile-image`,
      payload.dataForm,
    );
    if (result.error) {
      if (!payload.form_client) {
        Message({
          type: "error",
          message: "Gagal mengubah gambar profil",
        });
      }

      commit("changePelajar", {
        loading: false,
        error: result.errorList || false,
      });

      return false;
    }
    commit("changePelajar", {
      loading: false,
      error: false,
    });
    return true;
  },
  async uploadFileExcelDapodik({ commit }, payload) {
    commit("changeDataImport", {
      loading: true,
    });
    console.log(payload);

    const result = await ApiPostMultipart(
      `school/student/import-dapodik`,
      payload.dataForm,
    );
    //const result = await ApiPostMultipart(
    //  `queue/validasi`,
    //  payload.dataForm
    //);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeDataImport", {
        loading: false,
        validasi: [],
        meta: {},
        error: result.errorList || false,
      });
      return false;
    }
    await commit("changeDataImport", {
      validasi: result.data.data,
      meta: result.data.meta,
      loading: false,
      error: false,
    });
    return true;
  },
  async uploadFileExcelDapodikExecution({ commit }, payload) {
    commit("changeDataImport", {
      loading: true,
    });
    const result = await ApiPostMultipartImportDapodikExecution(
      `school/student/import-execution`,
      payload.dataForm,
    );
    /*const result = await ApiPostMultipartImportDapodikExecution(
      `queue/import`,
      payload.dataForm
    );*/
    if (result.error) {
      Message({
        type: "error",
        message:
          "Gagal Proses Import. File Terdapat kendala saat pengiriman ke server.",
      });
      commit("changeDataImport", {
        loading: false,
        validasi: [],
        meta: {},
        error: result.errorList || false,
      });
      return false;
    }
    await commit("changeDataImport", {
      validasi: [],
      meta: {},
      loading: false,
      error: false,
    });
    return true;
  },
};

const getters = {
  // Hitung jumlah halaman berdasarkan jumlah item dan item per halaman
  totalData: (state) => {
    return Math.ceil(
      state.data.dataImport.validasi.length / state.data.dataImport.page,
    );
  }, // Potong data berdasarkan halaman saat ini dan item per halaman
  displayedItems: (state) => {
    const imp = state.data.dataImport;
    if (!imp.validasi.length) {
      return {
        list: [],
        totalData: 0,
      };
    }
    let filteredData = imp.validasi;
    if (imp.filter) {
      filteredData = filteredData.filter((item) =>
        item.user.name.toLowerCase().includes(imp.filter.toLowerCase()),
      );
    }
    if (imp.filterRombel && imp.filterRombel !== "Semua") {
      filteredData = filteredData.filter((item) =>
        item.user.student.rombel
          .toLowerCase()
          .includes(imp.filterRombel.toLowerCase()),
      );
    }
    if (imp.status) {
      filteredData = filteredData.filter(
        (item) => item.user.status === imp.status,
      );
    }
    if (!filteredData.length) {
      return {
        list: [],
        totalData: 0,
      };
    }

    const start = (imp.page - 1) * imp.limit;
    const end = start + imp.limit;
    return {
      list: filteredData.slice(start, end),
      totalData: filteredData.length,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
