// import { ApiGetRequest } from '@/utils/Api';
// import { Message } from 'element-ui';

const state = {
  data: {
    menu: [
      {
        label: "Presensi Guru & Tendik",
        name: "PresensiGuruStaff",
        url: "/presensi/presensi-guru-staff",
        lock: false,
      },
      {
        label: "Presensi Pelajar",
        name: "PresensiPelajar",
        url: "/presensi/presensi-pelajar",
        lock: false,
      },
      {
        label: "Jurnal KBM",
        name: "PresensiKelas",
        url: "/presensi/presensi-kelas",
        lock: false,
      },
      {
        label: "Pengaturan Jam",
        name: "PengaturanJam",
        url: "/presensi/pengaturan-jam",
        lock: false,
      },
      /*{
        label: 'Kalender Sekolah',
        name: 'KalenderSekolah',
        url: '/presensi/kalender-sekolah',
        lock: false,
      },*/
      {
        label: "Mesin Absensi",
        name: "MesinAbsensi",
        url: "/presensi/mesin-absensi",
        lock: true,
      },
			/*{
				label: 'Absensi CCTV',
				name: 'AbsensiCctv',
				url: '/presensi/absensi-cctv',
				lock: false,
			},*/
    ],
    manualActiveMenu: "",
  },
};

const mutations = {
  changeKlastime(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
