import { Message } from "element-ui";
import WebschoolService from "@/services/webschoolService";
import { ApiGetRequest } from "@/utils/Api";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    page: 1,
    total: 0,
    limit: 1,
    items: [],
    filter: "",
    filter_type: "",
    loading: false,
    loadingFilterStudent: false,
    showModal: false,
    showModalDetailBKK: false,
    summaryStudentBkk: {
			"total": 0,
			"internship": 0,
			"work": 0
		},
    formDataSelectedSosmed: [],
    formDataLinkSosmed: {
      facebook: "",
      instagram: "",
      linkedin: "",
      github: "",
    },
    formData: {
      id: null,
      uuid: null,
      name: null,
      email: null,
      phone: null,
      body: null,
      place_of_birth: null,
      date_of_birth: null,
      address: null,
      major: null,
      grade: null,
      type: null,
      graduate: null,
      next_education: null,
      program_studies: null,
      job: null,
      work_at: null,
      status: null,
      social_media: {
        facebook: "",
        instagram: "",
        linkedin: "",
        github: "",
      },
    },
    formDataBulk: {},
    columns: [
      {
        prop: "name",
        label: "Pelajar",
        minWidth: 200,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "type",
        label: "Kategori",
        minWidth: "160",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "grade",
        label: "Tingkat",
        width: "140",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "job",
        label: "Pekerjaan",
        width: "180",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "next_education",
        label: "Pendidikan",
        width: "120",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        width: 120,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        width: "160",
        "class-name": "body-14 col-black-2",
      },
    ],
    companyRequest: {
      page: 1,
      total: 0,
      limit: 1,
			sortBy: "",
			sortType: "",
      items: [],
      filter: "",
      loading: false,
      columns: [
        {
          prop: "company_name",
          label: "Perusahaan",
					sortable: true,
          minWidth: 230,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "name",
          label: "Pelajar",
          width: 160,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "type",
          label: "Jenis",
          width: "180",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "job",
          label: "Pekerjaan",
          width: 120,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "next_education",
          label: "Pendidikan",
          width: 140,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "work_at",
          label: "Bekerja di",
          width: 120,
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "created_at",
          label: "Tanggal",
          width: 110,
          "class-name": "body-14 col-black-2",
        },
      ],
    },
  },
};

const mutations = {
  changeKlasiteBkkCompany(state, payload) {
    state.data.companyRequest = Object.assign(
      {},
      state.data.companyRequest,
      payload,
    );
  },
  changeKlasiteBkkCompanyLoading(state, payload) {
    state.data.companyRequest.loading = payload;
  },
  changeKlasiteBkk(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKlasiteBkkLoading(state, payload) {
    state.data.loading = payload;
  },
  changeKlasiteBkkFormData(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
};

const actions = {
  async searchPelajar(context, payload = null) {
    context.commit("changeKlasiteBkk", {
      loadingFilterStudent: true,
    });
    const result = await ApiGetRequest(
      `school/student?q=${(payload && payload.search) || ""}`,
    );
    context.commit("changeKlasiteBkk", {
      loadingFilterStudent: false,
    });

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data pelajar",
      });

      return [];
    } else {
      return result.data.data || [];
    }
  },

  async fetchStudentBkkSummary({ commit }) {
    try {
      const webschoolService = new WebschoolService();
      const result = await webschoolService.getStudentBkkSummary();
      commit("changeKlasiteBkk", {
				summaryStudentBkk: result.data.data
      });

      if (!result) {
				console.log('no response')
        return false;
      }
      return true;
    } catch (error) {
      commit("changeKlasiteBkk", {
				summaryStudentBkk: {
					"total": 0,
					"internship": 0,
					"work": 0
				}
      });
    }
  },

  async fetchStudentBkk({ commit, state }, payload) {
    commit("changeKlasiteBkkLoading", true);
    if (typeof payload === "number") {
      commit("changeKlasiteBkk", {
        page: payload,
      });
    }

    const { page, filter, filter_type } = state.data;

    const params = {
      page: page,
      limit: 10,
    };

    if (filter) {
      params.q = filter;
    }
    if (filter_type) {
      params.type = filter_type;
    }

    try {
      const webschoolService = new WebschoolService();
      const result = await webschoolService.getStudentBkk(params);
      commit("changeKlasiteBkkLoading", false);

      commit("changeKlasiteBkk", {
        items: result.data?.data || [],
        total: result.data?.meta?.total || result.data?.data.length || 0,
      });

      if (!result) {
        Message({
          type: "info",
          message: "No Response",
        });
        return false;
      }
      return true;
    } catch (error) {
      commit("changeKlasiteBkkLoading", false);
      commit("changeKlasiteBkk", {
        items: [],
        total: 0,
      });
      if (!error.response) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }

      if (error.response.status >= 500) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }

      Message({
        type: "error",
        message: error.response.data.error
          ? error.response.data.error
          : error.response.data.message
            ? error.response.data.message
            : "Gagal mengambil data",
      });
    }
  },

  async updateStatusStudentBkk({ commit }, payload) {
    const { status, uuid } = payload;
    commit("changeKlasiteBkkLoading", true);
    try {
      const webschoolService = new WebschoolService();
      const result = await webschoolService.updateStatusStudentBkk(
        uuid,
        status,
      );
      commit("changeKlasiteBkkLoading", false);

      commit("changeKlasiteBkk", {
        page: 1,
      });

      if (!result) {
        Message({
          type: "info",
          message: "No Response",
        });
        return false;
      }
      return true;
    } catch (error) {
      commit("changeKlasiteBkkLoading", false);
      if (!error.response) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }
      if (error.response.status >= 500) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }

      Message({
        type: "error",
        message: error.response.data.error
          ? error.response.data.error
          : error.response.data.message
            ? error.response.data.message
            : "Gagal merubah data",
      });
      return false;
    }
  },

  async postStudentBkk({ commit, dispatch, state }) {
    commit("changeKlasiteBkkLoading", true);

    const { formData } = state.data;
    try {
      const webschoolService = new WebschoolService();
      let result = null;
      if (formData.id) {
        result = await webschoolService.updateStudentBkk(formData.id, formData);
      } else {
        result = await webschoolService.postStudentBkk(formData.uuid, formData);
      }
      commit("changeKlasiteBkkLoading", false);

      if (!result) {
        Message({
          type: "info",
          message: "No Response",
        });
        return false;
      }

      dispatch("fetchStudentBkk");
      return true;
    } catch (error) {
      commit("changeKlasiteBkkLoading", false);
      if (!error.response) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }
      if (error.response.status >= 500) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }

      Message({
        type: "error",
        message: error.response.data.error
          ? error.response.data.error
          : error.response.data.message
            ? error.response.data.message
            : "Gagal menambahkan data",
      });
      return false;
    }
  },

  async deleteStudentBkk({ commit }, uuid) {
    commit("changeKlasiteBkkLoading", true);
    try {
      const webschoolService = new WebschoolService();
      await webschoolService.deleteStudentBkk(uuid);
      commit("changeKlasiteBkkLoading", false);
      return true;
    } catch (error) {
      commit("changeKlasiteBkkLoading", false);
      if (!error.response) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }
      if (error.response.status >= 500) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }

      Message({
        type: "error",
        message: error.response.data.error
          ? error.response.data.error
          : error.response.data.message
            ? error.response.data.message
            : "Gagal menghapus data",
      });
      return false;
    }
  },

  async fetchCompanyStudentBkk({ commit, state }, payload) {
    commit("changeKlasiteBkkCompanyLoading", true);
    if (typeof payload === "number") {
      commit("changeKlasiteBkkCompany", {
        page: payload,
      });
    }

    const { page, sortBy, sortType, filter, filter_type } = state.data.companyRequest;

    const params = {
      page: page,
      limit: 10,
    };

    if (filter) {
      params.q = filter;
    }

    if (sortBy) {
      params.sort_field = sortBy;
      params.sort_dir = sortType || 'asc';
    }

    if (filter_type) {
      params.type = filter_type;
    }

    try {
      const webschoolService = new WebschoolService();
      const result = await webschoolService.getCompanyStudentBkk(params);
      commit("changeKlasiteBkkCompanyLoading", false);

      commit("changeKlasiteBkkCompany", {
        items: result.data?.data || [],
        total: result.data?.meta?.total || result.data?.data.length || 0,
      });

      if (!result) {
        Message({
          type: "info",
          message: "No Response",
        });
        return false;
      }
      return true;
    } catch (error) {
      commit("changeKlasiteBkkCompanyLoading", false);
      commit("changeKlasiteBkkCompany", {
        items: [],
        total: 0,
      });
      if (!error.response) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }

      if (error.response.status >= 500) {
        Message({
          type: "error",
          message: SERVER_ERROR_MESSAGE,
        });
        return false;
      }

      Message({
        type: "error",
        message: error.response.data.error
          ? error.response.data.error
          : error.response.data.message
            ? error.response.data.message
            : "Gagal mengambil data",
      });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
