import SchoolClassRepository from "@/repositories/SchoolClassRepository";

export default class SchoolClassService {
	constructor() {
		this.scRepository = new SchoolClassRepository();
	}

	async summaryGradesGet() {
		try {
			return await this.scRepository.getSummaryGrades();
		} catch (error) {
			console.error("call server error:", error);
			throw error;
		}
	}
}
