import ApiService from "@/utils/ApiService";

const ENDPOINT = "school/student";

/**
 * Modul untuk mengelola data terkait sekolah dan Klasdrive.
 * @module StudentImplement
 */
export default {
  /**
   * mengambail data berdasarkan data_simple, alumni ditampilkan
   */
  studentAllData() {
    const params = {};
    params.sort_field = "name";
    params.sort_dir = "asc";
    params.page = 1;
    params.limit = 0;
    params.data_simple = true;
    params.alumni = 1;
    return ApiService.get(`${ENDPOINT}`, { params });
  },
};
