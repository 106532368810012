import ApiService from "@/api/ApiService";
import axios from "axios";

const PATH_FIRST = "school";
//const MOCK = "http://localhost:3000/api";

export default class SchoolClassRepository {
	constructor() {
		this.apiService = ApiService;
		this.cancelTokenSource = null;
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getSummaryGrades() {
		return await this.apiService.get(`${PATH_FIRST}/grade/summary`);
	}
}
