import {ApiGetRequest} from "@/utils/Api";
import GlobalService from "@/services/GlobalService";
import {DUPLICATE_CALL_SERVER_MESSAGE} from "@/utils/messageUtils";
import moment from "moment";

const localStorageDashboardPresenceKey = "diskolaDashboard_presence";
//const localStorageDashboardStatisticKey = "diskolaDashboard_statistic";
const expirationTime = 10 * 60 * 1000; // 10 menit in milliseconds

const state = {
	data: {
		statistik: {
			total_student: 0,
			student: 0,
			teacher: 0,
			staff: 0,
			rombel: 0,
		},
		isLoadingStatik: false,
		isLoadingPresence: false,
		presence: {
			"summary": {
				"late": 0,
				"presence": 0,
				"early_out": 0,
				"absent": 0
			},
			"percentage": {
				"late": 0,
				"presence": 0,
				"early_out": 0,
				"absent": 0
			}
		},
		filterRole: "student",
		filterDate: [],
		callServer: false,
		callServerPresence: false,
		forceCallServerPresence: false,
		errors: [],
	},
};

const mutations = {
	changeBerandaStatistik(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeBerandaPresenceLoading(state, payload) {
		state.data.isLoadingPresence = payload
	},
	setDataDashboardPresence(state, payload) {
		const {data, lastUpdated} = payload
		localStorage.setItem(localStorageDashboardPresenceKey, JSON.stringify({
			data: data,
			lastUpdated: lastUpdated,
		}));
	},
	setDataDashboardStatistic(state, payload) {
		const {data, lastUpdated} = payload
		localStorage.setItem(localStorageDashboardPresenceKey, JSON.stringify({
			data: data,
			lastUpdated: lastUpdated,
		}));
	},
};

const actions = {
	async getSummary({commit}) {
		commit("changeBerandaStatistik", {
			statistik: {
				total_student: 0,
				student: 0,
				teacher: 0,
				staff: 0,
				rombel: 0,
			},
			callServer: false,
		});

		const result = await ApiGetRequest(`school/dashboard/statistic/summary`);
		if (!result.error) {
			await commit("changeBerandaStatistik", {
				statistik: result.data.data,
				callServer: true,
			});
		}
	},

	async getPresenceSummary({commit, state}) {
		const defaultData = {
			summary: {late: 0, presence: 0, early_out: 0, absent: 0},
			percentage: {late: 0, presence: 0, early_out: 0, absent: 0}
		};

		const now = moment().valueOf();
		const storedData = JSON.parse(localStorage.getItem(localStorageDashboardPresenceKey));

		// Check if stored data is valid and not expired
		if (!state.data.forceCallServerPresence && storedData && typeof storedData === "object" && (now - storedData.lastUpdated < expirationTime)) {
			commit("changeBerandaStatistik", {callServerPresence: false, presence: storedData.data});
			return true;
		}

		// Check if presence is already loading
		if (state.data.isLoadingPresence) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE);
			return false;
		}

		// Reset presence statistics to default values
		commit("changeBerandaStatistik", {
			callServerPresence: false,
			presence: JSON.parse(JSON.stringify(defaultData))
		});

		const params = {
			role: state.data.filterRole
		};

		if (state.data.filterDate.length > 0) {
			params.filter = 'range_date'
			params.start_date = state.data.filterDate[0]
			params.end_date = state.data.filterDate[1]
		}

		try {
			commit("changeBerandaPresenceLoading", true);
			const globalService = new GlobalService();
			const result = await globalService.presenceSummaryGet(params);

			commit("setDataDashboardPresence", {lastUpdated: now, data: result.data?.date || defaultData});
			commit("changeBerandaStatistik", {presence: result.data?.date || JSON.parse(JSON.stringify(defaultData))});
		} catch (error) {
			console.log(error);
			commit("changeBerandaStatistik", {callServerPresence: true, presence: JSON.parse(JSON.stringify(defaultData))});
		} finally {
			commit("changeBerandaPresenceLoading", false);
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
