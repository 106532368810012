import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest,
} from "@/utils/Api";
import { Message } from "element-ui";
import { _ } from "vue-underscore";
import moment from "moment";
import store from "../../index";
import { checkVw } from "@/services/internalService";
import { findIndexDatases } from "@/utils/UjianUtils";

const state = {
  data: {
    formJadwalUjian: {
      modalConfirm: true,
      modalConfirmJadwalUjian: false,
      messageConfirmJadwalUjian: "",
      titleModal: "",
      wherepage: "",
      progress: 0,
      color: "",
      formName: "",
      formData: [],
      messageResults: [],
    },
    labelMenu: "Jadwal Ujian",
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    prosesCallExamTemplate: false,
    selectUjian: false,
    loadingSearchUjian: false,
    loadingDetailTemplate: false,
    errors: [],
    filterClass: "",
    filterSubject: "",
    searchParams: "",
    ujian: [],
    kelas: [],
    items: [],
    detailDataJadwalUjian: [
      {
        id: "",
        waktu: "",
        kelas: "",
        ruang_kelas: "",
        pelajar: "",
        nama_ujian: "",
        mapel: "",
      },
    ],
    formData: {
      id: "",
      time_picker: new Date(),
      school_class: "",
      exam_template: "",
      template_name: "",
      class_name: "",
      password: "",
      teacher_name: "",
      date: "",
      school_subject_id: "",
      start_at: {
        hour: "00",
        minute: "00",
      },
      end_at: {
        hour: "00",
        minute: "00",
      },
    },
    detailJadwalUjian: [
      {
        name: "Jadwal Ujian",
        active: true,
      },
      {
        name: "Daftar Pelajar",
        active: false,
      },
    ],
    activeDetailJadwalUjian: "Jadwal Ujian",
    formRules: {
      school_subject_id: [
        {
          required: true,
          message:
            "Pastikan sudah mengisi Nama Ujian, Jika sudah terisi, Tekan Tombol Biru",
          trigger: "blur",
        },
      ],
      list_class_name: [
        {
          required: true,
          message:
            "Kelas tidak boleh kosong, Jika sudah terisi, Tekan Tombol Biru",
          trigger: "blur",
        },
      ],
      class_name: [
        {
          required: true,
          message: "Kelas harus diisi, Jika sudah terisi, Tekan Tombol Biru",
          trigger: "blur",
        },
      ],
      exam_template: [
        {
          required: true,
          message:
            "Nama ujian tidak boleh kosong, Jika sudah terisi, Tekan Tombol Biru",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "Password tidak boleh kosong",
          trigger: "blur",
        },
      ],
      date: [
        {
          required: true,
          message: "Tanggal tidak boleh kosong",
          trigger: "blur",
        },
      ],
    },
    columns_jadwal_ujian: [
      {
        prop: "schedule_exam_time",
        label: "Waktu",
        width: "170",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_class",
        label: "Kelas",
        width: "80",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_subject",
        label: "Mapel",
        width: "180",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_classroom",
        label: "Ruang Kelas",
        width: "200",
        minWidth: "150",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },

      {
        prop: "schedule_exam_student",
        label: "Pelajar",
        width: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_name",
        label: "Nama Ujian",
        sortable: true,
        minWidth: 200,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        width: "100",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "show_score",
        label: "Nilai",
        width: "100",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: checkVw(140),
        "class-name": "body-14 col-black-2",
      },
    ],
    examle_data: [
      {
        id: "1",
        schedule_exam_time: "9 Apr 2021, 08:00 - 09:30",
        schedule_exam_class: "X IPA 1",
        schedule_exam_subject: `Fisika`,
        schedule_exam_classroom: `X IPA 1`,
        schedule_exam_password: `123456`,
        schedule_exam_student: `30`,
        schedule_exam_name: `Ujian Mingguan Seri 1`,
      },
    ],
    columns_detail_jadwal_ujian: [
      {
        prop: "schedule_exam_time",
        label: "Waktu",
        minWidth: "150",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_class",
        label: "Kelas",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_subject",
        label: "Mapel",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_classroom",
        label: "Ruang Kelas",
        width: "200",
        minWidth: "150",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_student",
        label: "Pelajar",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "schedule_exam_name",
        label: "Nama Ujian",
        minWidth: "150",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
  },
};

const mutations = {
  changeJadwalUjian(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeFormJadwalUjian(state, payload) {
    state.data.formJadwalUjian = Object.assign(
      {},
      state.data.formJadwalUjian,
      payload,
    );
  },
};

const actions = {
  async searchUjian({ commit }, payload) {
    let params = {};
    params.filter = "active";
    if (payload) {
      params.q = payload;
    }
    commit("changeJadwalUjian", {
      loadingSearchUjian: true,
    });
    const result = await ApiGetRequest(`school/exam/kkm/exam-template`, params);

    commit("changeJadwalUjian", {
      loadingSearchUjian: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      // const dataObj = result.data.data.map(item => {
      // if (item && item.teacher && item.teacher.user && item.teacher.user.id) {
      //   if (item.teacher.user.id === "5658e29f09334937ad711bd96621fbaf") {
      // item["value"] = item.name;
      // return item;
      // }
      // }
      // return {};
      // });
      let idUserLogin =
        (store.state && store.state.user && store.state.user.id) || 0;
      let role =
        (store.state && store.state.user && store.state.user.role) || [];
      let dataObj = null;
      if (
        result &&
        result.data &&
        result.data.data &&
        result.data.data.length
      ) {
        dataObj = result.data.data
          .filter((item) => {
            const schoolAdmin = role.find(
              (roleItem) => roleItem.name === "School Admin",
            );
            const SuperAdmin = role.find(
              (roleItem) => roleItem.name === "SuperAdmin",
            );
            const vicePrincipal = role.find(
              (roleItem) => roleItem.name === "Vice Principal",
            );
            if (schoolAdmin || vicePrincipal || SuperAdmin) {
              return true; // Mengambil semua data jika peran "school-admin" ditemukan
            }
            let user_id = (item.teacher.user && item.teacher.user.id) || "012";
            return user_id === idUserLogin;
          })
          .map((item) => ({ value: item.name, ...item }));
      }

      commit("changeJadwalUjian", {
        ujian: dataObj || [],
      });
    }
  },
  async searchKelas({ commit }, payload) {
    let params = {};
    if (payload) {
      params.q = payload;
    }
    params.wherepage = "exam";
    const result = await ApiGetRequest(`school/class`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      const dataObj = result.data.data.map((item) => {
        item["value"] = item.name;
        return item;
      });

      commit("changeJadwalUjian", {
        kelas: dataObj || [],
      });
    }
  },
  async getJadwalUjian({ commit, state }, payload) {
    commit("changeJadwalUjian", {
      loading: true,
    });

    if (payload) {
      await commit("changeJadwalUjian", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
      class: data.filterClass,
      subject: data.filterSubject,
      q: data.searchParams,
      sort_field: data.sortBy || undefined,
      sort_dir: data.sortType || undefined,
    };

    const result = await ApiGetRequest("school/exam/kkm/exam-schedule", params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeJadwalUjian", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeJadwalUjian", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async deleteJadwalUjian({ commit }, payload = null) {
    commit("changeJadwalUjian", {
      loading: true,
    });

    if (payload && payload.id) {
      const result = await ApiDeleteRequest(
        `school/exam/kkm/exam-schedule/${payload.id}`,
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });

        commit("changeJadwalUjian", {
          errors: result.errorList,
          loading: false,
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data telah dihapus",
        });

        commit("changeJadwalUjian", {
          errors: [],
          loading: false,
        });

        return true;
      }
    }
  },

  async getDetailUjianById({ commit, state }, payload) {
    const { data } = state;

    commit("changeJadwalUjian", {
      loadingDetailTemplate: true,
      loading: true,
      formData: {
        ...state.data.formData,
        exam_template: "",
        school_subject_id: "",
        teacher_name: "",
        template_name: "",
        class_name: "",
        password: "",
      },
    });

    const result = await ApiGetRequest(
      `school/exam/kkm/exam-template/${payload}`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeJadwalUjian", {
        loadingDetailTemplate: false,
        loading: false,
      });

      return [];
    } else {
      let formData = data.formData;
      formData.school_subject_id = result.data.data.subject.id;
      formData.teacher_name = result.data.data.teacher.name;

      await commit("changeJadwalUjian", {
        loadingDetailTemplate: false,
        loading: false,
        formData: formData,
      });
    }
  },

  async submitJadwalUjian({ commit, state, dispatch }) {
    const { data } = state;

    commit("changeJadwalUjian", {
      loading: true,
    });

    const formData = {
      // password: data.formData.password,
      school_class_id: data.formData.school_class,
      exam_template_id: data.formData.exam_template,
      date: moment(data.formData.date).format(),
      start_at: data.formData.start_at,
      end_at: data.formData.end_at,
      show_score: data.formData.show_score || 0,
    };

    let result = "";

    if (data.formData.id) {
      result = await ApiPutRequest(
        `school/exam/kkm/exam-schedule/${data.formData.id}`,
        formData,
      );
    } else {
      result = await ApiPostRequest("school/exam/kkm/exam-schedule", formData);
    }

    if (!result) {
      commit("changeJadwalUjian", {
        loading: false,
      });
      Message({
        type: "error",
        message: "Terjadi kesalahan, Coba Ulangi lagi!",
      });
      return false;
    }
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      if (data.formData.id) {
        Message({
          type: "error",
          message: message,
        });
      }
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeJadwalUjian", {
          errors: errors,
        });
      }
      commit("changeJadwalUjian", {
        loading: false,
      });
      return result.error;
    }

    if (data.formData.id) {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
    dispatch("resetForm");
    return true;
  },

  async getDetailJadwalUjianById({ commit }, payload) {
    commit("changeJadwalUjian", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/exam/kkm/exam-schedule/${payload}`,
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeJadwalUjian", {
        loading: false,
      });

      return [];
    } else {
      // let dataArray = [
      //   {
      //     waktu: result.data.data.date,
      //     kelas: result.data.data.class_room.grade,
      //     ruang_kelas: result.data.data.class_room.name,
      //     pelajar: result.data.data.class_room.total_student,
      //     nama_ujian: result.data.data.exam.name,
      //   },
      // ];
      await commit("changeJadwalUjian", {
        detailDataJadwalUjian: [
          {
            id: result.data.data.id,
            waktu: result.data.data.date,
            kelas: result.data.data.class_room.grade,
            ruang_kelas: result.data.data.class_room.name,
            pelajar: result.data.data.class_room.total_student,
            nama_ujian: result.data.data.exam.name,
            mapel: result.data.data.exam.subject.name,
          },
        ],
        loading: false,
      });
    }
  },

  cariKelasLocal({ state }, payload) {
    const { data } = state;
    if (payload && payload.q) {
      return data.kelas.find((item) => item.id === payload.q);
    }
    return {};
  },

  resetForm({ commit }) {
    commit("changeJadwalUjian", {
      errors: [],
      formData: {
        id: "",
        school_class: "",
        exam_template: "",
        class_name: "",
        password: "",
        date: "",
        school_subject_id: "",
        template_name: "",
        teacher_name: "",
        list_class_name: [],
        time_picker: "",
        start_at: {
          hour: "00",
          minute: "00",
        },
        end_at: {
          hour: "00",
          minute: "00",
        },
      },
      loading: false,
    });
  },

  async changeStatusJadwalUjian({ commit, state, dispatch }, payload) {
    if (!payload || !payload.id) return false;
    const { data } = state;

    commit("changeJadwalUjian", {
      loading: true,
    });

    const formData = {
      status: payload.status,
    };

    let result = await ApiPutRequest(
      `school/exam/kkm/exam-schedule/status/${payload.id}`,
      formData,
    );

    if (!result) {
      commit("changeJadwalUjian", {
        loading: false,
      });
      Message({
        type: "error",
        message: "Terjadi kesalahan, Coba Ulangi lagi!",
      });
      return false;
    }
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      if (data.formData.id) {
        Message({
          type: "error",
          message: message,
        });
      }
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeJadwalUjian", {
          errors: errors,
        });
      }
      commit("changeJadwalUjian", {
        loading: false,
      });
      return false;
    }

    if (data.formData.id) {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
    dispatch("getJadwalUjian");
    return result;
  },

  async changeShowScoreJadwalUjian({ commit, state }, payload) {
    if (!payload) {
      return false;
    }

    const formData = {
      // password: data.formData.password,
      school_class_id: payload.school_class_id,
      exam_template_id: payload.exam_template_id,
      date: moment(payload.date).format(),
      start_at: payload.start_at,
      end_at: payload.end_at,
      show_score: payload.show_score || 0,
    };

    try {
      commit("changeJadwalUjian", {
        loading: true,
      });
      let result = await ApiPutRequest(
        `school/exam/kkm/exam-schedule/${payload.id}`,
        formData,
      );
      commit("changeJadwalUjian", {
        loading: false,
      });
      if (result.code >= 500) {
        Message({
          type: "error",
          message: "Terjadi kesalahan, Coba Ulangi lagi!",
        });
        return false;
      }

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }
        Message({
          type: "error",
          message: message,
        });
        return false;
      }

      const { data } = state;

      const scheduleIndex = findIndexDatases(payload.id, data.items);
      data.items[scheduleIndex].show_score = payload.show_score;
      commit("changeJadwalUjian", {
        items: data.items,
      });
      return true;
    } catch (error) {
      console.log(error);

      Message({
        type: "error",
        message: "Terjadi kesalahan, Coba Ulangi lagi!",
      });
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
