import {Message} from "element-ui"
import WebschoolService from "@/services/webschoolService"
import {NO_INTERNET_MESSAGE, SERVER_ERROR_MESSAGE} from "@/services/messageService"
import {DUPLICATE_CALL_SERVER_MESSAGE} from "@/utils/messageUtils"
import {checkVw} from "@/services/internalService"
import {CONTENT_TYPE_ID} from "@/utils/WebsiteSchool"
import {isServerErrorOrNoInternet} from "@/utils/Helpers"
import Vue from "vue"

const state = {
	data: {
		ppdbTemplate: {
			isRequestError: false,
			template: "",
			detail: {},
		},
		max_child: 99,
		limit: 99,
		page: 1,
		total: 0,
		filterQ: "",
		filterCategory: "new_student",
		loading: false,
		itemsMessageTable: null,
		errorDataItems: null,
		showModal: false,
		formDataErrors: [],
		formDataRules: {
			title: [
				{
					required: true,
					message: "Harus diisi",
					trigger: "blur",
				},
			],
			category: [
				{
					required: true,
					message: "Harus diisi",
					trigger: "blur",
				},
			],
			file: [
				{
					validator: (rule, value, callback) => {
						if (state.data.formData.id) {
							callback()
						} else {
							// If no 'id', file is required
							if (!value) {
								// If file is not provided, trigger an error message
								callback(new Error("File harus diisi"))
							} else {
								// If file is provided, success
								callback()
							}
						}
					}
				},
			],
		},
		formData: {
			id: null,
			category: null,
			title: null,
			file: null,
		},
		items: [],
		columns: [
			{
				prop: "image",
				label: "File",
				width: "220",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "title",
				label: "Judul",
				minWidth: "150",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "category",
				label: "Kategori",
				width: 120,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "status",
				label: "Status",
				width: "160",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "aksi",
				label: "Aksi",
				width: checkVw(140),
				"class-name": "body-14 col-black-2",
			},
		],
	},
}

const mutations = {
	changeKlasiteSchoolPaidTemplate(state, payload) {
		state.data.ppdbTemplate = Object.assign({}, state.data.ppdbTemplate, payload)
	},

	changeKlasiteSchoolPaid(state, payload) {
		state.data = Object.assign({}, state.data, payload)
	},
	changeKlasiteSchoolPaidLoading(state, payload) {
		state.data.loading = payload
	},
	changeKlasiteSchoolPaidFormData(state, payload) {
		state.data.formData = Object.assign({}, state.data.formData, payload)
	},

	SET_FORM_DATA(state, payload) {
		const {id, title, image, body, status, category} = payload
		state.data.formData = {
			id: id,
			category: category,
			title: title,
			file: null,
			imagePreview: image,
			body: body,
			status: status,
		}
	},

	SET_ITEMS(state, payload) {
		state.data.items.push({
			id: payload.id,
			url: payload.url,
			category: payload.category,
			title: payload.title,
			author: payload.author,
			image: payload.image,
			body: payload.body,
			published_at: payload.published_at,
			updated_at: payload.updated_at,
			status: payload.status,
			additional_data: payload.additional_data,
		})
	},

	/**
	 * Proses pembaruan item di dalam state.items.
	 *
	 * @function
	 * @param {Object} state - State saat ini.
	 * @param {Object} payload - Harus berupa objek yang mengandung id item yang akan diperbarui dan data baru.
	 */
	UPDATE_ITEMS(state, payload) {
		// Mencari index item yang akan diperbarui berdasarkan id atau properti unik lainnya.
		const index = state.data.items.findIndex((item) => item.id === payload.id)

		// Jika item ditemukan, lakukan pembaruan.
		if (index !== -1) {
			state.data.items[index] = payload
			Vue.set(state.data.items, index, payload)
		}
	},

	REMOVE_ITEMS(state, id) {
		const index = state.data.items.findIndex((item) => item.id === id)

		if (index !== -1) {
			state.data.items.splice(index, 1)
		}
	},

	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
}

const actions = {
	async fetchListPamfletSchoolPaid({commit, state}) {

		if (state.data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

		const {limit, page} = state.data

		commit("changeKlasiteSchoolPaidLoading", true)
		try {
			const params = {
				limit: limit,
				page: page,
				content_type: CONTENT_TYPE_ID.school_paid,
			}
			const webschoolService = new WebschoolService()
			const result = await webschoolService.webContentGet(params)

			if (!result) {
				console.log("no response")
				return false
			}

			if (result.data.data) {
				commit("changeKlasiteSchoolPaid", {
					items: result.data.data,
					total: result.data?.meta?.total || result.data.data.length,
				})
				commit("setMessageItemsTable", null)
			} else {
				commit("setMessageItemsTable", "Data tidak ditemukan")
			}
			commit("setDataItemsError", null)
			return true
		} catch (e) {
			console.log(e)

			commit("changeKlasiteSchoolPaid", {
				items: [],
				total: 0
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("setDataItemsError", NO_INTERNET_MESSAGE)
				commit("setMessageItemsTable", NO_INTERNET_MESSAGE)
				return false
			}

			Message({
				icon: "error",
				message: e.error || "gagal mengambil data"
			})
			return false
		} finally {
			commit("changeKlasiteSchoolPaidLoading", false)
		}
	},

	async postPamfletSchoolPaid({commit, state}) {

		const {file, title} = state.data.formData

		try {
			const formData = {
				status: "draft",
				title: title,
				body: state.data.filterCategory,
				type: CONTENT_TYPE_ID.school_paid,
				image: file
			}
			const webschoolService = new WebschoolService()
			const result = await webschoolService.webContentPost(formData)
			commit("SET_ITEMS", result.data.data)
			return true
		} catch (e) {

			if (isServerErrorOrNoInternet(e)) {
				return false
			}

			Message({
				icon: "error",
				message: e.error || "gagal tambah data"
			})
			return false
		}
	},

	async putPamfletSchoolPaid({commit, state}) {

		const {file, title, id, status} = state.data.formData

		try {
			const formData = {
				id: id,
				body: state.data.filterCategory,
				status: status,
				title: title,
				type: CONTENT_TYPE_ID.school_paid,
				image: file
			}
			const webschoolService = new WebschoolService()
			const result = await webschoolService.webContentPut(formData)

			if (!result) {
				console.log("no response")
				return false
			}

			commit("UPDATE_ITEMS", result.data.data)
			return true
		} catch (e) {
			if (isServerErrorOrNoInternet(e)) {
				return false
			}
			Message({
				icon: "error",
				message: e.error || "gagal update data"
			})
			return false
		}
	},

	async updateStatusPamfletSchoolPaid({commit, state}, typeStatus) {

		const {file, title, id, body, category} = state.data.formData

		try {
			const formData = {
				id: id,
				status: typeStatus,
				category: category,
				title: title,
				type: CONTENT_TYPE_ID.ppdb,
				body: body,
				image: file
			}
			const webschoolService = new WebschoolService()
			const result = await webschoolService.webContentPut(formData)

			if (!result) {
				console.log("no response")
				return false
			}

			commit("UPDATE_ITEMS", result.data.data)
			return true
		} catch (e) {
			if (isServerErrorOrNoInternet(e)) {
				return false
			}
			Message({
				icon: "error",
				message: e.error || "gagal update data"
			})
			return false
		}
	},

	async deletePamfletSchoolPaid({commit, state}, id) {

		if (state.data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

		commit("changeKlasiteSchoolPaidLoading", true)
		try {
			const webschoolService = new WebschoolService()
			await webschoolService.webContentDelete(id)
			commit("REMOVE_ITEMS", id)
			return true
		} catch (e) {
			if (isServerErrorOrNoInternet(e)) {
				return false
			}

			Message({
				icon: "error",
				message: e.error || "gagal menghapus data"
			})
			return false
		} finally {
			commit("changeKlasiteSchoolPaidLoading", false)
		}
	},
}

const getters = {
	listSchoolPaidGetter: state => {
		const { filterCategory, filterQ } = state.data;

		return state.data.items.filter(item => {
			// Filter by category (case-insensitive)
			const matchesCategory = filterCategory
				? item.body.toLowerCase().includes(filterCategory.toLowerCase())
				: true;

			// Filter by query (case-insensitive search in title or description)
			const matchesQuery = filterQ
				? item.title.toLowerCase().includes(filterQ.toLowerCase())
				: true;

			// Return item if both conditions are true
			return matchesCategory && matchesQuery;
		});
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
